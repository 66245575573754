<template>
    <section id="infos-utilisateur">

        <!-- Modifier mon profil -->
        <div class="general" v-show="general_visible">
          <v-row
            class="text-center"
            align="center"
            justify="center">
            <v-col>
              <h3 class="titre">Modifier mon profil</h3>
              <div class="content">
                <div class="input-group inline-block">
                    <label for="civilite">Civilité</label>
                    <select
                        id="civilite"
                        name="civilite"
                        v-model="utilisateur.sexe">
                        <option value="F">Madame</option>
                        <option value="M">Monsieur</option>
                        <option value="X">Ni l'un ni l'autre</option>
                    </select>
                </div>
                <div class="input-group inline-block">
                    <label for="prenom">Prénom</label>
                    <input
                        id="prenom"
                        name="prenom"
                        type="text"
                        :class="{ 'input-erreur' : erreur && !utilisateur.prenom}"
                        v-model="utilisateur.prenom"
                        placeholder="Michel" />
                </div>
                <div class="input-group inline-block">
                    <label for="nom">Nom</label>
                    <input
                        id="nom"
                        name="nom"
                        type="text"
                        :class="{ 'input-erreur' : erreur && !utilisateur.nom}"
                        v-model="utilisateur.nom"
                        placeholder="Leonst" />
                </div>
                <div class="input-group inline-block" >
                    <label for="nom">Date de naissance</label>
                    <input
                        id="date_naissance"
                        name="date_naissance"
                        type="text"
                        readonly 
                        :value="utilisateur.date_naissance | formatDate"
                        v-on:focus="date_picker_visible = true"
                        :class="{ 'input-erreur' : erreur && !utilisateur.date_naissance}"/>
                </div>
                <div class="input-group inline-block" v-show="date_picker_visible">
                  <v-row
                    class="text-center"
                    align="center"
                    justify="center">
                    <v-col>
                      <v-date-picker
                          color="#50d8af"
                          locale="fr-fr"
                          @click:date="date_picker_visible = false"
                          v-model="utilisateur.date_naissance" />
                    </v-col>
                  </v-row>
                  <v-row
                    class="text-center"
                    align="center"
                    justify="center">
                    <v-col>
                      <a v-on:click="date_picker_visible = false">Masquer le  calendrier</a>
                    </v-col>
                  </v-row>
                </div>
                <div class="input-group inline-block">
                    <label for="civilite">Nationalité</label>
                    <select
                        id="nationalite"
                        name="nationalite"
                        v-model="utilisateur.nationalite"
                        :class="{ 'input-erreur' : erreur && !utilisateur.nationalite}">
                        <option value="FR">France</option>
                        <option value="BE">Belgique</option>
                        <option value="LU">Luxembourg</option>
                        <option value="CH">Suisse</option>
                        <option>------------</option>
                        <option v-for="pays in liste_pays" :key="pays.code" :value="pays.code">{{ pays.name }}</option>
                    </select>
                </div>
                <div class="input-group inline-block">
                    <label for="mobile">Numéro mobile</label>
                    <input
                        id="mobile"
                        name="mobile"
                        type="text"
                        v-model="utilisateur.mobile"
                        placeholder="Exemple : 06.12.34.56.78" />
                </div>
                <div class="input-group inline-block">
                    <label for="adresse">Adresse</label>
                    <input
                        id="adresse"
                        name="adresse"
                        type="text"
                        v-model="utilisateur.adresse"
                        placeholder="Exemple : 1 Place de la Liberté" />
                </div>
                <div class="input-group inline-block">
                    <label for="ville">Ville</label>
                    <input
                        id="ville"
                        name="ville"
                        type="text"
                        v-model="utilisateur.ville"
                        placeholder="Exemple : Brest" />
                </div>
                <!-- @TODO Etablir un filtre par CP -->
                <div class="input-group inline-block">
                    <label for="code_postal">Code postal</label>
                    <input
                        id="code_postal"
                        name="code_postal"
                        type="text"
                        v-model="utilisateur.code_postal"
                        placeholder="Exemple : 29200" />
                </div>
                <div class="input-group inline-block">
                    <label for="civilite">Pays de Résidence</label>
                    <select
                        id="pays_residence"
                        name="pays_residence"
                        v-model="utilisateur.pays_residence"
                        :class="{ 'input-erreur' : erreur && !utilisateur.pays_residence}">
                        <option value="FR">France</option>
                        <option value="BE">Belgique</option>
                        <option value="LU">Luxembourg</option>
                        <option value="CH">Suisse</option>
                        <option>------------</option>
                        <option v-for="pays in liste_pays" :key="pays.code" :value="pays.code">{{ pays.name }}</option>
                    </select>
                </div>
                <v-row
                  class="text-center"
                  align="center"
                  justify="center">
                  <v-col>
                    <div class="bouton">
                      <input type="button"
                        v-on:click="valider"
                        value="Valider"
                        v-show="!loaded"
                        class="btn">
                      <button  
                        class="btn centre valide"
                        v-show="loaded"
                        disabled="disabled">
                        Modification effectuée <span class="mdi mdi-check"></span>
                      </button>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>  
        </div>

        <!-- Ma photo -->
        <div class="photo" v-show="photo_visible" >
          <v-row
            class="text-center"
            align="center"
            justify="center">
            <v-col>
              <h3 class="titre">Ma photo de profil actuelle</h3>
              <div class="input-group" id="photoInput">
                <div style="margin: auto;">
                  <form method="post">
                    <avatar />
                  </form>
                </div>
              </div>
            </v-col>
          </v-row>    
        </div>

        <!-- Mon numéro de téléphone -->
        <div class="telephone" v-show="telephone_visible">
          <v-row
            class="text-center"
            align="center"
            justify="center">
            <v-col>
              <label for="telephone"><h3 class="titre">Mon numéro de téléphone</h3></label>
              <div class="input-group" id="telephoneInput">
                <input 
                  id="telephone"
                  name="telephone"
                  type="text"
                  class="text-center"
                  placeholder="Exemple: 06.67.89.75.60" />
                  <!--
                  :disabled="utilisateur.telephone_infos.id"
                  v-model="utilisateur.telephone_infos.numero" />
                  -->
              </div>
              <!--
                <button 
                class="btn"
                v-if="!utilisateur.telephone_infos.id"
                v-on:click="saveTelephone">
                Enregistrer
              </button>
              <div
                class="input-group"
                v-else-if="utilisateur.telephone_infos.is_verifie == 0">
                <h6>Renseignez avec le code que vous aller recevoir par SMS.</h6>
                <div>
                  <input 
                    id="telephone_code"
                    name="telephone_code"
                    type="text"
                    class="text-center"
                    placeholder="Exemple : 7777"
                    v-model="telephone_code"
                    style="width:250px; margin-right:36px" />
                </div>
                <button 
                  class="btn"
                  v-on:click="verifierTelephone">
                  Envoyer
                </button>
              </div>
              <div v-else>
                <v-row
                  align="center"
                  justify="space-around"
                >
                  <v-col cols="2">
                    <span class="mdi mdi-24px mdi-check-circle" style="color: green;"></span>
                  </v-col>
                  <v-col cols="8">
                    Votre numéro de téléphone a bien été certifié
                  </v-col>
                </v-row>
              </div>
              -->
            </v-col>
          </v-row>
        </div>

        <!-- Mes documents -->
        <div class="documents" v-show="documents_visible">
          <v-row
            class="text-center"
            align="center"
            justify="center">
            <v-col cols="12"
              lg="12"
              md="12"
              sm="12">
              <h3 class="titre">Mes documents</h3>
              <div class="documents-container">
                <p style="text-align: justify">
                  Nous voulons garantir un environnement sécurisé à nos utilisateurs. C'est la raison 
                  pour laquelle nous vérifions l'identité des conducteurs. Sans la vérification de 
                  l'identité des conducteurs sur la plateforme, nous nous serons pas en mesure d'effectuer
                  de virement vers votre compte bancaire.
                </p>
                <v-row 
                  v-for="(typeDocument, index) in liste_types_documents"
                  :key="index"
                  align="center"
                  justify="center"
                  style="min-height: 90px; background-color: #fff; margin: 18px auto; border-radius: 12px; border: 1px solid #ccc;">
                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                    class="text-left"
                  >
                    <span :class="'mdi ' + typeDocument.icon"></span>
                    <span style="display:inline-block; margin-left: 12px">{{ typeDocument.nom }}</span>
                  </v-col>
                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <!-- <div
                      v-if="utilisateur.documents && utilisateur.documents[typeDocument.code] != null && utilisateur.documents[typeDocument.code].created_at != null">
                      <span>Ajouté le {{ utilisateur.documents[typeDocument.code].created_at | formatDate }}</span>
                      <br />
                      <span 
                        class="text-left" 
                        v-if="utilisateur.documents[typeDocument.code] != null && utilisateur.documents[typeDocument.code].date_verification != null"
                        style="color: green">
                        Vérifié &nbsp; <i class="mdi mdi-check" />
                      </span>
                      <span
                        class="text-left" 
                        v-if="utilisateur.documents[typeDocument.code] != null && utilisateur.documents[typeDocument.code].date_verification == null"
                        style="color: orange">
                        En cours de vérification &nbsp; <i class="mdi mdi-dots-horizontal" />
                      </span>
                    </div> -->
                    <div v-if="hasDocument(typeDocument)">
                      <span>Ajouté le {{ documentCreatedAt(typeDocument) | formatDate }}</span>
                      <br />
                      <span :class="{ 'text-left': true, 'green': isVerified(typeDocument), 'orange':!isVerified(typeDocument) }">
                        {{ verificationStatus(typeDocument) }} &nbsp; <i :class="mdiIcon" />
                      </span>
                    </div>
                    <div v-else>
                      <b>Télécharger le document</b><br />
                      <label :for="'document[' + typeDocument.code + ']'">
                        Prenez une photo bien cadrée de votre document [{{ typeDocument.nom }}]
                      </label>
                      <div>
                        <input 
                          :name="typeDocument.code" 
                          type="file"
                          @change="selectDocument" />
                      </div>
                      <div style="margin: 12px auto;">
                        <input
                          class="btn"
                          type="button"
                          value="Envoyez"
                          v-on:click="envoyerDocument(typeDocument.code)"
                        />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div> 
            </v-col>
          </v-row>          
        </div>

        <!-- Mes véhicules -->
        <div class="vehicules" v-show="vehicules_visible">
          <v-row
            v-show="!nv_vehicule_visible"
            class="text-center"
            align="center"
            justify="center">
            <v-col>
              <h3 class="titre">Mes véhicules</h3>
              <br />
              <input type="button"
                value="Ajoutez un Vehicule"
                v-on:click="nv_vehicule_visible = true"
                class="btn pointer">
              <br />
              <v-container class="vehicules-container" id="vehicules-content">
                <v-row
                  class="text-center"
                  align="center"
                  justify="center"
                  v-show="!nv_vehicule_visible">
                  <v-col
                    cols="12"
                    lg="4"
                    md="4"
                    sm="6"
                    v-for="vehicule in utilisateur.vehicules"
                    :key="vehicule.id">
                    <v-card
                      class="vehicule-data">
                        <v-card-title>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center">
                              <v-col>
                                <span>{{ vehicule.marque }} - {{ vehicule.modele }}</span>
                              </v-col>
                            </v-row>
                        </v-card-title>

                          <v-card-subtitle>
                            <h5>{{ vehicule.couleur }}</h5>
                          </v-card-subtitle>
                          
                          <v-card-text>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center">
                              <v-col>
                                <!--<button class="hoverbtn" type="button">
                                  <span style="color: #17a2b8">Ajouter une photo du vehicule</span>
                                </button><br>
                                <button class="hoverbtn" type="button">
                                  <span style="color: #17a2b8">Modifier les info du vehicule</span>
                                </button><br>-->
                                <button class="hoverbtn" type="button">
                                  <span style="color: #17a2b8" v-on:click="supprimer(vehicule.id)">Supprimer vehicule</span>
                                </button><br>
                              </v-col>
                            </v-row>
                          </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row v-show="nv_vehicule_visible"
            class="content text-center"
            align="center"
            justify="center">
            <v-col
              cols="12">
              <v-icon
                v-on:click="nv_vehicule_visible = false">
                mdi-arrow-left
              </v-icon>
              <h3 class="titre">Mon nouveau véhicule</h3>
              <br />
              <div class="input-group inline-block">
                <v-autocomplete
                  v-model="vehicule.marque"
                  :items="liste_marques"
                  placeholder="Saisissez la marque du véhicule"
                  dense
                  filled />
              </div>
              <div class="input-group inline-block">              
                <v-autocomplete
                  v-model="vehicule.modele"
                  :items="liste_modeles"
                  v-show="vehicule.marque"
                  placeholder="Saisissez la modèle du véhicule"
                  dense
                  filled />
              </div>
              <div class="input-group inline-block">
                <label for="nom">Couleur</label>
                <input
                    id="couleur"
                    name="couleur"
                    v-model="vehicule.couleur"
                    type="text"
                    placeholder="Bleu Nuit" />
              </div>
              <!--
              <div class="input-group inline-block">
                <label>Nombre de places</label>
                <div class="chiffres">
                  <div 
                    class="btn centre chiffre"
                    :class="{ 'valide': vehicule.nb_places == chiffre, 'neutre': vehicule.nb_places != chiffre }"
                    v-for="chiffre in 4"
                    :key="chiffre"
                    v-on:click="vehicule.nb_places = chiffre">
                    {{ chiffre }}
                  </div>
                </div>
              </div>
              -->
              <div class="input-group bouton">
                <input type="button"
                  value="Valider"
                  v-on:click="ajouterVehicule"
                  v-show="!loaded"
                  class="btn pointer">
                <button  
                  class="btn centre valide"
                  v-show="loaded"
                  disabled="disabled">
                  Ajout du véhicule effectuée <span class="mdi mdi-check"></span>
                </button>
              </div>
            </v-col>
          </v-row>
        </div>

    </section>
</template>

<style>

#infos-utilisateur > div {
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
}

/* #infos-utilisateur div .photo{
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
}

#infos-utilisateur div .documents{
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
} 

#infos-utilisateur div .vehicules{
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
}

#infos-utilisateur div .telephone{
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
} */

#infos-utilisateur .vehicule-container{
  display: center;
  text-align: left;
}

#infos-utilisateur input[type=text],
#infos-utilisateur textarea,
#infos-utilisateur select {
  background-color: white;
}

#infos-utilisateur .hoverbtn:hover{
  cursor: pointer;
  background: #cce7e8;  
}

#info-utilisateur .titre {
  margin-bottom: 16px;
}

#infos-utilisateur .content {
  padding-top: 36px;
}

#infos-utilisateur .content label,
#infos-utilisateur .content a {
  width: 34%;
  /* text-align: left; */
  height: 39px;
  padding-top: 9px;
}

#infos-utilisateur .content input[type=button] {
  width: 39%;
}

#infos-utilisateur .content input[type=text],
#infos-utilisateur .content select {
  width: 63%;
  border: 1px solid #ccc;
  border-radius: 12px;
  height: 39px;
  margin: 0;
  padding-left: 12px;
}

#infos-utilisateur .content input:focus {
  border: 2px solid #17a2b8;
}

#infos-utilisateur .content input[type=button] {
  margin: 12px auto;
}

#infos-utilisateur .content .input-group, 
#infos-utilisateur .input-group {
  margin: 16px auto;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
}

#infos-utilisateur .content .chiffres {
  width: 500px
}

#infos-utilisateur .content .chiffres .chiffre {
  min-width: 50px
}

#telephoneInput {
  width: 50vw;
  margin: auto;
  padding-left: 12px;
}

#info .documents {
  text-align: left;
}

#infos-utilisateur .documents .documents-container {
  padding: 25px;
}

#infos-utilisateur .documents .document.container {
  text-align: center;
  margin-bottom: 18px;
}

#infos-utilisateur .documents .document.container input[type=file] {
  margin: 3px auto 12px;
  height: 39px;
  border: 1px solid;
  border-radius: 7px;
  padding: 7px;
  background-color: white;

}
</style>

<script>
import axios from 'axios';
import constantes from '../../constantes';
import listePays from '../../util/liste_pays';
import listeVoitures from '../../util/liste_voitures';
import listeTypesDocuments from '../../util/liste_types_documents';
import Avatar from '../../components/Avatar';
import { mapGetters } from "vuex";
import { USER_REQUEST } from "../../store/actions/user";

export default {
  name: 'infos-utilisateurs',
  filters: {
    formatDate(value) {
      if (!value) return '';
      const options = {
        year: 'numeric', 'month': 'long', 'day': 'numeric'
      }
      return new Date(value).toLocaleDateString('fr-FR', options);
    }
  },
  components: { Avatar },
  data() {
    return {
      message: '',
      erreur: false,
      loaded: false,
      is_premiere_visite: false,
      utilisateur: {
        documents: []
      },
      vehicule: {},
      telephone_code: null,
      general_visible: true,
      date_picker_visible: false,
      photo_visible: false,
      telephone_visible: false,
      documents_visible: false,
      vehicules_visible: false,
      nv_vehicule_visible: false,
      vehicule2_existant: false,
      liste_pays: listePays,
      documents: {},
      liste_voitures: listeVoitures,
      liste_types_documents: listeTypesDocuments,
      /* eslint-disable */
      regex_telephone: /^(06|07)[0-9]{8}$/,
      /* eslint-enable */
    };
  },
  mounted() {

    this.utilisateur = this.profile;
    this.$root.$emit("start_loading");

    // Récupération des informations relatives à l'utilisateur
    this.$api.get('/utilisateurs/' + this.profile.id + '/full')
    .then(res => {
      // console.log(res.data.data);
      // Affectation du résultat dans la variable utilisateur du composant
      this.utilisateur = res.data.data;

      // Cas particuliers du téléphone
      if (this.utilisateur.telephone_infos && this.utilisateur.telephone_infos.numero) {
        this.utilisateur.mobile = this.utilisateur.telephone_infos.numero;
      }
    })
    .finally(() => this.$root.$emit("hide_loading"));

    this.$root.$emit("start_loading");
    // Récupération des informations complémentaires relatives à l'utilisateur
    this.$api.get('/utilisateurs/' + this.profile.id + '/info')
    .then(res => {
      // Relation one-to-on dans le modèle de données
      // Si ces informations obligatoire ne sont pas remplies, 
      // on déduit qu'il s'agit d'une première visite (ou modification)
      if (res.data.length == 0) {
        this.is_premiere_visite = true;
      }
    })
    .finally(() => this.$root.$emit("hide_loading"));

    this.general_visible = !this.$route.query.info || this.$route.query.info == 'general';
    this.telephone_visible = this.$route.query.info == 'telephone';
    this.documents_visible = this.$route.query.info == 'documents';
    this.vehicules_visible = this.$route.query.info == 'vehicules';

    this.utilisateur.vehicule2 != null ? this.vehicule2_existant == true : "Vous n'avez pas enregistré de véhicule secondaire.";

  },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
    profile() {
      return this.getProfile;
      },
    liste_marques() {
      var marques = [];
      for (let i=0; i<this.liste_voitures.length; i++) {
        if (marques[marques.length - 1] != this.liste_voitures[i].marque) {
          marques.push(this.liste_voitures[i].marque);
        }
      }
      return marques;
    },
    liste_modeles() {
      var modeles = [];
      for (let i=0; i<this.liste_voitures.length; i++) {
        if (this.vehicule.marque == this.liste_voitures[i].marque) {
          modeles.push(this.liste_voitures[i].modele);
        }
      }
      return modeles;
    }
  },

  methods: {
    hasDocument(typeDocument) {
      return this.utilisateur.documents && typeDocument && typeDocument.code && this.utilisateur.documents[typeDocument.code] && this.utilisateur.documents[typeDocument.code].created_at;
    },
    documentCreatedAt(typeDocument) {
      if (this.utilisateur.documents && typeDocument && typeDocument.code && this.utilisateur.documents[typeDocument.code]) {
        return this.utilisateur.documents && typeDocument && typeDocument.code && this.utilisateur.documents[typeDocument.code].created_at;
      }
      else {
        return null;
      }
    },
    isVerified(typeDocument) {
      if (this.utilisateur.documents && typeDocument && typeDocument.code && this.utilisateur.documents[typeDocument.code]) {
        return this.utilisateur.documents && typeDocument && typeDocument.code && this.utilisateur.documents[typeDocument.code].is_verifie === 1;
      }
      else {
        return false;
      }
    },
    verificationStatus(typeDocument) {
      return this.isVerified(typeDocument) ? 'Vérifié' : 'En cours de vérification';
    },
    mdiIcon() {
      return this.isVerified(typeDocument) ? 'di mdi-check' : 'di mdi-dots-horizontal';
    },
    saveTelephone: function() {
      console.log('saveTelephone');
      if (this.utilisateur.mobile && this.utilisateur.mobile.length > 0) {
        this.utilisateur.mobile = this.utilisateur.mobile
          .replaceAll('.', '')
          .replaceAll(';', '')
          .replaceAll('-', '')
          .replaceAll(' ', '');
      }
      if (!this.utilisateur.telephone_infos 
        || this.utilisateur.telephone_infos.numero != this.utilisateur.mobile) { 
        
        if (this.regex_telephone.test(this.utilisateur.mobile)) {
          var bodyFormData = new FormData();
          bodyFormData.append('telephone', this.utilisateur.mobile);

          this.$api.post('/utilisateurs/' + this.profile.id + '/telephone', bodyFormData)
          .then(res => {
            this.utilisateur.telephone_infos = res.data;
            this.$root.$emit('print_dialog_changement_mobile');
          })
          .catch(err => {
            this.traitementErreur(err);
          });
        } else {
          // 
          this.erreur = true;
          this.message = "Le numéro de téléphone n'a pas le format requis.";
        }
      }
    },
    /*
    verifierTelephone: function() {
      console.log('this.telephone_code', this.telephone_code);
      console.log('this.utilisateur.telephone_infos.numero', this.utilisateur.telephone_infos.numero);
      if (this.utilisateur.telephone_infos.numero && this.telephone_code) {
        var bodyFormData = new FormData();
        bodyFormData.append('numero', this.utilisateur.telephone_infos.numero);
        bodyFormData.append('code', this.telephone_code);

        this.$api.post('/utilisateurs/' + this.profile.id + '/telephone/verifier', bodyFormData)
        .then(res => {
          this.utilisateur.telephone_infos = res.data;
          this.traitementSucces();
        })
        .catch(err => {
          this.traitementErreur(err);
        });
      }
    },*/
    valider: function() {
      // Vérification des champs obligatoires
      if (
        !this.utilisateur.nom
        || !this.utilisateur.prenom
        || !this.utilisateur.date_naissance
        || !this.utilisateur.nationalite
        || !this.utilisateur.pays_residence
        || !this.utilisateur.mobile
      ) {
        // Erreur & message
        this.erreur = true;
        this.message = "Un des champs requis n'est pas renseigné. Veuillez le remplir pour valider la saisie."
      }

      // Pas d'erreur donc on continue le script d'enregistrement des données
      if (!this.erreur) {
        this.$root.$emit("start_loading");

        // Création du request body utile pour les appels methode PUT url-encoded 
        const requestBody = {
          nom: this.utilisateur.nom,
          prenom: this.utilisateur.prenom,
          sexe: this.utilisateur.sexe,
          date_naissance: this.utilisateur.date_naissance
        };

        // Envoi des informations basique de l'utilisateur
        // Celle-ci sont forcément modifié car enregistrées la première fois à l'inscription
        axios({
          method: 'put',
          url: constantes.apiBaseUrl + '/utilisateurs/' + this.profile.id,
          data: new URLSearchParams(requestBody),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .then(() => {
          // Si première viste alors on crée une ligne d'information complémentaires
          // au sujet de l'utilisateur
          if (this.is_premiere_visite) {
            var bodyFormData = new FormData();
            bodyFormData.append('nationalite', this.utilisateur.nationalite);
            bodyFormData.append('pays_residence', this.utilisateur.pays_residence);
            if (this.utilisateur.adresse) {
              bodyFormData.append('adresse', this.utilisateur.adresse);
            }
            if (this.utilisateur.code_postal) {
              bodyFormData.append('code_postal', this.utilisateur.code_postal);
            }
            if (this.utilisateur.ville) {
              bodyFormData.append('ville', this.utilisateur.ville);
            }

            this.$api.post('/utilisateurs/' + this.profile.id + '/info', bodyFormData)
            .then(() => {
              // Le cas particulier du numéro de téléphone
              this.saveTelephone();
              this.traitementSucces();
              this.is_premiere_visite = false;
            })
            .catch(err => {
              this.traitementErreur(err);
            });
          }
          // Si ce n'est pas une première visite, on modifie la ligne d'informations
          // complémentaires déjà créée.
          else {
            let requestInfosBody = {
              nationalite: this.utilisateur.nationalite,
              pays_residence: this.utilisateur.pays_residence
            };
            if (this.utilisateur.adresse) {
              requestInfosBody.adresse = this.utilisateur.adresse;
            }
            if (this.utilisateur.code_postal) {
              requestInfosBody.code_postal = this.utilisateur.code_postal;
            }
            if (this.utilisateur.ville) {
              requestInfosBody.ville = this.utilisateur.ville;
            }

            // on enregistre les informations
            axios({
              method: 'put',
              url: constantes.apiBaseUrl + '/utilisateurs/' + this.profile.id + '/info/' + this.profile.id,
              data: new URLSearchParams(requestInfosBody),
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then(() => {
              // Le cas particulier du numéro de téléphone
              this.saveTelephone();
              this.traitementSucces();
            })
            .catch(err => {
              this.traitementErreur(err);
            });
          }

          this.$store.dispatch(USER_REQUEST);
        })
        .catch(err => {
          this.traitementErreur(err);
        })
        .finally(() => {
          this.$root.$emit("hide_loading");
        });
      }
      
    },

    traitementErreur: function(err) {
      console.error(err);
      this.erreur = true;
      this.message = "Le service est momentanément inaccessible. Veuillez réessayer plus tard.";
    },

    traitementSucces: function() {
      this.succes = true;
      this.loaded = true;

      var self = this;
      setTimeout(function() {
        self.loaded = false;
      }, 7000);
      this.message = "Votre profil utilisateur a merveilleusement été modifié.";
    },

    supprimer: function(vehicule_id) {

      this.$api.delete('/utilisateurs/' + this.profile.id + '/vehicules/' + vehicule_id)
      .then(() => {
        this.utilisateur.vehicules = this.utilisateur.vehicules.filter(v =>  v.id != vehicule_id);
      })
    },

    ajouterVehicule: function() {
      
      this.$root.$emit("start_loading");

      var bodyFormData = new FormData();
        bodyFormData.append('marque', this.vehicule.marque);
        bodyFormData.append('modele', this.vehicule.modele);
        bodyFormData.append('couleur', this.vehicule.couleur);
        bodyFormData.append('utilisateur_id', this.profile.id);

      this.$api.post('/vehicules', bodyFormData)
      .then(() => {
        this.succes = true;
        this.message = "Votre véhicule a bien été enregistré.";
        this.$router.go();
        this.nv_vehicule_visible = false;
      })
      .finally(() => this.$root.$emit("hide_loading"));
    },
    selectDocument: function(event) {
      var typeDocumentCode = event.target.name;
      this.documents[typeDocumentCode] = event.target.files[0];
    },
    envoyerDocument: function(typeDocumentCode) {
      var bodyFormData = new FormData();
      bodyFormData.append('type_doc_code', typeDocumentCode);
      bodyFormData.append('document', this.documents[typeDocumentCode]);

      this.$api.post('/utilisateurs/' + this.profile.id + '/document', bodyFormData)
      .then(res => {
        this.utilisateur.documents[typeDocumentCode] = res.data;
      })
      .catch(err => this.traitementErreur(err))
      .finally();
    }
  },

  updated() {
    this.photo_visible = this.$route.query.info == 'photo';
    this.telephone_visible = this.$route.query.info == 'telephone';
    this.documents_visible = this.$route.query.info == 'documents';
    this.vehicules_visible = this.$route.query.info == 'vehicules';
  },
};

</script>