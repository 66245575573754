<template>
  <v-container>
    <v-row style="margin-bottom: 12px">
      <h2>Utilisateurs</h2>
      <v-col
        v-for="({ actionIcon, actionText, ...attrs }, i) in utilisateurStats"
        :key="i"
        cols="12"
        md="6"
        lg="3"
      >
        <material-stat-card v-bind="attrs">
          <template #actions>
            <v-icon
              class="mr-2"
              small>{{actionIcon}}
            </v-icon>
            <div class="text-truncate">
              {{ actionText }}
            </div>
          </template>
        </material-stat-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3">
        <Pie
          :data="camembertDesGenres.data"
        />
      </v-col>
    </v-row>
    <v-row style="margin-bottom: 12px">
      <h2>Recherches</h2>
      <v-col cols="12">
        <v-row v-if="histogrammeRecherches" style="margin-bottom: 12px">
          <LineChartGenerator 
            :data="courbeRecherchesParDates.data" 
            :options="courbeRecherchesParDates.options" />
        </v-row>
        <v-row>
          <Bar 
            :data="histogrammeRecherches.data" 
            :options="histogrammeRecherches.options" />
        </v-row>
      </v-col>
    </v-row>
    <v-row style="margin-bottom: 12px">
      <v-col
        v-for="({ actionIcon, actionText, ...attrs }, i) in rechercheStats"
        :key="i"
        cols="12"
        md="6"
        lg="4"
      >
        <material-stat-card v-bind="attrs">
          <template #actions>
            <v-icon
              class="mr-2"
              small>{{actionIcon}}
            </v-icon>
            <div class="text-truncate">
              {{ actionText }}
            </div>
          </template>
        </material-stat-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MaterialStatCard from '../../components/MaterialStatCard.vue';

import { Bar, Line as LineChartGenerator, Pie } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, ArcElement, BarElement, LineElement, PointElement, Title, Tooltip, Legend)

export default {
  components: { MaterialStatCard, Bar, LineChartGenerator, Pie },
  data() {
    return {
      utilisateurs: [],
      utilisateurStats: [],
      recherches: [],
      rechercheStats: [],
      histogrammeRecherches: {
        data: {
          labels: [],
          datasets: [{ data: [] }]
        },
        options: {
          responsive: true
        }
      },
      courbeRecherchesParDates: {
        data: {
          labels: [],
          datasets: [{ data: [] }]
        },
        options: {
          responsive: true
        }
      },
      camembertDesGenres: {
        data: {
          labels: ['Hommes', 'Femmes'],
          datasets: [{ data: [50, 50] }]
        },
        options: {
          responsive: true
        }
      }
    };
  },
  mounted() {
    this.$root.$emit("start_loading");
    this.$api
      .get("/utilisateurs")
      .then((res) => {
        this.utilisateurs = res.data;
        this.utilisateurStats.push(
          {
            actionIcon: 'mdi-users',
            actionText: 'Tous les utilisateurs...',
            color: '#FD9A13',
            icon: 'mdi-sofa-single',
            title: 'Utilisateurs',
            value: `${this.utilisateurs.length}`,
          });
        this.utilisateurStats.push(
          {
            actionIcon: 'mdi-users',
            actionText: 'Tous les ages',
            color: '#FD9A13',
            icon: 'mdi-sofa-single',
            title: 'Age moyen',
            value: `${Math.floor(
              this.utilisateurs.filter(u => u.date_naissance && u.date_naissance !== '0000-00-00')
              .map(u => this.$options.filters.dateToAge(u.date_naissance))
              .reduce((a, b) => a + b, 0) 
              / this.utilisateurs.length)}`,
          });
        this.utilisateurStats.push(
          {
            actionIcon: 'mdi-users',
            actionText: 'Activation',
            color: '#FD9A13',
            icon: 'mdi-sofa-single',
            title: 'Nb cpte actif',
            value: `${Math.round(this.utilisateurs.filter(u => u.activated === 1).length / this.utilisateurs.length * 100, 2)} %`,
          });
        this.camembertDesGenres.data = {
          labels: ['Hommes', 'Femme', 'X'],
          datasets: [{ 
            backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
            data: [
              this.utilisateurs.filter(u => u.sexe === 'M').length,
              this.utilisateurs.filter(u => u.sexe === 'F').length,
              this.utilisateurs.filter(u => u.sexe === 'X').length
            ] 
          }]
        };
      })
      .finally(() => this.$root.$emit("hide_loading"));
      
    this.$api
      .get("/admin/recherches")
      .then((res) => {
        res.data = res.data.filter(recherche => recherche.localite !== null && recherche.moment !== null);
        this.recherches = res.data;

        let labelDates = [...new Set(this.recherches.map(recherche => recherche.moment.substring(0, 10)))];
        labelDates = labelDates.length > 28 ? labelDates.slice(-28) : labelDates;

        this.recherches = this.recherches.filter(r => labelDates.includes(r.moment.substring(0, 10)));

        const labels = [...new Set(this.recherches.map(recherche => recherche.localite))];
        const labelsDepart = [...new Set(
          this.recherches.filter(recherche => recherche.nom_commune_depart && recherche.nom_commune_depart.toLowerCase() != 'null')
            .map(recherche => recherche.nom_commune_depart)
          )
        ];
        const labelsArrivee = [...new Set(this.recherches.map(recherche => recherche.nom_commune_arrivee))];
        
        this.histogrammeRecherches = {
          data: {
            labels: labels,
            datasets: [
              { 
                label: 'Villes origines des recherches',
                backgroundColor: '#f87979',
                data: labels.map(label => this.recherches.filter(recherche => recherche.localite === label).length)
              }
            ]
          },
          options: {
            responsive: true
          }
        };

        this.courbeRecherchesParDates = {
          data: {
            labels: labelDates,
            datasets: [
              { 
                label: 'Nombre de recherches de trajets par date',
                backgroundColor: '#f87979',
                data: labelDates.map(label => this.recherches.filter(recherche => recherche.moment.substring(0, 10) === label).length)
              }
            ]
          },
          options: {
            responsive: true
          }
        };

        function compareRecherches(label1, label2) {
          const c1 = label1.split('_')[1];
          const c2 = label2.split('_')[1];

          return c2 - c1;
        }

        this.rechercheStats.push({
          actionIcon: 'mdi-town',
          actionText: 'Ville générant +',
          color: '#FD9A13',
          icon: 'mdi-sofa-single',
          title: '',
          value: `${labels.map(label => label + '_' + this.recherches.filter(recherche => recherche.localite === label).length).sort(compareRecherches)[0]}`,
        });

        this.rechercheStats.push({
          actionIcon: 'mdi-town',
          actionText: 'Top ville départ',
          color: '#FD9A13',
          icon: 'mdi-sofa-single',
          title: '',
          value: `${labelsDepart.map(label => label + '_' + this.recherches.filter(recherche => recherche.nom_commune_depart && recherche.nom_commune_depart.toLowerCase() != 'null' && recherche.nom_commune_depart === label).length).sort(compareRecherches)[0]}`,
        });

        this.rechercheStats.push({
          actionIcon: 'mdi-town',
          actionText: 'Top ville arrivée',
          color: '#FD9A13',
          icon: 'mdi-sofa-single',
          title: '',
          value: `${labelsArrivee.map(label => label + '_' + this.recherches.filter(recherche => recherche.nom_commune_arrivee === label).length).sort(compareRecherches)[0]}`,
        });
      })
      .finally(() => this.$root.$emit("hide_loading"));
  }
}
</script>
