<template>
  <div>
    <v-row
      align="center"
      justify="start"
      v-if="otps.length > 0"
    >
      <v-col
        cols="3"
        v-for="otp in otps"
        :key="otp.id"
        style="border: 1px solid #ccc"
      >
        <div>{{ otp.prenom }} {{ otp.nom }}</div>
        <div>{{ otp.numero }}</div>
        <div><b>{{ otp.token }}</b></div>
        <div v-if="otp.sent == 0">
          <button 
            @click="$root.$emit('envoyer_otp', otp)"
          >
            Envoyer le code
          </button>
        </div>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-else
    >
      <p>Il n'y a pas de code vérification à envoyer.</p>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      otps: [],
    };
  },
  mounted() {
    this.$root.$emit("start_loading");

    this.$api.get("/admin/otps-mobiles")
    .then(res => {
      this.otps = res.data;
    })
    .finally(() => this.$root.$emit("hide_loading"));
  },
}
</script>

