<template>
    <section id="activite-utilisateur">
        <!-- Nous découvrir -->
        <!-- S'amuser -->
        <div v-show="fun_visible">
            <div>
                <h1 class="title lv1">Amusez vous</h1>
                <p>Choisissez une activitée et amusez vous!</p>
                <v-row id="class-game">
                    <v-col>
                        <h3>Les plus choisis</h3>
                        <v-card
                            flat
                            tile
                        >
                            <v-window
                                v-model="integration"
                            >
                                <v-window-item
                                    v-for="n in taille"
                                    :key="`card-${n}`"
                                >
                                    <v-card
                                    color="grey"
                                    height="200"
                                    >
                                        <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <h1
                                                style="font-size: 5rem;"
                                                class="white--text"
                                            >
                                            jeux {{ n }}
                                            </h1>
                                        </v-row>
                                    </v-card>
                                </v-window-item>
                            </v-window>

                            <v-card-actions class="justify-space-between">
                                <v-btn
                                    text
                                    @click="prev"
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-item-group
                                    v-model="integration"
                                    class="text-center"
                                    mandatory
                                >
                                    <v-item
                                        v-for="n in taille"
                                        :key="`btn-${n}`"
                                        v-slot="{ active, toggle }"
                                    >
                                        <v-btn
                                            :input-value="active"
                                            icon
                                            @click="toggle"
                                        >
                                            <v-icon>mdi-record</v-icon>
                                        </v-btn>
                                    </v-item>
                                </v-item-group>
                                <v-btn
                                    text
                                    @click="next"
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>    
                    <v-col cols="4">
                        <v-card>
                            <v-img
                                src="https://media.istockphoto.com/photos/hangman-chalk-writing-on-old-grunge-chalkboard-background-with-space-picture-id1146252014?k=20&m=1146252014&s=612x612&w=0&h=oLeiDUaywVraqT3GBDcEBRoi3Chf7N3OXQWnL5_ROPU="
                                height="125"
                                class="grey darken-4"
                            ></v-img>
                            <v-card-title class="text-h6 text-center">
                                LE PENDU
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card>
                            <v-img
                                src="https://media.istockphoto.com/photos/hangman-chalk-writing-on-old-grunge-chalkboard-background-with-space-picture-id1146252014?k=20&m=1146252014&s=612x612&w=0&h=oLeiDUaywVraqT3GBDcEBRoi3Chf7N3OXQWnL5_ROPU="
                                height="125"
                                class="grey darken-4"
                            ></v-img>
                            <v-card-title class="text-h6 text-center">
                                LE PENDU
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card>
                            <v-img
                                src="https://media.istockphoto.com/photos/hangman-chalk-writing-on-old-grunge-chalkboard-background-with-space-picture-id1146252014?k=20&m=1146252014&s=612x612&w=0&h=oLeiDUaywVraqT3GBDcEBRoi3Chf7N3OXQWnL5_ROPU="
                                height="125"
                                class="grey darken-4"
                            ></v-img>
                            <v-card-title class="text-h6 text-center">
                                LE PENDU
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- Pitcher -->
        <div v-show="pitcher_visible">
            <div> 
                <h1 class="title-lv1">Pitcher</h1>
                <p style="text-align: justify">Le jeux du pitcher consiste à développer un thème choisie par vous ou généré par notre système puis les autres covoitureurs vous attribuerons une note. Le covoitureur qui finis avec le plus de point à la fin gagne.</p>
                <!-- Affichage du thème -->
                <v-row>
                    <v-col v-show="theme_visible">
                        <h5>Ton thème sera: {{theme[nombre]}}</h5>
                        <input 
                            v-on:click="demarrerChrono"
                            v-show="!chronoRunning"
                            type="button"
                            class="btn"
                            value="Démarrer" />  
                    </v-col>
                </v-row>
                <!-- Affichage du timer -->
                <v-row>
                    <v-col v-show="timer_visible">
                        <v-row>
                            <v-col>
                                <h5>C'est partie!</h5>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span id="timer"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Boutton timer -->
                <v-row>
                    <v-col v-show="demarrer_visible">
                        <input 
                            v-on:click="demarrerChrono"
                            v-show="!chronoRunning"
                            type="button"
                            class="btn"
                            value="Démarrer" />
                        <input 
                            v-on:click="arreterChrono"
                            v-show="chronoRunning"
                            type="button"
                            class="btn"
                            value="Pause" />  
                    </v-col>
                    <v-col v-show="!chronoRunning">
                        <input
                            v-on:click="reprendreChrono"
                            v-show="button_visible"
                            type="button"
                            class="btn"
                            value="Reprendre" />
                    </v-col>
                </v-row>
                <!-- Boutton démarer et générer  -->
                <v-row v-show="!button_visible">
                    <v-col >
                        <input 
                            v-on:click="demarrerChrono"
                            v-show="!chronoRunning"
                            type="button"
                            class="btn"
                            value="Démarrer" /> 
                          <input 
                              v-on:click="arreterChrono"
                              v-show="chronoRunning"
                              type="button"
                              class="btn"
                              value="Arreter" /> 
                    </v-col>
                    <v-col>
                        <input 
                        v-on:click="theme_visible = true; button_visible = true"
                        type="button"
                        class="btn align-center"
                        value="Générer un thème" />
                        <v-col v-show="!chronoRunning">
                          <input
                              v-on:click="reprendreChrono"
                              v-show="button_visible"
                              type="button"
                              class="btn"
                              value="Reprendre" />  
                        </v-col> 
                    </v-col>
                    
                </v-row>
                
            </div>
        </div>
        <!-- Guide touristique -->
    </section>
</template>

<style>

body {
  background: #fff;
  color: #444;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #50d8af;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #20c997;
  outline: none;
  text-decoration: none;
}

p {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 15px !important;
}

textarea {
  width: 90%;
  border: 1px dotted #aaa;
  border-radius: 12px;
  margin: 12px auto;
  padding: 7px;
}

input {
  width: 84%;
  border: 1px solid #ccc;
  border-radius: 12px;
  height: 39px;
  margin: 0 auto;
  padding-left: 12px;
}

input[type="text"] {
  background-color: white;
}

input:focus {
  border: 2px solid #17a2b8;
}

input[type="button"] {
  cursor: pointer;
}

input[type="button"]:disabled {
  cursor: crosshair;
  opacity: 0.33;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  margin: 0 0 16px 0;
  padding: 0;
}

h2 {
  font-size: 1.29rem;
}

h3,
h4 {
  font-size: 1.2rem;
}

.pointer {
  cursor: pointer;
}

#activite-utilisateur > div {
  background: rgba(191, 191, 191, 0.3);
  padding: 23px;
  border-radius: 9px;
}

#timer {
  font-weight: bold;
  font-size: 100px;
}

ul.etape{ 
  list-style: decimal;
  padding: 0;
  margin: 0;
}

ul.etape li{
  float: left;
  font-size: 14px;
  margin-left: 13px;
}

.title-lv1 {
  color: #17a2b8;
}

#title-2 {
  color: #50d8af;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.v-card {
  height: 100%;
}


</style>

<script>

export default{
  data() {
    return {
      theme: ['L\'unnivers', 'Les Mathematiques', 'La Spiritualité', 'Les Voitures', 'La musique', 'Le cinema', 'La politique', 'Iphone Vs Android',],
      decouvrir_visible: false,
      fun_visible: false,
      pitcher_visible: false,
      guide_visible: false,
      theme_visible: false,
      timer_visible: false,
      button_visible: false,
      demarrer_visible: false,
      chronoEvent: null,
      chronoRunning: false,
      chronoStop: null,
      nombre: null,
      taille: 3,
      integration: 0,
    };
  },
  created() {
    this.decouvrir_visible = this.$route.query.info == 'decouvrir';
    this.fun_visible = this.$route.query.info == 'fun';
    this.pitcher_visible = this.$route.query.info == 'pitcher';
    this.guide_visible = this.$route.query.info == 'guide';
  },
  updated() {
    this.decouvrir_visible = this.$route.query.info == 'decouvrir';
    this.fun_visible = this.$route.query.info == 'fun';
    this.pitcher_visible = this.$route.query.info == 'pitcher';
    this.guide_visible = this.$route.query.info == 'guide';
  },
  mounted() {
    this.nombre = Math.floor(Math.random()*this.theme.length)

  },
  methods: {
    demarrerChrono: function() {
      this.timer_visible = true;
      this.chronoRunning = true;
      this.button_visible = true;
      this.demarrer_visible = true;
      const departMinutes = 3;
      this.chronoStop = departMinutes * 60;

      const timerElement = document.getElementById("timer");
      this.chronoEvent = setInterval(() => {
        let minutes = parseInt(this.chronoStop / 60, 10);
        let secondes = parseInt(this.chronoStop % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        secondes = secondes < 10 ? "0" + secondes : secondes;

        timerElement.innerText = `${minutes}:${secondes}`;
        this.chronoStop = this.chronoStop <= 0 ? 0 : this.chronoStop - 1;

      }, 1000);
    },
    arreterChrono: function() {
      this.chronoRunning = false;
    //   this.button_visible = false; 
      clearInterval(this.chronoEvent);
    },
    reprendreChrono: function() {
      const timerElement = document.getElementById("timer");
      this.chronoEvent = setInterval(() => {
        let minutes = parseInt(this.chronoStop / 60, 10);
        let secondes = parseInt(this.chronoStop % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        secondes = secondes < 10 ? "0" + secondes : secondes;

        timerElement.innerText = `${minutes}:${secondes}`;
        this.chronoStop = this.chronoStop <= 0 ? 0 : this.chronoStop - 1;

      }, 1000);
      this.chronoRunning = true;
    },

    next () {
      this.integration = this.integration + 1 === this.taille
      ? this.taille - 1
      : this.integration + 1
    },
    prev () {
      this.integration = this.integration - 1 < 0
      ? 0
      : this.integration - 1
    },
  }
}
</script>
