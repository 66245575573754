<template>
  <v-container class="dashboard">
    <v-row
      id="main-accueil-utilisateur"
      class="text-center"
      justify="center"
    >
      <v-col cols="12">
        <!-- Première ligne du Dashboard -->
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar-title
                class="title white--text pl-0 pa-2"
                style="background: #20c997"
              >
                Tableau de bord SimoneVerte
              </v-toolbar-title>
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-card-text class="title black--text pl-0 pa-2" style="margin-top: 50px">
                    <span>Félicitations ! Vous êtes:</span>
                    <div style="display: flex; justify-content: center; align-items: center">
                      <h2><strong> ROOKIE </strong></h2>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center">
                      <i class="mdi mdi-trophy" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                    </div>
                    <img 
                      src="@/assets/img/badges/badge-rookie-nenuphar.png"
                      style="width:120px; height:120px; margin-top:12px" />
                    <br/>
                    <div style="display:flex; flex-direction:column;">
                      <span class="etape pa-6">Voici comment vous avez gagné ce badge:</span>                 
                      <ul class="etape pa-6">
                        <li><i>Votre inscription sur Simoneverte.fr</i> <span style="color:green" class="mdi mdi-check" /></li>
                        <li><i>La validation de votre inscription par mail</i> <span style="color:green" class="mdi mdi-check" /></li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-card-text class="title black--text pl-0 pa-2" style="margin-top: 50px">
                    <span>Prochain badge à gagner:</span>
                    <div style="display: flex; justify-content: center; align-items: center">
                      <h2><strong> SIMONE </strong></h2>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center">
                      <i class="mdi mdi-trophy" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                      <i class="mdi mdi-trophy-outline" style="color: #1976d2"></i>
                    </div>
                    <img 
                      src="@/assets/img/badges/badge-simone-tournesol.png"
                      style="width:120px; height:120px; margin-top:12px" />
                    <br/>
                    <div style="display:flex; flex-direction:column; align-items: space-between">
                      <span class="etape pa-6">Voici comment vous pourrez gagner ce badge :</span>                 
                      <ul class="etape pa-6">
                        <li><i>Completer votre profil</i></li>
                        <li><i>Participer à au moins 1 trajet</i></li>
                        <li><i>Partager au moins une fois le site autour de vous (pour ça on vous croit sur parole).</i></li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- Deuxième ligne du dashboard -->
        <v-row>
          <!-- Onglet Mon Profil -->
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-card class="v-card">
              <v-toolbar-title
                class="title white--text pl-0 pa-2"
                style="background: #20c997"
              >
                Mon profil
              </v-toolbar-title>
              <v-row class="card-img avatar">
                <v-list-item-avatar size="90px">
                  <router-link
                    :to="{
                      name: 'infosUtilisateurs',
                      query: { info: 'photo' },
                    }"
                  >
                    <img
                      :src="profile.avatar | avatar"
                      v-show="profile.avatar && !avatar_par_defaut"
                      @error="avatar_par_defaut = true"
                      style="width: 100px"
                    />
                    <img
                      src="@/assets/img/profil/profil_defaut.jpeg"
                      v-show="!profile.avatar || avatar_par_defaut"
                      style="width: 100px"
                    />
                  </router-link>
                </v-list-item-avatar>
              </v-row>
              <v-row class="text-center" justify="center">
                <h5>
                  Bonjour <br /><strong>{{
                    profile.prenom | premiereLettreCapitale
                  }}</strong>
                </h5>
              </v-row>
              <v-row class="text-center" justify="center">
                <v-btn class="pa-2 ma-3">
                  <router-link
                    :to="{
                      name: 'infosUtilisateurs',
                      query: { info: 'general' },
                    }"
                    >Modifier mon profil</router-link
                  >
                </v-btn>
              </v-row>
            </v-card>
          </v-col>

          <!-- Onglet Documents -->
          <!--<v-col cols="12" lg="4" md="12" sm="12">
            <v-card>
              <v-toolbar-title
                class="title white--text pl-0 pa-2"
                style="background: #20c997"
              >
                Vos documents
              </v-toolbar-title>
              <v-card-text class="mt-5">
                <v-icon size="100px" style="color: #1976d2">
                  mdi-marker-check </v-icon
                ><br />
                <h5>Vos documents sont à jour.</h5>
              </v-card-text>
              <v-toolbar-title class="title white--text pl-0 pb-1">
                <v-btn>
                  <router-link
                    :to="{
                      name: 'infosUtilisateurs',
                      query: { info: 'documents' },
                    }"
                    >Voir</router-link
                  >
                </v-btn>
              </v-toolbar-title>
            </v-card>
          </v-col>
          -->

          <!-- Onglet Conversation -->
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-card class="v-card">
              <v-toolbar-title
                class="title white--text pl-0 pa-2"
                style="background: #20c997"
              >
                Vos conversations
              </v-toolbar-title>
              <div>
                <v-card-text>
                  <v-timeline align-top dense v-show="messages.length > 0">
                    <v-timeline-item
                      v-for="message in messages.slice(0, 2)"
                      :key="message.id"
                      small
                    >
                      <div>
                        <div class="font-weight-normal">
                          <strong>{{ message.expediteur.prenom }}</strong>
                          @{{ message.datetime }}
                        </div>
                        <div>{{ message.message }}</div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                  <span v-show="messages.length == 0"
                    >Vous n'avez aucun message.</span
                  >
                </v-card-text>
              </div>
              <!--
              <v-toolbar-title class="title white--text pl-0 pa-2">
                <v-btn>
                  voir plus
                </v-btn>
              </v-toolbar-title>
              -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<style>
#main-accueil-utilisateur [class^="col"] {
  border: none;
}

.dashboard {
  background: rgba(191, 191, 191, 0.3);
  display: flex;
}
ul.etape{ 
  list-style: decimal;
  padding: 0;
  margin: 0;
}

ul.etape li{
  float: left;
  font-size: 0.72em;
  margin-left: 13px;
  text-align: left;
}

.title-lv1 {
  color: #17a2b8;
  padding-bottom: 30px !important;
}

#title-2 {
  color: #50d8af;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.v-card {
  height: 100%;
}

/* .dashboard-header {

} */
</style>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      avatar_par_defaut: false,
      messages: [], //
    };
  },
  created() {
    this.$root.$emit("start_loading");

    // Récupération des messages
    this.$api
      .get("/utilisateurs/" + this.profile.id + "/messages")
      .then((res) => {
        this.messages = res.data.data;
      })
      .finally(() => this.$root.$emit("hide_loading"));
  },
  computed: {
    ...mapGetters(["getProfile"]),
    profile() {
      return this.getProfile;
    },
  },
};
</script>

