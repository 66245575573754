<template>
  <section id="trajets-utilisateur">
    <v-container>
      <h3 class="titre">Mes trajets</h3>
      <br />
      <div v-show="type_de_trajet == 'conducteur'"
        style="margin-bottom: 39px;">
        <h5>En tant que conducteur</h5>
        <i>{{ nbTrajetsConducteurs }} trajet{{ nbTrajetsConducteurs > 1 ? 's' : '' }} enregistré{{ nbTrajetsConducteurs > 1 ? 's' : '' }}.</i>
      </div>
      <v-row 
        class="text-center"
        align="center"
        justify="center"
        v-show="type_de_trajet == 'conducteur'"> 
        <v-col cols="12"
          lg="10"
          md="10"
          sm="12"
          v-for="covoiturage in trajets.conducteur"
          v-show="!covoiturage.parent_id"
          :key="covoiturage.id">
          <carte-covoiturage 
            type-carte="CONDUCTEUR"
            v-show="covoiturage.actif == 1"
            :covoiturage="covoiturage" />
        </v-col>
      </v-row>
      
      <div v-show="type_de_trajet == 'passager'"
        style="margin-bottom: 39px;">
        <h5>En tant que passager</h5>
        <i>{{ nbTrajetsPassagers }} trajet{{ nbTrajetsPassagers > 1 ? 's' : '' }} enregistré{{ nbTrajetsPassagers > 1 ? 's' : '' }}.</i>
      </div>
      <v-row 
        class="text-center"
        align="center"
        justify="center"
        v-show="type_de_trajet == 'passager'">
        <v-col cols="12"
          lg="10"
          md="10"
          sm="12"
          v-for="covoiturage in trajets.passager"
          :key="covoiturage.passager_id">
          <carte-covoiturage 
            type-carte="PASSAGER"
            v-show="covoiturage.actif == 1"
            :covoiturage="covoiturage" />
        </v-col>
      </v-row>
      
      <v-row 
        class="text-center"
        align="center"
        justify="center"
        v-show="loading"> 
        <v-col cols="12"
          lg="1"
          md="1"
          sm="1">
          <button  
            class="btn">
            <span class="mdi mdi-spin mdi-loading"></span>
          </button>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style type="text/css">

#trajets-utilisateur > div {
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
}
</style>

<script>
import { mapGetters } from "vuex";
import CarteCovoiturage from '../../components/CarteCovoiturage';

export default {
  components: { CarteCovoiturage },
  data() {
    return {
      type_de_trajet: '',
      page_courante: {
        conducteur: 1,
        passager: 1
      },
      derniere_page:  {
        conducteur: 1,
        passager: 1
      },
      total:  {
        conducteur: 0,
        passager: 0
      },
      trajets: {
        conducteur: [],
        passager: []
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
    profile() {
      return this.getProfile;
    },
    nbTrajetsPassagers() {
      return this.total.passager - this.trajets.passager.filter(t => t.actif == 0).length;
    },
    nbTrajetsConducteurs() {
      return this.total.conducteur - this.trajets.conducteur.filter(t => t.actif == 0).length;
    }
  },
  mounted() {
    this.type_de_trajet = this.$route.query.type;    
    this.$root.$emit("start_loading");

    if (this.profile && this.profile.id) 
    {
      if (this.type_de_trajet == 'conducteur' && this.page_courante.conducteur == 1) {
        this.$api.get('/trajets?filter[utilisateur_id]=' + this.profile.id + '&filter[actif]=1&page=1')
        .then(res => {
          this.trajets.conducteur = res.data.data;
          this.total.conducteur = res.data.meta.total;
          this.derniere_page.conducteur = res.data.meta.last_page; 
          if (res.data.meta.last_page >= this.page_courante.conducteur) {
            this.page_courante.conducteur++;
          }
        })
        .finally(() => this.$root.$emit("hide_loading"));
      } 
      else if (this.type_de_trajet == 'passager' && this.page_courante.passager == 1) {
        
        this.$api.get('/trajets?filter[passager]=' + this.profile.id + '&filter[actif]=1&page=1')
        .then(res => {

          this.trajets.passager = res.data.data;
          this.total.passager = res.data.meta.total;
          this.derniere_page.passager = res.data.meta.last_page; 
          if (res.data.meta.last_page >= this.page_courante.passager) {
            this.page_courante.passager++;
          }
        })
        .finally(() => this.$root.$emit("hide_loading"));
      }
    }

    //
    this.$root.$on(
      "succes_annulation_trajet",
      covoiturage_id => {
        this.trajets.conducteur.filter(t => {
          return t.id != covoiturage_id;
        })
      }
    );

    // Rafraichissement
    this.$root.$on("refreshed_user", () => {
      setTimeout(this.loadTrajets(), 4500);
    });

    // Lazy loading
    this.$root.$on("scrolled_to_bottom", () => {
      this.loadTrajets();
    });
  },
  methods: {
    // this.$root.$emit("refreshed_user");
    loadTrajets : function() {
      if (this.profile && this.profile.id &&!this.loading && this.$route.name === 'trajetsUtilisateurs') {
        this.loading = true;

        if (this.type_de_trajet == 'conducteur') {
          if (this.page_courante.conducteur <= this.derniere_page.conducteur) {
            this.$api.get('/trajets?filter[utilisateur_id]=' + this.profile.id + '&filter[actif]=1&page=' + this.page_courante.conducteur)
            .then(res => {
              var _trajets = res.data.data;
              for (var k = 0; k < _trajets.length; k++) {
                if (this.trajets.conducteur.indexOf(_trajets[k]) === -1) {
                  this.trajets.conducteur.push(_trajets[k]);
                }
              }
              this.page_courante.conducteur++;
            })
            .finally(() => this.loading = false);
          } else {
            this.loading = false;
          }
        } 
        else if (this.type_de_trajet == 'passager') {
          if (this.page_courante.passager <= this.derniere_page.passager) {
            this.$api.get('/trajets?filter[passager]=' + this.profile.id + '&filter[actif]=1&page=' + this.page_courante.passager)
            .then(res => {
              var _trajets = res.data.data;
              for (var k = 0; k < _trajets.length; k++) {
                if (this.trajets.passager.indexOf(_trajets[k]) === -1) {
                  this.trajets.passager.push(_trajets[k]);
                }
              }
              this.page_courante.passager++;
            })
            .finally(() => this.loading = false);
          } else {
            this.loading = false;
          }
        }
      }
    },
  }
}
</script>