<template>    
    <div class="etape-x" v-show="trajet.date && trajet.heure && (typeAdresse == 'depart' ? trajet.adresse_depart == null : (trajet.adresse_depart != null && trajet.adresse_arrivee == null))">
        <label for="depart" class="titre" v-if="typeAdresse == 'depart'">Où prendrez-vous vos covoitureurs ?</label>
        <label for="arrivee" class="titre" v-if="typeAdresse == 'arrivee'">Votre adresse de destination ?</label>
        <div class="input-group">
            <div 
                class="flex"
                v-show="notValidCP()">
                <span class="note">Entrez le code postal ou le nom de la commune de votre adresse de {{ typeAdresse == 'depart' ? 'départ' : 'destination' }}.</span>
                <input
                    v-model="adresses[typeAdresse].cp"
                    type="text"
                    v-on:input="showCommunes(typeAdresse)"
                    :placeholder="'Code postal. Ex: ' + (typeAdresse == 'depart' ? '29200' : '75001')" />
            </div>
            <br />
            <div 
                class="flex"
                v-show="adresses[typeAdresse].communes.length > 1 && !adresses[typeAdresse].commune_choisie.nom"
                style="margin-top: 36px">
                <span class="note">Sélectionnez la commune de votre adresse de {{ typeAdresse == 'depart' ? 'départ' : 'destination' }}.</span>
            
                <div 
                    class="btn commune"
                    v-for="commune in adresses[typeAdresse].communes"
                    :key="commune.code"
                    v-on:click="showAdressesEnregistrees(commune, typeAdresse)">
                    {{ commune.nom }}
                </div>
            </div>

            <div 
                class="flex"
                v-show="adresses[typeAdresse].lieux.length > 0 && !trajet.adresse_arrivee">
                <span class="note">Sélectionnez ou saisissez l'adresse précise de votre {{ typeAdresse == 'depart' ? 'départ' : 'destination finale' }}.</span>

                <div 
                    class="btn lieu"
                    :class="{'valide' : lieu.id == -2}"
                    v-show="!adresses[typeAdresse].autre"
                    v-for="lieu in adresses[typeAdresse].lieux"
                    :key="lieu.id"
                    v-on:click="gererClicLieu(lieu, typeAdresse)">
                    <span v-if="lieu.id == -2" class="mdi mdi-plus-circle-outline"></span><span>{{ lieu.complete }}</span>
                </div>

                <v-autocomplete
                    v-model="adresse_model"
                    v-show="adresses[typeAdresse].autre"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    color="#ccc"
                    no-filter
                    clearable
                    hide-no-data
                    hide-selected
                    item-text="Description"
                    item-value="API"
                    label=""
                    :placeholder="'Saisissez votre adresse de ' + (typeAdresse == 'depart' ? 'départ' : 'destination') + ' ...'"
                    prepend-icon="mdi-home-circle"
                    return-object />
            </div>

            <div v-show="isLoading">
                <button  
                    class="btn centre">
                    <span class="mdi mdi-spin mdi-loading"></span>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ajouter-trajet-saisir-adresse',
  props: [
    'typeAdresse',
    'adresses',
    'trajet',
    'setAdresse',
    'showCommunes',
    'showAdressesEnregistrees',
    'gererClicLieu',
  ],
  data() {
    return {
      adresse_model_depart: null,
      adresse_model_arrivee: null,
      search: null,
      descriptionLimit: 60,
      isLoading: false,
      entries_depart: [],
      entries_arrivee: [],
      adresse_depart: '',
      adresse_arrivee: '',
    }
  },
  computed: {
    adresse_model: {
      // getter
      get: function() {
        return this.typeAdresse == 'depart' ? this.adresse_model_depart : this.adresse_model_arrivee;
      },
      // setter
      set: function (newValue) {
        if (this.typeAdresse == 'depart') {
          this.adresse_model_depart = newValue;
        } else {
          this.adresse_model_arrivee = newValue;
        }
      }
    },
    items() {
      return this.computedItems();
    }
  },
  watch: {
    adresse_model_depart(value) {
      if (value) {
        this.setAdresse(value, 'depart');
        this.search = null;
      }
    },
    adresse_model_arrivee(value) {
      if (value) {
        this.setAdresse(value, 'arrivee');
        this.search = null;
      }
    },
    search(val) {
      this.watchSearch(val);
    }
  },
  mounted() {
    this.adresse_model = this.typeAdresse == 'depart' ? this.adresse_depart : this.adresse_arrivee;
  },
  methods: {
    notValidCP: function() {
      var v = false;

      if (this.typeAdresse == 'depart')
        v = /^\d+$/.test(this.adresses.depart.cp) && this.adresses.depart.cp.length == 5;
      else
        v = /^\d+$/.test(this.adresses.arrivee.cp) && this.adresses.arrivee.cp.length == 5;

      return !v;
    },
    computedItems: function() {
      
      var entries = this.typeAdresse == 'depart' ? this.entries_depart : this.entries_arrivee;
      return entries.map(entry => {
        var description = entry.properties.name;
        if (entry.properties.postcode)
          description += ', ' + entry.properties.postcode;
        if (entry.properties.city)
          description += ', ' + entry.properties.city;
        
        const entryText = description.length > this.descriptionLimit
          ? description.slice(0, this.descriptionLimit) + '...'
          : description
        
        return entryText;
      })
    },
    watchSearch: function(adresse) {

      // Items have already been requested
      if (this.isLoading) return;

      if (adresse && adresse.length > 3) {
        var citycode = this.typeAdresse == 'depart' ? 
          this.adresses.depart.commune_choisie.code : 
          this.adresses.arrivee.commune_choisie.code;
        
        this.isLoading = true;
        // Lazily load input items
        axios.get('https://api-adresse.data.gouv.fr/search/?q='+adresse.replace(' ', '+').toLowerCase() +'&autocomplete=0&citycode=' + citycode,
          {
            transformRequest: (data, headers) => {
              delete headers['Authorization'];
            }
          }
        )
        .then(res => {
          if (this.typeAdresse == 'depart')
            this.entries_depart = res.data.features;
          else
            this.entries_arrivee = res.data.features;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
      }
    },
  }
}
</script>