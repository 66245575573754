import axios from 'axios';
import router from '../router';
import Cookies from 'js-cookie';

const ApiPlugin = {};

ApiPlugin.install = function (Vue, options) {
    var api = {
        options: options,
        client: null,
        userToken: null,
        setUserToken: function(userToken) {
            api.userToken = userToken
            api.createClient()
        },
        unsetUserToken: function() {
            api.userToken = null
            api.createClient()
        },
        createClient: function() {
            var headers = {}
            if (api.userToken) {
                headers['Authorization'] = api.userToken
            }
            window.c=Cookies
            if (Cookies.get(process.env.VUE_APP_CSRF_COOKIE_NAME)) {
                headers['X-CSRFTOKEN'] = Cookies.get(process.env.VUE_APP_CSRF_COOKIE_NAME)
            }
            api.client = axios.create({
                baseURL: options.apiBaseUrl,
                headers: headers,
                withCredentials: true,
            })
            api.client.interceptors.request.use((config) => 
                {
                    let token = localStorage.getItem('token');
                    if (token) {
                        config.headers['Authorization'] = `${ token }`;
                    }
                    return config;
                }, (error) => {
                    return Promise.reject(error);
                }
            ),
            axios.interceptors.response.use(undefined, function (err) {
                return new Promise(function () {
                    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized, logout the user
                        // localStorage.removeItem('token');
                        // localStorage.removeItem('expires');
                        // you can also redirect to /login if needed !
                        router.push({ name: 'erreur401'})
                    }
                    throw err;
                });
            });
        },
        get: function(endpoint, data) {
            return api.client.get(endpoint, {params: data});
        },
        post: function(endpoint, data) {
            return api.client.post(endpoint, data);
        },
        delete: function(endpoint) {
            return api.client.delete(endpoint);
        },
        erreur: function(type) {
            router.push({ name: 'erreur' + type})
        }
    };
    api.createClient();

    Object.defineProperty(Vue.prototype, '$api', {
        get () { return api; }
    })
}

export default ApiPlugin;
