<template>
    <v-container>
        <v-row 
            id="main" style="min-height:70vh;"
            class="text-center"
            justify="center">
            <v-col cols="12"
                lg="10"
                md="10"
                sm="12">
                <h1 
                  v-show="titre && titre != ''" 
                  style="font-size: 0.93em; text-align: left; padding-top: 12px; padding-bottom: 9px; font-weight: bold;">
                  {{ titre }}
                </h1>
                <v-row class="entete text-center"
                    justify="center"
                    align="center"
                    v-sticky="{ zIndex: 18, stickyTop: 84, disabled: false }">
                     <v-col>
                        <v-row
                            justify="center"
                            align="center">
                            <v-col
                                cols="6"
                                lg="4"
                                md="4"
                                sm="6"
                                v-show="recherche.adresse_depart || recherche.ville_depart">
                                <span class="mdi mdi-map-marker hidden-sm-and-down"> &nbsp; Départ</span>
                                <div class="hidden-sm-and-down information-recherche">{{ recherche.adresse_depart ? recherche.adresse_depart : recherche.ville_depart }}</div>
                                <span class="mdi mdi-map-marker hidden-md-and-up"> &nbsp; {{ recherche.adresse_depart ? recherche.adresse_depart : recherche.ville_depart }}</span>
                                <!-- <span class="mdi mdi-pencil pointer"></span> -->
                            </v-col>
                            
                            <v-col
                                cols="6"
                                lg="4"
                                md="4"
                                sm="6"
                                v-show="recherche.adresse_arrivee || recherche.ville_arrivee"
                            >
                                <span class="mdi mdi-flag-variant hidden-sm-and-down"> &nbsp; Arrivée</span> 
                                <div class="hidden-sm-and-down information-recherche">{{ recherche.adresse_arrivee ? recherche.adresse_arrivee : recherche.ville_arrivee }}</div>
                                <span class="mdi mdi-flag-variant hidden-md-and-up"> &nbsp; {{ recherche.adresse_arrivee ? recherche.adresse_arrivee : recherche.ville_arrivee }}</span>
                                <!-- <span class="mdi mdi-pencil pointer"></span> -->
                            </v-col>
                            
                            <!--
                            <v-col
                                cols="12"
                                lg="3"
                                md="3"
                                sm="6"
                            >
                                <span class="mdi mdi-calendar hidden-sm-and-down"> &nbsp; Date</span>
                                <div class="hidden-sm-and-down information-recherche">{{ recherche.date | formatDate }}</div>
                                <span class="mdi mdi-calendar hidden-md-and-up"> &nbsp; {{ recherche.date | dateEnLettres }}</span>
                                <! -- <span class="mdi mdi-pencil pointer"></span> -- >
                            </v-col>-->
                            
                            <!--<v-col
                                cols="6"
                                lg="3"
                                md="3"
                                sm="6"
                            >
                                <span class="mdi mdi-human-male-female hidden-sm-and-down"> &nbsp; Voyageurs</span>
                                <div class="hidden-sm-and-down information-recherche">{{ recherche.nb_voyageurs ? recherche.nb_voyageurs : 1 }}</div>
                                <span class="mdi mdi-human-male-female hidden-md-and-up"> &nbsp; {{ recherche.nb_voyageurs ? recherche.nb_voyageurs : 1 }}</span>
                                <! -- <span class="mdi mdi-pencil pointer"></span> -- >
                            </v-col>-->
        
                            <v-col
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                                class="hidden-sm-and-down">
                                <router-link :to="{name: 'trouver'}" class="btn" style="color:white;font-size:0.75em;line-height:1.33em;padding-top:12px"><span class="mdi mdi-restart"> &nbsp;</span> Nouvelle recherche</router-link>
                            </v-col>
                        </v-row>
    
                        <v-row
                            justify="center"
                            align="center">
                            <v-col
                                cols="4"
                                lg="4"
                                md="4"
                                sm="4"
                                class="calendrier-date before"
                                :class="{'impossible': isToday(recherche.date)}"
                                v-on:click="goToDate(-1)">
                                {{ recherche.dateVeille | dateEnLettres(true) }}
                            </v-col>
                            <v-col
                                cols="4"
                                lg="4"
                                md="4"
                                sm="4"
                                class="calendrier-date now">
                                {{ recherche.date | dateEnLettres(true) }}
                            </v-col>
                            <v-col
                                cols="4"
                                lg="4"
                                md="4"
                                sm="4"
                                class="calendrier-date after"
                                v-on:click="goToDate(1)">
                                {{ recherche.dateLendemain | dateEnLettres(true) }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="filtres flex hidden-sm-and-down">
                    <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="6"
                        v-on:click="filtres.genre = (filtres.genre + 1) % 3"
                    >
                        <div class="filtre-bouton" :class="{ 'active-male' : filtres.genre == 1, 'active-female' : filtres.genre == 2 }">
                            <span class="mdi" :class="{ 'mdi-gender-female' : filtres.genre % 2 == 0 }">&nbsp;</span>
                            <span>{{ filtres.genre == 0 ? 'Mixte' : (filtres.genre == 1 ? 'Entre gars' : 'Entre filles') }}</span>
                            <span class="mdi" :class="{ 'mdi-gender-male' : filtres.genre <= 1 }">&nbsp;</span>
                        </div>
                    </v-col>
                    
                    <!--<v-col
                        cols="12"
                        lg="3"
                        md="6"
                        sm="6"
                        v-on:click="filtres.avis = !filtres.avis"
                    >
                        <div class="filtre-bouton" :class="{ 'active' : filtres.avis }">
                            <span class="mdi" :class="{ 'mdi-star': filtres.avis, 'mdi-star-outline': !filtres.avis }"> &nbsp;</span>
                            <span>Mieux notés</span>
                            <span class="mdi" :class="{ 'mdi-star': filtres.avis, 'mdi-star-outline': !filtres.avis  }"> &nbsp;</span>
                        </div>
                    </v-col>

                    <v-col
                        cols="12"
                        lg="3"
                        md="6"
                        sm="6"
                        v-on:click="filtres.peage = !filtres.peage"
                    >
                        <div class="filtre-bouton" :class="{ 'active' : filtres.peage }">
                            <span class="mdi" :class="{ 'mdi-road-variant': filtres.peage, 'mdi-road': !filtres.peage }"> &nbsp;</span>
                            <span>Plus rapide</span>
                            <span class="mdi" :class="{ 'mdi-road-variant': filtres.peage, 'mdi-road': !filtres.peage  }"> &nbsp;</span>
                        </div>
                    </v-col>
                    -->

                    <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="6"
                        v-on:click="filtres.automatique = !filtres.automatique"
                    >
                        <div class="filtre-bouton" :class="{ 'active' : filtres.automatique }">
                            <span class="mdi" :class="{ 'mdi-flash-auto': filtres.automatique, 'mdi-flash-off': !filtres.automatique }"> &nbsp;</span>
                            <span>Automatique</span>
                            <span class="mdi" :class="{ 'mdi-flash-auto': filtres.automatique, 'mdi-flash-off': !filtres.automatique  }"> &nbsp;</span>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="covoiturages" v-if="filteredCovoiturages.length > 0">
                    <v-col
                        cols="12"
                        v-for="covoiturage in filteredCovoiturages"
                        :key="covoiturage.id">
                        <carte-covoiturage :covoiturage="covoiturage" type-carte="SIMPLE" />
                    </v-col>
                </v-row>
                <v-row 
                    v-if="fourchette != null && filteredCovoiturages.length == 0"
                    justify="center">
                    
                    <v-col 
                      cols="12"
                      lg="12"
                      md="12"
                      sm="12">

                      <div 
                        class="box-prix-encadres">
  
                        <span><b>{{ fourchette.ville1 }} <i class="mdi mdi-arrow-left-right"></i> {{ fourchette.ville2 }}</b></span><br />
                        <span><i>Toujours</i> compris entre</span><br />
                        <h2 class="prix">{{ fourchette.min }} &euro;</h2><b> {{ fourchette.minDecimal | double }}</b><span class="et"> et </span><h2 class="prix">{{ fourchette.max }} &euro;</h2><b> {{ fourchette.maxDecimal | double }}</b>
                      </div>

                    </v-col>
                </v-row>
                <v-row class="covoiturages" v-if="filteredCovoiturages.length == 0">
                    <v-col
                        cols="12"
                        style="margin-top: 18px; margin-bottom: 34px;">
                        <i v-if="fourchette != null">Trajets de covoiturage pas cher de {{ fourchette.ville1 }} vers {{ fourchette.ville2 }}.</i>
                        <i v-else-if="recherche.ville_arrivee != null">Voyagez en covoiturage pas cher vers {{ recherche.ville_arrivee }}.</i>
                        <br />
                        <br />
                        <v-row>
                          <v-col
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12">
                            <router-link class="btn" :to="{ name: 'inscription' }" style="color:white">
                              Rejoingnez la communauté
                            </router-link>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12">
                            <span> OU </span>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12">
                            <router-link class="btn" :to="{ name: 'proposer' }" style="color:white">
                              Proposer un trajet
                            </router-link>
                          </v-col>
                        </v-row>                         
                        <br />
                        <br />
                        <span>SimoneVerte est votre site de covoiturage avec une communauté de conducteurs et passagers centrée sur l'échange et le partage.</span><br />
                        <span>En plus, de nombreux bons plans sont régulièrement proposés à nos utilisateurs.</span>
                        <br />
                        <br />
                        <i>Désolé <span class="mdi mdi-emoticon-sad"></span> ! Il n'y a pas encore d'expérience de covoiturage avec ces critères.</i>
                    </v-col>
                </v-row>
                <v-row 
                    v-if="fourchette != null && filteredCovoiturages.length > 0"
                    justify="center">
                    
                    <v-col 
                      cols="12"
                      lg="12"
                      md="12"
                      sm="12">

                      <div 
                        class="box-prix-encadres">
  
                        <span><b>{{ fourchette.ville1 }} <i class="mdi mdi-arrow-left-right"></i> {{ fourchette.ville2 }}</b></span><br />
                        <span><i>Toujours</i> compris entre</span><br />
                        <h2 class="prix">{{ fourchette.min }} &euro;</h2><b> {{ fourchette.minDecimal | double }}</b><span class="et"> et </span><h2 class="prix">{{ fourchette.max }} &euro;</h2><b> {{ fourchette.maxDecimal | double }}</b>
                      </div>

                    </v-col>
                </v-row>
                <v-row 
                    justify="center"
                    style="margin-top: 36px">
                    
                    <v-col
                      cols="12"
                      lg="6"
                      md="6"
                      sm="12">

                      <div 
                        v-on:click="goToProposerTrajet()">

                        <img src="@/assets/img/banniere_commission.webp" style="width: 100%; cursor:pointer" />

                      </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VueSticky from "vue-sticky";
import moment from 'moment';
import CarteCovoiturage from '../components/CarteCovoiturage';
import router from '../router';
import constantes from '../constantes';

export default {
  components: { CarteCovoiturage },
  directives: {
    sticky: VueSticky,
  },
  data() {
    return {
      n: 1,
      filtres: {
        avis: false,
        genre: 0,
        peage: false,
        automatique: false,
      },
      titre: '',
      recherche: {},
      covoiturages: [],
      pagination: {
        current_page: 1,
        last_page: 1,
      },
      fourchette: null
    }
  },
  mounted() {
    // Titre de l'onglet
    var currentDate = !this.$route.query.date || this.$route.query.date == 'JOUR_J' ? moment().format('YYYY-MM-DD') : this.$route.query.date;
    var v_depart = this.$route.query.from_city && this.$route.query.from_city != '' ? this.$route.query.from_city : this.$route.params.nom_ville_depart,
      v_arrivee = this.$route.query.to_city && this.$route.query.to_city != '' ? this.$route.query.to_city : this.$route.params.nom_ville_arrivee,
      description = '';
    
    // Cas de la route covoiturages-vers-ville
    if (!v_arrivee && this.$route.params.nom_ville) {
      v_arrivee = this.$route.params.nom_ville;
    }
    this.titre = 'Covoiturages pas cher';
    document.title = 'Covoiturages pas cher';
    description = 'Voyagez pas cher en covoiturage';

    if (v_depart) {
      this.titre += ' de ' + v_depart;
      document.title += ' de ' + v_depart;
      description += ' de ' + v_depart;
    }
    if (v_arrivee) {
      this.titre += ' vers ' + v_arrivee;
      document.title += ' vers ' + v_arrivee;
      description += ' vers ' + v_arrivee;
    }
    document.title += ' | SimoneVerte.fr';
    description += ' avec SimoneVerte. Bénéficiez de la convivialité du covoiturage pour vous déplacer dans toute la France. '
      + 'Notre plateforme sécurisée est faite pour vous, nous mettons la technologies au service de la réussite de votre trajet. '
      + 'Vous avez juste à proposer votre trajet ou à choisir parmi les trajets proposés et le tour est joué.';

    this.$root.$emit("change_meta_description", description);

    // 
    this.recherche = {
      date: currentDate,
      code_insee_depart: this.$route.query.from,
      code_insee_arrivee: this.$route.query.to,
      ville_depart: v_depart,
      ville_arrivee: v_arrivee,
      dateLendemain: moment(currentDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
      dateVeille: moment(currentDate, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD'),
    };

    var bornesUrl = '';
    if (this.$route.query.from && this.$route.query.to) {
      bornesUrl = '/bornes/' + this.$route.query.from + '/' + this.$route.query.to;
    } else if (v_depart && v_arrivee) {
      bornesUrl = '/bornes/' + v_depart + '/' + v_arrivee;
    }
      
    if (bornesUrl != '') {
      this.$api.get(bornesUrl)
      .then(res => {
        var min = res.data.min * (1 + constantes.pourcentage_fees) + constantes.autres_fees,
          max = res.data.max * (1 + constantes.pourcentage_fees) + constantes.autres_fees,
          ville1 = this.$root.$options.filters.premiereLettreCapitale(res.data.ville_depart),
          ville2 = this.$root.$options.filters.premiereLettreCapitale(res.data.ville_arrivee);
          
        min = min < 5 ? 5 : min;
  
        this.fourchette = {
          ville1: ville1,
          ville2: ville2,
          codeCommune1: this.$route.query.from_city,
          codeCommune2: this.$route.query.to_city,
          min: Math.trunc(min),
          minDecimal: Math.round((min % 1) * 100),
          max: Math.trunc(max),
          maxDecimal: Math.round((max % 1) * 100)
        };
  
        // Référencement naturel
        document.title += ' | Contribution comprise entre '  + min + ' euro et ' + max + ' euro'
  
        if (!this.$route.query.from) {
          this.$route.query.from = res.data.code_commune_depart;
        }
  
        if (!this.$route.query.to) {
          this.$route.query.to = res.data.code_commune_arrivee;
        }
      })
      .catch(err => {
        console.error(err);
      })
    }
  
    if (!this.$route.query.from_city && v_depart) {
      this.$route.query.from_city = v_depart.replace(' 01', '');
    }

    if (!this.$route.query.to_city && v_arrivee) {
      this.$route.query.to_city = v_arrivee.replace(' 01', '');
    }

    // Appel de la recherche de trajet correspondant aux criteres
    if (this.recherche.date) {

      this.$root.$emit("start_loading");
      // var url_recherche = '/trajets?'
      //   + 'filter[date]=' + this.recherche.date 
      //   + '&filter[actif]=1';
      var url_recherche = '/covoiturages/' 
        + this.recherche.date + '?actif=1';

      if (this.recherche.ville_depart) {
        this.recherche.ville_depart = this.recherche.ville_depart.replace(' 01', '');
        url_recherche += '&nom_commune_depart=' + this.recherche.ville_depart;
      }

      if (this.recherche.ville_arrivee) {
        this.recherche.ville_arrivee = this.recherche.ville_arrivee.replace(' 01', '');
        url_recherche += '&nom_commune_arrivee=' + this.recherche.ville_arrivee;
      }

      // Appel de la recherche de trajet correspondant aux criteres
      this.$api.get(url_recherche
        + '&page=' + this.pagination.current_page
      ).then(res => {
        this.covoiturages = res.data.data;
        // this.pagination.last_page = res.data.meta.last_page;
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => this.$root.$emit("hide_loading"));
    }
  },
  unmounted() {
    this.$root.$emit("reset_meta_description");
  },
  computed: {
    filteredCovoiturages() {
      return this.covoiturages.filter(covoiturage => {
        var r = true;

        switch (this.filtres.genre) {
          case 1:
            r = r && covoiturage.conducteur.sexe === 'M'
              && covoiturage.passagers.filter(passager => (passager.sexe === 'F')).length == 0;
            break;
          case 2:
            r = r && covoiturage.conducteur.sexe == 'F'
              && covoiturage.passagers.filter(passager => (passager.sexe === 'M')).length == 0;
            break;
        }
        if (this.filtres.automatique) {
          r = r && covoiturage.is_resa_automatique == 1;
        }
        return r;
      });
    }
  },
  methods: {
    goToDate: function(indice) { 
      if (!this.isToday(this.recherche.date) || indice === 1) {
        router.push(
        { 
          name: 'resultats', 
          query: {
            date: indice === 1 ? this.recherche.dateLendemain : this.recherche.dateVeille,
            from: this.recherche.code_insee_depart,
            to: this.recherche.code_insee_arrivee,
            from_city: this.$route.query.from_city && this.$route.query.from_city != '' ? this.$route.query.from_city : this.$route.params.nom_ville_depart,
            to_city: this.$route.query.to_city && this.$route.query.to_city != '' ? this.$route.query.to_city : this.$route.params.nom_ville_arrivee,
          }
        });
        this.$router.go();
      }
    },
    isToday: function(dateDonnee) {
      return moment().format('YYYY-MM-DD') == dateDonnee;
    },
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

        if (bottomOfWindow) {
          if (this.pagination.last_page > this.pagination.current_page) {
            
            this.$root.$emit("start_loading");
            this.pagination.current_page++;

            // Récupération des données de la page suivante
            this.$api.get('/trajets?'
              + 'filter[adresse_depart]=' + this.recherche.ville_depart 
              + '&filter[adresse_arrivee]=' + this.recherche.ville_arrivee
              + '&filter[date]=' + this.recherche.date
              + '&filter[actif]=1' 
              + '&page=' + this.pagination.current_page
            )
            .then(res => {
              this.covoiturages.push(...res.data.data);
            })
            .finally(() => this.$root.$emit("hide_loading"));
          }
        }
      }
    },
    goToProposerTrajet: function() {
      router.push({ name: 'proposer'});
    }
  }
}
</script>

<style>
.entete {
  font-size: 0.90em;
  border-radius: 12px;
  margin: 12px 0px;
  background-color: #ddf;
}
.entete .information-recherche {
  font-style: italic;
}
.entete .calendrier-date {
  color: #fff;
  background: #20c997;
  border-color: #20c997;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: 12px;
  padding: 12px 4px;
}
.entete .calendrier-date.before:hover,
.entete .calendrier-date.after:hover {
  font-weight: bold;
  cursor: pointer;
}
.entete .calendrier-date.now {
  height: 63px;
  border-left: 1px solid;
  border-right: 1px solid;
  padding-top: 1.2rem;
  cursor: auto;
}
.entete .calendrier-date.before,
.entete .calendrier-date.after {
  color: #17a2b8;
  border-color: #17a2b8;
  background: #fff;
  font-weight: normal;
}
.entete .calendrier-date.before {
  border-left: 1px solid;
}
.entete .calendrier-date.after {
  border-right: 1px solid;
}
.entete .calendrier-date.impossible,
.entete .calendrier-date.impossible:hover,
.entete .calendrier-date.before.impossible:hover,
.entete .calendrier-date.after.impossible:hover {
  color: #777;
  border-color: #777;
  background: #ccc;
  font-weight: normal;
  cursor: auto;
} 
.filtres {
  margin: 18px auto;
}
.filtres div span {
  color: #fff;
  font-size: 0.84em;
  display: inline-block;
  margin: 7px 7px 0 7px;
}
.entete div span div {
  color: #333;
  font-weight: 600;
}
.filtre-bouton {
  border-radius: 12px;
  height: 36px;
  border: 1px solid;
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 3px;
  width: 90%;
  background-color: #777;
  border-color: white;
  cursor: pointer;
}
.filtre-bouton.active,
.filtre-bouton.active-male {
  background-color: #17a2b8;
  border-color: white;
}
.filtre-bouton.active-female {
  background-color: #fd6c9e;
  border-color: white;
}
</style>
