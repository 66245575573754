<template>
  <v-container>
    <v-row align="center"
        justify="center">
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12">
        <table>
          <tbody>
          <tr v-for="(row,idx) in grid" :key="idx">
            <td v-for="(cell,idy) in row" :key="idy" 
            :class="{ 
              locked: grid[idx][idy].locked, 
              selected: grid[idx][idy].selected,
              error: grid[idx][idy].error
            }"
            @click="setSelected(grid[idx][idy], idx, idy)"> {{ grid[idx][idy].value }}</td>
          </tr>
          </tbody>
        </table>
        <table id="virtual_keybord" style="display: none">
          <tr v-for="i in 3" :key="i">
            <td 
              v-for="j in 3" 
              :key="j"
              @click="pickNumber(3 * (i - 1) + j)">
              {{ 3 * (i - 1) + j }}
            </td>
          </tr>
        </table> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SudokuGrid',
  computed: {
    ...mapGetters([
      'getGrid'
    ]),
    grid() {
      return this.getGrid;
    },
    mobileOSDetected() {
      const platform = navigator.platform.toLowerCase();
      return platform && /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform);
    }
  },
  methods: {
    pickNumber(value) {
      let typed = value; // parseInt(String.fromCharCode(e.keyCode),10);
      // if it was NaN, split out
      if(!typed) return;
      
      document.querySelector("#virtual_keybord").style = 'display: none';
      this.$store.commit('setNumber', typed);
    },
    setSelected(cell, x, y) {
      document.querySelector("#virtual_keybord").style = 'display: table';
      this.$store.commit('setSelected',{x, y});
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  border-collapse: collapse;
  border: 2px solid;
  margin: auto;
}

td {
  border: 1px solid;
  text-align: center;
  height: 40px;
  width: 40px;
}

table tbody tr td:nth-child(3), table tbody tr td:nth-child(6) {
  border-right: 2px solid;
}

table tbody tr:nth-child(3), table tbody tr:nth-child(6) {
  border-bottom: 2px solid;
}

td.locked {
  cursor: not-allowed;
}

td {
  cursor: pointer;
}

td.selected {
  background-color: bisque;
}

td.error {
  color: red;
}


</style>