<template>
  <v-container id="paiement">
    <header>
      <h5><span class="mdi mdi-lock"></span>Paiement sécurisé</h5>
    </header>

    <!-- Données chargées -->
    <v-row
      class="text-center"
      align="start"
      justify="center"
      v-if="firstLoadingDone && covoiturage">
      <v-col 
        cols="12"
        sm="12"
        md="4"
        lg="4">
        <!-- Resumé -->
        <div class="titre">Données du voyage</div>
        <div class="section donnees hidden-sm-and-down">
          <v-row>
            <v-col class="text-left">
              <v-list-item-avatar
                v-if="covoiturage.conducteur.avatar"
                size="70px">
                  <img 
                    :src="covoiturage.conducteur.avatar | avatar" 
                    v-show="!avatar_par_defaut"
                    @error="avatar_par_defaut = true"
                    style="width: 70px" />
                  <img 
                    src="~@/assets/img/profil/profil_defaut.jpeg" 
                    v-show="avatar_par_defaut" 
                    style="width: 70px" />
              </v-list-item-avatar>
              <v-icon
                v-else
                large
                left
                >
                mdi-account
              </v-icon>
            </v-col>
            <v-col class="text-right">
              <div class="texte">
                <span>{{ covoiturage.conducteur.prenom | premiereLettreCapitale }}</span><br />
                <span>{{ covoiturage.conducteur.date_naissance | dateToAge }} ans</span><br />
                <span v-for="n in 5" class="mdi mdi-star etoiles" :class="{ 'coloree' : !covoiturage.conducteur.note || n <= covoiturage.conducteur.note }" :key="n"></span>
              </div>
            </v-col>
          </v-row>
          <hr />
        </div>
        <div class="section donnees">
          <v-row>
            <v-col class="text-left" cols="2"><span class="mdi mdi-calendar"></span></v-col>
            <v-col class="text-right">{{ covoiturage.date | dateComplete }} à {{ covoiturage.heure | heure }}</v-col>
          </v-row>
          <v-row>
            <v-col class="text-left" cols="2"><span class="mdi mdi-map-marker"></span></v-col>
            <v-col class="text-right">{{ covoiturage.adresses.depart.complete | cleanAdresseComplete }}</v-col>
          </v-row>
          <v-row>
            <v-col class="text-left" cols="2"><span class="mdi mdi-flag-variant"></span></v-col>
            <v-col class="text-right">{{ covoiturage.adresses.arrivee.complete | cleanAdresseComplete }}</v-col>
          </v-row>
          <v-row v-if="covoiturage.duree_secondes">
            <v-col class="text-left" cols="2"><span class="mdi mdi-clock-fast"></span></v-col>
            <v-col class="text-right">durée aproximative du trajet : {{ covoiturage.duree_secondes | dureeToHeure }}</v-col>
          </v-row>
        </div>
      </v-col>
      <v-col 
        cols="12"
        sm="12"
        md="8"
        lg="8">
        <div class="section">
          <div class="titre">Récapitulatif des frais</div>
          <div class="donnees">
            <v-row>
              <v-col class="text-left">{{ nb_places }} place{{ nb_places > 1 ? 's' : ''}} x {{ covoiturage.montant_contribution | price }} &euro;</v-col>
              <v-col class="text-right">{{ (nb_places * covoiturage.montant_contribution) | price }} &euro;</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">Frais de réservation ({{ nb_places }} x {{ frais | price }} &euro;)</v-col>
              <v-col class="text-right">{{ (nb_places * frais) | price }} &euro;</v-col>
            </v-row>
            <v-row v-if="nb_places > 1">
              <v-col class="text-left">Remise</v-col>
              <v-col class="text-right">- {{ remise | price }} &euro;</v-col>
            </v-row>
            <hr />
            <v-row>
              <v-col class="text-left"><span class="total">Total</span></v-col>
              <v-col class="text-right">{{ total_a_payer | price }} &euro;</v-col>
            </v-row>
          </div>
        </div>
        <div class="section">
          <!-- -->
          <div class="titre">Paiement sécurisé par carte bancaire</div>
          <div class="donnees">
            <v-row
              align="center"
              justify="center">
              <v-col class="padding-top-20" cols="6">
                <button class="btn center large"
                  :disabled="show_formulaire_nouvelle_cb"
                  v-on:click="show_cartes = false; show_formulaire_nouvelle_cb = true; resetFormulaire();">
                  Paiement sécurisé par carte bancaire
                </button>
              </v-col>
              <!--
              <v-col 
                class="padding-top-20" 
                cols="6"
                v-show="cartes_bancaires.length > 0">
                <button class="btn center"
                  :disabled="show_cartes"
                  v-on:click="show_cartes = true; show_formulaire_nouvelle_cb = false; resetFormulaire();">
                  Utiliser une carte bancaire enregistrée
                </button>
              </v-col>
              -->
            </v-row>
            <v-row v-show="show_cartes">
              <v-col cols="12">
                <div v-if="cartes_bancaires" class="cartes-container">
                  <v-row>
                    <v-col cols="12">
                      <h5>Bientôt disponible</h5>
                    </v-col>
                  </v-row>
                  <!--
                  <v-row
                    align="center"
                    justify="space-around"
                    v-on:click="carte_selectionnee = carte.id"
                    v-for="carte in cartes_bancaires"
                    :key="carte.id"
                    class="cb"
                    :class="{'selectionnee' : carte_selectionnee == carte.id, 'pointer': carte_selectionnee != carte.id }">
                    <v-col cols="3">
                      <button 
                        class="btn center" 
                        v-on:click="carte_selectionnee = carte.id"
                        :disabled="carte_selectionnee == carte.id">
                        Sélectionnez
                      </button>
                    </v-col>
                    <v-col cols="6" class="padding-top-20">
                      <span>Numéro:</span> <span>{{ carte.alias | numeroCarteBancaire }}</span><br />
                      <i>Carte ajoutée le {{ new Date(carte.creation_date * 1000) | dateComplete }}</i>
                    </v-col>
                    <v-col cols="1" class="padding-top-20">
                      <span class="mdi mdi-24px mdi-check-circle" :class="{'selectionnee' : carte_selectionnee == carte.id, 'non-selectionnee': carte_selectionnee != carte.id}"></span>
                    </v-col>
                  </v-row>
                  -->
                </div>
              </v-col>
            </v-row>
            <v-row v-show="show_formulaire_nouvelle_cb">
              <v-col class="text-left padding-top-20" cols="4">
                <label>Titulaire de la carte</label>
              </v-col>
              <v-col class="text-left" cols="8">
                <input type="text" name="tituaire" v-model="carte.titulaire" />
              </v-col>
            </v-row>
            <v-row v-show="show_formulaire_nouvelle_cb">
              <v-col class="text-left padding-top-20" cols="4">
                <label>Numéro de la carte</label>
              </v-col>
              <v-col class="text-left" cols="8">
                <input 
                  v-show="edit_numero"
                  type="text" 
                  name="numero" 
                  maxlength="16"
                  @blur="edit_numero = false"
                  v-model="carte.numero" />
                <div class="edit-numero-cb"
                  v-show="!edit_numero">
                  <span>{{ carte.numero | numeroCB }}</span>
                  <span
                    class="mdi mdi-pencil pointer"
                    @click="edit_numero = true"
                  ></span>
                </div>
              </v-col>
            </v-row>
            <v-row v-show="show_formulaire_nouvelle_cb">
              <v-col class="text-left padding-top-20" cols="4">
                <label>Cryptogramme</label>
              </v-col>
              <v-col class="text-left" cols="8">
                <input type="text" maxlength="3" name="crypto" v-model="carte.cryptogramme" />
              </v-col>
            </v-row>
            <v-row v-show="show_formulaire_nouvelle_cb">
              <v-col class="text-left padding-top-20" cols="4">
                <label>Date d'expiration</label>
              </v-col>
              <v-col class="text-left" cols="8">
                <div style="display: inline-flex">
                  <v-select
                    :items="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
                    v-model="carte.expiration.mois"
                    />
                  <v-select
                    :items="annees"
                    v-model="carte.expiration.annee"
                    />
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- -->
          <v-row
            v-show="erreur">
            <v-col>
              <h4 
                class="erreur"
              >
                {{ erreur }}
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col 
              class="text-center"
              align="center"
              justify="center">
                <div
                  class="btn large"
                  :class="{'pointer': peut_payer, 'no-pointer': ne_peut_pas_payer}"
                  v-on:click="payer"
                >
                  <span>Payez {{ total_a_payer | price }} &euro; </span> <span class="mdi mdi-lock"></span>
                </div>
                <!--
                <button  
                  class="btn large valide"
                  v-show="loaded">
                  Paiement effectué avec succès <span class="mdi mdi-check"></span>
                </button>
                -->
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
#paiement {
  margin: 9px auto;
  width: 75%;
  padding: 9px;
}

#paiement .no-pointer {
  cursor: initial !important;
  opacity: 0.63;
}

#paiement div.row {
  margin: 3px auto;
}

#paiement .padding-top-20 {
  padding-top: 20px;
}

#paiement .section {
  margin: auto 25px;
}

#paiement .section .donnees {
  border: 1px solid #999;
  background-color: #ccc;
  border-radius: 12px;
  margin: 12px auto 25px;
  padding: 12px;
}

#paiement .section .edit-numero-cb span {
  display: inline-block;
  padding-top: 7px;
  margin-right: 18px;
}

#paiement .cartes-container .cb i {
  font-size: 12px;
}

#paiement .cartes-container .cb.selectionnee {
  background-color: white;
  border-radius: 7px;
}

#paiement .cartes-container span.selectionnee {
  color: green;
}

#paiement .cartes-container span.non-selectionnee {
  color: #ccc;
}

@media(max-width: 480px) {  
  #paiement {
    width: 93%;
  }

  #paiement .padding-top-20 {
    padding-top: 7px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
import constantes from '../constantes';
import router from '../router';

export default {
  data() {
    return {
      firstLoadingDone: false,
      avatar_par_defaut: false,
      covoiturage: null,
      nb_places: null,
      edit_numero: true,
      carte: {
        titulaire: null,
        numero: null,
        expiration: {
          mois: null,
          annee: null,
        },
        cryptogramme: null,
      },
      show_formulaire_nouvelle_cb: false,
      show_cartes: false,
      cartes_bancaires: null,
      carte_selectionnee: null,
      erreur: null,
    }
  },
  created() {
    var parametres = atob(this.$route.params.base64_id_nbplace).split('|');

    this.nb_places = parametres[1];
    
    this.$api.get('/trajets/' + parametres[0])
    .then(res => {
      this.covoiturage = res.data.data;
      this.covoiturage.conducteur.note = 5;

      // Titre de l'onglet
      document.title = 'Paiement sécurisé : ' + this.covoiturage.adresses.depart.ville;
      document.title += ' - ' +  this.covoiturage.adresses.arrivee.ville;
      document.title += ' | ' +  this.$options.filters.dateComplete(this.covoiturage.date);
      document.title += ' | ' +  this.$options.filters.heure(this.covoiturage.heure);
      document.title += ' | Partagez une expérience. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';
    })
    .finally(() => this.firstLoadingDone = true);   

    this.$api.get('/utilisateurs/' + this.profile.id + '/cartes')
    .then(res => {
      this.cartes_bancaires = res.data;
      if (res.data.length == 0) {
        this.show_formulaire_nouvelle_cb = true;
      }
    })
    .finally(() => this.firstLoadingDone = true);    
  },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
    profile() {
      return this.getProfile;
    },
    prix_public() {
      return Math.round(constantes.autres_fees * 100 + this.covoiturage.montant_contribution * (1 + constantes.pourcentage_fees) * 100) / 100;
    },
    frais() {
      return Math.round(constantes.autres_fees * 100 + this.covoiturage.montant_contribution * constantes.pourcentage_fees * 100) / 100;
    },
    remise() {
      return (this.nb_places - 1) * constantes.montant_retrait_degressif;
    },
    fees() {
      return this.nb_places * this.frais - this.remise;
    },
    total_a_payer() {
      return this.nb_places * this.prix_public - this.remise;
    },
    annees() {
      let current_year = new Date().getFullYear();
      return [0, 1, 2, 3, 4].map(number => {
        return parseInt(current_year + number);
      });
    },
    peut_payer() {
      return this.carte.titulaire != null 
        && this.carte.titulaire != ''
        && this.carte.numero != null 
        && this.carte.numero != ''
        && this.carte.cryptogramme != null 
        && this.carte.cryptogramme != ''
        && this.carte.expiration.mois != null 
        && this.carte.expiration.mois != ''
        && this.carte.expiration.annee != null 
        && this.carte.expiration.annee != '';
    },
    ne_peut_pas_payer() {
      return !this.peut_payer;
    }
  },
  methods: {
    resetFormulaire: function() {
      this.carte = {
        titulaire: null,
        numero: null,
        expiration: {
          mois: null,
          annee: null,
        },
        cryptogramme: null,
      };
      this.carte_selectionnee = null;
      
    },
    payer: function() {

      if (this.peut_payer) {
        this.$root.$emit("start_loading");
        
        //
        var bodyFormData = new FormData();
        bodyFormData.append('trajet_id', this.covoiturage.id);
        bodyFormData.append('conducteur_id', this.covoiturage.utilisateur_id);
        bodyFormData.append('nb_places', this.nb_places);
        bodyFormData.append('montant', Math.round(this.total_a_payer * 100));
        bodyFormData.append('fees', Math.round(this.fees * 100));
        bodyFormData.append('titulaire', this.carte.titulaire);
        bodyFormData.append('numero', this.carte.numero);
        bodyFormData.append('mois', this.carte.expiration.mois);
        bodyFormData.append('annee', this.carte.expiration.annee);
        bodyFormData.append('cryptogramme', this.carte.cryptogramme);
        bodyFormData.append('token', this.$route.params.base64_id_nbplace);
        // Envoie des nouvelles informations requises pour 3D2S
        bodyFormData.append('browser_info_java_enabled', navigator.javaEnabled());
        bodyFormData.append('browser_info_language', navigator.language || navigator.userLanguage);
        bodyFormData.append('browser_info_color_depth', screen.colorDepth);
        bodyFormData.append('browser_info_screen_height', screen.height);
        bodyFormData.append('browser_info_screen_width', screen.width);
        bodyFormData.append('browser_info_time_zone_offset', new Date().getTimezoneOffset().toString());
        bodyFormData.append('browser_info_user_agent', navigator.userAgent);

        axios.get('https://api.ipify.org?format=json',
          {
            transformRequest: (_data, headers) => {
              delete headers['Authorization'];
            }
          }
        )
        .then((resIp) => {
          bodyFormData.append('ip_address', resIp.data.ip);  
          
          this.$api.post(
            'utilisateurs/' + this.profile.id + '/paiements', 
            bodyFormData
          )
          .then(res => {
            
            if (res.data.status == 'PAIEMENT_REUSSI') {
              // Redirection vers la page de détails du covoiturage
              // avec l'affichage du numéro de téléphone du conducteur
              router.push({ path: '/covoiturage/' + this.covoiturage.id });
            }
          })
          .catch(() => {
            this.resetFormulaire();
            this.erreur = "Une erreur est survenu. Veuillez réessayer plus tard. Si l'erreur persiste, essayer un autre moyen de paiement.";
            setTimeout(() => {
              this.erreur = '';
            }, 10000);
          })
          .finally(() => this.$root.$emit("hide_loading"));
        })
        .catch(err => console.error(err));

      }
    },
  }
}
</script>