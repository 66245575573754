<template>
  <div class="card vue-avatar-cropper-demo text-center">
    <div class="card-body">
      <img 
        :src="profile.avatar | avatar" 
        v-show="profile.avatar && !avatar_par_defaut"
        @error="avatar_par_defaut = true"
        class="card-img avatar" />
      <img 
        src="@/assets/img/profil/profil_defaut.jpeg" 
        v-show="!profile.avatar || avatar_par_defaut" 
        class="card-img avatar" />
      <div class="card-img">
        <button class="btn btn-primary btn-sm" id="pick-avatar">Changer</button>
      </div>
      <h6 class="card-title mb-0" style="margin-top: 18px">
        {{ profile.prenom | premiereLettreCapitale }}
        <span class="text-muted">{{ profile.nom.toUpperCase() }}</span>
      </h6>
    </div>
    <div class="card-footer text-muted" v-html="message"></div>
    <s-v-avatar-cropper
      trigger="#pick-avatar"
      :upload-handler="envoyerAvatar" />
  </div>
</template>

<script>
import SVAvatarCropper from "./SVAvatarCropper";
import { USER_REQUEST } from "../store/actions/user";
import { mapGetters } from "vuex";
import router from '../router';

export default {
  components: { SVAvatarCropper },
  data() {
    return {
      message: "Prêt",
      avatar_par_defaut: false
    };
  },
  computed: {
    ...mapGetters(["getProfile"]),
    profile() {
      return this.getProfile;
    }
  },
  methods: {
    envoyerAvatar(cropped) {
      
      this.message = "en cours de chargement...";

      cropped.getCroppedCanvas({}).toBlob(blob => {
        var data = new FormData();
        data.append('avatar', blob, 'avatar.jpg');

        this.message = "Chargement terminé.";

        this.$api.post('/utilisateurs/' + this.profile.id + '/avatar', data)
        .then(() => {
          this.message = "Votre avatar a bien été téléchargé.";
          this.$store.dispatch(USER_REQUEST);
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.message = "Oops ! Il semblerait que votre image soit trop grande. Choisissez en une plus petite SVP.";
            // Affichage du message
            if (err.response.data.errors && err.response.data.errors.length > 0) {
              this.message = err.response.data.errors[0].detail;
            }
          } 
          else {
            this.message = "Oops ! Il y a eu un problème technique. Réessayer svp !";
            router.push({ name: 'erreur404' });}
          }
        );
      }); 
    }
  }
};
</script>

<style>
.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}
.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
}
.card-img-overlay {
  display: none;
  transition: all 0.5s;
}
.card-img-overlay button {
  margin-top: 20vh;
}
.card:hover .card-img-overlay {
  display: block;
}
</style>
