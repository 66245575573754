<template>
  <v-container id="details-covoiturage">
    <v-row
      class="text-center"
      align="center"
      justify="center"
      v-show="info_message">
      <h5>{{ info_message }}</h5>
      <div v-show="covoiturage == null" style="padding: 18px">
        <img src="@/assets/gifs/covoiturage-deja-effectue.gif" style="max-width: 450px" />
      </div>
    </v-row>

    <v-row
      class="text-center"
      align="center"
      justify="center"
      v-if="covoiturage != null">

      <v-col cols="12"
        lg="8"
        md="8"
        sm="12">
        
        <!-- L'identité et le prix -->
        <v-row
          class="border-bottom"
          align="center"
          justify="center"
          v-if="covoiturage.conducteur">
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            align="space-between"
            >
            <v-row 
              class="sans-margin-top"
              align="center"
              justify="center">
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
                class="text-center">
                <v-list-item-avatar
                  style="margin: auto"
                  size="100px">
                  <img 
                    :src="covoiturage.conducteur.avatar | avatar" 
                    v-show="covoiturage.conducteur.avatar && !avatar_par_defaut"
                    @error="avatar_par_defaut = true" 
                    style="width: 100px; height: 100px" />
                  <img 
                    src="@/assets/img/profil/profil_defaut.jpeg" 
                    v-show="!covoiturage.conducteur.avatar || avatar_par_defaut" 
                    style="width: 100px; height: 100px" />
                </v-list-item-avatar>
              </v-col>
              <v-col
                cols="12"
                lg="8"
                md="8"
                sm="8"
                >
                <div class="title font-weight-light texte">
                  <span>{{ covoiturage.conducteur.prenom | premiereLettreCapitale }}</span><br />
                  <span class="age">{{ covoiturage.conducteur.date_naissance | dateToAge }} ans</span><br />
                  <span v-for="n in 5" class="mdi mdi-star etoiles" :class="{ 'coloree' : !covoiturage.conducteur.note || n <= covoiturage.conducteur.note }" :key="n"></span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            >
            <div class="title text-center font-weight-bold">{{ covoiturage.date | dateComplete }}</div>
            <div class="title text-center font-weight-bold prix-carte-covoiturage">{{ prix_public | price }} &euro; par passager</div>
            <div class="title text-center font-weight-light">
              <span 
                class="mdi mdi-email-outline enveloppe"
                v-if="!profile.id || covoiturage.conducteur.id != profile.id"
                v-on:click="ouvrirDialogMessage()">
              </span>
            </div>
            <div class="text-justify description">{{ covoiturage.description ? covoiturage.description : 'Pas de description'}}</div>
          </v-col>          
        </v-row>
        
        <!-- Le trajet -->
        <v-row
          class="border-bottom padding-bottom text-center"
          v-if="covoiturage.adresses"
          align="center"
          justify="center">
          <v-col
            lg="3"
            md="3"
            sm="4">
            <span class="ville">{{ covoiturage.adresses.depart.ville }}</span><br />
            <span class="adresse hidden-sm-and-down">{{ covoiturage.adresses.depart.complete | cleanAdresseComplete }}</span><br />
            <span class="heure">{{ covoiturage.heure | heure }}</span>
          </v-col>
          <v-col
            lg="6"
            md="6"
            sm="4">
            <v-stepper class="hidden-sm-and-down">
              <v-stepper-header>
                <v-stepper-step
                  :complete="true"
                  step="1"
                >
                  
                </v-stepper-step>

                <v-divider class="complet"></v-divider>

                <v-stepper-step
                  :complete="true"
                  step="2"
                >
                  
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            <div class="hidden-md-and-up">
              <i class="mdi mdi-arrow-right"></i>
            </div>
          </v-col>
          <v-col
            lg="3"
            md="3"
            sm="4">
            <span class="ville">{{ covoiturage.adresses.arrivee.ville }}</span><br />
            <span class="adresse hidden-sm-and-down">{{ covoiturage.adresses.arrivee.complete | cleanAdresseComplete }}</span><br />
            <span class="heure" v-if="heure_arrivee == null">Indéterminée</span>
            <span class="heure" v-else>{{ heure_arrivee }}</span>
          </v-col>
        </v-row>
        
        <!-- Reservations -->
        <v-row>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12">
            <v-row class="sans-margin-top">
              <v-col
                cols="12"
              >
                <div class="font-weight-light">
                  <h6>Réservations effectuées</h6>
                </div>
              </v-col>
            </v-row>
            <v-row class="sans-margin-top" v-if="covoiturage.passagers && covoiturage.passagers.length > 0">
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="4"
                v-for="passager in covoiturage.passagers" :key="passager.id">
                <v-list-item-avatar
                  size="70px">
                  <img 
                    :src="passager.avatar | avatar" 
                    v-show="passager.avatar || !covoiturage.avatar_passagers_defaut[passager.id]" 
                    @error="covoiturage.avatar_passagers_defaut[passager.id] = true"
                    style="width: 70px" />
                  <img 
                    src="@/assets/img/profil/profil_defaut.jpeg" 
                    v-show="!passager.avatar || covoiturage.avatar_passagers_defaut[passager.id]" 
                    style="width: 70px" />
                </v-list-item-avatar>
              </v-col>
            </v-row>
            <v-row class="sans-margin-top" v-if="covoiturage.passagers && covoiturage.passagers.length > 0">
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="4"
                v-for="passager in covoiturage.passagers" :key="passager.id">
                <div class="font-weight-light">
                  <span>{{ passager.prenom | premiereLettreCapitale }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="covoiturage.passagers && covoiturage.passagers.length == 0">
              <v-col cols="12">
                <span class="font-weight-light">Pas encore de réservation</span>  
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12">
            <v-row class="sans-margin-top">
              <v-col cols="12">
                <span class="mdi mdi-flash-off" v-if="covoiturage.is_resa_automatique == 0"></span>
                <span class="mdi mdi-flash-auto" v-if="covoiturage.is_resa_automatique == 1"></span>
                <span class="font-weight-light">Réservation {{ covoiturage.is_resa_automatique == 1 ? 'automatique' : 'manuelle' }}.</span> 
              </v-col>
            </v-row>
            <v-row class="sans-margin-top" v-if="covoiturage.computed && covoiturage.computed.nb_places_disponibles > 0">
              <v-col
                cols="12"
                v-for="n in covoiturage.computed.nb_places_disponibles" :key="n">
                <div class="btn-reserver pointer" v-on:click="reserver(n)">
                  <span>Réserver {{n}} place{{n > 1 ? 's' : ''}}</span><br />
                  <b>{{ prixApresRemise(n) | price }} &euro;</b> <span v-if="n > 1">au lieu de <span style="text-decoration: line-through;"> {{ (prix_public * n) | price }} &euro;</span></span>
                </div>
              </v-col>
            </v-row>
            <v-row class="sans-margin-top" v-if="covoiturage.computed && covoiturage.computed.nb_places_disponibles == 0">
              <v-col
                cols="12">
                <b>Il n'y a plus de place disponible dans ce covoiturage</b> 
                <div 
                  class="btn-reserver pointer" 
                  v-on:click="trouverDAutresCovoiturages()"
                  style="margin-top: 25px;">
                  <span>Vous pouvez trouver un autre covoiturage</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  
    <!-- Dialogues -->
    <div class="text-center">
      <!-- SHARE IT NOW -->
      <v-dialog
        v-model="dialogue_share_it_now"
        width="50vw !important"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" style="word-break: normal">
            Partagez votre trajet en story !
          </v-card-title>

          <v-card-text class="dialog-text">
            Plusieurs conducteurs trouvent des passagers en postant l'annonce de leur trajet en story. 
          </v-card-text>
          <v-row justify="center">
            <ShareNetwork
              class="btn share whatsapp"
              network="whatsapp"
              :url="'https://www.simoneverte.fr/covoiturage/' + covoiturage.id"
              title="*SimoneVerte - Info Covoiturage*"
              :description="sharedMessageConducteur"
              quote="SimoneVerte le covoiturage longue distance qui vous ressemble."
              hashtags="simoneverte,covoiturage"
            >
              <span class="mdi mdi-whatsapp"></span> &nbsp; Partagez votre trajet maintenant
            </ShareNetwork>
          </v-row>
        </v-card>
      </v-dialog>
      
      <!-- Réservation Message -->
      <v-dialog
        v-model="dialogue_message"
        width="50vw"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" style="word-break: normal">
            Votre message
          </v-card-title>

          <v-card-text>
            <textarea 
              class="textarea-message" 
              v-model="message" 
              placeholder="Bonjour, ..." 
              maxlength="255">
            </textarea>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="envoyerMessage()"
            >
              Envoyer un message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- Réservation déja effectuée -->
      <v-dialog
        v-model="dialogue_deja_reserve"
        width="50vw"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" style="word-break: normal">
            Vous avez déjà réservé
          </v-card-title>

          <v-card-text class="dialog-text">
            Une réservation est déjà enregistrée à votre nom pour ce covoiturage.
            Êtes-vous sûr que vous souhaitez de nouveau réserver pour ce même trajet ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="reserver(nb_places_a_reserver)"
            >
              Réserver de nouveau
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Connexion requise -->
      <v-dialog
        v-model="dialogue_connexion_requise"
        width="50vw"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" style="word-break: normal">
            Vous devez vous connecter
          </v-card-title>

          <v-card-text class="dialog-text">
            Vous ne pouvez pas effectuer de paiement ou envoyer un message sans être connecter. 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="seConnecter()"
            >
              Se connecter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- Profil complet requis -->
      <v-dialog
        v-model="dialogue_profil_incomplet"
        width="50vw"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" style="word-break: normal">
            Vous devez compléter votre profile
          </v-card-title>

          <v-card-text class="dialog-text">
            Vous ne pouvez pas effectuer de paiement avant d'avoir compléter votre profil. 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="completerProfile()"
            >
              Je le complète maintenant
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>


<script>
import constantes from '../constantes';
import router from '../router';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      covoiturage: {},
      icon_changed: false,
      nb_places_a_reserver: 0,
      info_message: null,
      message: '',
      avatar_par_defaut: false,
      dialogue_deja_reserve: false,
      dialogue_connexion_requise: false,
      dialogue_message: false,
      dialogue_profil_incomplet: false,
      dialogue_share_it_now: false,
    }
  },
  mounted() {
    this.$root.$emit("start_loading");
    let params = this.$route.params;

    // Réinit du message
    this.message = '';

    // Appel de la recherche de trajet correspondant aux criteres
    this.$api.get('/trajets/' + params.id)
    .then(res => {

      this.covoiturage = res.data.data;
      if (this.covoiturage) {
        this.covoiturage.conducteur.note = 5;

        if (this.$route.query.shareNow && this.$route.query.shareNow == 1) {
          this.dialogue_share_it_now = true;
        }

        if (this.isBeforeToday(this.covoiturage.date)) {
          this.info_message = 'Ce covoiturage a déjà été effectué. Nous vous redirigeons gentillement vers une liste de covoiturages similaires.';

          setTimeout(() => {
            router.push({ 
              path: '/covoiturages/' 
                + res.data.data.adresses.depart.ville + '/' 
                + res.data.data.adresses.arrivee.ville
              });
          }, 10000);
          this.covoiturage = null;

        } else {

          this.covoiturage.avatar_passagers_defaut = [];
          for (var i = 0; i < this.covoiturage.passagers.length; i++) {
            this.covoiturage.avatar_passagers_defaut[this.covoiturage.passagers[i].id] = false;
          }

          /* this.$root.$emit("start_loading");
          // On vérifie si le profil connecté fait partie des passagers
          if (this.profile.id && this.covoiturage.passagers.filter(p => p.utilisateur_id == this.profile.id).length == 1) {
            this.$api.get('/numero-conducteur/' + params.id)
            .then(res => {
              this.covoiturage.conducteur.numero = res.data.numero;
            })
            .catch(err => {
              console.error(err);
              })
            .finally(() => {
              this.$root.$emit("hide_loading");
            });
          }*/

          // Titre de l'onglet
          document.title = 'Covoiturage ' + this.covoiturage.adresses.depart.ville;
          document.title += ' - ' +  this.covoiturage.adresses.arrivee.ville;
          document.title += ' | ' +  this.$options.filters.dateComplete(this.covoiturage.date);
          document.title += ', ' +  this.$options.filters.heure(this.covoiturage.heure);

          this.$root.$emit("change_meta_title");
          this.$root.$emit("change_meta_description", document.title);
        }
      } else {
        this.$router.push({ name: 'erreur404' });
      }
    })
    .catch(err => {
      console.error(err);
      this.$router.push({ name: 'erreur404' });
    })
    .finally(() => {
      this.$root.$emit("hide_loading");
    });  
  },
  unmounted() {
    this.$root.$emit("reset_meta_description");
  },
  updated() {
    if (!this.icon_changed) {
      var icons = document.querySelectorAll('.v-stepper i.v-icon');
      if (icons.length > 1) {
        icons[0].classList.replace('mdi-check', 'mdi-map-marker');
        icons[1].classList.replace('mdi-check', 'mdi-flag-variant');
        this.icon_changed = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated"
    ]),
    profile() {
      return this.getProfile;
    },
    prix_public() {
      return (constantes.autres_fees * 100 + this.covoiturage.montant_contribution * (1 + constantes.pourcentage_fees) * 100) / 100;
    },
    heure_arrivee() {
      if (this.covoiturage.duree_secondes) {
        return this.$options.filters.dureeToHeure(
          parseInt(this.$options.filters.heureToDuree(this.covoiturage.heure)) 
          + parseInt(this.covoiturage.duree_secondes)
        );
      }
      return null;
    },
    is_passager() {
      if (this.covoiturage.passagers && this.covoiturage.passagers.length > 0) {
        return this.isAuthenticated && this.covoiturage.passagers
          .filter(passager => passager.id == this.profile.id)
          .length > 0;
      }
      return this.isAuthenticated && this.covoiturage.conducteur.id == this.profile.id;
    },
    sharedMessageConducteur() {
      if (this.covoiturage && this.covoiturage.adresses) {
        return 'Bonjour ! Info Covoiturage : ' 
          + this.covoiturage.adresses.depart.ville + ' --> ' 
          + this.covoiturage.adresses.arrivee.ville 
          + ' | ' + this.$options.filters.formatDate(this.covoiturage.date) 
          + ' | ' + this.$options.filters.price(this.prix_public) + ' eur. À bientôt !';
      }
      return '';
    },
  },
  methods: {
    isBeforeToday: function(dateDonnee) {
      return moment(dateDonnee).isBefore(moment().format('YYYY-MM-DD'));
    },
    prixApresRemise: function(n) {
      return parseFloat(this.prix_public * n) - (n - 1) * constantes.montant_retrait_degressif;
    },
    completerProfile: function() {
      this.dialogue_profil_incomplet = false;
      router.push({name: 'infosUtilisateurs', query: {info: 'general'}});
    },
    trouverDAutresCovoiturages: function() {
      router.push(
      { 
        name: 'resultats', 
        query: {
          date: this.covoiturage.date,
          /*from: commune_depart[0].properties.citycode,
          to: commune_arrivee[0].properties.citycode,*/
          from_city: this.covoiturage.adresses.depart.ville,
          to_city: this.covoiturage.adresses.arrivee.ville,
          from_adr: this.covoiturage.adresses.depart.complete.replace(' ', '+').toLowerCase(),
          to_adr: this.covoiturage.adresses.arrivee.complete.replace(' ', '+').toLowerCase(),
        }
      })
    },
    ouvrirDialogMessage: function() {
      if (this.profile && this.profile.id > 0) {
        this.dialogue_message = true;
      } else {
        this.dialogue_connexion_requise = true;
      }
    },
    envoyerMessage: function() {
      var bodyFormData = new FormData();
      bodyFormData.append('trajet_id', this.covoiturage.id);
      bodyFormData.append('utilisateur_id', this.profile.id);
      bodyFormData.append('destinataire_id', this.covoiturage.conducteur.id);
      bodyFormData.append('objet', "Demande d'information");
      bodyFormData.append('message', this.message);
      bodyFormData.append('is_public', 1);

      this.$root.$emit("start_loading");

      this.$api.post(
        '/trajets/' + this.covoiturage.id + '/messages', 
        bodyFormData
      )
      .then(() => {
        this.dialogue_message = false;
        this.info_message = 'Votre message a bien été envoyé ! Une notification vous sera adressée lorsque vous recevrez une réponse.';
        setTimeout(() => {
          this.info_message = '';
        }, 9000);
      })
      .catch(err => console.error(err))
      .finally(() => this.$root.$emit("hide_loading"));
    },
    seConnecter: function() {
      router.push({
        path: '/vous-identifier?referer=/covoiturage/' + this.covoiturage.id
      });
    },
    reserver: function(nb_places) {
      // Affichage d'un popup si l'utilisateur a déjà réservé
      if (this.profile && this.profile.id) {
        if (
          this.covoiturage.passagers
            .filter(passager => passager.id == this.profile.id)
          .length > 0
          && !this.dialogue_deja_reserve
        ) {
          this.nb_places_a_reserver = nb_places;
          this.dialogue_deja_reserve = true;
        }
        else {
          this.$root.$emit("start_loading");

          // Récupération des informations complémentaires relatives à l'utilisateur
          this.$api.get('/utilisateurs/' + this.profile.id + '/info')
          .then(res => {
            // Relation one-to-on dans le modèle de données
            // Si ces informations obligatoires ne sont pas remplies, 
            // on déduit qu'il s'agit d'une première visite (ou modification)
            if (res.data.length == 0 
              || !res.data[0].nationalite
              || res.data[0].nationalite == ''
              || !res.data[0].pays_residence
              || res.data[0].pays_residence == '') {
              this.dialogue_profil_incomplet = true;
            } 
            // Le profil a bien été complété
            else {
              router.push({
                path: '/paiement/' + btoa(this.covoiturage.id + '|' + nb_places)
              });
            }
          })
          .catch(err => console.error(err))
          .finally(() => this.$root.$emit("hide_loading"));
        }
      } else {
        this.dialogue_connexion_requise = true;
      }
    }
  }
}
/**
 * 
 * 1. Le management humain
 *    - lire John C. MAXWELL
 * 2. Le management des finances
 *    - intelligence financière
 *    - argent église locale
 * 3. Le management du temps
 *    - organisation / agenda
 *    - attention, arrêtons les retards
 *    - soyons des modèles de ponctualité
 * 4. Les méthodes d'efficacité personnelle
 * 
 */
</script>

<style>
#details-covoiturage {
  margin: 9px auto;
}
#details-covoiturage div.row {
  margin: 16px auto;
}
#details-covoiturage div.row.sans-margin-top {
  margin-top: 0px;
}
#details-covoiturage div.row.padding-bottom {
  padding-bottom: 25px;
}
#details-covoiturage .description {
  margin: 16px auto;
  font-style: italic;
  line-height: 25px;
  font-size: 14px;
}
#details-covoiturage .v-stepper {
  box-shadow: none !important;
}
#details-covoiturage .v-stepper .v-stepper__header .v-divider.complet {
    border-color: #50d8af;
    border-width: 2px;
    text-align: center;
    content: 'Jésus';
}
#details-covoiturage .enveloppe,
#details-covoiturage .coeur,
#details-covoiturage .etoiles {
  display: inline-block;
  margin-right: 7px;
  font-size: 18px;
  cursor: pointer;
}
#details-covoiturage .etoiles {
  margin-right: 0px;
}
#details-covoiturage .enveloppe:hover {
  color: aqua;
}
#details-covoiturage .coeur,
#details-covoiturage .etoiles {
  color: #999;
}
#details-covoiturage .coeur:hover,
#details-covoiturage .etoiles.coloree {
  color: yellow;
}
#details-covoiturage .btn-reserver {
  color: white;
  border-radius: 12px;
  background-color:#17a2b8;
  font-weight: 500;
  font-size: 12px;
  padding: 6px;
}
.dialog-text {
  margin: 25px auto 18px;
  width: 90%;
  padding: 12px;
} 
.textarea-message {
  padding: 9px;
  margin: 36px auto 9px;
  width: 90%;
  display: block;
  background-color: #eef;
}
</style>