import Vue from 'vue';
import VueSocialSharing from 'vue-social-sharing';
import iban from 'iban';
import VuePusher from 'vue-pusher';
import router from './router';
import filters from './filters';
import ApiPlugin from './plugins/api.js';
import constantes from './constantes.js';
import App from './App.vue';
import store from './store'
import vuetify from './plugins/vuetify';


Vue.config.productionTip = false;

function loaded() {
  Vue.use(VueSocialSharing);
  Vue.use(iban);
  Vue.use(VuePusher, {
    api_key: constantes.pusher_app_key,
    options: {
      cluster: constantes.pusher_app_cluster,
      /*encrypted: true,
      authEndpoint: `${process.env.VUE_APP_API_URL}/api/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${store.state.auth.token}`
        }
      }*/
    }
  });

  Vue.use(ApiPlugin, {
    apiBaseUrl: constantes.apiBaseUrl
  });

  Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

  window.app = new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app');
}

window.addEventListener("DOMContentLoaded", loaded, false);