<template>
  <v-container class="gaming">
    <v-row 
      align="center"
      justify="center"
      class="text-center">
      <h2>Activités en voiture</h2>
    </v-row>
    
    <v-row 
      align="center"
      justify="center"
      class="text-center"
      v-show="!activitiesHidden">

      <v-col 
        cols="12"
        lg="4"
        md="4"
        sm="6"
        align="center"
        v-for="activity in activities"
        :key="activity.id">
        
        <div
          class="activity-box"
          :id="activity.id"
          @click="setCurrentActivity(activity.id)">
          
          <div style="background:#17a2b8; color:#fff; padding:7px;">
            {{activity.titre}}
          </div>
          
        </div>
      </v-col>
    </v-row>
    
    <v-row 
      align="center"
      justify="center"
      class="text-center"
      v-show="activitiesHidden">

      <v-col 
        cols="12"
        lg="12"
        md="12"
        sm="12"
        align="center">

        <iframe style="width: 50vw; height:50vh; min-height:360px" :src="'https://www.evangile.tv?activityId=' + currentActivity + '&amp;mode=' + currentMode" />

      </v-col>
    </v-row>

  </v-container>
</template>

<style>
.gaming .activity-box {
  width: 36vw;
  height: 36vw;
  max-width: 270px;
  max-height: 270px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activitiesHidden: false,
      currentMode: 'CPU',
      activities: [{
        id: 'je-decouvre',
        titre: 'Découverte',
        picName: 'je-decouvre.jpg'
      },
      {
        id: 'tic-tac-toe',
        titre: 'Tic Tac Toe',
        picName: 'tic-tac-toe.jpg'
      },
      {
        id: 'puzzle-1',
        titre: 'Puzzles',
        picName: 'pp-puzzle.jpg'
      },
      {
        id: 'actu',
        titre: 'Actu',
        picName: 'actu.jpg'
      }],
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated", 
      "isProfileLoaded"
    ]),
    profile() {
      return this.getProfile;
    }
  },
  mounted() {
    this.activities.forEach(activity => {
      let container = document.querySelector(`#${activity.id}`);
      switch(activity.id) {
        case 'je-decouvre' :
          container.style.backgroundImage = `url(${require("../assets/img/gaming/je-decouvre.jpg")})`;
          break;
        case 'tic-tac-toe' :
          container.style.backgroundImage = `url(${require("../assets/img/gaming/tic-tac-toe.jpg")})`;
          break;
        case 'puzzle-1' :
          container.style.backgroundImage = `url(${require("../assets/img/gaming/pp-puzzle.jpg")})`;
          break;
        case 'actu' :
          container.style.backgroundImage = `url(${require("../assets/img/gaming/actu.jpg")})`;
          break;
        default: break;
      }
      container.style.backgroundSize = 'cover';
    });
  },
  methods: {
    setCurrentActivity: function(id) {
      this.currentActivity = id;
      this.activitiesHidden = true;
    }
  }
}

</script>