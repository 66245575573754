<template>
  <section id="infos-utilisateur">
    <!-- Modifier mon profil -->
    <!-- IBAN -->
    <div class="general" v-show="iban_visible">
      <v-row
        class="text-center"
        align="center"
        justify="center">
        <v-col>
          <h3 class="titre">Mon IBAN</h3>
          <h5 v-show="message != ''">{{ message }}</h5>
          <div class="content">
            <div 
              class="input-group inline-block"
              v-show="!edit_iban && iban == ''">
              
              <div class="bouton" style="width: 100%">
                <input type="button"
                  v-on:click="edit_iban = true"
                  value="Ajouter votre IBAN"
                  class="btn"
                  style="width:100%" />
              </div>
            </div>

            <!-- LECTURE -->
            <div 
              class="input-group inline-block" 
              style="margin: 0 0 39px 0;"
              v-show="!edit_iban && iban != ''">
              <label style="padding-top:18px">Votre IBAN</label>
              <input
                id="iban"
                name="iban"
                type="text"
                v-show="edit_iban"
                :class="{ 'input-erreur' : erreur && !utilisateur.iban}"
                v-model="iban"
                placeholder="FRXXXXXXXXXXXXXXXXXX" />
              <span v-show="!edit_iban && iban"
                class="margin-top-inline-block"
              >
                {{ afficherIBAN() }}
              </span>
              <span 
                class="btn mdi mdi-pen pointer margin-top-inline-block"
                v-show="!edit_iban"
                @click="edit_iban = true">
                Modifier
              </span>
            </div>

            <!-- Edition -->
            <div 
              class="input-group inline-block" 
              v-show="edit_iban">
              <label v-show="edit_iban">Prénom</label>
              <input
                id="prenom"
                name="prenom"
                type="text"
                :class="{ 'input-erreur' : erreur && !prenom}"
                v-model="prenom"
                placeholder="Prénom du titulaire" />
            </div>
            <div class="input-group inline-block" v-show="edit_iban">
              <label v-show="edit_iban">Nom</label>
              <input
                id="nom"
                name="nom"
                type="text"
                :class="{ 'input-erreur' : erreur && !nom}"
                v-model="nom"
                placeholder="Nom du titulaire" />
            </div>
            <div class="input-group inline-block" v-show="edit_iban">
              <label>Votre IBAN</label>
              <input
                id="iban"
                name="iban"
                type="text"
                v-show="edit_iban"
                :class="{ 'input-erreur' : erreur && !utilisateur.iban}"
                v-model="iban"
                placeholder="FRXXXXXXXXXXXXXXXXXX" />
            </div>
            <div class="input-group inline-block" v-show="edit_iban">
              <label v-show="edit_iban">Adresse</label>
              <input
                id="adresse"
                name="adresse"
                type="text"
                :class="{ 'input-erreur' : erreur && !adresse}"
                v-model="adresse"
                placeholder="Adresse de domiciliation" />
            </div>
            <div class="input-group inline-block" v-show="edit_iban">
              <label v-show="edit_iban">Ville</label>
              <input
                id="ville"
                name="ville"
                type="text"
                :class="{ 'input-erreur' : erreur && !ville}"
                v-model="ville"
                placeholder="Ville de domiciliation" />
            </div>
            <div class="input-group inline-block" v-show="edit_iban">
              <label v-show="edit_iban">Code postal</label>
              <input
                id="code_postal"
                name="code_postal"
                type="text"
                :class="{ 'input-erreur' : erreur && !code_postal}"
                v-model="code_postal"
                placeholder="Ville de domiciliation" />
            </div>
            <div class="input-group inline-block" v-show="edit_iban">
              <label>Pays</label>
              <select
                id="pays"
                name="pays"
                v-model="pays"
                 v-show="edit_iban"
                :class="{ 'input-erreur' : erreur && !pays}">
                <option value="FR">France</option>
                <option value="BE">Belgique</option>
                <option value="LU">Luxembourg</option>
                <option value="CH">Suisse</option>
                <option>------------</option>
                <option v-for="pays in liste_pays" :key="pays.code" :value="pays.code">{{ pays.name }}</option>
              </select>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center"
        align="center"
        justify="center"
        v-show="edit_iban">
        <v-col>
          <div class="bouton">
            <input type="button"
              v-on:click="enregistrerIban"
              value="Valider"
              v-show="!loaded"
              :disabled="iban == '' || nom == '' || prenom == '' || pays == ''"
              class="btn"
              style="width:50%" />
            <button  
              class="btn centre valide"
              v-show="loaded"
              disabled="disabled">
              Enregistrement effectué <span class="mdi mdi-check"></span>
            </button>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- PAIEMENTS -->
    <div class="general" v-show="paiements_visible">
      <v-row
        class="text-center"
        align="center"
        justify="center">
        <v-col>
          <h3 class="titre">Mes paiements</h3>
          <h5 v-show="message != ''">{{ message }}</h5>
          <div class="content">
            <v-row 
              class="text-center"
              align="center"
              justify="center"
              v-show="paiements.envoyes.length > 0">
              <div
                v-for="paiement in paiements.envoyes"
                :key="paiement.id"
                style="border:1px solid #ccc; width:90%; margin: 12px auto; background-color: white; padding-top: 6px; padding-bottom: 3px;">
                <v-row>
                  <v-col cols="3">
                    {{ paiement.date_paiement | formatDate }}
                  </v-col>
                  <v-col cols="3">
                    <span class="positif">{{ (( paiement.montant + paiement.fees ) / 100) | price }} &euro;</span>
                  </v-col>
                  <v-col class="text-left" cols="6">
                    {{ paiement.trajet.adresse_depart | ville }} VERS {{ paiement.trajet.adresse_arrivee | ville }} LE {{ paiement.trajet.date | formatDate }}
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <v-row 
              class="text-center"
              align="center"
              justify="center"
              v-show="paiements.envoyes.length == 0">
              <v-col>
                <p>Vous n'avez pas encore effectué de paiement.</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<style scoped>
.margin-top-inline-block {
  display: inline-block;
  margin-top: 8px;
  margin-left: 12px;
}
.positif,
.negatif {
  display: inline-block;
  padding: 7px;
  background-color: green;
  color: white;
  border-radius: 3px;
}
.negatif {
  background-color: orange;
}
</style>

<script>
import { mapGetters } from "vuex";
import listePays from '../../util/liste_pays';
import IBAN from "iban/iban";

export default {
  name: 'argent-utilisateurs',
  data() {
    return {
      message: '',
      nom: '',
      prenom: '',
      adresse: '',
      ville: '',
      code_postal: '',
      pays: '',
      iban: '',
      paiements: {
        'envoyes': [],
        'recus': []
      },
      erreur: false,
      loaded: false,
      iban_visible: false,
      paiements_visible: false,
      edit_iban: false,
      liste_pays: listePays,
    };
  },
  mounted() {
    this.iban_visible = this.$route.query.type == 'comptes';
    this.paiements_visible = this.$route.query.type == 'paiements';
    
    if (this.iban_visible) {
      this.utilisateur = this.profile;
      this.$root.$emit("start_loading");

      // Récupération des informations relatives à l'utilisateur
      this.$api.get('/utilisateurs/' + this.profile.id + '/iban')
      .then(res => {
        // Affectation du résultat dans la variable utilisateur du composant
        if (res.data && res.data != "") {
          this.iban = res.data.IBAN;
          this.adresse = res.data.adresse;
          this.ville = res.data.ville;
          this.code_postal = res.data.code_postal;
          this.pays = res.data.pays;
          this.id = res.data.id;
          this.nom = res.data.nom;
          this.prenom = res.data.prenom;
          this.edit_iban = !this.iban || this.iban == '';
        }
      })
      .finally(() => this.$root.$emit("hide_loading"));
    }
    else if (this.paiements_visible) {
      this.$root.$emit("start_loading");
      // Récupération des informations relatives à l'utilisateur
      this.$api.get('/utilisateurs/' + this.profile.id + '/paiements?filter[status]=PAIEMENT_REUSSI')
      .then(res => {

        // Affectation du résultat dans la variable paiements du composant
        for (let i=0; i<res.data.data.length; i++) {
          let paiement = res.data.data[i];
          
          if (paiement.montant > 0)
            this.paiements.envoyes.push(paiement);
          else 
            this.paiements.recus.push(paiement);
        }
      })
      .finally(() => this.$root.$emit("hide_loading"));
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
    profile() {
      return this.getProfile;
    },
  },

  methods: {
    afficherIBAN: function() {
      return IBAN.printFormat(this.iban);
    },
    enregistrerIban: function() {
      console.log('Enregistrement IBAN');
      this.erreur = this.iban == '';
      if (!this.erreur)
        this.erreur = this.nom == '';
      if (!this.erreur)
        this.erreur = this.prenom == '';
      if (!this.erreur)
        this.erreur = this.adresse == '';
      if (!this.erreur)
        this.erreur = this.ville == '';
      if (!this.erreur)
        this.erreur = this.code_postal == '';

      if (!this.erreur) {
        var bodyFormData = new FormData();
        bodyFormData.append('iban', this.iban);
        bodyFormData.append('prenom', this.prenom);
        bodyFormData.append('nom', this.nom);
        bodyFormData.append('adresse', this.adresse);
        bodyFormData.append('ville', this.ville);
        bodyFormData.append('code_postal', this.code_postal);
        bodyFormData.append('bic', null);

        this.$api.post('/utilisateurs/' + this.profile.id + '/iban', bodyFormData)
        .then(res => {
          console.log(res);
          this.message = 'Votre IBAN a bien été enregistré.';
          setTimeout(() => this.$router.push({ name: 'utilisateurs' }), 7500);
        })
        .catch(err => {
          console.error(err);
          this.erreur = true;
          this.message = "Le service est momentanément inaccessible. Veuillez réessayer plus tard.";
        });
      }
    }
  },
};

</script>