<template>
    <v-container>
        <v-row 
            id="main" style="min-height:70vh;"
            class="text-center"
            justify="center">
            <v-col cols="12"
                lg="10"
                md="10"
                sm="12">
                <h1 v-show="titre && titre != ''" style="font-size: 1.08em; font-weight:bold; text-align: left;">{{ titre }}</h1>
                <div class="text-justify" style="margin-bottom: 27px">
                  Prenez un covoiturage pas cher en {{ nom_region }} et partout en France à des prix défiant toute concurrence avec un service de qualité remarquable.
                </div>
                        <router-link class="btn" :to="{ name: 'inscription'}" style="color:white">
                          Rejoins la communauté
                        </router-link>

                <v-row class="covoiturages">
                    <v-col
                        cols="6"
                        v-for="departement in region.departements"
                        :key="departement.num_dep">
                        <a :href="'https://www.simoneverte.fr/covoiturage-departement/' + departement.num_dep ">Covoiturage {{ departement.dep_name }} </a>
                    </v-col>
                </v-row>
                <v-row class="covoiturages">
                    <v-col
                        cols="12"
                        style="margin-top: 18px; margin-bottom: 34px;">
                        <i v-if="nom_region != null">Voyagez en covoiturage pas cher en {{ nom_region }}.</i>
                        <br />
                        <br />
                        <router-link class="btn" :to="{ name: 'proposer'}" style="color:white">
                          Proposer un trajet
                        </router-link>
                    </v-col>
                </v-row>
                <v-row 
                    justify="center"
                    style="margin-top: 36px">
                    
                    <v-col
                      cols="12"
                      lg="6"
                      md="6"
                      sm="12">

                      <div 
                        v-on:click="goToProposerTrajet()">

                        <img src="@/assets/img/banniere_commission.webp" style="width: 100%; cursor:pointer" />

                      </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import router from '../router';
import listeDepartementsFrance from '../util/liste_departements_france';

export default {
  data() {
    return {
      titre: '',
      nom_region: '',
      departements: listeDepartementsFrance,
      region: { nom: '', departements: []}
    }
  },
  mounted() {
    // Titre de l'onglet
    var description = '';
    
    // Cas de la route covoiturages-vers-ville
    if (this.$route.params.nom_region) {
      this.nom_region = this.$route.params.nom_region;
      
      let regions = [];

      this.departements.forEach(departement => {
        let regionName = String(departement["region_name"]);
        
        let region = regions.filter(r => r.nom === regionName)[0];
        
        if (!region) {
          region = {};
          region.nom = regionName;
          region.departements = [];
          region.departements.push(departement)
          regions.push(region);
        } else {
          region.departements.push(departement)
          regions.map(r => r.nom === regionName ? region : r);
        }
      });

      this.region = regions.filter(r => r.nom === this.nom_region)[0];
      
      // Appel de la recherche de trajet correspondant aux criteres
      this.titre = 'Voyagez en covoiturage en ' + this.nom_region;
      document.title = 'Voyagez en covoiturage en ' + this.nom_region ;
      description = 'Voyage pas cher en covoiturage en ' + this.nom_region;

      document.title += ' | SimoneVerte.fr';
      description += ', notre service déssert particulièrement ' + this.region.departements.join(', ') + ' et bien d\'autres départements.'
      description += 'Prenez la route en toute confiance.';
      
      this.$root.$emit("change_meta_description", description);
    }
  },
  unmounted() {
    this.$root.$emit("reset_meta_description");
  },
  methods: {
    goToProposerTrajet: function() {
      router.push({ name: 'proposer'});
    }
  }
}
</script>

<style>
.entete {
  font-size: 0.90em;
  border-radius: 12px;
  margin: 12px 0px;
  background-color: #ddf;
}
.entete .information-recherche {
  font-style: italic;
}
.entete .calendrier-date {
  color: #fff;
  background: #20c997;
  border-color: #20c997;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: 12px;
  padding: 12px 4px;
}
.entete .calendrier-date.before:hover,
.entete .calendrier-date.after:hover {
  font-weight: bold;
  cursor: pointer;
}
.entete .calendrier-date.now {
  height: 63px;
  border-left: 1px solid;
  border-right: 1px solid;
  padding-top: 1.2rem;
  cursor: auto;
}
.entete .calendrier-date.before,
.entete .calendrier-date.after {
  color: #17a2b8;
  border-color: #17a2b8;
  background: #fff;
  font-weight: normal;
}
.entete .calendrier-date.before {
  border-left: 1px solid;
}
.entete .calendrier-date.after {
  border-right: 1px solid;
}
.entete .calendrier-date.impossible,
.entete .calendrier-date.impossible:hover,
.entete .calendrier-date.before.impossible:hover,
.entete .calendrier-date.after.impossible:hover {
  color: #777;
  border-color: #777;
  background: #ccc;
  font-weight: normal;
  cursor: auto;
} 
.filtres {
  margin: 18px auto;
}
.filtres div span {
  color: #fff;
  font-size: 0.84em;
  display: inline-block;
  margin: 7px 7px 0 7px;
}
.entete div span div {
  color: #333;
  font-weight: 600;
}
.filtre-bouton {
  border-radius: 12px;
  height: 36px;
  border: 1px solid;
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 3px;
  width: 90%;
  background-color: #777;
  border-color: white;
  cursor: pointer;
}
.filtre-bouton.active,
.filtre-bouton.active-male {
  background-color: #17a2b8;
  border-color: white;
}
.filtre-bouton.active-female {
  background-color: #fd6c9e;
  border-color: white;
}
</style>
