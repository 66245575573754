<template>
    <div id="main" style="min-height:70vh;">
        <section id="validation">
            <div class="validation-content">
                <label class="titre">
                  Erreur {{ code_erreur }} !
                </label>
                <div>
                  <h4>Désolé <span class="mdi mdi-emoticon-sad"></span> ! La page que vous cherchez n'existe pas ou plus.</h4>
                  <p>Repassez par <router-link :to="{name: 'home'}">l'accueil</router-link> pour mieux vous retrouver.</p>               
                </div>
            </div> 
        </section>
    </div>
</template>

<style>
#validation {
    margin:auto;
    width: 100%;
    padding: 36px;
}

.validation-content {
    text-align: center;
}

.titre {
  color: #17a2b8;
  margin: 9px 0 39px 0;
  font-size: 25px;
  font-weight: 600;
}
</style>

<script>
export default {
  data() {
    return {
      code_erreur: null,
    };
  },
  mounted() {
    // Titre de l'onglet
    document.title = "Oops cette page n'existe pas. Simoneverte, le covoiturage longue distance, pas cher et innovant | SimoneVerte.fr";
    this.code_erreur = 404;
  }
}
</script>