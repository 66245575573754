<template>
  <div>
    <h2>Administration des trajets</h2>

    <v-row 
      justify="center" 
      align="center"
      class="covoiturages" 
      v-if="trajets.length > 0">
      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="12" 
        class="a_la_une"
        v-for="covoiturage in trajets"
        :key="covoiturage.id">
        <carte-covoiturage :covoiturage="covoiturage" type-carte="SIMPLE" />
      </v-col>
      <v-col
        cols="12">
        <v-row>
          <v-col
            v-on:click="goToPage(1)"
            v-show="current_page > 4"
          >
            <span>
              1
            </span>
          </v-col>
          
          <v-col
            v-show="current_page > 4"
          >
            <span>
              ...
            </span>
          </v-col>

          <v-col
            v-for="i in (current_page + 3)"
            :key="i"
          >
            <span 
              v-on:click="goToPage(i)" 
              v-show="i < last_page"
              style="cursor:pointer">
              {{ i }}
            </span>
          </v-col>
          <v-col
            v-show="(current_page + 3) < last_page"
          >
            <span>
              ...
            </span>
          </v-col>
          <v-col
            v-on:click="goToPage(last_page)"
          >
            <span>
              {{ last_page }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-else
    >
      <p>Il n'y a pas de trajets.</p>
    </v-row>
  </div>
</template>

<script>
import CarteCovoiturage from '../../components/CarteCovoiturage.vue';

export default {
  components: { CarteCovoiturage },
  data() {
    return {
      trajets: [],
      current_page: 1,
      last_page: 1,
    };
  },
  mounted() {
    this.$root.$emit("start_loading");
    this.load();
  },
  methods: {
    load: function() {
      this.$api.get('/trajets?filter[actif]=1&page=' + this.current_page)
      .then(res => { 
        this.trajets = res.data.data;
        this.current_page = res.data.meta.current_page;
        this.last_page = res.data.meta.last_page;
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => this.$root.$emit("hide_loading"));
    },
    goToPage: function(i){ this.current_page = i; this.load(); }
  }
  
}
</script>

