<template>
    <v-container class="dashboard">
		<v-row 
          class="text-center"
          align="center"
          justify="center">
            <v-col>
                <v-row id="main-accueil-utilisateur" style="min-height:70vh;">
					<v-col cols="12">
                        <h1 class="title-lv1 pa-0">Mentions légales</h1>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <h5>
                                    Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la
                                    confiance en l’économie numérique, il est précisé aux utilisateurs du site
                                    www.simoneverte.fr l’identité des différents intervenants dans le cadre de sa
                                    réalisation et de son suivi.
                                </h5>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Editeur du site</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4">
                                                    <strong>SimoneVerte par Groupe Sentinelles</strong><br>
                                                    <strong>7 rue Kléber, 29200, Brest</strong><br>
                                                    <strong>Téléphone </strong>: 06 67 89 75 60<br>
                                                    <strong>Email </strong>: contact@simoneverte.fr<br>
                                                    <strong>Site Web </strong>: www.simoneverte.fr<br>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Conditions d'utilisation</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Le site accessible par les url suivants : www.simoneverte.fr est exploité dans le respect de la législation française. L'utilisation de ce site est régie par les présentes conditions générales. En utilisant le site, vous reconnaissez avoir pris connaissance de ces conditions et les avoir acceptées. Celles-ci pourront être modifiées à tout moment et sans préavis par la société Simone Verte.
Simone Verte ne saurait être tenue pour responsable en aucune manière d’une mauvaise utilisation du service.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Limitation de responsabilité</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Les informations contenues sur ce site sont aussi précises que possibles et le site est périodiquement remis à jour, mais peut toutefois contenir des inexactitudes, des omissions ou des lacunes. Si vous constatez une lacune, erreur ou ce qui paraît être un dysfonctionnement, merci de bien vouloir le signaler par email en décrivant le problème de la manière la plus précise possible (page posant problème, action déclenchante, type d’ordinateur et de navigateur utilisé, …).<br>
                                                    Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, Simone Verte ne saurait être tenue responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de données consécutives au téléchargement.<br><br>
                                                    Les photos sont non contractuelles.<br><br>
                                                    Les liens hypertextes mis en place dans le cadre du présent site internet en direction d'autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de Simone Verte.

                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Litiges</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Les présentes conditions sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société Simone Verte. La langue de référence, pour le règlement de contentieux éventuels, est le français.<br><br>
                                                    <strong><em>Déclaration à la CNIL</em></strong><br>
                                                    <em>"Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique, aux fichiers et aux libertés, le site a fait l'objet d'une déclaration auprès de la Commission nationale de l'informatique et des libertés (www.cnil.fr)."</em>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Droit d'accès</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    En application de cette loi, les internautes disposent d’un droit d’accès, de rectification, de modification et de suppression concernant les données qui les concernent personnellement. Ce droit peut être exercé par voie postale auprès de Simone Verte 7 rue Kléber, 29200, Brest ou par voie électronique à l’adresse email suivante : contact@simoneverte.fr<br><br>
                                                    Les informations personnelles collectées ne sont en aucun cas confiées à des tiers hormis pour l’éventuelle bonne exécution de la prestation commandée par l’internaute.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Confidentialité</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Vos données personnelles sont confidentielles et ne seront en aucun cas communiquées à des tiers hormis pour la bonne exécution de la prestation.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Propriété intellectuelle</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Tout le contenu du présent site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société Simone Verte à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.<br><br>
                                                    Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de Simone Verte. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.<br><br>
                                                    Simone Verte est identiquement propriétaire des "droits des producteurs de bases de données" visés au Livre III, Titre IV, du Code de la Propriété Intellectuelle   (loi n° 98-536 du 1er juillet 1998) relative aux droits d'auteur et aux bases de données.<br><br>
                                                    <strong><em>Les utilisateurs et visiteurs du site internet peuvent mettre en place un hyperlien en direction de ce site, mais uniquement en direction de la page d’accueil, accessible à l’URL suivante : www.simoneverte.fr, à condition que ce lien s’ouvre dans une nouvelle fenêtre. En particulier un lien vers une sous page (« lien profond ») est interdit, ainsi que l’ouverture du présent site au sein d’un cadre (« framing »), sauf l'autorisation expresse et préalable de Simone Verte.</em></strong><br><br>
                                                    Pour toute demande d'autorisation ou d'information, veuillez nous contacter par email : contact@simoneverte.fr. Des conditions spécifiques sont prévues pour la presse.<br><br>
                                                    <strong><em>Par ailleurs, la mise en forme de ce site a nécessité le recours à des sources externes dont nous avons acquis les droits ou dont les droits d'utilisation sont ouverts : Seosight, Designed by themefire Developed by Crumina. Les illustrations ont été acquises sur Adobe Stock.</em></strong>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Hébergeur</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    <strong>
                                                        OVH<br>
                                                        OVH SAS, 15, place de la Nation, 75011 Paris<br>
                                                        Site Web : <a href="https://www.ovh.com">www.ovh.com</a>
                                                    </strong>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Conditions de service</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Ce site est proposé en langages <strong>HTML5</strong> et <strong>CSS3</strong>, pour un meilleur confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Safari, Firefox, Chrome,...
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Informations et exclusion</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Simone Verte met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de Simone Verte, et signaler toutes modifications du site qu'il jugerait utile. Simone Verte n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Cookies</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Pour des besoins de statistiques et d'affichage, le présent site utilise des cookies. Il s'agit de petits fichiers textes stockés sur votre disque dur afin d'enregistrer des données techniques sur votre navigation. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Liens hypertextes</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Les sites internet de Simone Verte peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet.
                                                    <br><br>
                                                    Simone Verte ne dispose d'aucun moyen pour contrôler les sites en connexion avec ses sites internet. Simone Verte ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se conformer à leurs conditions d'utilisation.
                                                    <br><br>
                                                    Les utilisateurs, les abonnés et les visiteurs des sites internet de Simone Verte ne peuvent mettre en place un hyperlien en direction de ce site sans l'autorisation expresse et préalable de Simone Verte.
                                                    <br><br>
                                                    Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un des sites internet de Simone Verte, il lui appartiendra d'adresser un email accessible sur le site afin de formuler sa demande de mise en place d'un hyperlien. Simone Verte se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision. 
                                                    En outre, le renvoi sur un site internet pour compléter une information recherchée ne signifie en aucune façon que Simone Verte reconnaît ou accepte quelque responsabilité quant à la teneur ou à l'utilisation dudit site.
                                                    <br><br>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Précautions d'usage</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Il vous incombe par conséquent de prendre les précautions d'usage nécessaires pour vous assurer que ce que vous choisissez d'utiliser ne soit pas entaché d'erreurs voire d'éléments de nature destructrice tels que virus, trojans, etc....  
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Responsabilité</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Aucune autre garantie n'est accordée au client, auquel incombe l'obligation de formuler clairement ses besoins et le devoir de s'informer. Si des informations fournies par Simone Verte apparaissent inexactes, il appartiendra au client de procéder lui-même à toutes vérifications de la cohérence ou de la vraisemblance des résultats obtenus. Simone Verte ne sera en aucune façon responsable vis à vis des tiers de l'utilisation par le client des informations ou de leur absence contenues dans ses produits y compris un de ses sites Internet.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                md="8"
                                offset-md="2">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header style="background:#20c997">
                                            <strong>Contactez-nous</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="mt-4 text-justify">
                                                    Simone Verte est à votre disposition pour tous vos commentaires ou suggestions. Vous pouvez nous écrire en français par courrier électronique à : contact@simoneverte.fr.
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
					</v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
  
  computed: {
    profile() {
      return this.getProfile;
    }
  }
}

</script>