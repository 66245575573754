import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
// import Promotions from '../views/Promotions.vue';
import Blog from '../views/blog/Blog.vue';
import BlogArticle from '../views/blog/BlogArticle.vue';
import Trouver from '../views/Trouver.vue';
import Departements from '../views/Departements.vue';
import Regions from '../views/Regions.vue';
import Resultats from '../views/Resultats.vue';
import Inscription from '../views/Inscription.vue';
import Trajet from '../views/Trajet.vue';
import Connexion from '../views/Connexion.vue';
import Validation from '../views/Validation.vue';
import ChatCovoiturage from '../views/ChatCovoiturage.vue';
import Details from '../views/Details.vue';
import Paiement from '../views/Paiement.vue';
import ConditionsGenerales from '../views/ConditionsGenerales.vue';
import NousConnaitre from '../views/NousConnaitre.vue'
import MentionsLegales from '../views/MentionsLegales.vue';
import News from '../views/News.vue';
import Gaming from '../views/Gaming.vue';
import Sudoku from '../views/games/Sudoku.vue';

import Utilisateurs from '../views/Utilisateurs.vue';
import AccueilAdmin from '../views/admin/AccueilAdmin';
import EmailAdmin from '../views/admin/EmailAdmin';
import DashboardAdmin from '../views/admin/DashboardAdmin';
import InscriptionAdmin from '../views/admin/InscriptionAdmin';
import TrajetsAdmin from '../views/admin/TrajetsAdmin';
import OtpMobileAdmin from '../views/admin/OtpMobileAdmin';
import AccueilUtilisateurs from '../views/utilisateurs/AccueilUtilisateurs.vue';
import InfosUtilisateurs from '../views/utilisateurs/InfosUtilisateurs.vue';
import AvisUtilisateurs from '../views/utilisateurs/AvisUtilisateurs.vue';
import ArgentUtilisateurs from '../views/utilisateurs/ArgentUtilisateurs.vue';
import NotificationsUtilisateurs from '../views/utilisateurs/NotificationsUtilisateurs.vue';
import TrajetsUtilisateurs from '../views/utilisateurs/TrajetsUtilisateurs.vue';
import ActivitesUtilisateurs from '../views/utilisateurs/ActivitesUtilisateurs';
import { AUTH_LOGOUT } from '../store/actions/auth';
// Erreur
import Erreur from '../views/Erreur.vue';


Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (localStorage.getItem('token') === null) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  let expires = localStorage.getItem('expires');
  if (localStorage.getItem('token') !== null
    && expires !== null
  ) {
    // Jeton valide
    if (expires > (new Date()).getTime()) {
      next();
    } 
    // Jeton invalide
    else {
      store.dispatch(AUTH_LOGOUT);
      next({ 
        name: 'connexion', 
        query: { 
          'deconnexion_automatique' : true,
          'referer' : to.path 
        }
      });
    }
    return;
  }
  next("/vous-identifier?referer=" + to.path);
};

const routes = [
  {
    path: '/',
    name: 'welcome',
    components: { 
        default: Home,
        'vue-principale': Home
    }
  },
  {
    path: '/home',
    name: 'home',
    components: { 
        default: Home,
        'vue-principale': Home
    }
  },
  /*** Pages d'erreur ***/
  {
    path: '/erreur404',
    name: 'erreur404',
    components: { 
        default: Erreur,
        'vue-principale': Erreur
    },
      props: {
          code_erreur: '404'
      }
  },
  {
    path: '/erreur401',
    name: 'erreur401',
    components: { 
        default: Erreur,
        'vue-principale': Erreur
    },
      props: {
          code_erreur: '401'
      }
  },
  /*** Fin pages erreur  ***/
  {
    path: '/devenir-simone',
    name: 'inscription',
    components: { 
        default: Inscription,
        'vue-principale': Inscription
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/news-whatsapp-2022-10-25',
    name: 'news',
    components: { 
        default: News,
        'vue-principale': News
    },
  },
  {
    path: '/en-voiture',
    name: 'proposer',
    components: { 
        default: Trajet,
        'vue-principale': Trajet
    },
  },
  {
    path: '/games/sudoku',
    name: 'sudoku',
    components: { 
        default: Sudoku,
        'vue-principale': Sudoku
    },
  },
  {
    path: '/promotions',
    name: 'promotions',
    components: { 
        default: Home,
        'vue-principale': Home
    },
  },
  {
    path: '/blog-covoiturage',
    name: 'blog',
    components: { 
        default: Blog,
        'vue-principale': Blog
    },
    children: [
      {
        path: '/blog-covoiturage/:nom_article',
        name: 'blog-article',
        components: {
          'vue-interieure-blog': BlogArticle
        },
      }
    ]
  },
  {
    path: '/trouver-covoiturage',
    name: 'trouver',
    components: { 
        default: Trouver,
        'vue-principale': Trouver
    }
  },
  {
    path: '/gaming',
    name: 'gaming',
    components: { 
        default: Gaming,
        'vue-principale': Gaming
    }
  },
  {
    path: '/resultats',
    name: 'resultats',
    components: { 
        default: Resultats,
        'vue-principale': Resultats
    },
    props: {
        adresse_depart: '',
        adresse_arrivee: '',
        date_depart: '08/09/2020',
        nb_voyageurs: 1
    }
  },
  {
    path: '/covoiturage-region/:nom_region',
    name: 'covoiturages-region',
    components: { 
        default: Regions,
        'vue-principale': Regions
    },
  },
  {
    path: '/covoiturage-departement/:code_departement',
    name: 'covoiturages-departement',
    components: { 
        default: Departements,
        'vue-principale': Departements
    },
  },
  {
    path: '/covoiturages/:nom_ville',
    name: 'covoiturages-vers-ville',
    components: { 
        default: Resultats,
        'vue-principale': Resultats
    },
  },
  {
    path: '/covoiturages/:nom_ville_depart/:nom_ville_arrivee',
    name: 'covoiturages-villes',
    components: { 
        default: Resultats,
        'vue-principale': Resultats
    },
  },
  {
    path: '/covoiturage/:id',
    name: 'covoiturage',
    components: { 
        default: Details,
        'vue-principale': Details
    },
    alias: '/t/:id',
  },
  {
    path: '/chat-covoiturage/:id',
    name: 'chat-covoiturage',
    components: { 
        default: ChatCovoiturage,
        'vue-principale': ChatCovoiturage
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/paiement/:base64_id_nbplace',
    name: 'paiement',
    components: { 
        default: Paiement,
        'vue-principale': Paiement
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vous-identifier',
    name: 'connexion',
    components: { 
        default: Connexion,
        'vue-principale': Connexion
    },
      beforeEnter: function(to, from, next) {
          localStorage.removeItem('token');
          next();
          return;
      },
  },
  {
    path: '/recuperer-un-mot-de-passe-valide',
    name: 'motDePasseOublie',
    components: { 
        default: Connexion,
        'vue-principale': Connexion
    },
  },
  {
    path: '/reset-mot-de-passe/:hash/:email',
    name: 'resetMotDePasse',
    components: { 
        default: Connexion,
        'vue-principale': Connexion
    },
  },
  {
    path: '/validation-email/:hash',
    name: 'validation',
    components: { 
        default: Validation,
        'vue-principale': Validation
    }
  },
  {
    path: '/nous-connaitre',
    name: 'nous-connaitre',
    components: { 
        default: NousConnaitre,
        'vue-principale': NousConnaitre
    }
  },
  {
    path: '/conditions-generales',
    name: 'conditions',
    components: { 
        default: ConditionsGenerales,
        'vue-principale': ConditionsGenerales
    }
  },
  {
    path: '/mentions-legales',
    name: 'mentions',
    components: { 
        default: MentionsLegales,
        'vue-principale': MentionsLegales
    }
  },
  {
    path: '/utilisateurs',
    name: 'utilisateurs',
    components: { 
        default: Utilisateurs,
        'vue-principale': Utilisateurs
    },
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/utilisateurs/admin',
        name: 'admin',
        components: { 
            default: AccueilAdmin,
            'vue-interieure': AccueilAdmin
        },
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: '/utilisateurs/admin/dashboard',
            name: 'dashboardAdmin',
            components: {
              'vue-interieure-admin': DashboardAdmin
            },
            beforeEnter: ifAuthenticated,
          },
          {
            path: '/utilisateurs/admin/emails',
            name: 'emailAdmin',
            components: {
              'vue-interieure-admin': EmailAdmin
            },
            beforeEnter: ifAuthenticated,
          },
          {
            path: '/utilisateurs/admin/inscriptions',
            name: 'inscriptionAdmin',
            components: {
              'vue-interieure-admin': InscriptionAdmin
            },
            beforeEnter: ifAuthenticated,
          },
          {
            path: '/utilisateurs/admin/trajets',
            name: 'trajetsAdmin',
            components: {
              'vue-interieure-admin': TrajetsAdmin
            },
            beforeEnter: ifAuthenticated,
          },
          {
            path: '/utilisateurs/admin/otp-mobile',
            name: 'otpMobileAdmin',
            components: {
              'vue-interieure-admin': OtpMobileAdmin
            },
            beforeEnter: ifAuthenticated,
          }
        ]
      },
      {
        path: '/utilisateurs/',
        name: 'accueilUtilisateurs',
        components: {
          'vue-interieure': AccueilUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/utilisateurs/infos',
        name: 'infosUtilisateurs',
        components: {
          'vue-interieure': InfosUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/utilisateurs/avis',
        name: 'avisUtilisateurs',
        components: {
          'vue-interieure': AvisUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/utilisateurs/trajets',
        name: 'trajetsUtilisateurs',
        components: {
          'vue-interieure': TrajetsUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/utilisateurs/argent',
        name: 'argentUtilisateurs',
        components: {
          'vue-interieure': ArgentUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/utilisateurs/notifications',
        name: 'notifsUtilisateurs',
        components: {
          'vue-interieure': NotificationsUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/utilisateurs/en-voiture',
        name: 'activitesUtilisateurs',
        components: {
          'vue-interieure': ActivitesUtilisateurs
        },
        beforeEnter: ifAuthenticated,
      },
    ]
  },
  
  {
    path: '/*',
    name: '*',
    components: { 
      default: Erreur,
      'vue-principale': Erreur
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  let expires = localStorage.getItem('expires');
  if (localStorage.getItem('token') !== null
    && expires !== null
  ) {
    // Jeton valide
    if (expires > (new Date()).getTime()) {
      localStorage.setItem('expires', parseInt(expires) + 60 * 1000);
    } 
  }
  next();
});


export default router;
