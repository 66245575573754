<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
      style="text-align:center">
      <v-col>
        <h1 class="titre">Sudoku</h1>
        <p>
            Faites une grille de Sudoku avec vos compagnons de covoiturage pendant votre trajet.
        </p>
        <v-row
          align="center"
          justify="center">
          <v-col cols="12" lg="4" md="6" sm="12" style="margin-bottom: 18px">
            <div>
              <label>Niveau:</label>&nbsp; &nbsp;
              <select class="select" v-model="difficulty"> 
                <option v-for="(difficulty, idx) in difficulties" :key="idx">{{difficulty}}</option>
              </select> 
              <button class="btn" @click="newGame">Nouvelle partie</button>
            </div>
            <div v-if="wonGame">
              <h2 class="wonGame">Gagné ! Félicitations !</h2>
            </div>
          </v-col>
        </v-row>
        <SudokuGrid />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import SudokuGrid from '@/components/games/SudokuGrid';

export default {
  name: 'app',
  components: {
    SudokuGrid
  },
  data() {
    return {
      difficulty: null
    }
  },
  computed: {
    ...mapGetters([
      'getDifficulties', 'getWonGame'
    ]),
    difficulties() {
      return this.getDifficulties;
    },
    wonGame() {
      return this.getWonGame;
    }
  },
  created() {
    this.$store.commit('initGrid');
    this.difficulty = this.difficulties[0];
    
    // Titre de l'onglet
    document.title = 'Sudoku | Faites une grille pendant votre covoiturage avec vos compagnons de route. SimoneVerte, le nouveau site covoiturage longue distance. Pas cher et carrément plus cool | SimoneVerte.fr';
  },
  methods: {
    newGame() {
      this.$store.commit('initGrid', this.difficulty);
    }
  }
}
</script>

<style>
body {
  font-family: Arial, Helvetica, sans-serif;
}

.wonGame {
  color: green;
  text-decoration: underline;
}  
</style>