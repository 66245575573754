<template>
	<v-container class="dashboard">
    
		<v-row 
      class="text-center"
      align="center"
      justify="center">
      <v-col cols="12"
        lg="8"
        md="8"
        sm="12">
      <h3 class="titre">Vos notifications</h3>
      </v-col>
      <v-col cols="12"
        lg="8"
        md="8"
        sm="12">
        <v-container>
          <v-card v-for="notification in notifications" :key="notification.id">
            <v-card-title class="d-flex"
              ><v-input
                disabled
                v-model="notifications"
                :success-messages="notification.type_notif_code"
                >
                {{ notification.date_publication }}
              </v-input>
            </v-card-title>
            <v-divider></v-divider>
          </v-card>
        </v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import constantes from '../../constantes';
import { mapGetters } from "vuex";
export default{
  data(){
    return {
      notifications: {},
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
    profile() {
      return this.getProfile;
      },
  },
  created() {
    this.showNotifications();
  },  
  methods: {
    showNotifications: function() {
      if (this.profile.id)
        this.$api.get(constantes.apiBaseUrl + '/utilisateurs/' + this.profile.id + '/notifications')
        .then(res => {
          this.notifications = res.data;
          console.log(this.notifications);
        })
    },
  }
};
</script>
