<template>
    <div id="main" style="min-height:70vh;">
        <section id="blog">
            <div class="blog-content">
                <v-container>
                    <div v-if="$route.params.nom_article == '' || $route.params.nom_article == 'simoneverte' || $route.path == '/blog-covoiturage'">
                        <h1 class="titre">Le blog des covoitureurs de SimoneVerte</h1>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12">
                                <div class="main-image-blog"></div>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12">
                                <p class="description">
                                    Nous croyons que le covoiturage a toute sa place dans notre société. Que ce soit de façon
                                    occasionnelle ou très régulière, nous ne pouvons plus concevoir nos déplacements sans tenir
                                    compte de cette pratique vertueuse. Aussi, dans ce blog, nous vous partageons des articles 
                                    sur notre plateforme mais aussi de façon plus générale sur le covoiturage. Voilà, nous 
                                    n'avons plus qu'à vous souhaiter bonne route et bonne lecture en mode #coolvoiturage !
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="3"
                                md="3"
                                sm="6">
                                <button 
                                    class="btn rose-smv" 
                                    :class="{'rose-smv': etatCategories.simoneverte, 'disabled': !etatCategories.simoneverte}" 
                                    v-on:click="switchEtat(0)">
                                    #coolvoiturage
                                </button>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="3"
                                md="3"
                                sm="6">
                                <button 
                                    class="btn bleu-smv" 
                                    :class="{'bleu-smv': etatCategories.pratique, 'disabled': !etatCategories.pratique}"
                                    v-on:click="switchEtat(1)">
                                    Pratico-pratique
                                </button>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="3"
                                md="3"
                                sm="6">
                                <button 
                                    class="btn jaune-smv"
                                    :class="{'jaune-smv': etatCategories.economies, 'disabled': !etatCategories.economies}"
                                    v-on:click="switchEtat(2)">
                                    Economies
                                </button>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="3"
                                md="3"
                                sm="6">
                                <button
                                    class="btn vert-smv" 
                                    :class="{'vert-smv': etatCategories.tendances, 'disabled': !etatCategories.tendances}"
                                    v-on:click="switchEtat(3)">
                                    Tendances
                                </button>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                                class="article"
                                v-for="(article, index) in articlesFiltres.reverse().slice(0, 12)"
                                :key="index">
                                <div>
                                    <div class="head" :class="[article.code]">

                                    </div>
                                    <div class="description">
                                        <router-link :to="{name: 'blog-article', params: { nom_article: article.code }}">
                                            {{ article.titre }}
                                        </router-link>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>
                        <div class="fil-ariane">
                            <router-link :to="{ name: 'home'}">SimoneVerte</router-link> > <router-link :to="{ name: 'blog'}">Le blog des covoitureurs</router-link>
                        </div>
                        <router-view name="vue-interieure-blog" :key="$route.fullPath" />
                    </div>
                </v-container>
            </div>
        </section>
    </div>
</template>

<style>
#blog {
    margin: auto;
    width: 100%;
}

.blog-content {
    text-align: center;
}

#blog .blog-content .fil-ariane {
    text-align: left;
    margin: 12px auto 25px;
}

#blog .blog-content .titre {
    margin: 27px auto 36px;
}

#blog .blog-content .main-image-blog {
  background-image: url('~@/assets/img/banners/top3.jpg');
  background-size: cover;
  border-radius: 25px;
  background-position: center;
  width: 100%;
  height: 216px;
  border: 1px solid #ddd;
}

#blog .blog-content .description {
    text-align: justify;
}

#blog .blog-content .btn {
    padding: 7px 18px;
}

#blog .blog-content .btn.rose-smv,
#blog .blog-content .btn.rose-smv,
#blog .blog-content .btn.rose-smv,
#blog .blog-content .btn.rose-smv {
    opacity: 1;
}

#blog .blog-content .btn.rose-smv {
    background-color: #fd6c9e;
}

#blog .blog-content .btn.bleu-smv {
    background-color: #17a2b8;
}

#blog .blog-content .btn.jaune-smv {
    background-color: #ffc400;
}

#blog .blog-content .btn.vert-smv {
    background-color: #20c997;
}

#blog .blog-content .btn.disabled {
    opacity: 0.48;
}

#blog .blog-content .article {
    text-align: left;
    padding: 25px;
}

#blog .blog-content .article > div {
    width: 100%;
    height: 100%;
    border: 1px solid #999;
    border-radius: 9px;
    overflow: hidden;
}

#blog .blog-content .article .head {
    height: 160px;
    background-image: url("../../assets/img/banners/md/top6.webp");
    background-size: cover;
    background-position: center;
}

#blog .blog-content .article .head.conducteur-covoiturage-comment-faire-economies {
    background-image: url("../../assets/img/blogs/md/economies-conducteur.jpg");
}

#blog .blog-content .article .description {
    height: 90px;
    padding: 12px;
    background-color: #ddd;
}

#blog .blog-content .input-group {
    margin: 13px 0;
}

#blog .titre {
  color: #17a2b8;
  margin: 0 0 16px 0;
  font-size: 25px;
  font-weight: 600;
}

#blog .fil-ariane {
  text-align: left;
  margin: 12px;
  font-size: 14px;
}
</style>

<script>
export default {
  data() {
    return {
      etatCategories: {
        simoneverte: true,
        pratique: true,
        economies: true,
        tendances: true
      },
      articles: [
        {
          categories: [1,1,0,0],
          titre: '5 conseils pour réussir 😜 son covoiturage d\'été 😎 en 2024',
          code: '5-conseils-pour-reussir-son-covoiturage-d-ete-en-2024',
        },
        /*{
          categories: [0,1,0,1],
          titre: 'Covoiturage et fortes chaleurs : comment faire ?',
          code: 'covoiturage-et-fortes-chaleurs-comment-faire',
        },
        {
          categories: [1,1,0,0],
          titre: 'Harcèlement en covoiturage : que faire ?',
          code: 'stop-au-harcelement-en-covoiturage',
        },
        {
          categories: [0,1,0,0],
          titre: 'Comment éviter les arnaques en covoiturage ?',
          code: 'comment-prevenir-les-arnaques-en-covoiturage',
        },
        {
          categories: [0,0,1,1],
          titre: 'Comment gérer son covoiturage pour un grand évènement public ?',
          code: 'covoiturage-et-grands-evenements-publics',
        },
        {
          categories: [1,0,0,1],
          titre: 'SimoneVerte covoiturage : comment ça marche ?',
          code: 'simoneverte-covoiturage-comment-ca-marche',
        },*/
        {
          categories: [1,0,1,1],
          titre: 'Conducteur de covoiturage : comment vous garantir des économies sur SimoneVerte ?',
          code: 'conducteur-covoiturage-comment-faire-economies',
        },
        {
          categories: [1,0,1,1],
          titre: 'Conducteur de covoiturage : pourquoi SimoneVerte est fait pour vous ?',
          code: 'devenez-conducteur-simoneverte-2023',
        },
      ],
    }
  },
  created() {
    document.title = "Le blog des covoitureurs de SimoneVerte | SimoneVerte.fr"
  },
  computed: {
    articlesFiltres() {
      return this.articles.filter(article => {

        var c1 = (this.etatCategories.simoneverte && article.categories[0] == 1);
        var c2 = (this.etatCategories.pratique && article.categories[1] == 1);
        var c3 = (this.etatCategories.economies && article.categories[2] == 1);
        var c4 = (this.etatCategories.tendances * article.categories[3] == 1);
        
        return c1 || c2 || c3 || c4;
      });
    }
  },
  methods: {
    switchEtat: function(index) {
      switch(index) {
        case 0:
          this.etatCategories.simoneverte = !this.etatCategories.simoneverte;
          break;
        case 1:
          this.etatCategories.pratique = !this.etatCategories.pratique;
          break;
        case 2:
          this.etatCategories.economies = !this.etatCategories.economies;
          break;
        case 3:
          this.etatCategories.tendances = !this.etatCategories.tendances;
          break;
      }
    }
  }
}
</script>