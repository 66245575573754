<template>
  <v-container class="dashboar d">
	<v-row 
      class="text-center"
      align="center"
      justify="center"
      id="main-accueil-utilisateur" 
      style="min-height:70vh;">
      <v-col cols="12">
        <v-row>
          <v-col
            md="8"
            offset-md="2">
            <ul>
             <li 
               v-for="message in messages"
               :key="message.id">
               {{ message.text }}
             </li>
           </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import router from '../router';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      covoiturage: {},
      messages: [],
    }
  },
  mounted() {
    // No indexation
    document.querySelector('meta[name="robots"]').setAttribute("content", "noindex");

    this.$root.$emit("start_loading");
    let params = this.$route.params;

    // Appel de la recherche de trajet correspondant aux criteres
    this.$api.get('/trajets/' + params.id)
    .then(res => {

      this.covoiturage = res.data.data;
      this.covoiturage.conducteur.note = 5;

      /* if (this.isBeforeToday(this.covoiturage.date)) {
        this.info_message = 'Ce covoiturage a déjà été effectué. Nous vous redirigeons gentillement vers une liste de covoiturages similaires.';

        setTimeout(() => {
          router.push({ 
            path: '/covoiturages/' 
              + res.data.data.adresses.depart.ville + '/' 
              + res.data.data.adresses.arrivee.ville
            });
        }, 7500);
        this.covoiturage = null;

      } else { */

        this.covoiturage.avatar_passagers_defaut = [];
        for (var i = 0; i < this.covoiturage.passagers.length; i++) {
          this.covoiturage.avatar_passagers_defaut[this.covoiturage.passagers[i].id] = false;
        }

        this.$api.get('/message-chat-covoiturage/' + this.covoiturage.id)
        .then(response => {
            this.messages = response.data;
        })
        .catch(err => {
          console.error(err);
          this.$router.push({ name: 'erreur404' });
        });

        var channel = this.$pusher.subscribe('channel-chat-covoiturage.' + this.covoiturage.id);
        
        channel.bind("pusher:subscription_succeeded", () => {
          console.log("YESSSSSSSSSSSSSSSSSSSSSSSSS !");
        });

        var that = this;
        channel.bind('event-nouveau-message-chat-covoiturage', data => {
          console.log(data);
          that.messages.push(data);
        });

        var bodyFormData = new FormData();
        bodyFormData.append('texte', "Message " + (new Date()));
        bodyFormData.append('utilisateur_id', this.profile.id);
        
        /*this.$api.post('/message-chat-covoiturage/' + this.covoiturage.id, bodyFormData)
        .then((data) => {
          console.log(data);
        });*/

      // }
    })
    .catch(err => {
      console.error(err);
      this.$router.push({ name: 'erreur404' });
    })
    .finally(() => {
      this.$root.$emit("hide_loading");
    });  
  },
  unmounted() {
    // Reset indexation
    document.querySelector('meta[name="robots"]').setAttribute("content", "index,all,follow");
  },
  updated() {
    if (!this.icon_changed) {
      var icons = document.querySelectorAll('.v-stepper i.v-icon');
      if (icons.length > 1) {
        icons[0].classList.replace('mdi-check', 'mdi-map-marker');
        icons[1].classList.replace('mdi-check', 'mdi-flag-variant');
        this.icon_changed = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated"
    ]),
    profile() {
      return this.getProfile;
    },
  },
  methods: {
    isBeforeToday: function(dateDonnee) {
      return moment(dateDonnee).isBefore(moment().format('YYYY-MM-DD'));
    },
    seConnecter: function() {
      router.push({
        path: '/vous-identifier?referer=/covoiturage/' + this.covoiturage.id
      });
    },
  }
}
</script>
