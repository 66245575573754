<template>
    <section id="blog-article">
        <div>
            <v-row
                class="text-center"
                align="center"
                justify="center">
                <v-col style="padding: 25px">
                    <h1><b>{{ articleTitre }}</b></h1>
                    <v-row align="center">
                        <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12">
                            <div id="main-image"></div>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12">
                            <div>
                                <p style="text-align: justify">
                                    <span v-if="articleId === 1">L'été approche et vous vous demandez comment réussir votre trajet en covoiturage ? Voici 5 conseils qui vont sûrement vous aider.</span>
                                    <span v-if="articleId === 7">Devenez conducteur de covoiturage et garantissez-vous de réelles économies. Vous en doutez encore ? Nous allons vous montrer comment.</span>
                                    <span v-if="articleId === 8">Devenez conducteur de covoiturage avec SimoneVerte et bénéficiez de tous les avantages de notre plateforme.</span>
                                </p>
                                <u>Sommaire</u><br />
                                <ol >
                                    <!-- Article 1 -->
                                    <li v-if="articleId == 1">Bien choisir son covoiturage</li>
                                    <li v-if="articleId == 1">Bien préparer son trajet</li>
                                    <li v-if="articleId == 1">&Eacute;viter les sujets de discussion clivant</li>
                                    <li v-if="articleId == 1">Considérer la fatigue lors des longs trajets</li>
                                    <li v-if="articleId == 1">Laisser un avis objectif</li>
                                    <!-- Article 7 -->
                                    <li v-if="articleId == 7">Définir l'itinéraire</li>
                                    <li v-if="articleId == 7">Chiffrer le coût du trajet</li>
                                    <li v-if="articleId == 7">Choisir une plateforme attractive pour les passagers</li>
                                    <li v-if="articleId == 7">A vous les économies !</li>
                                    <!-- Article 8 -->
                                    <li v-if="articleId === 8">L'intérêt du covoiturage</li>
                                    <li v-if="articleId === 8">Postez votre trajet</li>
                                    <li v-if="articleId === 8">Quels avantages pour vous</li>
                                </ol>
                                <div style="text-align:left">
                                    <ShareNetwork
                                        v-if="articleId === 1"
                                        class="btn share facebook"
                                        network="facebook"
                                        url="https://www.simoneverte.fr/blog-covoiturage/5-conseils-pour-reussir-son-covoiturage-d-ete-en-2024"
                                        title="5 conseils pour réussir son covoiturage en 2024"
                                        description="5 conseils pour réussir son covoiturage en 2024 | SimoneVerte"
                                        quote="5 conseils pour réussir son covoiturage en 2024"
                                        hashtags="simoneverte,covoiturage,réussir,2024">
                                        <span class="mdi mdi-facebook"></span>
                                    </ShareNetwork>
                                    <ShareNetwork
                                        v-if="articleId === 8"
                                        class="btn share whatsapp"
                                        network="whatsapp"
                                        url="https://www.simoneverte.fr/blog-covoiturage/devenez-conducteur-simoneverte-2023"
                                        title="Conducteur de covoiturage : pourquoi SimoneVerte est fait pour vous ?"
                                        description="Comment se lancer dans le covoiturage grâce à SimoneVerte"
                                        quote="Comment se lancer dans le covoiturage grâce à SimoneVerte"
                                        hashtags="simoneverte,covoiturage,réussir,économies,2023">
                                        <span class="mdi mdi-whatsapp"></span>
                                    </ShareNetwork>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <div class="parrainage-kesako-titre">
                            <span v-if="articleId == 1">1. Bien choisir, c'est le début du succès de ton covoiturage</span>
                            <span v-if="articleId == 7">1. Bien définir son itinéraire est le commencement de vos économies de conducteur</span>
                            <span v-if="articleId == 8">1. L'intérêt de faire du covoiturage</span>
                        </div>
                        <div class="parrainage-kesako-description">
                            <p v-if="articleId == 1">
                                En ce qui concerne le covoiturage, il y a deux types de trajet : ceux pour lesquels on a largement le choix, ... et les autres.
                                Aussi lorsque vous avez le choix, ne vous précipitez pas forcément à prendre le moins cher ou le premier. Si vous avez des 
                                contraintes financières, fixez un budget max et commencez par examiner les profils 
                                des personnes avec lesquelles vous serez en covoiturage (le profil du conducteur et des passagers si des réservations ont déjà
                                été faites). Lisez les avis (pas besoin d'aller jusqu'à <a href="https://www.tripadvisor.com" target="_blank">TripAdvisor</a> non plus) et les notes des conducteurs et passagers (toutes les plateformes proposent ce service). Par exemple,
                                si vous êtes une fille et que vous savez que vous serez plus à l'aise avec une fille au volant, rien ne vous empêche à payer 
                                2 euros de plus tout en maximisant les chances de passer un bon trajet. &Agrave; cet effet, nous avons chez SimoneVerte des filtres 
                                qui vous aident à mieux <router-link :to="{name: 'trouver'}">choisir votre covoiturage</router-link>.
                            </p>
                            <p v-if="articleId == 7">
                                Prendre sa voiture pour partir en vacances, en visite familiale, en escapade amoureuse ou même en rendez-vous commercial, cela ne 
                                s'improvise pas. Et puisqu'il est question ici de réaliser des économies, vous allez avoir besoin d'un minimum d'organisation. Par exemple,
                                supposons que vous habitez <a href="https://www.simoneverte.fr/covoiturages/Rennes">Rennes</a> et que devez vous rendre le week-end 
                                à <a href="https://www.simoneverte.fr/covoiturages/Bordeaux">Bordeaux</a>. Définir son itinéraire c'est répondre aux questions comme
                                <i>quels seront les lieux de rendez-vous ? à quelle heure je compte partir / arriver ? Est-ce que je prends des covoitureurs dans des 
                                villes étapes ? ...</i> bref, toutes les questions qui vont vous permettre de rendre votre projet plus concret. Dans notre cas de figure 
                                cela implique aussi de choisir un itinéraire en fonction des conditions de circulations prévisionnelles.
                            </p>
                            <p v-if="articleId == 8">
                                La pensée moderne admet que le mode de transport d'une civilisation est un bon indicateur de son niveau de développement. En 2023, sûrement
                                plus qu'avant, les hommes sont en mouvement. Domicile-travail durant le gros de la semaine, visites et autres loisirs le reste du temps. 
                                Mais tous ces déplacements ont un coût. Un coût pour l'environnement : les prévisions sur le réchauffement climatique sont très pessimistes,
                                et il est plus important aujourd'hui en 2023 de considérer ces données que par le passé. L'autre coût (qui n'est pas totalement étranger au
                                premier cité), ce sont les dépenses occasionnées par les trajets... puisque nous parlons de covoiturage, vous comprendrez que nous parlons
                                principalement du prix du carburant et de l'usure du véhicule utilisé pour se déplacer. <br />
                                Le covoiturage a donc se double intérêt de limiter l'emprunte carbone d'un déplacement en embarquant le maximum de passagers allant dans un 
                                même lieu, tout en baissant la facture financière grâce à une contribution équitable des covoitureurs. 
                            </p>
                        </div>
                        <div class="parrainage-kesako-titre">
                            <span v-if="articleId == 1">2. Bien préparer ton trajet</span>
                            <span v-if="articleId == 7">2. Chiffrer son trajet</span>
                            <span v-if="articleId == 8">2. Postez votre trajet</span>
                        </div>
                        <div class="parrainage-kesako-description">
                            <p v-if="articleId == 1">
                                Une fois après avoir choisi le trajet qui vous correspond le plus, le deuxième conseil consiste dans le fait de se préparer. 
                                Une bouteille d'eau, un déjeuner ou un encas en fonction de l'heure à laquelle vous partez, le smartphone bien chargé ... 
                                bref, on essaye d'anticiper tout "manque" qui pourrait fortement vous contrarier durant le trajet. Dans cette partie, il y a 
                                également les questions aux conducteurs : "une ou deux pauses", "autoroute ou nationale", "détours ou pas" ? Autant de réponse dont la surprise 
                                pourrait vous être désagréable. Une fois que cela est prêt, votre covoiturage n'est pas loin d'être un franc succès.
                            </p>
                            <p v-if="articleId == 7">
                                Pour réaliser que vous faites des économies, vous devez chiffrer votre dépense pour le trajet. En reprenant l'exemple du 
                                <a href="https://www.simoneverte.fr/covoiturages/Rennes/Bordeaux">Rennes - Bordeaux</a>, à la rédaction de cet article, 
                                <a href="https://fr.mappy.com/itineraire#/voiture/Rennes%2035000-35700/Bordeaux%2033000-33800/car/5" target="_blank">Mappy.fr</a> 
                                indique un coût d'environ 45 &euro; de carburant pour 30 &euro; de péage soit un environ 75 &euro; pour l'itinéraire le plus rapide. 
                                Notez que bien que ce ne soit pas le sujet de cet article, le covoiturage contribue à un meilleur partage de la route et à un impact 
                                environnemental moindre. <a href="https://www.twitter.com/#pensezACovoiturer">
                                #PensezACovoiturer</a>! Une fois ce coût défini, vous pourrez alors établir une contribution par covoitureur pour que le coût soit partagé 
                                par tous.
                            </p>
                            <p v-if="articleId == 8">
                                Sur SimoneVerte, vous avez donc la possibilité de proposer facilement un trajet de covoiturage aux autres usagers de la plateforme. <br />
                                Pour le faire, connectez-vous avec vos identifiants puis rendez-vous dans la rubrique <b><a href="https://www.simoneverte.fr/en-voiture">
                                Proposer un trajet</a></b>. A ce niveau, il vous suffit de renseigner les informations qui concernent votre trajet : les adresses de départ
                                et d'arrivée, l'heure de départ, le nombre de passagers, ... c'est également à ce niveau que vous pouvez entrer un code promo si vous en 
                                avez un (il y a de nombreuses promotions sur SimoneVerte). Une fois toutes ces informations renseignées, il ne vous reste plus qu'à publier
                                votre trajet et à attendre que vos gentils covoitureurs réservent leur place.
                            </p>
                        </div>
                        <div class="parrainage-kesako-titre">
                            <span v-if="articleId == 1">3. &Eacute;viter les sujets de discussion qui dérangent</span>
                            <span v-if="articleId == 7">3. Choisir une plateforme attractive pour les passagers</span>
                            <span v-if="articleId == 8">3. Quels avantages pour vous ?</span>
                        </div>
                        <div class="parrainage-kesako-description">
                            <p v-if="articleId == 1">
                                &Ccedil;a y est ! Tu as choisi le meilleur covoiturage, tu es prêt(e), tu es au lieu de rendez-vous et hop ... en voiture. Maintenant,
                                le succès va résider dans le fait d'avoir des échanges plus ou moins plaisants avec les autres personnes en voiture. Pour cela,
                                notre conseil est simple : éviter les sujets qui fâchent. En effet, covoiturer avec des personnes dont les positions nous 
                                insupportent est tout simplement "insupportable". Aussi, si vous êtes du genre meneur de discussion, proposez des discussions 
                                "bâteau" : ' <i>Vous pensez-que les films d'aujourd'hui sont meilleurs que ceux d'il y a 20 ans ?</i> ' ... le genre de questions dont 
                                tous conviennent qu'il s'agit d'un point purement subjectif et donc sans grands risques de heurter la sensibilité de qui que ce soit. 
                                Si au contraire, vous n'êtes pas très bavard, faites l'effort d'intervenir de temps en temps. Mais dans tous les cas, si vous 
                                sentez que votre position peut heurter une personne, évitez de l'exprimer à ce moment là (dans le pire des cas vous échangez
                                les contacts et vous pourrez vous retrouver dans un autre cadre pour "débattre"). Une voiture n'est pas un hémicycle !
                            </p>
                            <p v-if="articleId == 7">
                                Le choix de la plateforme est très important. En France, <a href="https://www.blablacar.fr" target="_blank">Blablacar</a> a la plus forte communauté
                                (au moment de la rédaction de cet article). Cela veut dire, que sur des trajets avec une faible demande, en zone rurale par exemple,
                                c'est une plateforme à privilégier. Par contre, pour des trajets entre des grandes villes avec beaucoup de jeunes comme pour notre 
                                <a href="https://www.simoneverte.fr/covoiturages/Rennes/Bordeaux">Rennes - Bordeaux</a>, la concurrence est plus rude et l'aspect économique
                                peut avoir son importance dans le choix du passager. Aussi, là où certaines plateformes prennent pour ce genre de trajet plus de 30%
                                de commission, SimoneVerte se commissionne uniquement à hauteur de 2 &euro;. Qu'est ce que cela veut dire ? En tant que conducteur, si vous demandez une contribution de 
                                30 &euro; pour aller de Rennes à Bordeaux, le passager paiera 40 &euro; sur ce genre de plateforme tandis qu'il ne paiera que 32 &euro; 
                                sur <a href="https://www.simoneverte.fr">SimoneVerte</a>. Vous devinez là où les passagers vont regarder en premier ?
                            </p>
                            <p v-if="articleId == 8">
                                Vous avez posté votre trajet et c'est bon pour la planète ! Cependant, en plus de faire une très bonne action environnementale 
                                SimoneVerte présente de réels atouts pour réussir votre covoiturage. D'abord, SimoneVerte est moins cher que les autres plateformes
                                notamment sur les longs trajets. Cela est dû à notre politique de commission fixe de 2 euro pour tous les trajets là où la concurrence
                                applique un pourcentage de la contribution que vous demandez. Par ailleurs, l'encadrement strict des prix, permettant toutefois 
                                à toutes les parties de s'y retrouver, permet là encore que les prix soient plus attractifs sur notre plateforme que chez la concurrence. <br />
                                Pourquoi est-ce un avantage ? Parce que les passagers vont naturellement être attiré par le meilleur rapport qualité prix et donc vers
                                notre plateforme. De plus SimoneVerte propose régulièrement des offres promotionnelle pour encourager la pratique du covoiturage. 
                                Actuellement, le code SMV_DOUBLE_23 permet aux conducteurs de recevoir le double des contributions des passagers lors de son premier trajet. 
                            </p>
                        </div>
                        <div class="parrainage-kesako-titre">
                            <span v-if="articleId == 1">4. Tenir compte de la fatigue lors des longs trajets</span>
                            <span v-if="articleId == 7">4. Félicitations ! Vous avez fait des économies</span>
                        </div>
                        <div class="parrainage-kesako-description">
                            <p v-if="articleId == 1">
                                "Jusqu'ici, tout va bien" me diriez-vous. Les personnes correspondent à peu près à ce que vous aviez imaginé, les discussions sont 
                                cordiales voire drôles, ça se passe bien jusqu'au "trou" : le trou c'est quand la fatigue commence à prendre un peu le dessus et les 
                                conversations sont de moins en moins spontanées. Si ce trou arrive avant une pause, n'hésitez pas à proposer à vos compagnons de route 
                                de faire une pause. Si le trou arrive après la pause, c'est pas la peine d'insister car souvent la fatigue est inversement proportionnelle
                                à la patience des personnes qui la ressentent (quoi ?!). Aussi, à ce moment le mieux est de faire preuve de bienveillance et de commencer à parler des 
                                destinations des uns et des autres car à ce moment, c'est tout ce à quoi tous les covoitureurs pensent : l'arrivée. Vous pouvez par 
                                exemple faire le co-pilote pendant les 30 dernières minutes du trajet. Justement, nous vous proposons la possibilité de jouer le rôle 
                                de <router-link :to="{name: 'proposer'}">co-pilote durant votre trajet</router-link>.
                            </p>
                            <p v-if="articleId == 7">
                                Le trajet s'est bien passé et tout le monde est arrivé à destination. Avec vos 3 passagers de Rennes à Bordeaux ayant contribué à hauteur de
                                30 &euro; par passager, votre voyage ne vous a rien coûter
                                (et vous a même payé le goûter avec environ un surplus de 15 &euro;). Maintenant, vous n'avez plus qu'à attendre entre 3 et 5 jours ouvrés 
                                et les 90 &euro; seront virés sur votre compte. Félicitations !
                            </p>  
                        </div>
                        <div class="parrainage-kesako-titre" v-if="articleId == 1">
                            <span v-if="articleId == 1">5. Laissez un avis objectif</span>
                        </div>
                        <div class="parrainage-kesako-description">
                            <p v-if="articleId == 1">
                                Enfin ! Vous êtes arrivé(e) et confortablement installé(e). Ce trajet est derrière vous maintenant. Cependant, il reste une dernière chose à faire :
                                laisser votre avis sur la plateforme de #covoiturage que vous avez utilisée. Comment rédiger ces avis ? Ils doivent être sincères, objectifs, écrits 
                                dans l'unique but d'informer des potentiel(le)s futur(e)s covoitureur(se)s afin de les aider à mieux choisir leur covoitureur. Vous pouvez y noter 
                                brièvement vos impressions de vos compagnons de route, noter les points positifs et aussi les points négatifs. Et là ... c'est vraiment fini.
                            </p>
                            <br /><br />
                            <p>
                                En espérant que cela vous soit utile !<br />
                                A bientôt dans un prochain article.
                            </p>
                            <p style="font-style: italic; color: #444; text-align: right">
                                La rédaction du blog SimoneVerte.<br/>
                                <span v-if="articleId == 1">(Mise en ligne le 04/07/2024 à 12h26)</span>
                                <span v-if="articleId == 7">(Mise en ligne le 26/09/2022 à 23h18)</span>
                            </p>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<style>
#blog-article > div {
  background: rgba(191, 191, 191, 0.3);
  border-radius: 9px;
}
#blog-article h1 {
  font-size: 25px;
  margin: 25px auto 27px;
}
#main-image {
  background-image: url("../../assets/img/blogs/md/top6.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 270px;
  border: 1px solid #ddd;
}
.parrainage-kesako-titre,
.parrainage-kesako-description {
  text-align: justify;
  width: 100%;
  padding: 18px;
}
.parrainage-kesako-titre {
  font-size: 25px;
  padding: 12px 18px 9px;
  border-bottom: 1px solid #ccc;
}
.parrainage-kesako-description {
  margin: 3px auto;
}
#blog-article .btn.share {
  width: auto;
  margin: 0;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 7px 7px 0 0;
} 
</style>

<script>
export default {
  data() {
    return {
      articleId: 0,
      articleTitre: '',
    }
  },
  mounted() {
    var imageFile = null;
    
    switch (this.$route.params.nom_article) {
      case '5-conseils-pour-reussir-son-covoiturage-d-ete-en-2024':
        document.title = '5 conseils pour réussir son covoiturage à l\'été 2024 | Le blog SimoneVerte Covoiturage';
        imageFile = "top6.webp";
        this.articleId = 1;
        this.articleTitre = "5 conseils pour réussir 😜 son covoiturage d'été 😎 en 2024";
        break;
      case 'covoiturage-et-fortes-chaleurs-comment-faire':
        document.title = 'Covoiturage et fortes chaleurs : comment faire ? | Le blog SimoneVerte Covoiturage';
        this.articleId = 2;
        this.articleTitre = "Covoiturage et fortes chaleurs : comment faire ?";
        break;
      case 'stop-au-harcelement-en-covoiturage':
        document.title = 'Harcèlement en covoiturage : que faire ? | Le blog SimoneVerte Covoiturage';
        this.articleId = 3;
        this.articleTitre = "Harcèlement en covoiturage : que faire ?";
        break;
      case 'comment-prevenir-les-arnaques-en-covoiturage':
        document.title = 'Comment éviter les arnaques en covoiturage | Le blog SimoneVerte Covoiturage';
        this.articleId = 4;
        this.articleTitre = "Comment éviter les arnaques en covoiturage ?";
        break;
      case 'covoiturage-et-grands-evenements-publics':
        document.title = 'Comment gérer son covoiturage pour un grand évènement public | Le blog SimoneVerte Covoiturage';
        this.articleId = 5;
        this.articleTitre = "Comment gérer son covoiturage pour un grand évènement public ?";
        break;
      case 'simoneverte-covoiturage-comment-ca-marche':
        document.title = 'SimoneVerte covoiturage : comment ça marche | Le blog SimoneVerte Covoiturage';
        this.articleId = 6;
        this.articleTitre = "SimoneVerte covoiturage : comment ça marche ?";
        break;
      case 'conducteur-covoiturage-comment-faire-economies':
        document.title = 'Comment vous garantir des économies en proposant vos trajets de covoiturage | Le blog SimoneVerte Covoiturage';
        imageFile = "economies-conducteur.jpg";
        this.articleId = 7;
        this.articleTitre = "Conducteur de covoiturage : comment faire des économies garanties ?";
        break;
      case 'devenez-conducteur-simoneverte-2023':
        document.title = 'Proposez vos trajets de covoiturage sur SimoneVerte | Le blog SimoneVerte Covoiturage';
        imageFile = "economies-conducteur.jpg";
        this.articleId = 8;
        this.articleTitre = "Conducteur de covoiturage : pourquoi SimoneVerte est fait pour vous ?";
        break;
      default:
        imageFile = "top6.webp";
        break;
    }

    setTimeout(() => {
      var url1 = '',
        divMainImage = document.getElementById("main-image");

      if (imageFile) {
        if (this.$vuetify.breakpoint.name) {
          url1 = require("../../assets/img/blogs/" + this.$vuetify.breakpoint.name + "/" + imageFile);
        } else {
          url1 = require("../../assets/img/blogs/md/" + imageFile);
        }
      }

      divMainImage.style.backgroundImage = "url(" + url1 + ")";
    
    }, 360);
  },
}
</script>

