<template>
  <div>
    <h2>Test des emails d'administration</h2>
        
    <div 
      v-show="success" 
      class="alert success"
      >
      <i class="mdi mdi-check-circle"></i>
      <span>Votre email a été envoyé avec succès</span>
    </div>

    <v-row
      align="center"
      justify="start"
      v-if="utilisateurs.length > 0"
    >
      <v-col
        cols="6"
        v-for="utilisateur in utilisateurs"
        :key="utilisateur.id"
        style="border: 1px solid #ccc"
      >
        <div>{{ utilisateur.prenom }} {{ utilisateur.nom }}</div>
        <div>{{ utilisateur.email }}</div>
        <div><b>{{ utilisateur.date_naissance }}</b></div>
        <div>
          <button class="btn"
            @click="renvoyerEMailInscription(utilisateur)"
            :disabled="!check(utilisateur)"
          >
            Envoyer le mail
          </button>
        </div>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-else
    >
      <p>Il n'y a pas d'utilisateur.</p>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      utilisateurs: [],
      idRelances: [],
      success: false,
    };
  },
  mounted() {
    this.$root.$emit("start_loading");

    this.$api.get("/admin/inscriptions")
    .then(res => {
      this.utilisateurs = res.data;
    })
    .finally(() => this.$root.$emit("hide_loading"));
  },
  methods: {
    renvoyerEMailInscription: function(utilisateur) {
      var bodyFormData = new FormData();
      bodyFormData.append('utilisateur_id', utilisateur.id);

      this.$api.post('/admin/inscriptions', bodyFormData)
      .then((resp) => {
        console.log(resp);
        this.idRelances.push(utilisateur.id);
        this.notifierEnvoieReussi();
      });
    },
    notifierEnvoieReussi: function() {
      this.success = true;
      setTimeout(() => this.success = false, 7000);
    },
    check(utilisateur) {
      return this.idRelances.filter(id => id == utilisateur.id).length == 0
    }
  }
}
</script>

