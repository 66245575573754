<template>
    <div>
        <h2>Test des emails d'administration</h2>
        
        <div 
          v-show="success" 
          class="alert success"
          >
          <i class="mdi mdi-check-circle"></i>
          <span>Votre email a été envoyé avec succès</span>
        </div>

        <input 
          type="button" 
          class="btn center large"
          v-on:click="envoyerConfirmationInscription"
          value="Email confirmation inscription" />
        <br />
        <br />

        <h3>Conducteur</h3>
        <input 
          type="button" 
          class="btn center large"
          v-on:click="envoyerNotificationNouveauPassager"
          value="Email notification nouveau passager" />
        <br />
        <br />

        <h3>Passager</h3>
        <input 
          type="button" 
          class="btn center large"
          v-on:click="envoyerConfirmationReservation"
          value="Email notification réservation confirmée" />
        <br />
        <input 
          type="button" 
          class="btn center large"
          v-on:click="envoyerPaiementEffectue"
          value="Email notification paiement effectué" />
        <br />
        <br />

        <h3>Newsletter</h3>
        <input 
          type="button" 
          class="btn center large"
          v-on:click="envoyerNewsletterCourante"
          value="Email newsletter courante" />
        
    </div>
</template>

<script>
  export default {
    data() {
      return {
        success: false,
      };
    },
    methods: {
      envoyerConfirmationInscription: function() {
        this.$root.$emit("start_loading");
        this.success = false;

        this.$api.get("/admin/tests/email-activation")
        .then((res) => {
          console.log(res);
          this.notifierEnvoieReussi();
        })
        .finally(() => this.$root.$emit("hide_loading"));
      },

      envoyerNotificationNouveauPassager: function() {
        this.$root.$emit("start_loading");

        this.$api.get("/admin/tests/email-nouveau-passager")
        .then((res) => {
          console.log(res);
          this.notifierEnvoieReussi();
        })
        .finally(() => this.$root.$emit("hide_loading"));
      },

      envoyerConfirmationReservation: function() {
        this.$root.$emit("start_loading");

        this.$api.get("/admin/tests/email-confirmation-reservation")
        .then((res) => {
          console.log(res);
          this.notifierEnvoieReussi();
        })
        .finally(() => this.$root.$emit("hide_loading"));
      },

      envoyerPaiementEffectue: function() {
        this.$root.$emit("start_loading");

        this.$api.get("/admin/tests/email-paiement-effectue")
        .then((res) => {
          console.log(res);
          this.notifierEnvoieReussi();
        })
        .finally(() => this.$root.$emit("hide_loading"));
      },

      envoyerNewsletterCourante: function() {
        this.$root.$emit("start_loading");

        this.$api.get("/admin/tests/email-newsletter")
        .then((res) => {
          console.log(res);
          this.notifierEnvoieReussi();
        })
        .finally(() => this.$root.$emit("hide_loading"));
      },

      notifierEnvoieReussi: function() {
        this.success = true;
        setTimeout(() => this.success = false, 7000);
      }
    }
  }
</script>

