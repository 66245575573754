<template>

  <div id="main">

    <!--==========================
      Intro Section
    ============================-->
    <section id="intro">
      <div class="intro-content-overlay">
        <div 
          class="hidden-md-and-up search-box mobile">

          <input 
            class="location depart"
            type="text" 
            placeholder="Départ"
            v-model="location_depart"
            @focus="afficherDialogueLocation('DEPART')" />

          <input 
            class="location arrivee" 
            type="text" 
            placeholder="Arrivée" 
            v-model="location_arrivee"
            @focus="afficherDialogueLocation('ARRIVEE')" />

          <button class="btn center "
            :disabled="location_depart == '' || location_arrivee == ''"
            style="padding: 7px 12px; width: 100%; border-radius: 0 0 12px 12px;"
            @click="trouver()">
            <span class="mdi mdi-chevron-right"></span> &nbsp; Trouvez 
          </button>

        </div>
      </div>
      
      <div id="intro-1" class="intro-block">

        <v-row
          class="intro-content"
          justify="center"
        >
          <div class="box-btn">
          </div>
          <h1 style="color:#fff">#Courtoisie</h1>
        </v-row>
      </div>
      
      <div id="intro-2" class="intro-block" style="display: none">

        <v-row
          class="intro-content"
          align="center"
          justify="center"
        >
          <div class="box-btn">
          </div>
          <h1 style="color: #000">#Amitié</h1>
        </v-row>
      </div>
  
      <div id="intro-3" class="intro-block" style="display: none">

        <v-row
          class="intro-content"
          align="center"
          justify="start"
        >
          <div class="box-btn">
          </div>
          <h1> #FousRires </h1>
        </v-row>
      </div>

    </section><!-- #intro -->

    <!--==========================
      About Section
    ============================-->
    <section id="about" class="wow fadeInUp">
            
      <div class="container">

        <!--<v-row
          justify="center"
          style="background-color: #ffc400">
          
          <v-col 
            cols="12"
            lg="12"
            md="12"
            sm="12"
            class="content">
            
            <h1 class="h2" style="margin-bottom: 3px">Parle avec <span style="color: #fd6c9e">ELLE</span> !</h1>
          </v-col>

        </v-row>

        <v-row
          justify="center">
          <div class="content info-box">
            <h1 class="h2" style="margin-bottom: 3px"><span class="mdi mdi-gift-open" /> Promotions covoiturage <span style="color: #fd6c9e">du moment <span class="mdi mdi-gift-open" /></span></h1>
          </div>
        </v-row>  
        
        <v-row
          justify="center">

          <v-col 
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="content info-box">

            <div id="promotion-1" style="
              width: 100%; 
              position: relative;
              padding-top: 100%;
              background-size: cover; 
              background-position-x: center; 
              background-position-y: center;" />
          </v-col>
          
          <v-col 
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="content info-box">

            <div id="promotion-2" style="
              width: 100%; 
              position: relative;
              padding-top: 100%;
              background-size: cover; 
              background-position-x: center; 
              background-position-y: center;" />
          </v-col>

        </v-row>-->

        <v-row
          justify="center">
          
          <v-col 
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="content info-box">
            
            <h1 class="h2" style="margin-bottom: 3px"><span class="mdi mdi-currency-eur" /> Le covoiturage <span style="color: #fd6c9e">pas cher</span> partout en France <span class="mdi mdi-trending-down" /></h1>
            <p>
              Partout où vous souhaitez vous rendre en covoiturage, SimoneVerte vous garantit des prix bas grâce 
              à un encadrement strict des tarifs proposés et à une toute petite commission fixe.
              En effet, certaines plateformes prennent jusqu'à 33% de commission là où la commission
              est toujours de 2&nbsp;&euro; chez nous.
              Par exemple, pour un trajet Rennes - Reims à 30&nbsp;&euro; de participation, certaines
              plateformes présentent le trajet à 40&nbsp;&euro; pour le passager tandis que le même trajet ne revient qu'à 32&nbsp;&euro; chez nous ! 
            </p>
          </v-col>
          
          <v-col 
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="content info-box">
            
            <h1 class="h2" style="margin-bottom: 3px"><span class="mdi mdi-emoticon-cool-outline" /> Bonne humeur <span style="color: #fd6c9e">garantie</span> durant votre trajet <span class="mdi mdi-emoticon-excited-outline" /></h1>
            <p>
              Avec un peu de bonne volonté, votre covoiturage sera sûrement un moment plaisant pour vous et vos partenaires de trajet.
              En effet, pour atteindre cet objectif, nous proposons à chacun d'avoir un rôle particulier durant le trajet comme DJ 
              ou copilote. De plus, nous proposons des activités via notre plateforme pour vous aider à tisser le lien avec vos 
              compagnons de route d'un jour. Alors n'hésitez plus et adoptez simoneverte.fr . 
            </p>
          </v-col>

        </v-row>

        <v-row
          justify="center"
          v-show="trajets_a_la_une.length > 0">
          
          <v-col 
            cols="12"
            lg="12"
            md="12"
            sm="12"
            class="content">
            
            <h1 class="h2" style="margin-bottom: 3px">
              <span class="mdi mdi-heart" /> Top <span style="color: #fd6c9e">trajets <span class="mdi mdi-heart" /></span>
            </h1>
          </v-col>

        </v-row>

        <v-row 
          justify="center" 
          align="center"
          class="covoiturages" 
          v-show="trajets_a_la_une.length > 0">
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12" 
            class="a_la_une"
            v-for="covoiturage in trajets_a_la_une"
            :key="covoiturage.id">
            <carte-covoiturage :covoiturage="covoiturage" type-carte="SIMPLE" />
          </v-col>
        </v-row>

        <v-row
          justify="center">
          
          <v-col 
            cols="12"
            lg="12"
            md="12"
            sm="12"
            class="content">
            
            <h1 class="h2" style="margin-bottom: 3px">Simoneverte, ce sont des prix <span style="color: #fd6c9e">toujours</span> encadrés</h1>
          </v-col>

        </v-row>

        <v-row
          justify="space-between"
          style="margin-bottom: 36px">
        
          <v-col 
            cols="12"
            lg="3"
            md="3"
            sm="6"
            v-for="(fourchette, index) in fourchettes.slice(0, 8)"
            :key="index">

            <div 
              class="box-prix-encadres"
              :class="getBoxColorCssClass(index)"
              v-on:click="goTo(fourchette)">

              <span><b>{{ fourchette.ville1.replace(' 01', '') }} <i class="mdi mdi-arrow-left-right"></i> {{ fourchette.ville2.replace(' 01', '') }}</b></span><br />
              <span>Compris entre</span><br />
              <h2 class="prix">{{ fourchette.min }} &euro;</h2><b> {{ fourchette.minDecimal | double }}</b><span class="et"> et </span><h2 class="prix">{{ fourchette.max }} &euro;</h2><b> {{ fourchette.maxDecimal | double }}</b>
            </div>

          </v-col>

        </v-row>

        <v-row
          justify="center">
          
          <v-col 
            cols="12"
            lg="6"
            md="6"
            sm="12">
            <div class="content fond-vert" style="margin-right:14px;margin-left:14px; cursor:pointer"
              v-on:click="goToProposerTrajet()">
              <h2>Opération découverte : 10 &euro; offerts aux conducteurs *</h2>
              <h3 style="text-align: center">10 &euro; de carburant offert</h3>
              <div style="text-align: justify">
                <div>
                  <span>
                    * Nous offrons 10 &euro; aux conducteurs qui ont effectué 
                    un <b>trajet complet</b>, c'est à dire <b>avec au moins 2 covoitureurs</b>
                    dans une <span class="limited">limite de 900 trajets avec un maximum de 30 &euro; par conducteur</span>.
                  </span>
                </div>
              </div>
            </div>
          </v-col>
          
          <v-col 
            cols="12"
            lg="6"
            md="6"
            sm="12">
            <div class="content fond-jaune" 
              style="margin-right:14px;margin-left:14px;">
              <h2>Des petits prix CLAIRS et COMPRIS</h2>
              <h3 style="text-align: center">Covoiturez pour 2 euros !</h3>
              <div style="text-align: justify">
                <div>
                  <span>
                    En plus des prix toujours encadrés 
                    chez nous la commission est toujours de <b>2 &euro;</b> (covoiturage = <b>partage</b>).
                    <span class="limited">Nous appliquons également des tarifs dégressifs</span> si vous réservez à plusieurs.
                  </span>
                </div>
              </div>
            </div>
          </v-col>
          
        </v-row>

      </div>
    </section><!-- #about -->
    
      <div>

        <v-dialog 
          v-model="dialog_promo" 
          max-width="600px">

          <v-card>
            <!-- <v-card-title>
              <span class="headline">Covoiturez et gagnez de l'argent avec SimoneVerte</span>
            </v-card-title> -->
            <v-spacer></v-spacer>
            <v-container style="padding-bottom: 0;">
                <div class="corpus principal">
                    <div class="operation hidden-sm-and-down"> - Nouveau - </div>
                    <div class="covoit-gagnant">Offre découverte</div>
                    <div id="main-image-home"></div>
                    <div class="operation bandeau hidden-sm-and-down"><span class="mdi mdi-car"></span> Amis convoitureurs</div>
                    <div class="sous-titre">
                        <div class="promo-popup-promo">Avec le code promo <i>SMV_DEC_24</i></div>
                        <span class="hidden-xs-and-down">Profitez de <span class="dix-euro">10 &euro;</span> en plus des contributions passagers !</span>
                    </div>
                    <div class="bloc-bouton-popup-promo">
                        <a class="bouton-popup-promo hidden-sm-and-down" href="https://www.simoneverte.fr/en-voiture">Je m'inscris</a>
                        <a class="bouton-popup-promo hidden-md-and-up" href="https://www.simoneverte.fr/en-voiture">Je m'inscris</a>
                    </div>
                    <!-- <div class="operation bandeau"><span class="mdi mdi-wallet-travel"></span> Pour les passagers</div>
                    <div class="sous-titre">
                        <div class="promo-popup-promo">Gagnez des bons de 5&nbsp;&euro;</div>
                        <span class="hidden-sm-and-down">Chaque parrainage validé vous rapporte <span class="dix-euro">1 point de parrainage</span></span><br />
                        <span class="hidden-sm-and-down">Et chaque fois que vous avez 3 points vous recevez <span class="dix-euro">un bon de 5&nbsp;&euro;</span></span>
                    </div>
                    <div class="bloc-bouton-popup-promo">
                        <a class="bouton" href="https://www.simoneverte.fr/je-parraine">Parrainez vos proches dès maintenant</a>
                    </div> -->
                </div>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_promo = false">
                Fermer X
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog 
          v-model="dialog_location" 
          fullscreen
          hide-overlay>

          <v-card>
            <v-row
              align="center"
              justify="start"
            >
              <v-col cols="2">
                <v-btn
                  icon
                  @click="dialog_location = false"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="9">
                
                <v-autocomplete
                  v-model="location_depart"
                  v-show="dialog_location"
                  :items="items_depart"
                  :loading="isLoading"
                  :search-input.sync="search_depart"
                  color="#ccc" 
                  :autofocus="true"
                  no-filter
                  clearable
                  hide-no-data
                  hide-selected
                  item-text="Description"
                  item-value="API"
                  label=""
                  placeholder="Ville de départ..."
                  prepend-icon="mdi-home-circle"
                  return-object />

                <v-autocomplete
                  v-model="location_arrivee"
                  v-show="dialog_location"
                  :items="items_arrivee"
                  :loading="isLoading"
                  :search-input.sync="search_arrivee"
                  color="#ccc" 
                  :autofocus="true"
                  no-filter
                  clearable
                  hide-no-data
                  hide-selected
                  item-text="Description"
                  item-value="API"
                  label=""
                  placeholder="Ville d'arrivée ..."
                  prepend-icon="mdi-home-map-marker"
                  return-object />

              </v-col>
              
            </v-row>
            
          </v-card>

        </v-dialog>

      </div>

  </div>

</template>

<style>
#intro {
  width: 100%;
  height: 57vh;
  position: relative;
}

.intro-block {
  /*background: url("../assets/img/banners/top4.webp") no-repeat;*/
  background-size: cover;
  background-position-x: center;
  background-position-y: 27%;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 570ms;
}
  
#intro .intro-content,
#intro .intro-content-overlay {
  position: absolute;
  bottom: 18px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: end;
  -ms-flex-pack: center;
  justify-content: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

#intro .intro-content-overlay {
  /*background-color: #fff;*/
  /* opacity: 45%; */
  z-index: 100;
}

#intro .intro-content-overlay .search-box {
  border-radius: 12px; 
  width: 63vw; 
  height: 16.5vh;
}

#intro .intro-content-overlay .search-box input.location {
  color: #777; 
  width: 100%; 
  border:0px; 
  border-bottom:1px dashed; 
  height: 48px;
}

#intro .intro-content-overlay .search-box input.location.depart {
  border-radius: 12px 12px 0 0;
}

#intro .intro-content-overlay .search-box input.location.arrivee {
  border-radius: 0;
}

#intro .intro-content h1,
#intro .intro-content h2 {
  color: #17a2b8;
  margin: 30px 0 21px 0;
  font-size: 25px;
  font-weight: 600;
}

#intro .intro-content h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 500;
}

#intro .intro-content h2 span {
  color: #50d8af;
  text-decoration: underline;
}

#intro .intro-content .box-btn {
  display: flex;
}

#intro .intro-content .btn-get-started,
#intro .intro-content .btn-projects {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  width: 21vw;
  height: 63px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 7px;
  transition: 0.5s;
  margin: 10px;
  text-align: center;
  color: #fff;
}

#intro .intro-content .btn-get-started {
  background: #17a2b8;
  border: 2px solid #17a2b8;
}

/*#intro .intro-content .btn-projects {
  padding-top: 18px;
}*/

#intro .intro-content .btn-get-started:hover {
  background: none;
  color: #17a2b8;
}

#intro .intro-content .btn-projects {
  background: #50d8af;
  border: 2px solid #50d8af;
}

#intro .intro-content .btn-projects:hover {
  background: none;
  color: #50d8af;
}
#intro #intro-carousel {
  z-index: 8;
}

#intro #intro-carousel::before {
  content: '';
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 7;
}

#intro #intro-carousel .item {
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
}

/* About Section
--------------------------------*/   
#about {
  padding: inherit;
}

#about .about-img {
  overflow: hidden;
}

#about .about-img img {
  margin-left: -15px;
  max-width: 100%;
}

#about .content {
  padding: 16px 39px;
}

#about .content h1,
#about .content h2 {
  color: #17a2b8;
  text-align: center;
  font-weight: 700;
  font-size: 1.33rem;
  font-family: "Raleway", sans-serif;
}

#about .content.info-box p {
  color: #4c858e;
  font-size: 1.06em;
  text-align: justify;
  line-height: 1.27em;
  margin-top: 16px;
}

#about .content.fond-bleu {
  background-color: #17a2b8;
  color: white;
}

#about .content.fond-bleu h2 {
  color: white;
}

#about .content.fond-vert {
  background-color: #20c997;
  color: white;
}

#about .content.fond-vert h2,
#about .content.fond-vert h3 {
  color: white;
}

#about .content.fond-vert .limited {
  font-style: italic;
}

#about .content h3 {
  color: #555;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
}

#about .content p {
  line-height: 26px;
}

#about .content p:last-child {
  margin-bottom: 0;
}

#about .content i {
  font-size: 20px;
  padding-right: 4px;
  color: #50d8af;
}

#about .content ul {
  list-style: none;
  padding: 0;
}

#about .content ul li {
  padding-bottom: 10px;
}

/***********
  Dialogues
*/
.dialog_location_over_mobile .v-menu__content {
  left: 0 !important;
  width: 100vw !important;
}

/****************
   POPUP PROMO
****************/
.corpus {
    width: 100%;
    margin: auto;
}
.corpus.principal {
    background-color: #20c997;
    min-height: 450px;
}
.principal {
    background-color: #20c997;
    min-height: 450px;
}
.operation {
    width: 36%;
    height: 39px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background-color: #ffc400;
    margin: 0 auto;
    padding: 7px;
}
.operation.bandeau {
    width: 93%;
}
.covoit-gagnant {
    color: white;
    text-transform: uppercase;
    background-color: teal;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    padding: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#main-image-home {
    /*background-image: url("../assets/img/banners/top6.webp");*/
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 154px;
}
.sous-titre {
    margin: 18px auto;
    width: 75%;
    text-align: center;
    font-size: 0.93rem;
    color: black;
    letter-spacing: -0.3pt;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.promo-popup-promo {
    font-family: Roboto, 'Segoe UI';
    font-size: 1.8em;
    color: white;
    font-weight: bold;
    margin-bottom: 9px;
}
.sous-titre a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}
.sous-titre .dix-euro {
    padding: 0px 4px;
    font-weight: bold;
    text-decoration: underline;
    /*background-color: white;*/
}
.bloc-bouton-popup-promo {
    width: 90%;
    padding: 25px;
    margin: 9px auto 36px;
    text-align: center;
}
.bouton-popup-promo {
    border: 1px solid white;
    border-radius: 9px;
    background-color: teal;
    color: white !important;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 9px 18px;  
}

.box-prix-encadres {
  background-color: teal;
  color: white !important;
  border-radius: 18px;
  padding: 12px 12px 3px 12px;
  margin-bottom: 9px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.box-prix-encadres.vert-smv {
  background-color: #20c997;
}
.box-prix-encadres.jaune-smv {
  background-color: #ffc400;
}
.box-prix-encadres.bleu-smv {
  background-color: #17a2b8;
}
.box-prix-encadres.rose-smv {
  background-color: #fd6c9e;
}

.box-prix-encadres h2 {
  color: white !important;
  display: inline-block;
  font-size: 1.3rem !important;
}

.box-prix-encadres .et {
  display: inline-block;
  padding-left: 18px;
  padding-right: 18px;
}

.box-prix-encadres h2.prix {
  margin-top: 7px;
}

@media (max-width: 1060px) {
  #intro .intro-content .btn-get-started,
  #intro .intro-content .btn-projects {
    width: 27vw;
  }
}

@media (max-width: 840px) {
  #intro .intro-content .btn-get-started,
  #intro .intro-content .btn-projects {
    width: 27vw;
    height: 81px;
    font-size: 13px;
  }
}

@media (max-width: 630px) {
  #intro .intro-content .btn-get-started,
  #intro .intro-content .btn-projects {
    width: 43vw;
    height: 75px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  #intro {
    width: 100%;
    height: 45vh;
  }

  #intro .intro-content .btn-get-started,
  #intro .intro-content .btn-projects {
    width: 43vw;
    height: 90px;
    font-size: 13px;
  }

  #about .content h2 {
    font-size: 1.12rem;
  }
  
  .bouton-popup-promo {
    font-size: 10px;
  }
  .covoit-gagnant {
    font-size: 25px;
    font-weight: bold;
  }
  .promo-popup-promo {
    font-size: 1.26em;
  }
}
</style>

<script>
import CarteCovoiturage from '../components/CarteCovoiturage';
import axios from 'axios';
import moment from 'moment';
import router from './../router';
import constantes from '../constantes';

export default {
  components: { CarteCovoiturage },
  data() {
    return {
      covoiturages: [],
      dialog_promo: false,
      villes: [
        [75101, 29019], // 1. Paris - Brest
        [29019, 44109], // 2. Nantes - Brest
        [59350, 35238], // 3. Lille - Rennes
        [75101, 69381], // 4. Lyon - Paris
        [33063, 31555], // 5. Bordeaux - Toulouse
        [44109, 33063], // 6. Nantes - Bordeaux
        [75101, 67482], // 7. Strasbourg - Paris
        [75101, 51454], // 8. Reims - Paris
        [34172, 30189], // 9. Montpellier - Nimes
        [31555, 30189], // 10. Toulouse - Nimes
        [29019, 35238], // 11. Brest - Rennes
        [35238, 69381], // 12. Rennes - Lyon
        [54395, 75101], // 13. Nancy - Paris
        [45234, 75101], // 14. Orléans - Paris
      ],
      bornes: [
        /* 1 */{"ville_depart":"PARIS 01","ville_arrivee":"BREST","code_commune_depart":"75101","code_commune_arrivee":"29019","min":26,"max":34,"duree_en_secondes":"23534.8"},
        /* 2 */{"ville_depart":"BREST","ville_arrivee":"NANTES","code_commune_depart":"29019","code_commune_arrivee":"44109","min":12,"max":18,"duree_en_secondes":"13154.1"},
        /* 3 */{"ville_depart":"LILLE","ville_arrivee":"RENNES","code_commune_depart":"59350","code_commune_arrivee":"35238","min":23,"max":31,"duree_en_secondes":"21610"},
        /* 4 */{"ville_depart":"PARIS 01","ville_arrivee":"LYON 01","code_commune_depart":"75101","code_commune_arrivee":"69381","min":18,"max":26,"duree_en_secondes":"17670.9"},
        /* 5 */{"ville_depart":"BORDEAUX","ville_arrivee":"TOULOUSE","code_commune_depart":"33063","code_commune_arrivee":"31555","min":7,"max":13,"duree_en_secondes":"9250.5"},
        /* 6 */{"ville_depart":"NANTES","ville_arrivee":"BORDEAUX","code_commune_depart":"44109","code_commune_arrivee":"33063","min":12,"max":18,"duree_en_secondes":"12999.1"},
        /* 7 */{"ville_depart":"PARIS 01","ville_arrivee":"STRASBOURG","code_commune_depart":"75101","code_commune_arrivee":"67482","min":19,"max":27,"duree_en_secondes":"18450"},
        /* 8 */{"ville_depart":"PARIS 01","ville_arrivee":"REIMS","code_commune_depart":"75101","code_commune_arrivee":"51454","min":4,"max":9,"duree_en_secondes":"6036.8"},
        /* 9 */{"ville_depart":"MONTPELLIER","ville_arrivee":"NIMES","code_commune_depart":"34172","code_commune_arrivee":"30189","min":4,"max":6,"duree_en_secondes":"2705.3"},
        /* 10 */{"ville_depart":"TOULOUSE","ville_arrivee":"NIMES","code_commune_depart":"31555","code_commune_arrivee":"30189","min":10,"max":16,"duree_en_secondes":"10975"},
        /* 11 */{"ville_depart":"BREST","ville_arrivee":"RENNES","code_commune_depart":"29019","code_commune_arrivee":"35238","min":8,"max":14,"duree_en_secondes":"10334.4"},
      ],
      trajets_a_la_une: [],
      fourchettes: [],
      dialog_location: false,
      location_depart: '',
      location_arrivee: '',
      type_location_activee: undefined,
      isLoading: false,
      entries_depart: [],
      entries_arrivee: [],
      adresse_depart: '',
      search_depart: null,
      adresse_arrivee: '',
      search_arrivee: null,
      recherche: {
        nb_voyageurs: 1,
        date_depart: moment().format("YYYY-MM-DD"),
      },
    }
  },
  mounted() {
    // Titre de l'onglet
    document.title = 'SimoneVerte.fr : le covoiturage longue distance pas cher | SimoneVerte';
    
    this.dialog_promo = this.$route.name.toLowerCase().includes('promotion');

    window.addEventListener('resize', this.showBanners);
    // window.addEventListener('resize', this.showPromotions);
    this.showBanners();
    // this.showPromotions(); 

    var divIntroBlocks = document.querySelectorAll(".intro-block"),
      currentBlock = 0,
      blocksLength = divIntroBlocks.length;
    divIntroBlocks[currentBlock].style.opacity = 1;
    
    setInterval(() => {
      divIntroBlocks[currentBlock].style.opacity = 0;
      divIntroBlocks[currentBlock].style.display = "none";
      currentBlock = (currentBlock + 1) % blocksLength;
      divIntroBlocks[currentBlock].style.opacity = 1;
      divIntroBlocks[currentBlock].style.display = "block";
    }, 7500);

    // Récupération des derniers trajets 
    // 1. Trajets
    // 2. MangoPay
    this.$api.get('/trajets?filter[actif]=1')
    .then(res => { 
      this.trajets_a_la_une = res.data.data.sort(() => 0.5 - Math.random())
      .slice(0, 4);
    })
    .catch(err => {
      console.error(err);
    });  

    // Appel de la recherche de trajet correspondant aux criteres
    this.villes.sort(() => 0.5 - Math.random()).slice(0, 8)
      .forEach((arrVilles) => {
      
      const currentBorne = this.bornes.filter(borne => borne.code_commune_depart == arrVilles[0] && borne.code_commune_arrivee == arrVilles[1]);
      if (currentBorne.length >= 1) {

        var min = currentBorne[0].min * (1 + constantes.pourcentage_fees) + constantes.autres_fees,
          max = currentBorne[0].max * (1 + constantes.pourcentage_fees) + constantes.autres_fees,
          ville1 = this.$root.$options.filters.premiereLettreCapitale(currentBorne[0].ville_depart),
          ville2 = this.$root.$options.filters.premiereLettreCapitale(currentBorne[0].ville_arrivee);
        
        min = min < 5 ? 5 : min;

        this.fourchettes.push(
          {
            ville1: ville1,
            ville2: ville2,
            codeCommune1: arrVilles[0],
            codeCommune2: arrVilles[1],
            min: Math.trunc(min),
            minDecimal: Math.round((min % 1) * 100),
            max: Math.trunc(max),
            maxDecimal: Math.round((max % 1) * 100)
          });
      } 
      else {
        this.$api.get('/bornes/' + arrVilles[0] + '/' + arrVilles[1])
        .then(res => {
          var min = res.data.min * (1 + constantes.pourcentage_fees) + constantes.autres_fees,
            max = res.data.max * (1 + constantes.pourcentage_fees) + constantes.autres_fees,
            ville1 = this.$root.$options.filters.premiereLettreCapitale(res.data.ville_depart),
            ville2 = this.$root.$options.filters.premiereLettreCapitale(res.data.ville_arrivee);
          
          min = min < 5 ? 5 : min;
  
          this.fourchettes.push(
            {
              ville1: ville1,
              ville2: ville2,
              codeCommune1: arrVilles[0],
              codeCommune2: arrVilles[1],
              min: Math.trunc(min),
              minDecimal: Math.round((min % 1) * 100),
              max: Math.trunc(max),
              maxDecimal: Math.round((max % 1) * 100)
            });
          
        })
        .catch(err => {
          console.error(err);
        });  
      }  
    });
    // .finally(() => this.$root.$emit("hide_loading"));

  },
  destroyed() {
    window.removeEventListener('resize', this.showBanners);
    // window.removeEventListener('resize', this.showPromotions);
  },
  watch: {
    /*dialog_promo: function(val, old) {  
      if (!val && old) {
        localStorage.setItem("dialog_promo", false);
      }
    },*/
    location_depart(value) {
      if ((value && !this.recherche.adresse_depart) 
        || (value !== this.recherche.adresse_depart)) {
        
        this.recherche.adresse_depart = value;
      }
      this.$emit('input', value ? value : null);

      // L'adresse complète est saisie 
      if (value && value.indexOf(',') !== -1) {
        this.dialog_location = !this.location_arrivee;
      }
    },
    location_arrivee(value) {
      if ((value && !this.recherche.adresse_arrivee) 
        || (value !== this.recherche.adresse_arrivee)) {
        
        this.recherche.adresse_arrivee = value;
      }
      this.$emit('input', value ? value : null);

      // L'adresse complète est saisie 
      if (value && value.indexOf(',') !== -1) {
        this.dialog_location = !this.location_depart;
        if (!this.dialog_location)
          this.trouver();
      }
    },
    search_depart(val) {
      val && val !== this.location_depart && this.watchSearch(val, 'DEPART');
    },
    search_arrivee(val) {
      val && val !== this.location_arrivee && this.watchSearch(val, 'ARRIVEE');
    },
  },
  computed: {
    items_depart() {
      return this.computedItems('DEPART');
    },
    items_arrivee() {
      return this.computedItems('ARRIVEE');
    },
  },
  methods: {
    afficherDialogueLocation: function(typeLocation) {
      this.type_location_activee = typeLocation;
      setTimeout(() => {
        this.dialog_location = !this.location_depart || !this.location_arrivee;
      }, 125);
    },
    getBoxColorCssClass: function(index) {
      switch (index % 4) {
        case 0:
          return "bleu-smv";
        case 1:
          return "jaune-smv";
        case 2:
          return "vert-smv";
        default:
          return "rose-smv";
      }
    },
    goToProposerTrajet: function() {
      router.push({ name: 'proposer'});
    },
    goTo: function(fourchette) {
      router.push({ 
        path: '/covoiturages/' + fourchette.ville1 + '/' + fourchette.ville2
      });
    },
    showBanners: function() {
      setTimeout(() => {
        var url1 ='',
          url2 = '',
          url3 = '',
          urlPopup = '',
          divIntro1 = document.getElementById("intro-1"),
          divIntro2 = document.getElementById("intro-2"),
          divIntro3 = document.getElementById("intro-3");
        
        var divMainImage;
        if (this.dialog_promo) {
          divMainImage = document.getElementById("main-image-home");
        }

        if (this.$vuetify.breakpoint.name) {
          url1 = require("../assets/img/banners/" + this.$vuetify.breakpoint.name + "/top4.webp");
          url2 = require("../assets/img/banners/" + this.$vuetify.breakpoint.name + "/amitie-1.jpg");
          url3 = require("../assets/img/banners/" + this.$vuetify.breakpoint.name + "/top6.webp");
          urlPopup = require("../assets/img/banners/" + this.$vuetify.breakpoint.name + "/top6.webp");
        } else {
          url1 = require("../assets/img/banners/lg/top4.webp");
          url2 = require("../assets/img/banners/lg/amitie-1.jpg");
          url3 = require("../assets/img/banners/lg/top6.webp");
          urlPopup = require("../assets/img/banners/lg/top6.webp");
        }

        divIntro1.style.backgroundImage = "url(" + url1 + ")";
        divIntro2.style.backgroundImage = "url(" + url2 + ")";
        divIntro3.style.backgroundImage = "url(" + url3 + ")";
        
        if (this.dialog_promo) {
          divMainImage.style.backgroundImage = "url(" + urlPopup + ")";
        }
      
      }, 360);
    },

    showPromotions: function() {
      setTimeout(() => {
        var url1 ='',
          url2 = '',
          divPromotion1 = document.getElementById("promotion-1"),
          divPromotion2 = document.getElementById("promotion-2");

        if (this.$vuetify.breakpoint.name) {
          url1 = require("../assets/img/promotions/home/" + this.$vuetify.breakpoint.name + "/retour-gagnant.jpg");
          url2 = require("../assets/img/promotions/home/" + this.$vuetify.breakpoint.name + "/coup-double.jpg");
        } else {
          url1 = require("../assets/img/promotions/home/lg/retour-gagnant.jpg");
          url2 = require("../assets/img/promotions/home/lg/coup-double.jpg");
        }

        divPromotion1.style.backgroundImage = "url(" + url1 + ")";
        divPromotion2.style.backgroundImage = "url(" + url2 + ")";
      }, 360);
    },

    computedItems: function(type) {
      
      var entries = type == 'DEPART' ? this.entries_depart : this.entries_arrivee;
      return entries.map(entry => {
        var description = entry.properties.name;

        if (entry.properties.postcode)
          description += ', ' + entry.properties.postcode;
        // if (entry.properties.city)
          // description += ', ' + entry.properties.city;
        description += ', France';

        const entryText = description.length > this.descriptionLimit
          ? description.slice(0, this.descriptionLimit) + '...'
          : description
        
        return entryText;
      })
    },
    watchSearch: function(adresse, type) {

      // Items have already been requested
      if (this.isLoading) return;

      if (adresse && adresse.length > 1) {
        this.isLoading = true;
        // Lazily load input items
        axios.get('https://api-adresse.data.gouv.fr/search/?q='+adresse.replace(' ', '+').toLowerCase() +'&type=municipality&limit=7',
          {
            transformRequest: (data, headers) => {
              delete headers['Authorization'];
            }
          }
        )
        .then(res => {
          if (type == 'DEPART')
            this.entries_depart = res.data.features;
          else
            this.entries_arrivee = res.data.features;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
      }
    },
    trouver: function() {
      var adresse_depart_array = this.recherche.adresse_depart.split(', ');
      var commune_depart = this.entries_depart.filter(entry => {
        return entry.properties.name.indexOf(adresse_depart_array[0]) != -1 
          && entry.properties.postcode == adresse_depart_array[1];
      });

      if (!commune_depart || commune_depart.length == 0) {
        commune_depart = this.entries_depart.filter(entry => {
          return entry.properties.city.indexOf(adresse_depart_array[0]) != -1 
            && entry.properties.postcode == adresse_depart_array[1];
        });
      }

      var adresse_arrivee_array = this.recherche.adresse_arrivee.split(', ');
      var commune_arrivee = this.entries_arrivee.filter(entry => {
        return entry.properties.name.indexOf(adresse_arrivee_array[0]) != -1 
          && entry.properties.postcode == adresse_arrivee_array[1];
      });
      
      if (!commune_arrivee || commune_arrivee.length == 0) {
        commune_arrivee = this.entries_arrivee.filter(entry => {
          return entry.properties.city.indexOf(adresse_arrivee_array[0]) != -1 
            && entry.properties.postcode == adresse_arrivee_array[1];
        });
      }

      router.push(
      { 
        name: 'resultats', 
        query: {
          date: this.recherche.date_depart,
          from: commune_depart[0].properties.citycode,
          to: commune_arrivee[0].properties.citycode,
          from_city: commune_depart[0].properties.city,
          to_city: commune_arrivee[0].properties.city,
          from_adr: this.recherche.adresse_depart.replace(' ', '+').toLowerCase(),
          to_adr: this.recherche.adresse_arrivee.replace(' ', '+').toLowerCase(),
        }
      });
    }
  }
}
</script>