<template>

  <v-container>
    <v-row class="text-center">
      <v-col 
        class="hidden-sm-and-down"
        cols="12" 
        lg="3"
        md="3"
        sm="3">
        <div class="sv-vertical-menu">
          <!-- Notifications -->
          <div class="sv-menu-titre" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'">Administration</div>
          <div class="sv-menu-item" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'">
            <span><router-link :to="{name: 'inscriptionAdmin'}">Gestion des utilisateurs</router-link></span>
          </div>
          <div class="sv-menu-item" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'">
            <span><router-link :to="{name: 'trajetsAdmin'}">Gestion des trajets</router-link></span>
          </div>
          <div class="sv-menu-item" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'">
            <span><router-link :to="{name: 'emailAdmin'}">Gestion des E-mail</router-link></span>
          </div>
          <div class="sv-menu-item" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'">
            <span><router-link :to="{name: 'emailAdmin'}">Gestion des newsletter</router-link></span>
          </div>
          <div class="sv-menu-item" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'">
            <span><router-link :to="{name: 'otpMobileAdmin'}">Gestion des OTPs</router-link></span>
          </div>
          <div class="sv-menu-separateur" v-show="profile.role == 'ADMIN' || profile.role == 'MANAGER'"></div>

          <!-- Parrainage -->
          <!--
          <div class="sv-menu-titre">Mon parrainage</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'parrainageUtilisateurs'}">Je parraine</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'parrainageUtilisateurs'}">Mes gains</router-link></span>
          </div>
          <div class="sv-menu-separateur"></div>
          -->

          <!-- Mon profil -->
          <div class="sv-menu-titre">Mon profil</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'infosUtilisateurs', query: {info: 'general'}}">Modifier mon profil</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'infosUtilisateurs', query: {info: 'photo'}}">Ma photo</router-link></span>
          </div>
          <!--<div class="sv-menu-item">
            <span><router-link :to="{name: 'infosUtilisateurs', query: {info: 'telephone'}}">Mon téléphone</router-link></span>
          </div>-->
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'infosUtilisateurs', query: {info: 'documents'}}">Mon identité</router-link></span>
          </div>
          <!--<div class="sv-menu-item">
            <span><router-link :to="{name: 'infosUtilisateurs', query: {info: 'vehicules'}}">Mes vehicules</router-link></span>
          </div>-->
          <div class="sv-menu-separateur"></div>
          
          <!-- A bord -->
          <!--<div class="sv-menu-titre">En voiture</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'activitesUtilisateurs', query: {type: 'decouverte'}}">Découvrir</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'activitesUtilisateurs', query: {info: 'fun'}}">S'amuser</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'activitesUtilisateurs', query: {info: 'pitcher'}}">Pitcher</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'activitesUtilisateurs', query: {info: 'guide'}}">Guide touristique</router-link></span>
          </div>
          <div class="sv-menu-separateur"></div>-->
          
          <!-- Trajet -->
          <div class="sv-menu-titre">Mes trajets</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'trajetsUtilisateurs', query: {type: 'conducteur'}}">Mes trajets conducteurs</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'trajetsUtilisateurs', query: {type: 'passager'}}">Mes trajets passagers</router-link></span>
          </div>
          <div class="sv-menu-separateur"></div>

          <!-- Avis -->
          <!--
            <div class="sv-menu-titre">Mes avis</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'avisUtilisateurs', query: {type: 'recus'}}">Sur moi</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'avisUtilisateurs', query: {type: 'laisses'}}">De moi</router-link></span>
          </div>
          <div class="sv-menu-separateur"></div>
          -->

          <!-- Argent -->
          <div class="sv-menu-titre">Mon argent</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'argentUtilisateurs', query: {type: 'comptes'}}">Mon IBAN / RIB</router-link></span>
          </div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'argentUtilisateurs', query: {type: 'paiements'}}">Mes paiements</router-link></span>
          </div>
          <!--<div class="sv-menu-item">
            <span><router-link :to="{name: 'argentUtilisateurs', query: {type: 'virements'}}">Mes virements reçus</router-link></span>
          </div>-->
          <div class="sv-menu-separateur"></div>

          <!-- Notifications -->
          <!--<div class="sv-menu-titre">Mes notifications</div>
          <div class="sv-menu-item">
            <span><router-link :to="{name: 'notifsUtilisateurs', query: {info: 'notifications'}}">Nouvelles</router-link></span>
          </div>
          <div class="sv-menu-separateur"></div>-->
        </div>
      </v-col>

      <v-col 
          cols="12" 
          lg="9"
          md="9"
          sm="12">
          <router-view name="vue-interieure" :key="$route.fullPath" />
      </v-col>
      
    </v-row>

  </v-container>
</template>


<style>
.sv-vertical-menu {
  display: flex;
  flex-direction: column;
}

.sv-vertical-menu > div {
  border: 1px solid #ccc;
  padding: 4px;
}

.sv-vertical-menu .sv-menu-titre {
  font-weight: 600;
  background: #50d8af;
  color: #fff;
}

.sv-vertical-menu .sv-menu-item {
  background: #f2f3f7;
}

.sv-vertical-menu .sv-menu-separateur {
  border-width: 0px;
  height: 16px;
}
</style>


<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getProfile"]),
    profile() {
      return this.getProfile;
    },
  },
  mounted() {
    // Titre de l'onglet
    document.title = 'Espace utilisateur SimoneVerte.fr, le covoiturage longue distance à petits prix | SimoneVerte.fr';
    // Après le délai on vérifie la connexion
    setTimeout(() => {
      if (!this.profile || !this.profile.id) {
        this.$router.push({ name: "home" });
      }
    }, 12000);   
  }      
}
</script>
