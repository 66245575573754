import moment from 'moment';
import constantes from '../constantes';

export default {
  premiereLettreCapitale: function(value) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    }
  },
  numeroCB: function(value) {
    if (value) {
      var res = '';
      if (value.length > 3) {
        res += value.slice(0, 4);
      }
      if (value.length > 7) {
        res += ' - ' + value.slice(4, 8);
      }
      if (value.length > 11) {
        res += ' - ' + value.slice(8, 12);
      }
      if (value.length > 15) {
        res += ' - ' + value.slice(12, 16);
      }
      return res;
    }
  },
  avatar: function(value) {
    if (value) {
      return constantes.avatarsUrl + value;
    }
  },
  numeroCarteBancaire: function(value) {
    if (value) {
      var res = '';
      for (let i=0; i<4; i++) {
        if (value.length >= i * 4) {
          res += ' ' + value.substr(i*4, 4) + ' '; 
        }
      }
      return res;
    }
  },
  dureeToHeure: function(value) {
    if (value) {
      var v = parseInt(value),
        h = Math.floor(v / 3600),
        m = Math.floor((v - 3600 * h) / 60);
      h = h >= 24 ? h - 24 : h;
      return h + 'h' + (m < 10 ? ("0" + m) : m);
    }
  },
  heureToDuree: function(value) {
    if (value) {
      var hh = value.substr(0, 2),
        mm = value.substr(3, 2);
      return parseInt(hh * 3600 + mm * 60);
    }
  },
  formatDate: function(value) {
    if (value) {
      if (value == 'JOUR_J') {
        return moment().format('DD/MM/YYYY');
      }
      return moment(String(value)).format('DD/MM/YYYY');
    }
  },
  formatDateHeure: function(value) {
    if (value) {
      if (value == 'JOUR_J') {
        return moment().format('DD/MM/YYYY (HH:mm:ss)');
      }
      return moment(String(value)).format('DD/MM/YYYY (HH:mm:ss)');
    }
  },
  dateToAge: function(value) {
    if (value) {
      var a = moment();
      var b = moment(value, 'YYYY-MM-DD');
      var d = moment.duration(a.diff(b));
      return d.years();
    }
  },
  dateComplete: function(date) {
    if (date) {
      moment.locale('fr');
      var d = moment(date, 'YYYY-MM-DD');
      return d.format('dddd Do MMMM');
    }
  },
  dateEnLettres: function(date, masquerAnnee) {
    if (date) {
      var d = date == 'JOUR_J' ? moment() : moment(date, 'YYYY-MM-DD'),
        mToday = moment(),
        dStr = d.format("DD/MM/YYYY"),
        mTodayStr = mToday.format("DD/MM/YYYY");
        
      if (dStr == mTodayStr) {
        return 'Aujourd\'hui';
      } else if (dStr == mToday.add(1,'days').format("DD/MM/YYYY")) {
        return 'Demain';
      } else if (dStr == mToday.add(-1,'days').format("DD/MM/YYYY")) {
        return 'Hier';
      }

      var returnFormat = 'dddd Do' + (!masquerAnnee ? ' MMMM' : '/MM');
      
      moment.locale('fr');
      return moment(date, 'YYYY-MM-DD')
        .format(returnFormat);
    }
  },
  ville: function(value) {
    if (value) {
      var arr = value.split(',');
      return arr[arr.length - 1];
    }
  },
  cleanAdresseComplete: function(value) {
    if (value) {
      value = value.charAt(0) == "'" ? value.substring(1) : value;
      value = value.charAt(value.length - 1) == "'" ? value.substring(0, value.length - 1) : value;
      return value;
    }
  },
  price: function(value) {
    if (value) {
      var decimal = Math.round((value % 1) * 100);
      var p_entiere = Math.floor(value);
      var p_decimal = '' + (decimal == 0 ? '00' : decimal) + '';
      
      return p_entiere + ',' + p_decimal;
    }
    return '0,00'
  },
  heure: function(value) {
    if (value) {
      var hh = value.substr(0, 2),
        mm = value.substr(3, 2);
      
      hh = (parseInt(hh) % 24);
      
      return hh + 'h' + mm;
    }
  },
  double: function(value) {
    if (value || value == 0) {
      return (value < 10) ? ('0' + value) : value;
    }
  }
};