<template>
    <div id="main" style="min-height:70vh;">
        <section id="trouver">
            <div class="trouver-content" v-show="!calendrier_visible">
                <h1 class="titre" style="margin-bottom: 16px">Trouver un covoiturage</h1>
                <div class="input-group v-input-sm-container">

                    <v-autocomplete
                        v-model="adresse_depart"
                        :items="items_depart"
                        :loading="isLoading"
                        :search-input.sync="search_depart"
                        color="#ccc"
                        no-filter
                        clearable
                        hide-no-data
                        hide-selected
                        item-text="Description"
                        item-value="API"
                        label=""
                        placeholder="Saisissez votre adresse de départ..."
                        prepend-icon="mdi-home-circle"
                        return-object />
                </div>
                
                <div class="input-group v-input-sm-container">
                    
                    <v-autocomplete
                        v-model="adresse_arrivee"
                        :items="items_arrivee"
                        :loading="isLoading"
                        :search-input.sync="search_arrivee"
                        color="#ccc"
                        no-filter
                        clearable
                        hide-no-data
                        hide-selected
                        label=""
                        placeholder="Saisissez votre destination ..."
                        prepend-icon="mdi-home-map-marker"
                        return-object />
                </div>
                
                <div class="data-content" v-show="!calendrier_visible">
                    <div id="bouton-calendrier-container">
                        <div class="mdi mdi-calendar pointer" v-on:click="calendrier_visible = true"></div>
                        <input 
                            type="button"
                            class="bouton"
                            value="Quand ?"
                            v-on:click="calendrier_visible = true" />
                        <div class="reponse" v-show="recherche.date_depart">
                          {{ recherche.date_depart | dateEnLettres }}
                        </div>
                    </div>
                    <div id="bouton-passager-container">
                        <div class="mdi mdi-numeric pointer" v-on:click="compteur_voyageurs_visible = true"></div>
                        <input 
                            type="button"
                            class="bouton"
                            value="A combien ?"
                            v-on:click="compteur_voyageurs_visible = true" />
                        <div class="passenger-data" v-show="compteur_voyageurs_visible">
                            <input
                                type="number"
                                max="4"
                                min="1"
                                v-model="recherche.nb_voyageurs" />
                            <i class="mdi mdi-check pointer vert" v-on:click="compteur_voyageurs_visible = false"></i>
                        </div>
                        <div class="reponse" v-show="!compteur_voyageurs_visible && recherche.nb_voyageurs">{{ recherche.nb_voyageurs }}</div>
                    </div>
                </div>
                <div class="input-group">
                    <input 
                      class="btn centre rechercher"
                      :disabled="!recherche.adresse_depart || !recherche.date_depart"
                      type="button" value="Rechercher" v-on:click="rechercher" />
                </div>
            </div>
            <div class="calendrier" v-show="calendrier_visible">
                <label for="trouver" class="titre">Date de Voyage</label>
                <div class="cal-data">
                  <v-row justify="center">
                      <v-date-picker 
                          id="date_depart"
                          class="centre" 
                          color="#50d8af"
                          locale="fr-fr"
                          v-model="date"
                          :min="today"
                          :first-day-of-week="1"
                          @click:date="confirmer" />
                  </v-row>
                </div>
            </div>
        </section>
    </div>
</template>

<style>
#trouver {
    margin: auto;
    width: 100%;
    padding: 16px;
}

.trouver-content {
    text-align: center;
}

#trouver .trouver-content .input-group {
    margin: 13px 0;
}

.titre {
  color: #17a2b8;
  margin: 9px 0 39px 0;
  font-size: 25px;
  font-weight: 600;
}

.vert {
  color: green;
}

.rechercher {
  margin: 16px auto;
}

#trouver .trouver-content input {
  width: 39%;
  border-radius: 12px;
  border: 1px solid #ccc;
  height: 39px;
  max-height: 39px;
  margin: 0 auto;
  padding-left: 12px;
}

#trouver .trouver-content .v-input-sm-container, 
#trouver .trouver-content .trouver-description {
  width: 39%;
  margin: 0 auto;
}

#trouver .trouver-content .trouver-description {
  margin-bottom: 18px;
  text-align: left;
}

#trouver .trouver-content input:focus {
  border: 2px solid #17a2b8;
} 

#trouver .trouver-content input.bouton {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  width: 210px;background: #17a2b8;
  border: 2px solid #17a2b8;
}


#trouver .trouver-content .data-content {
    display:flex;
    width: 39%;
    margin: 3px auto;
    padding: 4px;
    justify-content: space-between;
}

#trouver .trouver-content .data-content #bouton-passager-container,
#trouver .trouver-content .data-content #bouton-calendrier-container {
  display: inline-block;
  width: 48%;
}

#trouver .trouver-content .data-content #bouton-passager-container input,
#trouver .trouver-content .data-content #bouton-calendrier-container input {
    background: none;
    color: #777;
    border: none;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: normal;
    width: 84%;
}

#trouver .trouver-content .data-content #bouton-passager-container input:hover,
#trouver .trouver-content .data-content #bouton-calendrier-container input:hover {
  color: #17a2b8;
  /* font-weight: bold; */
}

#trouver .trouver-content .data-content #bouton-passager-container {
  margin-right: 48px;
}

.calendrier{
    justify-content: center;
    text-align: center;
}

.calendrier cal-data{ 
    justify-content: center;
    align-content: center;
}

.nb-voyageurs{
    text-align: center;
}

.passenger-data{
    width: 63%;
    margin: -7px auto; 
}

.passenger-data > input {
    text-align: center;
    width: 120px;
}

.button {
    width: 10%;
    margin: auto;
    margin-top: 25px;
    padding: 10px;
    background-color: #50d8af;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style:solid;
    border-radius: 30px;
    border-color: #50d8af;
}

.pointer {
  cursor: pointer;
}

#trouver .trouver-content input[type=button].centre {
    margin: 0px auto;
}

.centre {
    margin: 0px auto;
}

/* Surcharge style v-autocomplete */
.v-text-field > .v-input__control > .v-input__slot::before,
.v-text-field > .v-input__control > .v-input__slot::after {
  width: 0px !important;
}

@media(max-width: 930px) {
  #trouver .trouver-content .v-input-sm-container,
  #trouver .trouver-content .trouver-description {
    width: 72%;
    margin: 0 auto;
  }

  #trouver .trouver-content input,
  #trouver .trouver-content .data-content {
    width: 72%;
  }
}


@media(max-width: 480px) {
  #trouver .trouver-content .v-input-sm-container,
  #trouver .trouver-content .trouver-description {
    width: 93%;
    margin: 0 auto;
  }

  #trouver .trouver-content input,
  #trouver .trouver-content .data-content {
    width: 93%;
  }
}
</style>

<script>
import axios from 'axios';
import moment from 'moment';
import router from '../router';

export default {
  data() {
    return {
      recherche: {
        nb_voyageurs: 1,
        date_depart: moment().format("YYYY-MM-DD"),
      },
      date: new Date().toISOString().substr(0, 10),
      calendrier_visible: false,
      compteur_voyageurs_visible: false,
      descriptionLimit: 60,
      isLoading: false,
      entries_depart: [],
      entries_arrivee: [],
      adresse_depart: '',
      search_depart: null,
      adresse_arrivee: '',
      search_arrivee: null,
    }
  },
  mounted() {
    // Titre de l'onglet
    document.title = 'Trouvez et réservez un covoiturage. Partagez une expérience. Pas cher et carrément plus cool | SimoneVerte.fr';
    this.$root.$emit(
      "change_meta_description", 
      "Réserver votre covoiturage sur SimoneVerte dans toute la France."
    );
    document.getElementById("date_depart").setAttribute("min", this.today.toString());
  },
  unmounted() {
    this.$root.$emit("reset_meta_description");
  },
  computed: {
    items_depart() {
      return this.computedItems('DEPART');
    },
    items_arrivee() {
      return this.computedItems('ARRIVEE');
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    }
  },
  watch: {
    adresse_depart(value) {
      if ((value && !this.recherche.adresse_depart) 
        || (value !== this.recherche.adresse_depart)) {
        
        this.recherche.adresse_depart = value;
      }
      this.$emit('input', value ? value : null);
    },
    adresse_arrivee(value) {
      if ((value && !this.recherche.adresse_arrivee) 
        || (value !== this.recherche.adresse_arrivee)) {
        
        this.recherche.adresse_arrivee = value;
      }
      this.$emit('input', value ? value : null);
    },
    search_depart(val) {
      val && val !== this.adresse_depart && this.watchSearch(val, 'DEPART');
    },
    search_arrivee(val) {
      val && val !== this.adresse_arrivee && this.watchSearch(val, 'ARRIVEE');
    },
  },
  methods: {
    computedItems: function(type) {
      
      var entries = type == 'DEPART' ? this.entries_depart : this.entries_arrivee;
      return entries.map(entry => {
        var description = entry.properties.name;

        if (entry.properties.postcode)
          description += ', ' + entry.properties.postcode;
        // if (entry.properties.city)
          // description += ', ' + entry.properties.city;
        description += ', France';

        const entryText = description.length > this.descriptionLimit
          ? description.slice(0, this.descriptionLimit) + '...'
          : description
        
        return entryText;
      })
    },
    watchSearch: function(adresse, type) {

      // Items have already been requested
      if (this.isLoading) return;

      if (adresse && adresse.length > 1) {
        this.isLoading = true;
        // Lazily load input items
        axios.get('https://api-adresse.data.gouv.fr/search/?q='+adresse.replace(' ', '+').toLowerCase() +'&type=municipality&limit=7',
          {
            transformRequest: (data, headers) => {
              delete headers['Authorization'];
            }
          }
        )
        .then(res => {
          if (type == 'DEPART')
            this.entries_depart = res.data.features;
          else
            this.entries_arrivee = res.data.features;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
      }
    },
    confirmer: function() {
  
      if (this.calendrier_visible && this.date) {
        this.recherche.date_depart = this.date;
        this.calendrier_visible = false;

        if (!this.recherche.nb_voyageurs)
          this.compteur_voyageurs_visible = true;
      } 
    },
    rechercher: function() {
      var adresse_depart_array = this.recherche.adresse_depart.split(', ');
      var commune_depart = this.entries_depart.filter(entry => {
        return entry.properties.name.indexOf(adresse_depart_array[0]) != -1 
          && entry.properties.postcode == adresse_depart_array[1];
      });

      if (!commune_depart || commune_depart.length == 0) {
        commune_depart = this.entries_depart.filter(entry => {
          return entry.properties.city.indexOf(adresse_depart_array[0]) != -1 
            && entry.properties.postcode == adresse_depart_array[1];
        });
      }

      var adresse_arrivee_array = this.recherche.adresse_arrivee.split(', ');
      var commune_arrivee = this.entries_arrivee.filter(entry => {
        return entry.properties.name.indexOf(adresse_arrivee_array[0]) != -1 
          && entry.properties.postcode == adresse_arrivee_array[1];
      });
      
      if (!commune_arrivee || commune_arrivee.length == 0) {
        commune_arrivee = this.entries_arrivee.filter(entry => {
          return entry.properties.city.indexOf(adresse_arrivee_array[0]) != -1 
            && entry.properties.postcode == adresse_arrivee_array[1];
        });
      }

      router.push(
      { 
        name: 'resultats', 
        query: {
          date: this.recherche.date_depart,
          from: commune_depart[0].properties.citycode,
          to: commune_arrivee[0].properties.citycode,
          from_city: commune_depart[0].properties.city,
          to_city: commune_arrivee[0].properties.city,
          from_adr: this.recherche.adresse_depart.replace(' ', '+').toLowerCase(),
          to_adr: this.recherche.adresse_arrivee.replace(' ', '+').toLowerCase(),
        }
      });
    }
  }
}
</script>