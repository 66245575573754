const listeVoitures = [
    { marque: "ABARTH", modele: "124" },
    { marque: "ABARTH", modele: "500" },
    { marque: "ABARTH", modele: "GRANDE PUNTO" },
    { marque: "ABARTH", modele: "PUNTO EVO" },
    { marque: "ABARTH", modele: "SCORPION" },
    { marque: "AC", modele: "COBRA" },
    { marque: "AC", modele: "ROADSTER ACE" },
    { marque: "AIXAM", modele: "400" },
    { marque: "AIXAM", modele: "500" },
    { marque: "AIXAM", modele: "A721" },
    { marque: "AIXAM", modele: "A741" },
    { marque: "AIXAM", modele: "CITY" },
    { marque: "AIXAM", modele: "COUPE" },
    { marque: "AIXAM", modele: "CROSSLINE" },
    { marque: "AIXAM", modele: "CROSSOVER" },
    { marque: "AIXAM", modele: "D-TRUCK" },
    { marque: "AIXAM", modele: "GTO" },
    { marque: "AIXAM", modele: "MINAUTO" },
    { marque: "ALFA ROMEO", modele: "33" },
    { marque: "ALFA ROMEO", modele: "145" },
    { marque: "ALFA ROMEO", modele: "147" },
    { marque: "ALFA ROMEO", modele: "155" },
    { marque: "ALFA ROMEO", modele: "156" },
    { marque: "ALFA ROMEO", modele: "159" },
    { marque: "ALFA ROMEO", modele: "166" },
    { marque: "ALFA ROMEO", modele: "1300" },
    { marque: "ALFA ROMEO", modele: "2000" },
    { marque: "ALFA ROMEO", modele: "2600" },
    { marque: "ALFA ROMEO", modele: "4C" },
    { marque: "ALFA ROMEO", modele: "8C" },
    { marque: "ALFA ROMEO", modele: "ALFASUD" },
    { marque: "ALFA ROMEO", modele: "ALFETTA" },
    { marque: "ALFA ROMEO", modele: "BRERA" },
    { marque: "ALFA ROMEO", modele: "GIULIA" },
    { marque: "ALFA ROMEO", modele: "GIULIETTA" },
    { marque: "ALFA ROMEO", modele: "GT" },
    { marque: "ALFA ROMEO", modele: "GTV" },
    { marque: "ALFA ROMEO", modele: "MITO" },
    { marque: "ALFA ROMEO", modele: "MONTREAL" },
    { marque: "ALFA ROMEO", modele: "SPIDER" },
    { marque: "ALFA ROMEO", modele: "STELVIO" },
    { marque: "ALPINA", modele: "B3" },
    { marque: "ALPINA", modele: "B4" },
    { marque: "ALPINA", modele: "B5" },
    { marque: "ALPINA", modele: "D3" },
    { marque: "ALPINA", modele: "D4" },
    { marque: "ALPINA", modele: "D5" },
    { marque: "ALPINA", modele: "XD3" },
    { marque: "ALPINE", modele: "A110" },
    { marque: "ALPINE", modele: "A310" },
    { marque: "ALPINE", modele: "A610" },
    { marque: "ALPINE", modele: "GTA" },
    { marque: "APAL", modele: "356" },
    { marque: "APAL", modele: "COUPE" },
    { marque: "ARIEL", modele: "ATOM" },
    { marque: "ASTON MARTIN", modele: "CYGNET" },
    { marque: "ASTON MARTIN", modele: "DB11" },
    { marque: "ASTON MARTIN", modele: "DB6" },
    { marque: "ASTON MARTIN", modele: "DB7" },
    { marque: "ASTON MARTIN", modele: "DB9" },
    { marque: "ASTON MARTIN", modele: "DBS" },
    { marque: "ASTON MARTIN", modele: "DBX" },
    { marque: "ASTON MARTIN", modele: "LAGONDA" },
    { marque: "ASTON MARTIN", modele: "RAPIDE" },
    { marque: "ASTON MARTIN", modele: "V12 VANTAGE" },
    { marque: "ASTON MARTIN", modele: "V8 VANTAGE" },
    { marque: "ASTON MARTIN", modele: "V8" },
    { marque: "ASTON MARTIN", modele: "VANQUISH" },
    { marque: "ASTON MARTIN", modele: "VANTAGE GT8" },
    { marque: "ASTON MARTIN", modele: "VANTAGE" },
    { marque: "ASTON MARTIN", modele: "VIRAGE" },
    { marque: "AUDI", modele: "80" },
    { marque: "AUDI", modele: "100" },
    { marque: "AUDI", modele: "A1" },
    { marque: "AUDI", modele: "A2" },
    { marque: "AUDI", modele: "A3" },
    { marque: "AUDI", modele: "A4" },
    { marque: "AUDI", modele: "A4 ALLROAD" },
    { marque: "AUDI", modele: "A5" },
    { marque: "AUDI", modele: "A6 ALLROAD" },
    { marque: "AUDI", modele: "A6 " },
    { marque: "AUDI", modele: "A7" },
    { marque: "AUDI", modele: "A8" },
    { marque: "AUDI", modele: "COUPE GT" },
    { marque: "AUDI", modele: "COUPE " },
    { marque: "AUDI", modele: "E-TRON SPORTBACK" },
    { marque: "AUDI", modele: "E-TRON" },
    { marque: "AUDI", modele: "Q2" },
    { marque: "AUDI", modele: "Q3" },
    { marque: "AUDI", modele: "Q5" },
    { marque: "AUDI", modele: "Q7" },
    { marque: "AUDI", modele: "Q8" },
    { marque: "AUDI", modele: "R8" },
    { marque: "AUDI", modele: "RS Q3" },
    { marque: "AUDI", modele: "RS Q8" },
    { marque: "AUDI", modele: "RS3" },
    { marque: "AUDI", modele: "RS4" },
    { marque: "AUDI", modele: "RS5" },
    { marque: "AUDI", modele: "RS6" },
    { marque: "AUDI", modele: "RS7" },
    { marque: "AUDI", modele: "S1" },
    { marque: "AUDI", modele: "S3" },
    { marque: "AUDI", modele: "S4" },
    { marque: "AUDI", modele: "S5" },
    { marque: "AUDI", modele: "S6" },
    { marque: "AUDI", modele: "S7" },
    { marque: "AUDI", modele: "S8" },
    { marque: "AUDI", modele: "SQ2" },
    { marque: "AUDI", modele: "SQ5" },
    { marque: "AUDI", modele: "SQ7" },
    { marque: "AUDI", modele: "SQ8" },
    { marque: "AUDI", modele: "TT RS" },
    { marque: "AUDI", modele: "TT" },
    { marque: "AUDI", modele: "TTS" },
    { marque: "AUSTIN", modele: "1000" },
    { marque: "AUSTIN", modele: "MINI" },
    { marque: "AUSTIN", modele: "MINI-MOKE" },
    { marque: "AUSTIN HEALEY", modele: "100" },
    { marque: "AUSTIN HEALEY", modele: "3000" },
    { marque: "AUSTIN HEALEY", modele: "SPRITE" },
    { marque: "AUTO UNION", modele: "1000" },
    { marque: "AUTOBIANCHI", modele: "500" },
    { marque: "AUTOBIANCHI", modele: "A112" },
    { marque: "AUTOBIANCHI", modele: "BIANCHINA" },
    { marque: "AUVERLAND", modele: "A3" },
    { marque: "BELIER", modele: "B8" },
    { marque: "BENTLEY", modele: "ARNAGE" },
    { marque: "BENTLEY", modele: "AZURE" },
    { marque: "BENTLEY", modele: "BENTAYGA" },
    { marque: "BENTLEY", modele: "BROOKLANDS" },
    { marque: "BENTLEY", modele: "CONTINENTAL" },
    { marque: "BENTLEY", modele: "FLYING SPUR" },
    { marque: "BENTLEY", modele: "MULSANNE" },
    { marque: "BENTLEY", modele: "NEX GTC" },
    { marque: "BENTLEY", modele: "S3" },
    { marque: "BENTLEY", modele: "TURBO R" },
    { marque: "BMW", modele: "1800" },
    { marque: "BMW", modele: "2000" },
    { marque: "BMW", modele: "2002" },
    { marque: "BMW", modele: "2800" },
    { marque: "BMW", modele: "3.0" },
    { marque: "BMW", modele: "I3" },
    { marque: "BMW", modele: "I3S" },
    { marque: "BMW", modele: "I8" },
    { marque: "BMW", modele: "M1" },
    { marque: "BMW", modele: "SERIE 1" },
    { marque: "BMW", modele: "SERIE 2" },
    { marque: "BMW", modele: "SERIE 3" },
    { marque: "BMW", modele: "SERIE 3 GT" },
    { marque: "BMW", modele: "SERIE 4" },
    { marque: "BMW", modele: "SERIE 5" },
    { marque: "BMW", modele: "SERIE 5 GT" },
    { marque: "BMW", modele: "SERIE 6" },
    { marque: "BMW", modele: "SERIE 7" },
    { marque: "BMW", modele: "SERIE 8 GRAN COUPE" },
    { marque: "BMW", modele: "SERIE 8" },
    { marque: "BMW", modele: "X1" },
    { marque: "BMW", modele: "X2" },
    { marque: "BMW", modele: "X3" },
    { marque: "BMW", modele: "X4" },
    { marque: "BMW", modele: "X5" },
    { marque: "BMW", modele: "X6" },
    { marque: "BMW", modele: "X7" },
    { marque: "BMW", modele: "Z1" },
    { marque: "BMW", modele: "Z3" },
    { marque: "BMW", modele: "Z4" },
    { marque: "BMW", modele: "Z8" },
    { marque: "BOLLORE", modele: "BLUECAR" },
    { marque: "BOLLORE", modele: "BLUESUMMER" },
    { marque: "BOLLORE", modele: "BLUEUTILITY" },
    { marque: "BUGATTI", modele: "CHIRON" },
    { marque: "BUICK", modele: "ELECTRA" },
    { marque: "BUICK", modele: "PARK AVENUE" },
    { marque: "BUICK", modele: "REGAL" },
    { marque: "BUICK", modele: "RIVIERA" },
    { marque: "BUICK", modele: "ROADMASTER" },
    { marque: "BUICK", modele: "WILDCAT" },
    { marque: "CADILLAC", modele: "BLS" },
    { marque: "CADILLAC", modele: "BROUGHAM" },
    { marque: "CADILLAC", modele: "COUPE DEVILLE" },
    { marque: "CADILLAC", modele: "CT6" },
    { marque: "CADILLAC", modele: "CTS-V" },
    { marque: "CADILLAC", modele: "CTS" },
    { marque: "CADILLAC", modele: "DEVILLE" },
    { marque: "CADILLAC", modele: "DTS" },
    { marque: "CADILLAC", modele: "ELDORADO" },
    { marque: "CADILLAC", modele: "ESCALADE" },
    { marque: "CADILLAC", modele: "FLEETWOOD" },
    { marque: "CADILLAC", modele: "SERIE 62" },
    { marque: "CADILLAC", modele: "SERIE 70" },
    { marque: "CADILLAC", modele: "SEVILLE" },
    { marque: "CADILLAC", modele: "SRX" },
    { marque: "CADILLAC", modele: "XT4" },
    { marque: "CADILLAC", modele: "XT5" },
    { marque: "CASALINI", modele: "M20" },
    { marque: "CASALINI", modele: "WRS" },
    { marque: "CATERHAM", modele: "SUPER SEVEN" },
    { marque: "CHATENET", modele: "CH 26" },
    { marque: "CHATENET", modele: "CH 40" },
    { marque: "CHATENET", modele: "CH 46" },
    { marque: "CHEVROLET", modele: "3100" },
    { marque: "CHEVROLET", modele: "ALERO" },
    { marque: "CHEVROLET", modele: "APACHE" },
    { marque: "CHEVROLET", modele: "AVALANCHE" },
    { marque: "CHEVROLET", modele: "AVEO" },
    { marque: "CHEVROLET", modele: "BEL AIR" },
    { marque: "CHEVROLET", modele: "C20" },
    { marque: "CHEVROLET", modele: "C30" },
    { marque: "CHEVROLET", modele: "CAMARO" },
    { marque: "CHEVROLET", modele: "CAPRICE" },
    { marque: "CHEVROLET", modele: "CAPTIVA" },
    { marque: "CHEVROLET", modele: "CHEVELLE" },
    { marque: "CHEVROLET", modele: "CHEVY" },
    { marque: "CHEVROLET", modele: "CORVAIR" },
    { marque: "CHEVROLET", modele: "CORVETTE" },
    { marque: "CHEVROLET", modele: "CRUZE" },
    { marque: "CHEVROLET", modele: "EL CAMINO" },
    { marque: "CHEVROLET", modele: "EPICA" },
    { marque: "CHEVROLET", modele: "FLEETLINE" },
    { marque: "CHEVROLET", modele: "IMPALA" },
    { marque: "CHEVROLET", modele: "KALOS" },
    { marque: "CHEVROLET", modele: "LACETTI" },
    { marque: "CHEVROLET", modele: "MALIBU" },
    { marque: "CHEVROLET", modele: "MATIZ" },
    { marque: "CHEVROLET", modele: "MONTE CARLO" },
    { marque: "CHEVROLET", modele: "ORLANDO" },
    { marque: "CHEVROLET", modele: "REZZO" },
    { marque: "CHEVROLET", modele: "SILVERADO" },
    { marque: "CHEVROLET", modele: "SPARK" },
    { marque: "CHEVROLET", modele: "SSR" },
    { marque: "CHEVROLET", modele: "SUBURBAN" },
    { marque: "CHEVROLET", modele: "TAHOE" },
    { marque: "CHEVROLET", modele: "TRAILBLAZER" },
    { marque: "CHEVROLET", modele: "TRAX" },
    { marque: "CHEVROLET", modele: "VAN EXPRESS" },
    { marque: "CHEVROLET", modele: "VOLT" },
    { marque: "CHRYSLER", modele: "300 B" },
    { marque: "CHRYSLER", modele: "300 C" },
    { marque: "CHRYSLER", modele: "300 M" },
    { marque: "CHRYSLER", modele: "CROSSFIRE" },
    { marque: "CHRYSLER", modele: "GRAND VOYAGER" },
    { marque: "CHRYSLER", modele: "LEBARON" },
    { marque: "CHRYSLER", modele: "PACIFICA" },
    { marque: "CHRYSLER", modele: "PROWLER" },
    { marque: "CHRYSLER", modele: "PT CRUISER" },
    { marque: "CHRYSLER", modele: "SEBRING" },
    { marque: "CHRYSLER", modele: "STRATUS" },
    { marque: "CHRYSLER", modele: "VIPER" },
    { marque: "CHRYSLER", modele: "VOYAGER" },
    { marque: "CITROEN", modele: "2CV" },
    { marque: "CITROEN", modele: "ACADIANE" },
    { marque: "CITROEN", modele: "AMI" },
    { marque: "CITROEN", modele: "AX" },
    { marque: "CITROEN", modele: "BERLINGO" },
    { marque: "CITROEN", modele: "BX" },
    { marque: "CITROEN", modele: "C-CROSSER" },
    { marque: "CITROEN", modele: "C-ELYSEE" },
    { marque: "CITROEN", modele: "C-ZERO" },
    { marque: "CITROEN", modele: "C1" },
    { marque: "CITROEN", modele: "C15" },
    { marque: "CITROEN", modele: "C2" },
    { marque: "CITROEN", modele: "C3" },
    { marque: "CITROEN", modele: "C3 AIRCROSS" },
    { marque: "CITROEN", modele: "C3 PICASSO" },
    { marque: "CITROEN", modele: "C3 PLURIEL" },
    { marque: "CITROEN", modele: "C35" },
    { marque: "CITROEN", modele: "C4" },
    { marque: "CITROEN", modele: "C4 AIRCROSS" },
    { marque: "CITROEN", modele: "C4 CACTUS" },
    { marque: "CITROEN", modele: "C4 PICASSO" },
    { marque: "CITROEN", modele: "C4 SPACETOURER" },
    { marque: "CITROEN", modele: "C5 AIRCROSS" },
    { marque: "CITROEN", modele: "C5 AIRCROSS" },
    { marque: "CITROEN", modele: "C6" },
    { marque: "CITROEN", modele: "C8" },
    { marque: "CITROEN", modele: "CX" },
    { marque: "CITROEN", modele: "DS3" },
    { marque: "CITROEN", modele: "DS4" },
    { marque: "CITROEN", modele: "DS5" },
    { marque: "CITROEN", modele: "DS" },
    { marque: "CITROEN", modele: "DYANE" },
    { marque: "CITROEN", modele: "E-MEHARI" },
    { marque: "CITROEN", modele: "EVASION" },
    { marque: "CITROEN", modele: "GRAND C4 PICASSO" },
    { marque: "CITROEN", modele: "GRAND C4 SPACETOURER" },
    { marque: "CITROEN", modele: "GSA" },
    { marque: "CITROEN", modele: "ID" },
    { marque: "CITROEN", modele: "JUMPER" },
    { marque: "CITROEN", modele: "JUMPY" },
    { marque: "CITROEN", modele: "MEHARI" },
    { marque: "CITROEN", modele: "NEMO" },
    { marque: "CITROEN", modele: "PICASSO" },
    { marque: "CITROEN", modele: "SAXO" },
    { marque: "CITROEN", modele: "SM" },
    { marque: "CITROEN", modele: "SPACETOURER" },
    { marque: "CITROEN", modele: "TRACTION" },
    { marque: "CITROEN", modele: "VISA" },
    { marque: "CITROEN", modele: "XANTIA" },
    { marque: "CITROEN", modele: "XM" },
    { marque: "CITROEN", modele: "XSARA" },
    { marque: "CITROEN", modele: "ZX" },
    { marque: "CUPRA", modele: "ATECA" },
    { marque: "CUPRA", modele: "FORMENTOR" },
    { marque: "CUPRA", modele: "LEON" },
    { marque: "DACIA", modele: "DOKKER" },
    { marque: "DACIA", modele: "DUSTER" },
    { marque: "DACIA", modele: "LODGY" },
    { marque: "DACIA", modele: "LOGAN" },
    { marque: "DACIA", modele: "SANDERO" },
    { marque: "DAEWOO", modele: "KALOS" },
    { marque: "DAEWOO", modele: "KORANDO" },
    { marque: "DAEWOO", modele: "LACETTI" },
    { marque: "DAEWOO", modele: "MATIZ" },
    { marque: "DAEWOO", modele: "REZZO" },
    { marque: "DAIHATSU", modele: "COPEN" },
    { marque: "DAIHATSU", modele: "CUORE" },
    { marque: "DAIHATSU", modele: "MATERIA" },
    { marque: "DAIHATSU", modele: "SIRION" },
    { marque: "DAIHATSU", modele: "TERIOS" },
    { marque: "DAIHATSU", modele: "TREVIS" },
    { marque: "DALLARA", modele: "STRADALE" },
    { marque: "DANGEL", modele: "BERLINGO" },
    { marque: "DANGEL", modele: "SCUDO" },
    { marque: "DATSUN", modele: "FAIRLADY" },
    { marque: "DE SOTO", modele: "CUSTOM" },
    { marque: "DE TOMASO", modele: "MANGUSTA" },
    { marque: "DE TOMASO", modele: "PANTERA" },
    { marque: "DEVINCI", modele: "DB 718" },
    { marque: "DEVINCI", modele: "DB 719" },
    { marque: "DODGE", modele: "AVENGER" },
    { marque: "DODGE", modele: "B3" },
    { marque: "DODGE", modele: "CALIBER" },
    { marque: "DODGE", modele: "CHALLENGER" },
    { marque: "DODGE", modele: "CHARGER" },
    { marque: "DODGE", modele: "D24" },
    { marque: "DODGE", modele: "DURANGO" },
    { marque: "DODGE", modele: "GRAND CARAVAN" },
    { marque: "DODGE", modele: "JOURNEY" },
    { marque: "DODGE", modele: "MAGNUM" },
    { marque: "DODGE", modele: "NITRO" },
    { marque: "DODGE", modele: "RAM " },
    { marque: "DODGE", modele: "RAMCHARGER" },
    { marque: "DODGE", modele: "VIPER" },
    { marque: "DONKERVOORT", modele: "D8" },
    { marque: "DONKERVOORT", modele: "S8" },
    { marque: "DS", modele: "DS 3" },
    { marque: "DS", modele: "DS 3 CROSSBACK" },
    { marque: "DS", modele: "DS 4" },
    { marque: "DS", modele: "DS 4 CROSSBACK" },
    { marque: "DS", modele: "DS 5" },
    { marque: "DS", modele: "DS 7 CROSSBACK" },
    { marque: "DUE", modele: "FIRST" },
    { marque: "EMBUGGY", modele: "VINTAGE" },
    { marque: "ESTRIMA", modele: "BIRO" },
    { marque: "EXCALIBUR", modele: "PHAETON" },
    { marque: "EXCALIBUR", modele: "SERIE 4" },
    { marque: "FERRARI", modele: "208" },
    { marque: "FERRARI", modele: "250" },
    { marque: "FERRARI", modele: "308" },
    { marque: "FERRARI", modele: "328" },
    { marque: "FERRARI", modele: "330" },
    { marque: "FERRARI", modele: "348" },
    { marque: "FERRARI", modele: "360" },
    { marque: "FERRARI", modele: "365" },
    { marque: "FERRARI", modele: "400" },
    { marque: "FERRARI", modele: "456" },
    { marque: "FERRARI", modele: "458" },
    { marque: "FERRARI", modele: "488" },
    { marque: "FERRARI", modele: "512" },
    { marque: "FERRARI", modele: "550" },
    { marque: "FERRARI", modele: "575" },
    { marque: "FERRARI", modele: "599" },
    { marque: "FERRARI", modele: "612" },
    { marque: "FERRARI", modele: "812" },
    { marque: "FERRARI", modele: "512 BB" },
    { marque: "FERRARI", modele: "812 GTS" },
    { marque: "FERRARI", modele: "812 SUPERFAST" },
    { marque: "FERRARI", modele: "CALIFORNIA" },
    { marque: "FERRARI", modele: "DINO" },
    { marque: "FERRARI", modele: "F12" },
    { marque: "FERRARI", modele: "F355" },
    { marque: "FERRARI", modele: "F40" },
    { marque: "FIAT", modele: "124" },
    { marque: "FIAT", modele: "126" },
    { marque: "FIAT", modele: "130" },
    { marque: "FIAT", modele: "131" },
    { marque: "FIAT", modele: "500" },
    { marque: "FIAT", modele: "600" },
    { marque: "FIAT", modele: "1100" },
    { marque: "FIAT", modele: "500 E" },
    { marque: "FIAT", modele: "500 L" },
    { marque: "FIAT", modele: "500 X" },
    { marque: "FIAT", modele: "BARCHETTA" },
    { marque: "FIAT", modele: "BRAVA" },
    { marque: "FIAT", modele: "BRAVO" },
    { marque: "FIAT", modele: "CINQUECENTO" },
    { marque: "FIAT", modele: "COUPE" },
    { marque: "FIAT", modele: "CROMA" },
    { marque: "FIAT", modele: "DINO" },
    { marque: "FIAT", modele: "DOBLO" },
    { marque: "FIAT", modele: "DOBLO CARGO" },
    { marque: "FIAT", modele: "DUCATO" },
    { marque: "FIAT", modele: "FIORINO" },
    { marque: "FIAT", modele: "FREEMONT" },
    { marque: "FIAT", modele: "FULLBACK" },
    { marque: "FIAT", modele: "GRANDE PUNTO" },
    { marque: "FIAT", modele: "IDEA" },
    { marque: "FIAT", modele: "MAREA" },
    { marque: "FIAT", modele: "MULTIPLA" },
    { marque: "FIAT", modele: "PALIO" },
    { marque: "FIAT", modele: "PANDA" },
    { marque: "FIAT", modele: "PUNTO" },
    { marque: "FIAT", modele: "PUNTO EVO" },
    { marque: "FIAT", modele: "QUBO" },
    { marque: "FIAT", modele: "SCUDO" },
    { marque: "FIAT", modele: "SEDICI" },
    { marque: "FIAT", modele: "SEICENTO" },
    { marque: "FIAT", modele: "STILO" },
    { marque: "FIAT", modele: "STRADA" },
    { marque: "FIAT", modele: "TALENTO" },
    { marque: "FIAT", modele: "TIPO" },
    { marque: "FIAT", modele: "ULYSSE" },
    { marque: "FIAT", modele: "UNO" },
    { marque: "FIAT", modele: "X1/9" },
    { marque: "FIBERFAB", modele: "BONITO FT" },
    { marque: "FISKER", modele: "KARMA" },
    { marque: "FORD", modele: "A" },
    { marque: "FORD", modele: "B-MAX" },
    { marque: "FORD", modele: "B" },
    { marque: "FORD", modele: "BRONCO" },
    { marque: "FORD", modele: "C-MAX" },
    { marque: "FORD", modele: "COUGAR" },
    { marque: "FORD", modele: "COUNTRY" },
    { marque: "FORD", modele: "COURRIER" },
    { marque: "FORD", modele: "CROWN" },
    { marque: "FORD", modele: "ECONOLINE" },
    { marque: "FORD", modele: "ECOSPORT" },
    { marque: "FORD", modele: "EDGE" },
    { marque: "FORD", modele: "ESCORT" },
    { marque: "FORD", modele: "EXPEDITION" },
    { marque: "FORD", modele: "EXPLORER" },
    { marque: "FORD", modele: "F 100" },
    { marque: "FORD", modele: "F100" },
    { marque: "FORD", modele: "F150" },
    { marque: "FORD", modele: "F350" },
    { marque: "FORD", modele: "FIESTA" },
    { marque: "FORD", modele: "FOCUS" },
    { marque: "FORD", modele: "FOCUS C-MAX" },
    { marque: "FORD", modele: "FUSION" },
    { marque: "FORD", modele: "GALAXIE" },
    { marque: "FORD", modele: "GALAXY" },
    { marque: "FORD", modele: "GRAND C-MAX" },
    { marque: "FORD", modele: "GRAND TORINO" },
    { marque: "FORD", modele: "GRAND TOURNEO CONNECT" },
    { marque: "FORD", modele: "GT" },
    { marque: "FORD", modele: "HOT ROD" },
    { marque: "FORD", modele: "KA+" },
    { marque: "FUSO", modele: "CANTER" },
    { marque: "GMC", modele: "100" },
    { marque: "GMC", modele: "ACADIA" },
    { marque: "GMC", modele: "DENALI" },
    { marque: "GMC", modele: "SIERRA" },
    { marque: "GMC", modele: "SIERRA PICK-UP" },
    { marque: "GMC", modele: "YUKON" },
    { marque: "GOUPIL", modele: "G3" },
    { marque: "HOMMELL", modele: "BERLINETTE" },
    { marque: "HONDA", modele: "ACCORD" },
    { marque: "HONDA", modele: "CIVIC" },
    { marque: "HONDA", modele: "CR-V" },
    { marque: "HONDA", modele: "CR-Z" },
    { marque: "HONDA", modele: "E" },
    { marque: "HONDA", modele: "FR-V" },
    { marque: "HONDA", modele: "HR-V" },
    { marque: "HONDA", modele: "INSIGHT" },
    { marque: "HONDA", modele: "INTEGRA" },
    { marque: "HONDA", modele: "JAZZ" },
    { marque: "HONDA", modele: "LEGEND" },
    { marque: "HONDA", modele: "NSX" },
    { marque: "HONDA", modele: "PRELUDE" },
    { marque: "HONDA", modele: "S2000" },
    { marque: "HONDA", modele: "S800" },
    { marque: "HOTCHKISS", modele: "617" },
    { marque: "HOTCHKISS", modele: "JEEP" },
    { marque: "HUDSON", modele: "COMMODORE" },
    { marque: "HUMMER", modele: "H1" },
    { marque: "HUMMER", modele: "H2" },
    { marque: "HUMMER", modele: "H3" },
    { marque: "HYUNDAI", modele: "ACCENT" },
    { marque: "HYUNDAI", modele: "ATOS PRIME" },
    { marque: "HYUNDAI", modele: "ATOS PRIME" },
    { marque: "HYUNDAI", modele: "AZERA" },
    { marque: "HYUNDAI", modele: "COUPE" },
    { marque: "HYUNDAI", modele: "GENESIS" },
    { marque: "HYUNDAI", modele: "GETZ" },
    { marque: "HYUNDAI", modele: "H1 VAN" },
    { marque: "HYUNDAI", modele: "I10" },
    { marque: "HYUNDAI", modele: "I20" },
    { marque: "HYUNDAI", modele: "I30" },
    { marque: "HYUNDAI", modele: "I40" },
    { marque: "HYUNDAI", modele: "IONIQ" },
    { marque: "HYUNDAI", modele: "IX20" },
    { marque: "HYUNDAI", modele: "IX35" },
    { marque: "HYUNDAI", modele: "IX55" },
    { marque: "HYUNDAI", modele: "KONA" },
    { marque: "HYUNDAI", modele: "MATRIX" },
    { marque: "HYUNDAI", modele: "SANTA FE" },
    { marque: "HYUNDAI", modele: "SONATA" },
    { marque: "HYUNDAI", modele: "TERRACAN" },
    { marque: "HYUNDAI", modele: "TRAJET" },
    { marque: "HYUNDAI", modele: "TUCSON" },
    { marque: "HYUNDAI", modele: "VELOSTER" },
    { marque: "INFINITI", modele: "EX" },
    { marque: "INFINITI", modele: "FX" },
    { marque: "INFINITI", modele: "G" },
    { marque: "INFINITI", modele: "M" },
    { marque: "INFINITI", modele: "Q30" },
    { marque: "INFINITI", modele: "Q50" },
    { marque: "INFINITI", modele: "Q60" },
    { marque: "INFINITI", modele: "Q70" },
    { marque: "INFINITI", modele: "QX30" },
    { marque: "INFINITI", modele: "QX50" },
    { marque: "INFINITI", modele: "QX56" },
    { marque: "INFINITI", modele: "QX70" },
    { marque: "ISUZU", modele: "D-MAX" },
    { marque: "ISUZU", modele: "M21" },
    { marque: "IVECO", modele: "DAILY" },
    { marque: "IVECO", modele: "DAILY CHASSIS CAB" },
    { marque: "IVECO", modele: "STRALIS" },
    { marque: "JAGUAR", modele: "240" },
    { marque: "JAGUAR", modele: "420" },
    { marque: "JAGUAR", modele: "2.4" },
    { marque: "JAGUAR", modele: "3.8" },
    { marque: "JAGUAR", modele: "DAIMLER" },
    { marque: "JAGUAR", modele: "E-PACE" },
    { marque: "JAGUAR", modele: "F-PACE" },
    { marque: "JAGUAR", modele: "F-TYPE" },
    { marque: "JAGUAR", modele: "I-PACE" },
    { marque: "JAGUAR", modele: "MK II" },
    { marque: "JAGUAR", modele: "ROADSTER XK" },
    { marque: "JAGUAR", modele: "S-TYPE" },
    { marque: "JAGUAR", modele: "TYPE C" },
    { marque: "JAGUAR", modele: "TYPE E" },
    { marque: "JAGUAR", modele: "X-TYPE" },
    { marque: "JAGUAR", modele: "XE" },
    { marque: "JAGUAR", modele: "XF" },
    { marque: "JAGUAR", modele: "XJ12" },
    { marque: "JAGUAR", modele: "XJ6" },
    { marque: "JAGUAR", modele: "XJ8" },
    { marque: "JAGUAR", modele: "XJ" },
    { marque: "JAGUAR", modele: "XJR" },
    { marque: "JAGUAR", modele: "XJS-C" },
    { marque: "JAGUAR", modele: "XJS" },
    { marque: "JAGUAR", modele: "XK" },
    { marque: "JAGUAR", modele: "XK8" },
    { marque: "JAGUAR", modele: "XKR" },
    { marque: "JDM SIMPA", modele: "ALOES" },
    { marque: "JDM SIMPA", modele: "TITANE" },
    { marque: "JDM SIMPA", modele: "XHEOS" },
    { marque: "JEEP", modele: "CHEROKEE" },
    { marque: "JEEP", modele: "CJ5" },
    { marque: "JEEP", modele: "CJ7" },
    { marque: "JEEP", modele: "COMMANDER" },
    { marque: "JEEP", modele: "COMPASS" },
    { marque: "JEEP", modele: "FORD" },
    { marque: "JEEP", modele: "GLADIATOR" },
    { marque: "JEEP", modele: "GRAND CHEROKEE" },
    { marque: "JEEP", modele: "GRANDE WAGONEER" },
    { marque: "JEEP", modele: "PATRIOT" },
    { marque: "JEEP", modele: "RENEGADE" },
    { marque: "JEEP", modele: "WILLYS" },
    { marque: "JEEP", modele: "WRANGLER" },
    { marque: "KIA", modele: "CARENS" },
    { marque: "KIA", modele: "CEE D" },
    { marque: "KIA", modele: "CEED" },
    { marque: "KIA", modele: "CERATO" },
    { marque: "KIA", modele: "E-NIRO" },
    { marque: "KIA", modele: "E-SOUL" },
    { marque: "KIA", modele: "MAGENTIS" },
    { marque: "KIA", modele: "NIRO" },
    { marque: "KIA", modele: "OPIRUS" },
    { marque: "KIA", modele: "OPTIMA" },
    { marque: "KIA", modele: "PICANTO" },
    { marque: "KIA", modele: "PRO CEE D" },
    { marque: "KIA", modele: "PROCEED" },
    { marque: "KIA", modele: "RIO" },
    { marque: "KIA", modele: "SORENTO" },
    { marque: "KIA", modele: "SOUL" },
    { marque: "KIA", modele: "SPORTAGE" },
    { marque: "KIA", modele: "STINGER" },
    { marque: "KIA", modele: "STONIC" },
    { marque: "KIA", modele: "VENGA" },
    { marque: "KIA", modele: "XCEED" },
    { marque: "KTM", modele: "X-BOW" },
    { marque: "LADA", modele: "2107" },
    { marque: "LADA", modele: "NIVA" },
    { marque: "LADA", modele: "PRIORA" },
    { marque: "LAMBORGHINI", modele: "AVENTADOR" },
    { marque: "LAMBORGHINI", modele: "COUNTACH" },
    { marque: "LAMBORGHINI", modele: "DIABLO" },
    { marque: "LAMBORGHINI", modele: "ESPADA" },
    { marque: "LAMBORGHINI", modele: "GALLARDO" },
    { marque: "LAMBORGHINI", modele: "HURACAN" },
    { marque: "LAMBORGHINI", modele: "JARAMA" },
    { marque: "LAMBORGHINI", modele: "MURCIELAGO" },
    { marque: "LAMBORGHINI", modele: "URUS" },
    { marque: "LANCIA", modele: "2000" },
    { marque: "LANCIA", modele: "BETA" },
    { marque: "LANCIA", modele: "DEDRA" },
    { marque: "LANCIA", modele: "DELTA" },
    { marque: "LANCIA", modele: "FLAVIA" },
    { marque: "LANCIA", modele: "FULVIA" },
    { marque: "LANCIA", modele: "GAMMA" },
    { marque: "LANCIA", modele: "MUSA" },
    { marque: "LANCIA", modele: "PHEDRA" },
    { marque: "LANCIA", modele: "THEMA" },
    { marque: "LANCIA", modele: "THESIS" },
    { marque: "LANCIA", modele: "VOYAGER" },
    { marque: "LANCIA", modele: "YPSILON" },
    { marque: "LAND ROVER", modele: "DEFENDER" },
    { marque: "LAND ROVER", modele: "DISCOVERY SPORT" },
    { marque: "LAND ROVER", modele: "DISCOVERY" },
    { marque: "LAND ROVER", modele: "FREELANDER" },
    { marque: "LAND ROVER", modele: "LAND" },
    { marque: "LAND ROVER", modele: "RANGE ROVER EVOQUE" },
    { marque: "LAND ROVER", modele: "RANGE ROVER SPORT" },
    { marque: "LAND ROVER", modele: "RANGE ROVER VELAR" },
    { marque: "LAND ROVER", modele: "RANGE ROVER" },
    { marque: "LEXUS", modele: "CT" },
    { marque: "LEXUS", modele: "ES" },
    { marque: "LEXUS", modele: "GS" },
    { marque: "LEXUS", modele: "IS" },
    { marque: "LEXUS", modele: "LC" },
    { marque: "LEXUS", modele: "LS" },
    { marque: "LEXUS", modele: "NX" },
    { marque: "LEXUS", modele: "RC" },
    { marque: "LEXUS", modele: "RX" },
    { marque: "LEXUS", modele: "SC" },
    { marque: "LEXUS", modele: "UX" },
    { marque: "LIGIER", modele: "AMBRA" },
    { marque: "LIGIER", modele: "IXO" },
    { marque: "LIGIER", modele: "JS" },
    { marque: "LIGIER", modele: "JS50" },
    { marque: "LIGIER", modele: "JS50L" },
    { marque: "LIGIER", modele: "OPTIMAX" },
    { marque: "LIGIER", modele: "X-TOO R" },
    { marque: "LINCOLN", modele: "AVIATOR" },
    { marque: "LINCOLN", modele: "CONTINENTAL" },
    { marque: "LINCOLN", modele: "MARK VIII" },
    { marque: "LINCOLN", modele: "NAVIGATOR" },
    { marque: "LINCOLN", modele: "TOWN CAR" },
    { marque: "LONDON TAXI COMPANY", modele: "TX4" },
    { marque: "LOTUS", modele: "2 ELEVEN" },
    { marque: "LOTUS", modele: "ELAN" },
    { marque: "LOTUS", modele: "ELISE" },
    { marque: "LOTUS", modele: "ELITE" },
    { marque: "LOTUS", modele: "ESPRIT" },
    { marque: "LOTUS", modele: "EUROPA" },
    { marque: "LOTUS", modele: "EVORA" },
    { marque: "LOTUS", modele: "EXCEL" },
    { marque: "LOTUS", modele: "EXIGE" },
    { marque: "MAN", modele: "TGE" },
    { marque: "MARTIN", modele: "AC COBRA" },
    { marque: "MASERATI", modele: "3200 GT" },
    { marque: "MASERATI", modele: "BITURBO" },
    { marque: "MASERATI", modele: "BORA" },
    { marque: "MASERATI", modele: "COUPE" },
    { marque: "MASERATI", modele: "GHIBLI" },
    { marque: "MASERATI", modele: "GRANCABRIO" },
    { marque: "MASERATI", modele: "GRANSPORT" },
    { marque: "MASERATI", modele: "GRANTURISMO" },
    { marque: "MASERATI", modele: "LEVANTE" },
    { marque: "MASERATI", modele: "MERAK" },
    { marque: "MASERATI", modele: "MEXICO" },
    { marque: "MASERATI", modele: "QUATTROPORTE" },
    { marque: "MASERATI", modele: "SPYDER" },
    { marque: "MATRA", modele: "BAGHEERA" },
    { marque: "MATRA", modele: "MURENA" },
    { marque: "MAYBACH", modele: "57 BERLINE" },
    { marque: "MAYBACH", modele: "62 BERLINE" },
    { marque: "MAZDA", modele: "2" },
    { marque: "MAZDA", modele: "3" },
    { marque: "MAZDA", modele: "5" },
    { marque: "MAZDA", modele: "6" },
    { marque: "MAZDA", modele: "323" },
    { marque: "MAZDA", modele: "B2500" },
    { marque: "MAZDA", modele: "CX-30" },
    { marque: "MAZDA", modele: "CX-3" },
    { marque: "MAZDA", modele: "CX-5" },
    { marque: "MAZDA", modele: "CX-7" },
    { marque: "MAZDA", modele: "MX-30" },
    { marque: "MAZDA", modele: "MX5" },
    { marque: "MAZDA", modele: "PREMACY" },
    { marque: "MAZDA", modele: "RX-7" },
    { marque: "MAZDA", modele: "RX-8" },
    { marque: "MCLAREN", modele: "540C" },
    { marque: "MCLAREN", modele: "570GT" },
    { marque: "MCLAREN", modele: "570S" },
    { marque: "MCLAREN", modele: "600 LT" },
    { marque: "MCLAREN", modele: "650 S" },
    { marque: "MCLAREN", modele: "675 LT" },
    { marque: "MCLAREN", modele: "720S" },
    { marque: "MCLAREN", modele: "GT" },
    { marque: "MCLAREN", modele: "MP4-12C" },
    { marque: "MCLAREN", modele: "P1" },
    { marque: "MEGA", modele: "CLUB" },
    { marque: "MERCEDES", modele: "180" },
    { marque: "MERCEDES", modele: "190" },
    { marque: "MERCEDES", modele: "200" },
    { marque: "MERCEDES", modele: "220" },
    { marque: "MERCEDES", modele: "230" },
    { marque: "MERCEDES", modele: "240" },
    { marque: "MERCEDES", modele: "250" },
    { marque: "MERCEDES", modele: "280" },
    { marque: "MERCEDES", modele: "300" },
    { marque: "MERCEDES", modele: "320" },
    { marque: "MERCEDES", modele: "350" },
    { marque: "MERCEDES", modele: "400" },
    { marque: "MERCEDES", modele: "450" },
    { marque: "MERCEDES", modele: "500" },
    { marque: "MERCEDES", modele: "560" },
    { marque: "MERCEDES", modele: "600" },
    { marque: "MERCEDES", modele: "1017" },
    { marque: "MERCEDES", modele: "560 SL" },
    { marque: "MERCEDES", modele: "ACTROS" },
    { marque: "MERCEDES", modele: "ANTOS" },
    { marque: "MERCEDES", modele: "AROCS" },
    { marque: "MERCEDES", modele: "ATEGO" },
    { marque: "MERCEDES", modele: "AXOR" },
    { marque: "MERCEDES", modele: "CITAN" },
    { marque: "MERCEDES", modele: "CL" },
    { marque: "MERCEDES", modele: "CLA" },
    { marque: "MERCEDES", modele: "CLASSE A" },
    { marque: "MERCEDES", modele: "CLASSE B" },
    { marque: "MERCEDES", modele: "CLASSE C" },
    { marque: "MERCEDES", modele: "CLASSE CLC" },
    { marque: "MERCEDES", modele: "CLASSE CLS" },
    { marque: "MERCEDES", modele: "CLASSE E" },
    { marque: "MERCEDES", modele: "CLASSE G" },
    { marque: "MERCEDES", modele: "CLASSE GL" },
    { marque: "MERCEDES", modele: "CLASSE GLK" },
    { marque: "MERCEDES", modele: "CLASSE M" },
    { marque: "MERCEDES", modele: "CLASSE R" },
    { marque: "MERCEDES", modele: "CLASSE S" },
    { marque: "MERCEDES", modele: "CLASSE V" },
    { marque: "MERCEDES", modele: "CLASSE X" },
    { marque: "MERCEDES", modele: "CLK" },
    { marque: "MERCEDES", modele: "EQC" },
    { marque: "MERCEDES", modele: "EQV" },
    { marque: "MERCEDES", modele: "GLA" },
    { marque: "MERCEDES", modele: "GLB" },
    { marque: "MERCEDES", modele: "GLC" },
    { marque: "MERCEDES", modele: "GLC COUPE" },
    { marque: "MERCEDES", modele: "GLE" },
    { marque: "MERCEDES", modele: "GLE COUPE" },
    { marque: "MERCEDES", modele: "GLS" },
    { marque: "MERCEDES", modele: "MARCO POLO" },
    { marque: "MERCEDES", modele: "SL" },
    { marque: "MERCEDES", modele: "SLC" },
    { marque: "MERCEDES", modele: "SLK" },
    { marque: "MERCEDES", modele: "SLR" },
    { marque: "MERCEDES", modele: "SLS" },
    { marque: "MERCEDES", modele: "SPRINTER" },
    { marque: "MERCEDES", modele: "VIANO" },
    { marque: "MERCEDES", modele: "VITO" },
    { marque: "MERCEDES", modele: "VITO FG" },
    { marque: "MERCEDES-AMG", modele: "GT" },
    { marque: "MERCURY", modele: "M100" },
    { marque: "MERCURY", modele: "MARAUDER" },
    { marque: "MG", modele: "A" },
    { marque: "MG", modele: "B" },
    { marque: "MG", modele: "F" },
    { marque: "MG", modele: "MG ZS" },
    { marque: "MG", modele: "MIDGET" },
    { marque: "MG", modele: "TD" },
    { marque: "MG", modele: "TF" },
    { marque: "MG", modele: "ZS EV" },
    { marque: "MG", modele: "ZS EV" },
    { marque: "MG", modele: "ZT" },
    { marque: "MIA ELECTRIC", modele: "MIA" },
    { marque: "MICROCAR", modele: "DUE6" },
    { marque: "MICROCAR", modele: "DUE" },
    { marque: "MICROCAR", modele: "M-PRO" },
    { marque: "MICROCAR", modele: "M.CROSS" },
    { marque: "MICROCAR", modele: "M.GO" },
    { marque: "MICROCAR", modele: "MC2" },
    { marque: "MICROCAR", modele: "MGO5" },
    { marque: "MINAUTO", modele: "ECO" },
    { marque: "MINI", modele: "COUNTRYMAN" },
    { marque: "MINI", modele: "MINI" },
    { marque: "MINI", modele: "MINI CABRIOLET" },
    { marque: "MINI", modele: "MINI CLUBMAN" },
    { marque: "MINI", modele: "MINI COUPE" },
    { marque: "MINI", modele: "MINI ROADSTER" },
    { marque: "MINI", modele: "PACEMAN" },
    { marque: "MITSUBISHI", modele: "3000 GT" },
    { marque: "MITSUBISHI", modele: "ASX" },
    { marque: "MITSUBISHI", modele: "CANTER" },
    { marque: "MITSUBISHI", modele: "CARISMA" },
    { marque: "MITSUBISHI", modele: "COLT" },
    { marque: "MITSUBISHI", modele: "ECLIPSE CROSS" },
    { marque: "MITSUBISHI", modele: "FUSO CANTER" },
    { marque: "MITSUBISHI", modele: "GRANDIS" },
    { marque: "MITSUBISHI", modele: "I-MIEV" },
    { marque: "MITSUBISHI", modele: "L200" },
    { marque: "MITSUBISHI", modele: "LANCER" },
    { marque: "MITSUBISHI", modele: "MONTERO" },
    { marque: "MITSUBISHI", modele: "OUTLANDER" },
    { marque: "MITSUBISHI", modele: "PAJERO PININ" },
    { marque: "MITSUBISHI", modele: "PAJERO SPORT" },
    { marque: "MITSUBISHI", modele: "PAJERO" },
    { marque: "MITSUBISHI", modele: "SPACE STAR" },
    { marque: "MORGAN", modele: "4/4" },
    { marque: "MORGAN", modele: "AERO 8" },
    { marque: "MORGAN", modele: "AEROMAX" },
    { marque: "MORGAN", modele: "PLUS 4" },
    { marque: "MORGAN", modele: "PLUS 6" },
    { marque: "MORGAN", modele: "PLUS 8" },
    { marque: "MORGAN", modele: "PLUS FOUR" },
    { marque: "MORGAN", modele: "PLUS SIX" },
    { marque: "MORGAN", modele: "ROADSTER" },
    { marque: "MORGAN", modele: "ROADSTER V6" },
    { marque: "MORGAN", modele: "THREEWHEELER" },
    { marque: "MORRIS", modele: "MINI" },
    { marque: "MPM MOTORS", modele: "ERELIS" },
    { marque: "MPM MOTORS", modele: "PS.160" },
    { marque: "NISSAN", modele: "100 NX" },
    { marque: "NISSAN", modele: "300 ZX" },
    { marque: "NISSAN", modele: "350Z" },
    { marque: "NISSAN", modele: "370Z" },
    { marque: "NISSAN", modele: "ALMERA TINO" },
    { marque: "NISSAN", modele: "ALMERA" },
    { marque: "NISSAN", modele: "CABSTAR" },
    { marque: "NISSAN", modele: "CUBE" },
    { marque: "NISSAN", modele: "E-NV200" },
    { marque: "NISSAN", modele: "EVALIA" },
    { marque: "NISSAN", modele: "GT-R" },
    { marque: "NISSAN", modele: "INTERSTAR" },
    { marque: "NISSAN", modele: "JUKE" },
    { marque: "NISSAN", modele: "LEAF" },
    { marque: "NISSAN", modele: "MAXIMA" },
    { marque: "NISSAN", modele: "MICRA" },
    { marque: "NISSAN", modele: "MURANO" },
    { marque: "NISSAN", modele: "NAVARA" },
    { marque: "NISSAN", modele: "NOTE" },
    { marque: "NISSAN", modele: "NP300" },
    { marque: "NISSAN", modele: "NT400 CABSTAR" },
    { marque: "NISSAN", modele: "NT500" },
    { marque: "NISSAN", modele: "NV200" },
    { marque: "NISSAN", modele: "NV250" },
    { marque: "NISSAN", modele: "NV300" },
    { marque: "NISSAN", modele: "NV400" },
    { marque: "NISSAN", modele: "PATHFINDER" },
    { marque: "NISSAN", modele: "PATROL" },
    { marque: "NISSAN", modele: "PICK UP" },
    { marque: "NISSAN", modele: "PIXO" },
    { marque: "NISSAN", modele: "PRIMASTAR" },
    { marque: "NISSAN", modele: "PRIMERA" },
    { marque: "NISSAN", modele: "PULSAR" },
    { marque: "NISSAN", modele: "QASHQAI +2" },
    { marque: "NISSAN", modele: "QASHQAI " },
    { marque: "NISSAN", modele: "SERENA" },
    { marque: "NISSAN", modele: "SUNNY" },
    { marque: "NISSAN", modele: "TERRANO" },
    { marque: "NISSAN", modele: "VANETTE" },
    { marque: "NISSAN", modele: "X-TRAIL" },
    { marque: "NOUN ELECTRIC", modele: "NOSMOKE" },
    { marque: "OLDSMOBILE", modele: "CUTLASS" },
    { marque: "OLDSMOBILE", modele: "F85" },
    { marque: "OPEL", modele: "ADAM" },
    { marque: "OPEL", modele: "AGILA" },
    { marque: "OPEL", modele: "AMPERA" },
    { marque: "OPEL", modele: "ANTARA" },
    { marque: "OPEL", modele: "ASTRA" },
    { marque: "OPEL", modele: "ASTRAVAN" },
    { marque: "OPEL", modele: "CALIBRA" },
    { marque: "OPEL", modele: "CASCADA" },
    { marque: "OPEL", modele: "COMBO" },
    { marque: "OPEL", modele: "COMBO 4 LIFE" },
    { marque: "OPEL", modele: "COMBO CARGO" },
    { marque: "OPEL", modele: "COMBO LIFE" },
    { marque: "OPEL", modele: "CORSA" },
    { marque: "OPEL", modele: "COUPE 1900" },
    { marque: "OPEL", modele: "CROSSLAND X" },
    { marque: "OPEL", modele: "CROSSLAND" },
    { marque: "OPEL", modele: "FRONTERA" },
    { marque: "OPEL", modele: "GRANDLAND X" },
    { marque: "OPEL", modele: "GT" },
    { marque: "OPEL", modele: "INSIGNIA" },
    { marque: "OPEL", modele: "KADETT" },
    { marque: "OPEL", modele: "KARL" },
    { marque: "OPEL", modele: "MERIVA" },
    { marque: "OPEL", modele: "MOKKA X" },
    { marque: "OPEL", modele: "MOKKA" },
    { marque: "OPEL", modele: "MOVANO" },
    { marque: "OPEL", modele: "OMEGA" },
    { marque: "OPEL", modele: "REKORD" },
    { marque: "OPEL", modele: "SPEEDSTER" },
    { marque: "OPEL", modele: "TIGRA" },
    { marque: "OPEL", modele: "VECTRA" },
    { marque: "OPEL", modele: "VIVARO" },
    { marque: "OPEL", modele: "ZAFIRA" },
    { marque: "OPEL", modele: "ZAFIRA LIFE" },
    { marque: "OPEL", modele: "ZAFIRA TOURER" },
    { marque: "PANTHER", modele: "LIMA" },
    { marque: "PEUGEOT", modele: "106" },
    { marque: "PEUGEOT", modele: "107" },
    { marque: "PEUGEOT", modele: "108" },
    { marque: "PEUGEOT", modele: "203" },
    { marque: "PEUGEOT", modele: "205" },
    { marque: "PEUGEOT", modele: "206" },
    { marque: "PEUGEOT", modele: "207" },
    { marque: "PEUGEOT", modele: "208" },
    { marque: "PEUGEOT", modele: "301" },
    { marque: "PEUGEOT", modele: "304" },
    { marque: "PEUGEOT", modele: "305" },
    { marque: "PEUGEOT", modele: "306" },
    { marque: "PEUGEOT", modele: "307" },
    { marque: "PEUGEOT", modele: "308" },
    { marque: "PEUGEOT", modele: "309" },
    { marque: "PEUGEOT", modele: "404" },
    { marque: "PEUGEOT", modele: "405" },
    { marque: "PEUGEOT", modele: "406" },
    { marque: "PEUGEOT", modele: "407" },
    { marque: "PEUGEOT", modele: "504" },
    { marque: "PEUGEOT", modele: "505" },
    { marque: "PEUGEOT", modele: "508" },
    { marque: "PEUGEOT", modele: "604" },
    { marque: "PEUGEOT", modele: "605" },
    { marque: "PEUGEOT", modele: "607" },
    { marque: "PEUGEOT", modele: "806" },
    { marque: "PEUGEOT", modele: "807" },
    { marque: "PEUGEOT", modele: "1007" },
    { marque: "PEUGEOT", modele: "2008" },
    { marque: "PEUGEOT", modele: "3008" },
    { marque: "PEUGEOT", modele: "4007" },
    { marque: "PEUGEOT", modele: "4008" },
    { marque: "PEUGEOT", modele: "5008" },
    { marque: "PEUGEOT", modele: "206+" },
    { marque: "PEUGEOT", modele: "207+" },
    { marque: "PEUGEOT", modele: "508 RXH" },
    { marque: "PEUGEOT", modele: "BIPPER" },
    { marque: "PEUGEOT", modele: "BIPPER TEPEE" },
    { marque: "PEUGEOT", modele: "BOXER" },
    { marque: "PEUGEOT", modele: "EXPERT" },
    { marque: "PEUGEOT", modele: "EXPERT TEPEE" },
    { marque: "PEUGEOT", modele: "ION" },
    { marque: "PEUGEOT", modele: "J7" },
    { marque: "PEUGEOT", modele: "P4" },
    { marque: "PEUGEOT", modele: "PARTNER" },
    { marque: "PEUGEOT", modele: "PARTNER TEPEE" },
    { marque: "PEUGEOT", modele: "RCZ" },
    { marque: "PEUGEOT", modele: "RIFTER" },
    { marque: "PEUGEOT", modele: "TRAVELLER" },
    { marque: "PLYMOUTH", modele: "PROWLER" },
    { marque: "POLESTAR", modele: "POLESTAR 2" },
    { marque: "PONTIAC", modele: "CATALINA" },
    { marque: "PONTIAC", modele: "FIREBIRD" },
    { marque: "PONTIAC", modele: "FORMULA" },
    { marque: "PONTIAC", modele: "GRAND PRIX" },
    { marque: "PONTIAC", modele: "GTO" },
    { marque: "PONTIAC", modele: "PHOENIX" },
    { marque: "PONTIAC", modele: "STARCHIEF" },
    { marque: "PONTIAC", modele: "TRANS AM" },
    { marque: "PORSCHE", modele: "356" },
    { marque: "PORSCHE", modele: "718" },
    { marque: "PORSCHE", modele: "904" },
    { marque: "PORSCHE", modele: "911" },
    { marque: "PORSCHE", modele: "912" },
    { marque: "PORSCHE", modele: "914" },
    { marque: "PORSCHE", modele: "918" },
    { marque: "PORSCHE", modele: "924" },
    { marque: "PORSCHE", modele: "928" },
    { marque: "PORSCHE", modele: "944" },
    { marque: "PORSCHE", modele: "968" },
    { marque: "PORSCHE", modele: "BOXSTER" },
    { marque: "PORSCHE", modele: "CARRERA GT" },
    { marque: "PORSCHE", modele: "CAYENNE" },
    { marque: "PORSCHE", modele: "CAYMAN" },
    { marque: "PORSCHE", modele: "MACAN" },
    { marque: "PORSCHE", modele: "PANAMERA" },
    { marque: "PORSCHE", modele: "TAYCAN" },
    { marque: "RENAULT", modele: "460" },
    { marque: "RENAULT", modele: "4CV" },
    { marque: "RENAULT", modele: "4L" },
    { marque: "RENAULT", modele: "ALASKAN" },
    { marque: "RENAULT", modele: "AVANTIME" },
    { marque: "RENAULT", modele: "B120" },
    { marque: "RENAULT", modele: "CAPTUR" },
    { marque: "RENAULT", modele: "CARAVELLE" },
    { marque: "RENAULT", modele: "CLIO" },
    { marque: "RENAULT", modele: "DAUPHINE" },
    { marque: "RENAULT", modele: "ESPACE" },
    { marque: "RENAULT", modele: "ESTAFETTE" },
    { marque: "RENAULT", modele: "EXPRESS" },
    { marque: "RENAULT", modele: "FLORIDE" },
    { marque: "RENAULT", modele: "FLUENCE" },
    { marque: "RENAULT", modele: "FREGATE" },
    { marque: "RENAULT", modele: "GRAND ESPACE" },
    { marque: "RENAULT", modele: "GRAND KANGOO" },
    { marque: "RENAULT", modele: "GRAND MODUS" },
    { marque: "RENAULT", modele: "GRAND SCENIC" },
    { marque: "RENAULT", modele: "JUVAQUATRE" },
    { marque: "RENAULT", modele: "KADJAR" },
    { marque: "RENAULT", modele: "KANGOO" },
    { marque: "RENAULT", modele: "KANGOO EXPRESS" },
    { marque: "RENAULT", modele: "KERAX" },
    { marque: "RENAULT", modele: "KOLEOS" },
    { marque: "RENAULT", modele: "LAGUNA" },
    { marque: "RENAULT", modele: "LATITUDE" },
    { marque: "RENAULT", modele: "MASCOTT" },
    { marque: "RENAULT", modele: "MASTER" },
    { marque: "RENAULT", modele: "MAXITY" },
    { marque: "RENAULT", modele: "MEGANE" },
    { marque: "RENAULT", modele: "MESSENGER" },
    { marque: "RENAULT", modele: "MIDLUM" },
    { marque: "RENAULT", modele: "MODUS" },
    { marque: "RENAULT", modele: "ONDINE" },
    { marque: "RENAULT", modele: "PREMIUM" },
    { marque: "RENAULT", modele: "R10" },
    { marque: "RENAULT", modele: "R11" },
    { marque: "RENAULT", modele: "R12" },
    { marque: "RENAULT", modele: "R16" },
    { marque: "RENAULT", modele: "R19" },
    { marque: "RENAULT", modele: "R21" },
    { marque: "RENAULT", modele: "R25" },
    { marque: "RENAULT", modele: "R4" },
    { marque: "RENAULT", modele: "R5" },
    { marque: "RENAULT", modele: "R6" },
    { marque: "RENAULT", modele: "R8" },
    { marque: "RENAULT", modele: "R9" },
    { marque: "RENAULT", modele: "RODEO" },
    { marque: "RENAULT", modele: "S 170" },
    { marque: "RENAULT", modele: "SAFRANE" },
    { marque: "RENAULT", modele: "SCENIC" },
    { marque: "RENAULT", modele: "SPIDER" },
    { marque: "RENAULT", modele: "SUPER 5" },
    { marque: "RENAULT", modele: "TALISMAN" },
    { marque: "RENAULT", modele: "TRAFIC" },
    { marque: "RENAULT", modele: "TWINGO" },
    { marque: "RENAULT", modele: "TWIZY" },
    { marque: "RENAULT", modele: "VEL SATIS" },
    { marque: "RENAULT", modele: "WIND" },
    { marque: "RENAULT", modele: "ZOE" },
    { marque: "ROLLS ROYCE", modele: "CAMARGUE" },
    { marque: "ROLLS ROYCE", modele: "CORNICHE" },
    { marque: "ROLLS ROYCE", modele: "CULLINAN" },
    { marque: "ROLLS ROYCE", modele: "DAWN" },
    { marque: "ROLLS ROYCE", modele: "GHOST" },
    { marque: "ROLLS ROYCE", modele: "PHANTOM" },
    { marque: "ROLLS ROYCE", modele: "SILVER CLOUD" },
    { marque: "ROLLS ROYCE", modele: "SILVER SERAPH" },
    { marque: "ROLLS ROYCE", modele: "SILVER SHADOW" },
    { marque: "ROLLS ROYCE", modele: "SILVER SPUR" },
    { marque: "ROLLS ROYCE", modele: "SILVER WRAITH" },
    { marque: "ROLLS ROYCE", modele: "WRAITH" },
    { marque: "ROVER", modele: "25" },
    { marque: "ROVER", modele: "45" },
    { marque: "ROVER", modele: "75" },
    { marque: "ROVER", modele: "MINI" },
    { marque: "ROVER", modele: "SERIE 100" },
    { marque: "ROVER", modele: "SERIE 200" },
    { marque: "ROVER", modele: "SERIE 600" },
    { marque: "SAAB", modele: "96" },
    { marque: "SAAB", modele: "900" },
    { marque: "SAAB", modele: "9-3" },
    { marque: "SAAB", modele: "9-5" },
    { marque: "SAAB", modele: "9-7 X" },
    { marque: "SAAB", modele: "SONETT" },
    { marque: "SANTANA", modele: "PS10" },
    { marque: "SANTANA", modele: "S410" },
    { marque: "SANTANA", modele: "S413" },
    { marque: "SANTANA", modele: "SAMURAI" },
    { marque: "SANTANA", modele: "VITARA" },
    { marque: "SEAT", modele: "ALHAMBRA" },
    { marque: "SEAT", modele: "ALTEA" },
    { marque: "SEAT", modele: "ARONA" },
    { marque: "SEAT", modele: "AROSA" },
    { marque: "SEAT", modele: "ATECA" },
    { marque: "SEAT", modele: "CORDOBA" },
    { marque: "SEAT", modele: "EXEO" },
    { marque: "SEAT", modele: "IBIZA" },
    { marque: "SEAT", modele: "LEON" },
    { marque: "SEAT", modele: "MII" },
    { marque: "SEAT", modele: "TARRACO" },
    { marque: "SEAT", modele: "TOLEDO" },
    { marque: "SHELBY", modele: "COBRA 289" },
    { marque: "SHELBY", modele: "COBRA DAYTONA" },
    { marque: "SIMCA", modele: "1000" },
    { marque: "SKODA", modele: "CITIGO" },
    { marque: "SKODA", modele: "FABIA" },
    { marque: "SKODA", modele: "FELICIA" },
    { marque: "SKODA", modele: "KAMIQ" },
    { marque: "SKODA", modele: "KAROQ" },
    { marque: "SKODA", modele: "KODIAQ" },
    { marque: "SKODA", modele: "OCTAVIA" },
    { marque: "SKODA", modele: "RAPID" },
    { marque: "SKODA", modele: "ROOMSTER" },
    { marque: "SKODA", modele: "SCALA" },
    { marque: "SKODA", modele: "SUPERB" },
    { marque: "SKODA", modele: "YETI" },
    { marque: "SMART", modele: "FORFOUR" },
    { marque: "SMART", modele: "FORTWO" },
    { marque: "SMART", modele: "ROADSTER" },
    { marque: "SMART", modele: "SMART" },
    { marque: "SSANGYONG", modele: "ACTYON SPORTS" },
    { marque: "SSANGYONG", modele: "KORANDO" },
    { marque: "SSANGYONG", modele: "KYRON" },
    { marque: "SSANGYONG", modele: "MUSSO" },
    { marque: "SSANGYONG", modele: "REXTON" },
    { marque: "SSANGYONG", modele: "RODIUS" },
    { marque: "SSANGYONG", modele: "TIVOLI XLV" },
    { marque: "SSANGYONG", modele: "TIVOLI" },
    { marque: "SUBARU", modele: "B9 TRIBECA" },
    { marque: "SUBARU", modele: "BRZ" },
    { marque: "SUBARU", modele: "FORESTER" },
    { marque: "SUBARU", modele: "IMPREZA" },
    { marque: "SUBARU", modele: "LEGACY" },
    { marque: "SUBARU", modele: "LEVORG" },
    { marque: "SUBARU", modele: "OUTBACK" },
    { marque: "SUBARU", modele: "SVX" },
    { marque: "SUBARU", modele: "TREZIA" },
    { marque: "SUBARU", modele: "TRIBECA" },
    { marque: "SUBARU", modele: "WRX STI" },
    { marque: "SUBARU", modele: "XV" },
    { marque: "SUDA", modele: "SA 01" },
    { marque: "SUZUKI", modele: "ACROSS" },
    { marque: "SUZUKI", modele: "ALTO" },
    { marque: "SUZUKI", modele: "BALENO" },
    { marque: "SUZUKI", modele: "CARRY" },
    { marque: "SUZUKI", modele: "CELERIO" },
    { marque: "SUZUKI", modele: "GRAND VITARA" },
    { marque: "SUZUKI", modele: "GRAND VITARA XL-7" },
    { marque: "SUZUKI", modele: "IGNIS" },
    { marque: "SUZUKI", modele: "JIMNY" },
    { marque: "SUZUKI", modele: "LIANA" },
    { marque: "SUZUKI", modele: "SAMURAI" },
    { marque: "SUZUKI", modele: "SPLASH" },
    { marque: "SUZUKI", modele: "SWACE" },
    { marque: "SUZUKI", modele: "SWIFT" },
    { marque: "SUZUKI", modele: "SX4 S-CROSS" },
    { marque: "SUZUKI", modele: "SX4 " },
    { marque: "SUZUKI", modele: "VITARA" },
    { marque: "SUZUKI", modele: "WAGON R+" },
    { marque: "TESLA", modele: "MODEL 3" },
    { marque: "TESLA", modele: "MODEL S" },
    { marque: "TESLA", modele: "MODEL X" },
    { marque: "TESLA", modele: "ROADSTER" },
    { marque: "TOYOTA", modele: "AURIS" },
    { marque: "TOYOTA", modele: "AVENSIS" },
    { marque: "TOYOTA", modele: "AYGO" },
    { marque: "TOYOTA", modele: "C-HR" },
    { marque: "TOYOTA", modele: "CAMRY" },
    { marque: "TOYOTA", modele: "CELICIA" },
    { marque: "TOYOTA", modele: "COROLLA VERSO" },
    { marque: "TOYOTA", modele: "COROLLA" },
    { marque: "TOYOTA", modele: "GT86" },
    { marque: "TOYOTA", modele: "HI ACE" },
    { marque: "TOYOTA", modele: "HIGHLANDER" },
    { marque: "TOYOTA", modele: "HILUX" },
    { marque: "TOYOTA", modele: "IQ" },
    { marque: "TOYOTA", modele: "LAND CRUISER" },
    { marque: "TOYOTA", modele: "LAND CRUISER SW" },
    { marque: "TOYOTA", modele: "LITE ACE" },
    { marque: "TOYOTA", modele: "MIRAI" },
    { marque: "TOYOTA", modele: "MR" },
    { marque: "TOYOTA", modele: "PREVIA" },
    { marque: "TOYOTA", modele: "PRIUS+" },
    { marque: "TOYOTA", modele: "PRIUS" },
    { marque: "TOYOTA", modele: "PROACE" },
    { marque: "TOYOTA", modele: "PROACE CITY" },
    { marque: "TOYOTA", modele: "PROACE CITY VERSO" },
    { marque: "TOYOTA", modele: "PROACE VERSO" },
    { marque: "TOYOTA", modele: "RAV 4" },
    { marque: "TOYOTA", modele: "STARLET" },
    { marque: "TOYOTA", modele: "SUPRA" },
    { marque: "TRIUMPH", modele: "DOLOMITE" },
    { marque: "TRIUMPH", modele: "GT6" },
    { marque: "TRIUMPH", modele: "HERALD" },
    { marque: "TRIUMPH", modele: "SPITFIRE" },
    { marque: "TRIUMPH", modele: "TR 250" },
    { marque: "TRIUMPH", modele: "TR 3" },
    { marque: "TRIUMPH", modele: "TR 4" },
    { marque: "TRIUMPH", modele: "TR 6" },
    { marque: "TVR", modele: "350" },
    { marque: "TVR", modele: "CHIMAERA" },
    { marque: "TVR", modele: "GRIFFITH" },
    { marque: "TVR", modele: "TUSCAN" },
    { marque: "VOLKSWAGEN", modele: "181" },
    { marque: "VOLKSWAGEN", modele: "1200" },
    { marque: "VOLKSWAGEN", modele: "1302" },
    { marque: "VOLKSWAGEN", modele: "1303" },
    { marque: "VOLKSWAGEN", modele: "1500" },
    { marque: "VOLKSWAGEN", modele: "1600" },
    { marque: "VOLKSWAGEN", modele: "AMAROK" },
    { marque: "VOLKSWAGEN", modele: "ARTEON" },
    { marque: "VOLKSWAGEN", modele: "BORA" },
    { marque: "VOLKSWAGEN", modele: "CADDY" },
    { marque: "VOLKSWAGEN", modele: "CALIFORNIA" },
    { marque: "VOLKSWAGEN", modele: "CARAVELLE" },
    { marque: "VOLKSWAGEN", modele: "CC" },
    { marque: "VOLKSWAGEN", modele: "COCCINELLE" },
    { marque: "VOLKSWAGEN", modele: "COMBI" },
    { marque: "VOLKSWAGEN", modele: "CORRADO" },
    { marque: "VOLKSWAGEN", modele: "CRAFTER" },
    { marque: "VOLKSWAGEN", modele: "EOS" },
    { marque: "VOLKSWAGEN", modele: "FOX" },
    { marque: "VOLKSWAGEN", modele: "GOLF" },
    { marque: "VOLKSWAGEN", modele: "GOLF PLUS" },
    { marque: "VOLKSWAGEN", modele: "GOLF SPORTSVAN" },
    { marque: "VOLKSWAGEN", modele: "GRAND CALIFORNIA" },
    { marque: "VOLKSWAGEN", modele: "ID.3" },
    { marque: "VOLKSWAGEN", modele: "JETTA" },
    { marque: "VOLKSWAGEN", modele: "KARMAN GHIA" },
    { marque: "VOLKSWAGEN", modele: "LT" },
    { marque: "VOLKSWAGEN", modele: "LUPO" },
    { marque: "VOLKSWAGEN", modele: "MULTIVAN" },
    { marque: "VOLKSWAGEN", modele: "NEW BEETLE" },
    { marque: "VOLKSWAGEN", modele: "PASSAT CC" },
    { marque: "VOLKSWAGEN", modele: "PASSAT" },
    { marque: "VOLKSWAGEN", modele: "PHAETON" },
    { marque: "VOLKSWAGEN", modele: "POLO" },
    { marque: "VOLKSWAGEN", modele: "SCIROCCO" },
    { marque: "VOLKSWAGEN", modele: "SHARAN" },
    { marque: "VOLKSWAGEN", modele: "T-CROSS" },
    { marque: "VOLKSWAGEN", modele: "T-ROC" },
    { marque: "VOLKSWAGEN", modele: "TIGUAN" },
    { marque: "VOLKSWAGEN", modele: "TIGUAN ALLSPACE" },
    { marque: "VOLKSWAGEN", modele: "TOUAREG" },
    { marque: "VOLKSWAGEN", modele: "TOURAN" },
    { marque: "VOLKSWAGEN", modele: "TRANSPORTER" },
    { marque: "VOLKSWAGEN", modele: "UP!" },
    { marque: "VOLVO", modele: "144" },
    { marque: "VOLVO", modele: "240" },
    { marque: "VOLVO", modele: "740" },
    { marque: "VOLVO", modele: "850" },
    { marque: "VOLVO", modele: "960" },
    { marque: "VOLVO", modele: "C30" },
    { marque: "VOLVO", modele: "C70" },
    { marque: "VOLVO", modele: "FL" },
    { marque: "VOLVO", modele: "P1800" },
    { marque: "VOLVO", modele: "S40" },
    { marque: "VOLVO", modele: "S60" },
    { marque: "VOLVO", modele: "S70" },
    { marque: "VOLVO", modele: "S80" },
    { marque: "VOLVO", modele: "S90" },
    { marque: "VOLVO", modele: "V40 CROSS COUNTRY" },
    { marque: "VOLVO", modele: "V40" },
    { marque: "VOLVO", modele: "V50" },
    { marque: "VOLVO", modele: "V60 CROSS COUNTRY" },
    { marque: "VOLVO", modele: "V60 CROSS COUNTR" },
    { marque: "VOLVO", modele: "V70" },
    { marque: "VOLVO", modele: "V90 CROSS COUNTRY" },
    { marque: "VOLVO", modele: "XC40" },
    { marque: "VOLVO", modele: "XC60" },
    { marque: "VOLVO", modele: "XC70" },
    { marque: "VOLVO", modele: "XC90" },
    { marque: "WESTFIELD", modele: "SUPER SEVEN" },
    { marque: "WESTFIELD", modele: "ZEI" },
    { marque: "ZXET", modele: "NEIBOR" }
];

export default listeVoitures;