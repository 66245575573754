<template>

  <div id="main" style="min-height:70vh;">

    <!--==========================
      Trajet Section
    ============================-->
    <section id="trajet">
      <!-- Connecté -->
      <div class="trajet-content" v-show="profile.id">
        <!-- -->
        <v-row justify="center">
          <v-col
            cols="12"
            sm="12"
            md="10"
            lg="10">

            <v-stepper>
              <v-stepper-header>
                <v-stepper-step
                  :complete="trajet.date != null && trajet.heure != null"
                  step="1"
                >
                  Date et heure
                </v-stepper-step>

                <v-divider :class="{'complet': adresses.depart.cp.length > 0, 'incomplet': adresses.depart.cp.length == 0}"></v-divider>

                <v-stepper-step
                  :complete="trajet.adresse_depart != null"
                  step="2"
                >
                  Adresse de départ
                </v-stepper-step>

                <v-divider :class="{'complet': adresses.arrivee.cp.length > 0, 'incomplet': adresses.arrivee.cp.length == 0}"></v-divider>

                <v-stepper-step
                  :complete="trajet.adresse_arrivee != null"
                  step="3">
                  Adresse de destination
                </v-stepper-step>

                <v-divider :class="{'complet': trajet.nb_places_proposees > 0, 'incomplet': trajet.nb_places_proposees == 0 }"></v-divider>

                <v-stepper-step
                  :complete="trajet.nb_places_proposees > 0 && trajet.is_resa_automatique != null && trajet.is_roles_passagers != null"
                  step="4">
                  Passagers
                </v-stepper-step>

                <v-divider :class="{'complet': trajet.montant_contribution, 'incomplet': !trajet.montant_contribution }"></v-divider>

                <v-stepper-step
                  :complete="trajet.montant_contribution != null"
                  step="5">
                  Contribution
                </v-stepper-step>

                <v-divider :class="{'complet': isSaisieTerminee, 'incomplet': !isSaisieTerminee}"></v-divider>

                <v-stepper-step
                  :complete="trajet.publie == true"
                  step="6">
                  Publication
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>

        <v-row justify="center">
          
          <!-- Encart résumé -->
          <v-col
            cols="12"
            sm="12" 
            md="6" 
            lg="6"
            v-show="isSaisieTerminee">
            <div class="etape-x">

              <div>
                <div 
                  class="btn pointer"
                  v-on:click="publier"
                  :disabled="$root.globalLoading"
                  :visible="!$root.globalLoading && !trajet.id"
                  style="padding-left: 18px; padding-right: 18px; margin-top: 36px;">
                  Publier mon trajet
                </div>
              </div>

              <label class="titre" style="margin-top: 36px; margin-bottom: 25px;">Votre voyage</label>
              <div class="resume-voyage">
                <div class="resume-item" v-show="trajet.date">
                  <div class="resume-label">
                    <span class="mdi mdi-calendar"></span>
                    <span>Date :</span>
                  </div>
                  <div>{{ trajet.date | formatDate }}</div>
                  <div v-on:click="trajet.date = null" class="mdi mdi-pencil pointer"></div>
                </div>
                <div class="resume-item" v-show="trajet.heure">
                  <div class="resume-label">
                    <span class="mdi mdi-clock"></span>
                    <span>Heure :</span>
                  </div>
                  <div>{{ trajet.heure | heure }}</div>
                  <div v-on:click="trajet.heure = null" class="mdi mdi-pencil pointer"></div>                    
                </div>

                <!-- appel au composant -->
                <resume-adresse :trajet="trajet" :adresses="adresses" :toggles="toggles" type-adresse="depart" />
                
                <!-- appel au composant -->
                <resume-adresse :trajet="trajet" :adresses="adresses" :toggles="toggles" type-adresse="arrivee" />
                
                <div class="section pointer"
                   v-show="trajet.villes_etapes_set && trajet.villes_etapes_set >= 0"
                  v-on:click="toggles.v_etapes = !toggles.v_etapes">
                  <h6>
                    Villes étapes
                  </h6>
                  <span class="mdi mdi-chevron-right" v-show="!toggles.v_etapes"></span>
                  <span class="mdi mdi-chevron-up" v-show="toggles.v_etapes"></span>
                </div>
                <div class="resume-item" v-show="toggles.v_etapes && trajet.villes_etapes_set && trajet.villes_etapes_set >= 0">
                  <div class="resume-label">
                    <div
                      class="flex flex-content"
                      v-for="(borne, index) in bornes"
                      :key="index">
                      {{ borne.arrivee | premiereLettreCapitale }}
                    </div>
                  </div>
                  <div v-on:click="trajet.villes_etapes_set = -1" class="mdi mdi-pencil pointer"></div>
                </div>
                
                <div class="section pointer"
                  v-show="(trajet.nb_places_proposees && trajet.nb_places_proposees > 0)
                  || trajet.is_resa_automatique
                  || trajet.is_roles_passagers"
                  v-on:click="toggles.passagers = !toggles.passagers"
                  style="margin-top: 25px">
                  <h6>
                    Passagers
                  </h6>
                  <span class="mdi mdi-chevron-right" v-show="!toggles.passagers"></span>
                  <span class="mdi mdi-chevron-up" v-show="toggles.passagers"></span>
                </div>
                <div class="resume-item" v-show="toggles.passagers && trajet.nb_places_proposees && trajet.nb_places_proposees > 0">
                  <div class="resume-label">
                    <span class="mdi mdi-numeric"></span>
                    <span>Places :</span>
                  </div>
                  <div><span :class="'mdi mdi-numeric-' + trajet.nb_places_proposees + '-box-outline'" style="font-size: 21px;"></span></div>
                  <div v-on:click="trajet.nb_places_proposees = 0" class="mdi mdi-pencil pointer"></div>
                </div>
                <div class="resume-item" v-show="toggles.passagers && trajet.is_resa_automatique != null">
                  <div class="resume-label">
                    <span class="mdi mdi-flash"></span>
                    <span>Mode de réservation :</span>
                  </div>
                  <div>{{ trajet.is_resa_automatique ? 'Automatique' : 'Manuellle' }}</div>
                  <div v-on:click="trajet.is_resa_automatique = null" class="mdi mdi-pencil pointer"></div>
                </div>
                <div class="resume-item" v-show="toggles.passagers && trajet.is_roles_passagers != null && trajet.roles_passagers[4] == 1">
                  <div class="resume-label">
                    <span class="mdi mdi-account-multiple-outline"></span>
                    <span>Rôle passagers :</span>
                  </div>
                  <div>
                    <span v-if="trajet.is_roles_passagers == 0">Aucun</span>
                    <span v-else
                      v-for="(role, index) in trajet.roles_passagers.slice(0, 4)"
                      :key="index">
                      {{ role == 1 ? (index == 0 ? ' Co-pilote ' : (index == 1 ? ' Journaliste ' : (index == 2 ? ' DJ ' : ' Foot '))): ''}}
                    </span>
                  </div>
                  <div v-on:click="trajet.is_roles_passagers = null; 
                    $set(trajet.roles_passagers, 4, 0);
                    $set(trajet.roles_passagers, 0, 0);
                    $set(trajet.roles_passagers, 1, 0);
                    $set(trajet.roles_passagers, 2, 0);
                    $set(trajet.roles_passagers, 3, 0)" class="mdi mdi-pencil pointer"></div>
                </div>
                <div class="resume-item" v-show="toggles.passagers && trajet.is_resa_automatique != null">
                  <div class="resume-label">
                    <span class="mdi mdi-cash-multiple"></span>
                    <span v-show="!trajet.villes_etapes_set || trajet.villes_etapes_set == 0">Montant de contribution :</span>
                    <!--<span v-show="trajet.villes_etapes_set && trajet.villes_etapes_set >= 0">Montant de contribution (total) :</span>-->
                  </div>
                  <div>{{ trajet.montant_contribution }} &euro; <i v-show="trajet.villes_etapes_set && trajet.villes_etapes_set >= 0">(Si vous avez des villes étapes, le montant de la distance principale est fixée)</i></div>
                  <div 
                    v-on:click="trajet.montant_contribution = null" class="mdi mdi-pencil pointer">
                  </div>
                </div>
              </div>
              
              <div>
                <div 
                  class="btn pointer"
                  v-on:click="publier"
                  :disabled="$root.globalLoading"
                  :visible="!$root.globalLoading && !trajet.id"
                  style="padding-left: 18px; padding-right: 18px; margin-top: 34px; margin-bottom: 25px">
                  Publier mon trajet
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row justify="center">
          <!-- Encart actions / sélection -->
          <v-col
            cols="12"
            sm="12" 
            md="6" 
            lg="4" v-show="!isSaisieTerminee">

            <div class="etape-x" v-show="!trajet.date">
              <label class="titre">Quand partez-vous ?</label>
              <div class="input-group">
                  <v-row justify="center">
                      <v-date-picker 
                          id="date_depart"
                          class="centre" 
                          color="#50d8af"
                          locale="fr-fr"
                          v-model="trajet.date"
                          :min="today"
                          :first-day-of-week="1" />
                  </v-row>
              </div>
            </div>
            <div class="etape-x" v-show="trajet.date && !trajet.heure">
              <label class="titre">A quelle heure ?</label>
              <div class="input-group">
                  <span class="note">Sélectionnez l'heure de votre départ.</span>
                  <div 
                    class="btn centre pointer periode-tranche"
                    v-show="index_periode_selectionnee == null"
                    v-for="(periode, index) in periodes"
                    :key="periode.code"
                    v-on:click="index_periode_selectionnee = index"
                    >
                    <span>{{ periode.label }}</span><br />
                    <span class="asterisque">{{ periode.description }}</span>
                  </div>

                  <div 
                    class="btn centre pointer periode"
                    v-show="index_periode_selectionnee != null"
                    v-for="(horaire, index) in liste_horaires.filter(h => { return h.index_moment == index_periode_selectionnee })"
                    :key="index"
                    v-on:click="trajet.heure = horaire.heure"
                    >
                    {{ horaire.heure | heure }}
                  </div>
              </div>
            </div>

            <ajouter-trajet-saisir-adresse
              type-adresse="depart"
              :adresses="adresses"
              :trajet="trajet"
              :set-adresse="setAdresse"
              :show-communes="showCommunes"
              :show-adresses-enregistrees="showAdressesEnregistrees"
              :gerer-clic-lieu="gererClicLieu"
            />

            <ajouter-trajet-saisir-adresse
              type-adresse="arrivee"
              :adresses="adresses"
              :trajet="trajet"
              :set-adresse="setAdresse"
              :show-communes="showCommunes"
              :show-adresses-enregistrees="showAdressesEnregistrees"
              :gerer-clic-lieu="gererClicLieu"
            />

            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set == -1">
              <label for="depart" class="titre">Souhaitez-vous prendre des covoitureurs sur votre route ?</label>
              <div class="input-group flex">
                  
                  <span class="note">Prendre des covoitureurs sur votre route maximise vos possibilités de remplir votre véhicule et contribue à un covoiturage plus efficace.</span>

                  <div 
                    class="btn centre ville-etape"
                    :class="{'not-selected' : ville.selected == 0, 'selected' : ville.selected == 1}"
                    v-for="(ville, index) in villes_etapes"
                    :key="index"
                    v-on:click="changeSelection(index)">
                    <span 
                      class="mdi" 
                      :class="{'mdi-crop-square' : ville.selected == 0, 'mdi-marker-check' : ville.selected == 1}">
                    </span>
                    {{ ville.nom }}
                  </div>
              </div>
              <div>
                  <div
                    class="btn centre"
                    v-show="aucuneVillesSelectionnee()"
                    v-on:click="trajet.villes_etapes_set = 0">
                    <span><span class="mdi mdi-skip-next-circle"></span> &nbsp; Non merci, pas de ville intermédiare</span>
                  </div>
                  <div
                    class="btn centre"
                    v-show="!aucuneVillesSelectionnee()"
                    v-on:click="validerVillesEtapes()"
                    style="padding-left: 18px; padding-right: 18px;">
                    <span><span class="mdi mdi-check"></span> &nbsp; Valider ces villes</span>
                  </div>
              </div>
            </div>

            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set >= 0 && trajet.nb_places_proposees == 0">
              <label for="depart" class="titre">Combien de passagers souhaitez-vous prendre ?</label>
              <div class="input-group flex">
                  
                  <span class="note">Sélectionnez le nombre de place que vous proposez pour ce "road trip".</span>

                  <div 
                    class="btn centre chiffre"
                    v-for="chiffre in 8"
                    :key="chiffre"
                    v-show="chiffre < 5 || toggles.plus_de_passagers"
                    v-on:click="setNbPlacesProposees(chiffre)">
                    {{ chiffre }}
                  </div>
                  <div
                    v-show="!toggles.plus_de_passagers"
                    class="btn centre chiffre"
                    v-on:click="toggles.plus_de_passagers = true">
                    <span class="mdi mdi-plus-box"></span>
                  </div>
              </div>
            </div>

            <!-- Type de réservation automatique -->
            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set >= 0 && trajet.nb_places_proposees > 0 && trajet.is_resa_automatique == null">
              <label for="depart" class="titre">Qu'est-ce qui vous est plus pratique ?</label>
              <div class="input-group flex flex-content">
                  <span class="note">Sélectionnez le mode de réservation (automatique: signifie que vous n'avez rien à faire).</span>

                  <div 
                    class="btn centre pointer resa"
                    v-on:click="trajet.is_resa_automatique = 1">
                    Réservation Automatique
                  </div>

                  <div 
                    class="btn centre pointer resa"
                    v-on:click="trajet.is_resa_automatique = 0">
                    Réservation Manuelle
                  </div>

              </div>
            </div>

            <!-- Affectation des rôles aux passagers -->
            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set >= 0 && trajet.nb_places_proposees > 0 && trajet.is_resa_automatique != null && (trajet.is_roles_passagers == null || trajet.roles_passagers[4] == 0)">
              <label for="depart" class="titre">Des préférences ou des rôles pour vos passagers ?</label>
              
              <div class="input-group flex flex-content" v-show="!trajet.is_roles_passagers">
                  <span class="note">Souhaitez-vous que les rôles suivants soient affectés aux passagers : d-j, co-pilote, journaliste. Ou avez-vous des préférences comme "Avec" ou "Sans foot" ?</span>

                  <div 
                    class="btn centre pointer role"
                    v-on:click="trajet.is_roles_passagers = 1">
                    Oui, pourquoi pas
                  </div>

                  <div 
                    class="btn centre pointer role"
                    v-on:click="trajet.is_roles_passagers = 0; $set(trajet.roles_passagers, 4, 1)">
                    Non, c'est mieux spontanément
                  </div>
              </div>

              <div class="input-group flex flex-content" v-show="trajet.is_roles_passagers == 1">

                  <span class="note">Quels rôles ?</span>
                  <span class="note asterisque">L'affectation de rôle n'a absolument rien d'obligatoire, personne ne devra rien exiger malgré ce rôle. Cela est proposé uniquement pour permettre une meilleure expérience de covoiturage.</span>

                  <div 
                    class="btn pointer role"
                    :class="{'valide' : trajet.roles_passagers[3] == 1, 'neutre': trajet.roles_passagers[3] == 0}"
                    v-on:click="$set(trajet.roles_passagers, 3, Math.abs(trajet.roles_passagers[3] - 1))">
                    <span class="mdi mdi-soccer"></span><span>Foot</span><br />
                    <span class="asterisque">(Accepte de parler de la coupe du monde de football ou pas)</span>
                  </div>

                  <div 
                    class="btn pointer role"
                    :class="{'valide' : trajet.roles_passagers[0] == 1, 'neutre': trajet.roles_passagers[0] == 0}"
                    v-on:click="$set(trajet.roles_passagers, 0, Math.abs(trajet.roles_passagers[0] - 1))">
                    <span class="mdi mdi-sign-direction"></span><span>Co-pilote</span><br />
                    <span class="asterisque">(Veille avec le pilote au suivi de l'itinéraire en utilisant les moyens adéquats)</span>
                  </div>

                  <div 
                    class="btn pointer role"
                    :class="{'valide' : trajet.roles_passagers[1] == 1, 'neutre': trajet.roles_passagers[1] == 0}"
                    v-on:click="$set(trajet.roles_passagers, 1, Math.abs(trajet.roles_passagers[1] - 1))">
                    <span class="mdi mdi-microphone"></span><span>Journaliste</span><br />
                    <span class="asterisque">(Propose des questions pour découvrir chaque passager : 30 minutes maximum)</span>
                  </div>

                  <div 
                    class="btn pointer role"
                    :class="{'valide' : trajet.roles_passagers[2] == 1, 'neutre': trajet.roles_passagers[2] == 0}"
                    v-on:click="$set(trajet.roles_passagers, 2, Math.abs(trajet.roles_passagers[2] - 1))">
                    <span class="mdi mdi-music-note-bluetooth"></span><span>DJ</span><br />
                    <span class="asterisque">(Propose une playlist musicale : 30 minutes maximum)</span>
                  </div>
              </div>

              <div class="input-group flex flex-content" v-show="trajet.is_roles_passagers == 1">
                <div 
                  class="btn pointer role"
                  v-on:click="$set(trajet.roles_passagers, 4, 1)">
                  <span class="mdi mdi-thumb-up"></span> <span> Terminer le casting</span>
                </div>
              </div>
            </div>

            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set >= 0 && trajet.nb_places_proposees > 0 && trajet.is_resa_automatique != null && trajet.is_roles_passagers != null && trajet.roles_passagers[4] == 1 && trajet.montant_contribution == null">
              <label for="depart" class="titre">Quelle participation financière ?</label>
              
              <span class="note">Vous pouvez fixer la participation financière des passagers. Néanmoins notre plateforme encadre ces participations pour que chacun y trouve son compte.</span>

              <div class="flex">

                <!-- Tranche sélectionnée -->
                <div 
                  class="input-group flex flex-content"
                  v-show="trajet.villes_etapes_set <= 0">
                  <div 
                    class="btn pointer participation"
                    v-for="(n, index) in getContributionRange()"
                    :key="n"
                    :class="{ 'suggere': index == parseInt(getContributionRangeTaille() / 2) }"
                    v-on:click="trajet.montant_contribution = n">
                    {{ n }} &euro;
                  </div>
                </div>

                <div 
                  v-show="trajet.villes_etapes_set == 1">
                  
                  <div
                    class="flex flex-content"
                    v-for="(borne, index) in bornes"
                    :key="index"
                    style="border-bottom: 1px dotted #777; margin-bottom:18px; align-items:baseline; padding-bottom: 9px">
                    <div style="width: 61%">
                      <h6>{{ borne.depart | premiereLettreCapitale }} <i class="mdi mdi-arrow-right"></i> {{ borne.arrivee | premiereLettreCapitale }}</h6>
                    </div>
                    <div class="flex flex-content" style="width: 36%">
                      <div 
                        @click="decMontant(index)"
                        :class="{'b-disabled': isDownToMin(index), 'b-enabled': isUpToMin(index)}">
                        <span class="mdi mdi-minus"></span>
                      </div>
                      <div 
                        style="font-size:18px; text-align:center; width:36px; height:36px; padding:4px; color:#777; border: 1px solid; border-radius: 7px;">
                        {{ montants[index] }}
                      </div>
                      <div 
                        @click="incMontant(index)"
                        :class="{'b-disabled': isUpToMax(index), 'b-enabled': isDownToMax(index)}">
                        <span class="mdi mdi-plus"></span>
                      </div>
                    </div>

                  </div>
                  
                  <div
                    class="btn centre"
                    v-on:click="validerMontants()"
                    style="padding-left: 18px; padding-right: 18px;">
                    <span><span class="mdi mdi-check"></span> &nbsp; Valider ces montants</span>
                  </div>

                </div>

              </div>
            </div>

            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set >= 0 && trajet.nb_places_proposees > 0 && trajet.is_resa_automatique != null && trajet.is_roles_passagers != null && trajet.roles_passagers[4] == 1 && trajet.montant_contribution != null && (trajet.has_code_promo == null || Math.abs(trajet.has_code_promo) % 2 == 1)">
              <label for="depart" class="titre">Avez-vous un code promotionnel ?</label>
              <span class="note">Si vous disposez d'un code promotionnel, c'est le moment de l'utiliser.</span>
              <div style="display: flex" v-show="trajet.has_code_promo == -1">
                <div 
                  class="btn centre pointer resa"
                  v-on:click="trajet.has_code_promo = 1; trajet.code_promo = ''">
                  OUI
                </div>

                <div 
                  class="btn centre pointer resa"
                  v-on:click="trajet.has_code_promo = 0">
                  NON
                </div>
              </div>

              <div 
                v-show="trajet.has_code_promo == 1">
                
                <input type="text" v-model="trajet.code_promo" /> 
                
                <div 
                  class="btn centre pointer resa"
                  v-on:click="checkCodePromo()">
                  Vérifiez
                </div>

                <div v-show="erreur_code_promo">
                  <span class="mdi mdi-alert-circle-outline" /> Ce code n'est pas valide ou n'est plus valable. Veuillez saisir un code valide ou 
                  <span
                    v-on:click="trajet.has_code_promo = 0"
                    style="cursor: pointer">
                    <b>vous souhaitez continuer sans code promotionnel ?</b>
                  </span>
                </div>

              </div>
            </div>

            <div class="etape-x" v-show="trajet.date && trajet.heure && trajet.adresse_depart && trajet.adresse_arrivee && trajet.villes_etapes_set >= 0 && trajet.nb_places_proposees > 0 && trajet.is_resa_automatique != null && trajet.is_roles_passagers != null && trajet.roles_passagers[4] == 1 && trajet.montant_contribution != null && trajet.has_code_promo % 2 == 0 && trajet.description == null">
              <label for="depart" class="titre">Une description pour finir !</label>
              <span class="note">Vous pouvez décrire les raisons qui vous poussent à faire ce voyage ou donner des indications sur le voyage.</span>
              <textarea
                v-model="description"
                :placeholder="'Ex: Bonjour, je suis ' + profile.prenom + ' de ' + adresses.depart.commune_choisie.nom + ' et je vais à ' + adresses.arrivee.commune_choisie.nom + ' car ... '"
              >
              </textarea>
              <div 
                class="btn pointer"
                v-on:click="trajet.description = description"
                style="padding-left: 18px; padding-right: 18px;">
                Terminer
              </div>
            </div>

            <!--<div class="etape-x" v-show="loading">
                <button  
                    class="btn centre"
                    v-show="loading">
                    <span class="mdi mdi-spin mdi-loading"></span>
                </button>
            </div>-->
            
            <div class="etape-x" v-show="trajet.date" style="margin-top: 12vh">
                <button  
                    class="btn neutre pointer"
                    v-on:click="back"
                    style="height:18px; padding:3px; line-height:9px; font-size:12px; opacity: 0.54;">
                    <span class="mdi mdi-arrow-left"></span>
                </button>
            </div>
          </v-col>
        </v-row>
        
      </div>

      <!-- Non connecté -->
      <div class="trajet-content" v-show="!profile.id">
        <v-row>

          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            id="trouver-covoiturage-cover">
          </v-col>

          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12">

            <h1 
              class="titre"
              style="text-align: center">
              Comment proposer un trajet de covoiturage ?
            </h1>

            <v-row
              justify="center"
              align="center">
              <v-col cols="5">
                <router-link :to="{name: 'inscription'}">
                  Inscrivez-vous
                </router-link>
              </v-col>
              <v-col cols="2">
                - OU -
              </v-col>
              <v-col cols="5">
                <router-link :to="{name: 'connexion'}">
                  Connectez-vous
                </router-link>
              </v-col>
            </v-row>

            <v-row
              justify="center"
              align="center"
              style="color: white; text-align: justify; padding: 25px 12px;">

              <v-col cols="6" sm="12" class="fond-vert">
                1. &Eacute;conomisez jusqu'à 100% de vos frais de déplacements en prenant des covoitureurs.
              </v-col>
              
              <v-col cols="6" sm="12" class="fond-bleu">
                2. Voyagez avec des personnes qui ont envie de partager votre trajet avec bonne humeur.
              </v-col>
              
              <v-col cols="6" sm="12" class="fond-jaune">
                3. Participez à rendre la planète plus propre en réduisant l'émission de gaz à effet de serre.
              </v-col>
              
              <v-col cols="6" sm="12" class="fond-rose">
                4. Profitez de nombreuses promotions régulières de notre plateforme pour vous inciter au covoit'.
              </v-col>

            </v-row>

          </v-col>

        </v-row>
      </div>
    </section><!-- #trajet -->
    
    <v-dialog
      v-model="dialog_completer_infos"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Informations complémentaires requises</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="utilisateur.adresse"
                  label="Votre adresse*"
                  hint="Ex: 1 place de la Concorde"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="utilisateur.ville"
                  label="Votre ville*"
                  hint="Ex: Paris"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="utilisateur.code_postal"
                  label="Votre code postal*"
                  hint="Ex: 75008"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="utilisateur.pays_residence"
                  :items="liste_pays"
                  item-text="name"
                  item-value="code"
                  label="Votre pays de résidence*"
                  hint="Votre pays de Résidence*"
                  :persistent-hint="true"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select 
                  v-model="utilisateur.nationalite"
                  :items="liste_pays"
                  item-text="name"
                  item-value="code"
                  label="Votre Nationalité*"
                  hint="Votre Nationalité*"
                  :persistent-hint="true"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>* Champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_completer_infos = false"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="enregistrerInformationsConducteur"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

  </div>

</template>

<style>
#trajet {
    width: 100%;
    padding: 36px;
}

.trajet-content {
    text-align: center;
}

.trajet-content #trouver-covoiturage-cover {
  /* background-image: url("../assets/img/banners/top4.webp"); */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 360px;
}

.trajet-content .fond-vert {
  background-color: #20c997;
}
.trajet-content .fond-jaune {
  background-color: #ffc400;
}
.trajet-content .fond-bleu {
  background-color: #17a2b8;
}
.trajet-content .fond-rose {
  background-color: #fd6c9e;
}

.trajet-content .input-group {
    margin: 18px 0;
}

.trajet-content .v-stepper .v-stepper__header .v-divider.complet {
    border-color: #50d8af;
    border-width: 3px;
}

.trajet-content .v-stepper .v-stepper__header .v-divider.incomplet {
    border-color: #ccc;
    border-width: 1px;
}

.trajet-content .v-stepper {
    box-shadow: auto;
}

.trajet-content .v-stepper .v-stepper__label {
  font-size: 12px;
}

#trajet .trajet-content .titre {
  color: #17a2b8;
  margin: 9px 0 39px 0;
  font-size: 25px;
  font-weight: 600;
}

#trajet .trajet-content .flex-content {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

#trajet .trajet-content .flex-content .b-disabled {
  opacity: 0.48;
  cursor: normal;
}

#trajet .trajet-content .flex-content .b-enabled {
  opacity: 1;
  cursor: pointer;
}

#trajet .trajet-content [class^=etape-] {
  margin: auto;
}

#trajet .trajet-content [class^=etape-] .btn.commune,
#trajet .trajet-content [class^=etape-] .btn.lieu,
#trajet .trajet-content [class^=etape-] .btn.chiffre,
#trajet .trajet-content [class^=etape-] .btn.ville-etape,
#trajet .trajet-content [class^=etape-] .btn.resa,
#trajet .trajet-content [class^=etape-] .btn.role,
#trajet .trajet-content [class^=etape-] .btn.participation-tranche,
#trajet .trajet-content [class^=etape-] .btn.periode-tranche,
#trajet .trajet-content [class^=etape-] .btn.btn.participation,
#trajet .trajet-content [class^=etape-] .btn.btn.periode {
  padding: 7px 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 39px;
  height: auto;
}

#trajet .trajet-content [class^=etape-] .btn.chiffre {
  margin: 3px auto !important;
}

#trajet .trajet-content [class^=etape-] .btn.ville-etape {
  background-color: #ccc;
  color: #999;
}

#trajet .trajet-content [class^=etape-] .btn.ville-etape.selected {
  background-color: #50d8af;
  color: #fff;
}

#trajet .trajet-content [class^=etape-] .btn.commune,
#trajet .trajet-content [class^=etape-] .btn.lieu,
#trajet .trajet-content [class^=etape-] .btn.participation-tranche,
#trajet .trajet-content [class^=etape-] .btn.periode-tranche {
  min-width: 75%;
}
#trajet .trajet-content [class^=etape-] .btn.periode {
  min-width: 22%;
}

#trajet .trajet-content [class^=etape-] .note {
  font-size: 16px;
  color: #999;
  font-style: italic;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
}
#trajet .trajet-content [class^=etape-] .asterisque { 
  font-size: 0.7em;
  font-weight: 600;
}

#trajet .trajet-content .resume-voyage {
  text-align: left;
  width: 100%;
}

#trajet .trajet-content .resume-voyage .section,
#trajet .trajet-content .resume-voyage .resume-item {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}
#trajet .trajet-content .resume-voyage .section {
  margin-top: 39px;
}
#trajet .trajet-content .resume-voyage .section h6 {
  color: #50d8af;
}
#trajet .trajet-content .resume-voyage .resume-item.sans-margin-top {
  margin: 0 0 12px 0;
}

#trajet .trajet-content .resume-voyage .resume-item .resume-label {
  display: inline-block;
}
#trajet .trajet-content .resume-voyage .resume-item .resume-label span:first-child {
  margin-right: 12px;
}

#trajet .v-select__selections {
  height: 39px;
}
#trajet .v-list-item__content {
  text-align: center;
}
#trajet .v-list-item__title {
  line-height: 45px;
  vertical-align: auto;
  font-size: 34px;
}

#trajet .trajet-content input,
#trajet .trajet-content select {
  width: 84%;
  border: 1px solid #ccc;
  border-radius: 12px;
  height: 39px;
  margin: 0 auto;
  padding-left: 12px;
}

#trajet .trajet-content input:focus {
  border: 2px solid #17a2b8;
}

#trajet .trajet-content input[type=button] {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
}

.regles.password.container {
  background-color: #e7e7e7;
  font-size: 11px;
  width: 39%;
  margin: 12px auto;
  text-align: left;
}

.regles.password.container .regle {
  width: 100%;
  color: #d53e3e;
}

.regles.password.container .regle.valide {
  color: #3ed54b;
}

@media(max-width: 480px) {  
  #trajet {
    padding: 12px;
  }

  #trajet .trajet-content [class^=etape-] {
    width: auto;
  }
}
</style>


<script>
import listeHoraires from '../util/liste_horaires';
import listePays from '../util/liste_pays';
import ResumeAdresse from '../components/ResumeAdresse.vue';
import AjouterTrajetSaisirAdresse from '../components/AjouterTrajetSaisirAdresse';
import router from '../router';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  components: { ResumeAdresse, AjouterTrajetSaisirAdresse },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      trajet: {
        date: null,
        heure: null,
        adresse_depart: null,
        adresse_arrivee: null,
        adresse_depart_latitude: null,
        adresse_arrivee_latitude: null,
        adresse_depart_longitude: null,
        adresse_arrivee_longitude: null,
        villes_etapes_set: -1,
        nb_places_proposees: 0,
        is_resa_automatique: null,
        is_roles_passagers: null,
        roles_passagers: [0,0,0,0,0],
        montant_contribution: null,
        description: null,
        code_promo: null,
        has_code_promo: -1,
      },
      index_tranche_selectionnee: null,
      index_periode_selectionnee: null,
      villes_etapes: [],
      tranches: [
        [5, 9],
        [10, 19],
        [20, 29],
        [30, 39],
        [40, 49]
      ],
      montant_contribution_min: -1,
      montant_contribution_max: -1,
      loaded: true,
      nb_lieux_affiches: 5,
      descriptionLimit: 60,
      isLoading: false,
      entries_depart: [],
      entries_arrivee: [],
      adresses: {
        depart: {
          cp: '',
          communes: [],
          commune_choisie: {},
          lieux: [],
          autre: false,
        },
        arrivee: {
          cp: '',
          communes: [],
          commune_choisie: {},
          lieux: [],
          autre: false,
        },
      },
      adresse_depart: '',
      search_depart: null,
      adresse_arrivee: '',
      search_arrivee: null,
      liste_horaires: listeHoraires,
      description: '',
      periodes: [],
      bornes: [],
      montants: [],
      toggles: {
        adresse_depart: true,
        adresse_arrivee: true,
        passagers: true,
        v_etapes: true,
        plus_de_passagers: false
      },
      dialog_completer_infos: false,
      utilisateur: {},
      liste_pays: listePays,
      erreurs: {},
      erreur_code_promo: false,
    };
  },
  mounted() {
    // Titre de l'onglet
    document.title = 'Proposez un trajet de covoiturage. Ne voyagez pas seul(e). Partagez une expérience. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';

     if (this.$vuetify.breakpoint.name) {
      var url1 = require("../assets/img/banners/" + this.$vuetify.breakpoint.name + "/top4.webp");
      document.getElementById("trouver-covoiturage-cover")
        .style.backgroundImage = "url(" + url1 + ")";
    }

    this.periodes = [
      {label:'Matin', code:'matin', description:'entre 6h et 11h30'}, 
      {label:'Midi', code:'midi', description:'de 11h45 à 13h45'}, 
      {label:'Après-midi', code:'afternoon',  description:'de 14h et 17h45'}, 
      {label:'Début de soirée', code:'entrelesdeuxjours', description:'de 18h et 20h45'}, 
      {label:'Soirée', code:'soiree', description:'entre 21h et 23h45'}, 
      {label:'Plus tard', code:'nocturne', description:'le lendemain à partir de minuit'}, 
    ];


  },
  computed: {
    ...mapGetters([
      "getProfile"
    ]),
    profile() {
      return this.getProfile;
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    isSaisieTerminee() {
      return this.trajet.date != null
        && this.trajet.heure != null
        && this.trajet.adresse_depart != null
        && this.trajet.adresse_arrivee != null
        && this.trajet.villes_etapes_set >= 0
        && this.trajet.nb_places_proposees != null
        && this.trajet.is_resa_automatique != null
        && this.trajet.is_roles_passagers != null
        && this.trajet.montant_contribution != null
        && this.trajet.description != null
        && (this.trajet.has_code_promo != null && this.trajet.has_code_promo >= 0);
    }
  },
  methods: {
    setAdresse: function(value, type_adresse) {
      if (type_adresse == 'depart') {
        this.adresse_depart = value;
        this.trajet.adresse_depart = value;
        this.trajet.adresse_depart_id = null;
      } else {
        this.adresse_arrivee = value;
        this.trajet.adresse_arrivee = value;
        this.trajet.adresse_arrivee_id = null;
      }
    },
    showCommunes: function(type_adresse) {
      // Uniquement des chiffres
      if (/^\d+$/.test(this.adresses[type_adresse].cp)) {
        if (this.adresses[type_adresse].cp.length == 5) {
          if (this.adresses[type_adresse].cp == '75000'
            || this.adresses[type_adresse].cp == '13000'
            || this.adresses[type_adresse].cp == '69000') {
            this.adresses[type_adresse].cp = this.adresses[type_adresse].cp.slice(0, 4) + '1';
          }

          this.adresses[type_adresse].communes = [];
          this.$root.$emit("start_loading");
          axios.get('https://api-adresse.data.gouv.fr/search/?q='+this.adresses[type_adresse].cp+'&type=municipality&limit=7',
            {
              transformRequest: (_data, headers) => {
                delete headers['Authorization'];
              }
            }
          )
          .then(res => {
              var codeEnregistres = [],
                feature = {};
              for (let i = 0; i < res.data.features.length; i++) {
                feature = res.data.features[i];
                if (codeEnregistres.indexOf(feature.properties.citycode) == -1) {
                  codeEnregistres.push(feature.properties.citycode);
                  this.adresses[type_adresse].communes.push({
                    nom: this.removeArrondissementFromLabel(feature.properties.city),
                    code: feature.properties.citycode,
                    cp: feature.properties.postcode,
                    lat: feature.geometry.coordinates[1],
                    long: feature.geometry.coordinates[0],
                  });
                }
              }
              
              if (this.adresses[type_adresse].communes.length == 1) {
                this.adresses[type_adresse].cp = this.adresses[type_adresse].communes[0].cp;
                this.adresses[type_adresse].lat = this.adresses[type_adresse].communes[0].lat;
                this.adresses[type_adresse].long = this.adresses[type_adresse].communes[0].long;
                this.showAdressesEnregistrees(this.adresses[type_adresse].communes[0], type_adresse);
              }
              this.$root.$emit("hide_loading");
          })
          .catch(err => {
            console.error(err);
            this.$root.$emit("hide_loading");
          });
        } 
      } 
      // l'utilisateur a utilisé des lettres
      else if (this.adresses[type_adresse].cp.length >= 3) { 
        this.$root.$emit("start_loading");
        this.adresses[type_adresse].communes = [];
        axios.get('https://api-adresse.data.gouv.fr/search/?q='+this.adresses[type_adresse].cp+'&type=municipality&limit=7',
          {
            transformRequest: (_data, headers) => {
              delete headers['Authorization'];
            }
          }
        )
        .then(res => {
          this.adresses[type_adresse].communes = [];
          var codeEnregistres = [],
            feature = {};
          for (var i = 0; i < res.data.features.length; i++) {
            feature = res.data.features[i];
            if (codeEnregistres.indexOf(feature.properties.citycode) == -1) {
              codeEnregistres.push(feature.properties.citycode);
              if (feature.properties.score > 0.5) {
                this.adresses[type_adresse].communes.push({
                  nom: feature.properties.city,
                  code: feature.properties.citycode,
                  cp: feature.properties.postcode,
                  lat: feature.geometry.coordinates[1],
                  long: feature.geometry.coordinates[0],
                });
              }
            }
          }

          if (this.adresses[type_adresse].communes.length == 1) {
            this.adresses[type_adresse].cp = this.adresses[type_adresse].communes[0].cp;
            this.adresses[type_adresse].lat = this.adresses[type_adresse].communes[0].lat;
            this.adresses[type_adresse].long = this.adresses[type_adresse].communes[0].long;
            this.showAdressesEnregistrees(this.adresses[type_adresse].communes[0], type_adresse);
          }
          this.$root.$emit("hide_loading");
        })
        .catch(err => {
          console.error(err);
          this.$root.$emit("hide_loading");
        });
      }
    },
    showAdressesEnregistrees: function(commune, type_adresse) {
      this.adresses[type_adresse].commune_choisie = commune;
      // this.adresses[type_adresse].cp = this.adresses[type_adresse].commune_choisie.postcode;
      this.adresses[type_adresse].code = this.adresses[type_adresse].commune_choisie.code;
      this.adresses[type_adresse].lieux = [];

      this.$root.$emit("start_loading");

      // Appel API pour voir les adresses déjà présentes en base de données
      this.$api.get('/adresses?filter[code_commune]=' + commune.code + '&sort=-nb_usages&limit=5')
      .then(res => {
        if (res.data.length == 0) {
          this.adresses[type_adresse].lieux.push({
            id: -1,
            complete: commune.nom + ' (centre ville), ' + commune.cp + ', ' + commune.nom
          });
        } else {
          this.adresses[type_adresse].lieux = res.data.slice(0, this.nb_lieux_affiches);
        }
        // Saisie possible
        this.adresses[type_adresse].lieux.push({
          id: -2,
          complete: 'Saisir une autre adresse ...'
        });
        this.$root.$emit("hide_loading");
      })
      .catch(err => {
        console.error(err);
        this.$root.$emit("hide_loading");
      });
    },
    gererClicLieu: function(lieu, type_adresse) {
      if (lieu.id == -2) {
        this.adresses[type_adresse].autre = true;
      } else {
        this.trajet['adresse_' + type_adresse] = this['adresse_' + type_adresse] = lieu.complete;
        this.trajet['adresse_' + type_adresse + '_id'] = this['adresse_' + type_adresse + '_id'] = lieu.id;
        this.trajet['adresse_' + type_adresse + '_latitude'] = this.adresses[type_adresse].lat;
        this.trajet['adresse_' + type_adresse + '_longitude'] = this.adresses[type_adresse].long;
      }

      if (this.adresses.depart.cp && !/^\d+$/.test(this.adresses.depart.cp)) {
        this.adresses.depart.cp = this.adresses.depart.commune_choisie.cp;
      }
      if (this.adresses.arrivee.cp && !/^\d+$/.test(this.adresses.arrivee.cp)) {
        this.adresses.arrivee.cp = this.adresses.arrivee.commune_choisie.cp;
      }

      // Si les deux communes sont disponibles,
      // Appel API pour connaitre les bornes du prix par rapport à ces communes
      // TRES IMPORTANT
      if (this.adresses.depart.commune_choisie.code && this.adresses.arrivee.commune_choisie.code) {
        
        // Mise à jour des latitudes et longitudes
        this.trajet.adresse_depart_latitude = this.adresses.depart.commune_choisie.lat;
        this.trajet.adresse_depart_longitude = this.adresses.depart.commune_choisie.long;
        this.trajet.adresse_arrivee_latitude = this.adresses.arrivee.commune_choisie.lat;
        this.trajet.adresse_arrivee_longitude = this.adresses.arrivee.commune_choisie.long;
        
        this.$root.$emit("start_loading");

        this.$api.get('/bornes/' + this.adresses.depart.commune_choisie.code + '/' + this.adresses.arrivee.commune_choisie.code) 
        .then(res => {
          this.montant_contribution_min = res.data.min;
          this.montant_contribution_max = res.data.max;

          if (res.data.villes_json && res.data.villes_json != '') {

            this.villes_etapes = JSON.parse(res.data.villes_json);
            // 
            if (this.villes_etapes[0].nom.toLowerCase() == this.adresses.depart.commune_choisie.nom.toLowerCase()) {
              this.villes_etapes = this.villes_etapes.slice(1);
            }
            // 
            if (this.villes_etapes[this.villes_etapes.length - 1].nom.toLowerCase() == this.adresses.arrivee.commune_choisie.nom.toLowerCase()) {
              this.villes_etapes = this.villes_etapes.slice(0, -1);
            }
            this.villes_etapes = this.villes_etapes.map(v => { v.selected = 0; return v; });

            this.$root.$emit("hide_loading");
          } 
          else {

            // villes etapes
            this.$root.$emit("start_loading");
            let _coordinates = '&start='+ this.trajet.adresse_depart_longitude + ',' + this.trajet.adresse_depart_latitude;
              _coordinates += '&end='+ this.trajet.adresse_arrivee_longitude + ',' + this.trajet.adresse_arrivee_latitude;
  
            axios.get('https://wxs.ign.fr/calcul/geoportail/itineraire/rest/1.0.0/route?resource=bdtopo-pgr&profile=car&optimization=fastest' + _coordinates + '&intermediates=&geometryFormat=geojson&getSteps=true&getBbox=true&waysAttributes=cleabs',
              {
                transformRequest: (_data, headers) => {
                  delete headers['Authorization'];
                }
              }
            )
            .then(res => {
              let steps = res.data.portions[0].steps,
                nbSteps = steps.length,
                latLongs = [];
              
              for (let i = 48; i<nbSteps-48; i += 24) {
                var c = steps[i].geometry.coordinates[0];
                latLongs.push([c[0], c[1]]);
              }

              if (latLongs.length > 0) {
                // Mise à jours des villes étapes
                var bodyFormData = new FormData();
                bodyFormData.append('coordonnees', latLongs);
                
                this.$root.$emit("start_loading");
                
                this.$api.post('/bornes/' + this.adresses.depart.commune_choisie.code + '/' + this.adresses.arrivee.commune_choisie.code, bodyFormData)
                .then(res => {
                  this.villes_etapes = res.data;
                  // 
                  if (this.villes_etapes[0].nom.toLowerCase() == this.adresses.depart.commune_choisie.nom.toLowerCase()) {
                    this.villes_etapes = this.villes_etapes.slice(1);
                  }
                  // 
                  if (this.villes_etapes[this.villes_etapes.length - 1].nom.toLowerCase() == this.adresses.arrivee.commune_choisie.nom.toLowerCase()) {
                    this.villes_etapes = this.villes_etapes.slice(0, -1);
                  }
                  this.villes_etapes = this.villes_etapes.map(v => { v.selected = 0; return v; });
                  this.$root.$emit("hide_loading");
                })
                .catch(err => { 
                  console.error(err);
                  this.$root.$emit("hide_loading");
                  this.trajet.villes_etapes_set = 0;
                });
              }
            })
            .catch(err => { 
              this.trajet.villes_etapes_set = 0;
              this.$root.$emit("hide_loading");
              console.error(err);
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.$root.$emit("hide_loading")
        });
      }

    },
    setNbPlacesProposees: function(nbPlaces) {
      this.trajet.nb_places_proposees = nbPlaces;
    },
    getContributionRange: function() {
      var res = [];
      for (let i = this.montant_contribution_min; i <= this.montant_contribution_max; i++) {
        res.push(i);
      }
      return res;
    },
    getContributionRangeTaille: function() {
      return this.getContributionRange().length;
    },
    aucuneVillesSelectionnee: function() {
      return !this.villes_etapes.some(ville => { return ville.selected > 0 });
    },
    validerVillesEtapes: function() {
      this.trajet.villes_etapes = this.villes_etapes.filter(ville => {
        return ville.selected > 0;
      });

      this.bornes = [];
      
      this.trajet.villes_etapes.map((ville, index) => {
        const nomVilleDepart = this.removeArrondissementFromLabel(
          index === 0 ? this.adresses.depart.commune_choisie.nom : this.trajet.villes_etapes[index - 1].nom
        );

        ville.nom = this.removeArrondissementFromLabel(ville.nom);

        this.$api.get('/bornes/' + nomVilleDepart + '/' + ville.nom) 
        .then(res => {
          this.bornes[index] = {};
          this.bornes[index].depart = nomVilleDepart;
          this.bornes[index].arrivee = ville.nom;
          this.bornes[index].min = res.data.min;
          this.bornes[index].max = res.data.max;
          this.montants[index] = Math.round((res.data.max + res.data.min + 1) / 2);

          // Dernier sous trajet de la dernière ville à la destination finale
          if (index == this.trajet.villes_etapes.length - 1) {
            this.$api.get('/bornes/' + ville.nom + '/' + this.removeArrondissementFromLabel(this.adresses.arrivee.commune_choisie.nom)) 
            .then(res => {
              this.bornes[index + 1] = {};
              this.bornes[index + 1].depart = ville.nom;
              this.bornes[index + 1].arrivee = this.removeArrondissementFromLabel(this.adresses.arrivee.commune_choisie.nom);
              this.bornes[index + 1].min = res.data.min;
              this.bornes[index + 1].max = res.data.max;
              this.montants[index + 1] = Math.round((res.data.max + res.data.min + 1) / 2);
            })
          }
        })
      });

      this.trajet.villes_etapes_set = 1;
    },
    decMontant: function(index) {
      if (this.montants[index] > this.bornes[index].min) {
        this.montants[index]--;
        this.montants = Object.assign([], this.montants);
      }
    },
    incMontant: function(index) {
      if (this.montants[index] < this.bornes[index].max) {
        this.montants[index]++;
        this.montants = Object.assign([], this.montants);
      }
    },
    isDownToMin: function(index) {
      return this.montants[index] <= this.bornes[index].min;
    },
    isUpToMin: function(index) {
      return this.montants[index] > this.bornes[index].min;
    },
    isDownToMax: function(index) {
      return this.montants[index] < this.bornes[index].max;
    },
    isUpToMax: function(index) {
      return this.montants[index] >= this.bornes[index].max;
    },
    validerMontants: function() {
      this.trajet.montant_contribution = Math.floor((this.montant_contribution_min + this.montant_contribution_max + 1) / 2);
    },
    changeSelection: function(index) {
      this.villes_etapes[index].selected = (this.villes_etapes[index].selected + 1) % 2;
      this.villes_etapes = Object.assign([], this.villes_etapes);
    },
    checkCodePromo: function() {
      this.erreur_code_promo = false;
      this.$api.get('/code-promo/' + this.trajet.code_promo)
      .then(res => {
        console.log(res.data);
        if (res.data && res.data.status) { 
          this.trajet.has_code_promo = 2;
        } else {
          this.erreur_code_promo = true;
        }
      });
    },
    back: function() {
      if (this.trajet.description != null) {
        this.trajet.description = null;
      } 
      else if (this.trajet.code_promo != null || Math.abs(this.trajet.has_code_promo) % 2) {
        this.trajet.code_promo = null;
        this.trajet.has_code_promo = null;
        this.erreur_code_promo = false;
      } 
      else if (this.trajet.montant_contribution != null) {
        this.trajet.montant_contribution = null;
      } 
      else if (this.index_tranche_selectionnee != null) {
        this.index_tranche_selectionnee = null;
      }
      else if (this.trajet.is_roles_passagers != null) {
        this.trajet.is_roles_passagers = null;
        this.trajet.roles_passagers = [0,0,0,0,0];
      }
      else if (this.trajet.is_resa_automatique != null) {
        this.trajet.is_resa_automatique = null;
      }
      else if (this.trajet.nb_places_proposees > 0) {
        this.trajet.nb_places_proposees = 0;
      }
      else if (this.trajet.villes_etapes_set >= 0) {
        this.trajet.villes_etapes_set = -1; 
      }
      else if (this.trajet.adresse_arrivee != null) {
        this.trajet.adresse_arrivee = null;
        this.trajet.adresse_arrivee_latitude = null;
        this.trajet.adresse_arrivee_longitude = null;
        this.adresses.arrivee.autre = false;
      } 
      else if (this.adresses.arrivee.lieux.length > 0
        || (this.adresses.arrivee.commune_choisie != {} && this.adresses.arrivee.commune_choisie.nom != null)
        || this.adresses.arrivee.communes.length > 0) {
        this.trajet.adresse_arrivee = null;
        this.trajet.adresse_arrivee_latitude = null;
        this.trajet.adresse_arrivee_longitude = null;
        this.adresses.arrivee.autre = false;
        this.adresses.arrivee.lieux = [];
        this.adresses.arrivee.commune_choisie = {};
        this.adresses.arrivee.communes = [];
      } 
      else if (this.adresses.arrivee.cp.length > 1) {
        this.adresses.arrivee.cp = '';
      } 
      else if (this.trajet.adresse_depart != null) {
        this.trajet.adresse_depart = null;
        this.trajet.adresse_depart_latitude = null;
        this.trajet.adresse_depart_longitude = null;
        this.adresses.depart.autre = false;
      } 
      else if (this.adresses.depart.lieux.length > 0
        || (this.adresses.depart.commune_choisie != {} && this.adresses.depart.commune_choisie.nom != null)
        || this.adresses.depart.communes.length > 0) {
        this.adresses.depart.commune_choisie = {};
        this.trajet.adresse_depart = null;
        this.trajet.adresse_depart_latitude = null;
        this.trajet.adresse_depart_longitude = null;
        this.adresses.depart.autre = false;
        this.adresses.depart.lieux = [];
        this.adresses.depart.communes = [];
      } 
      else if (this.adresses.depart.cp.length > 1) {
        this.adresses.depart.cp = '';
      } 
      else if (this.trajet.heure != null) {
        this.trajet.heure = null;
      } 
      else if (this.index_periode_selectionnee != null) {
        this.index_periode_selectionnee = null;
      } 
      else if (this.trajet.date != null) {
        this.trajet.date = null;
      } 
      else {
        console.log('do nothing Yet !');
      } 
    },
    enregistrerInformationsConducteur: function () {
      
      this.erreurs.adresse = !this.utilisateur.adresse;
      this.erreurs.nationalite = !this.utilisateur.nationalite;
      this.erreurs.pays_residence = !this.utilisateur.pays_residence;
      this.erreurs.ville = !this.utilisateur.ville;
      this.erreurs.code_postal = !this.utilisateur.code_postal;

      if (
        !this.erreurs.adresse
        && !this.erreurs.nationalite 
        && !this.erreurs.pays_residence
        && !this.erreurs.ville
        && !this.erreurs.code_postal
      ) {

        var bodyFormData = new FormData();
        bodyFormData.append('nationalite', this.utilisateur.nationalite);
        bodyFormData.append('pays_residence', this.utilisateur.pays_residence);
        if (this.utilisateur.adresse) {
          bodyFormData.append('adresse', this.utilisateur.adresse);
        }
        if (this.utilisateur.code_postal) {
          bodyFormData.append('code_postal', this.utilisateur.code_postal);
        }
        if (this.utilisateur.ville) {
          bodyFormData.append('ville', this.utilisateur.ville);
        }

        this.$api.post('/utilisateurs/' + this.profile.id + '/info', bodyFormData)
        .then(() => {
          this.dialog_completer_infos = false;
          setTimeout(() => {
            this.publier();
          }, 100);
        })
        .catch(err => {
          console.error(err);
        });
      }
    },
    removeArrondissementFromLabel(nomCommune) {
      if (nomCommune.toLowerCase().includes("arrondissement")) {
        nomCommune = nomCommune.split(' ')[0];
      }
      return nomCommune
    },
    publier: function() {
      this.$root.$emit("start_loading");
      
      // Récupération des informations complémentaires relatives à l'utilisateur
      this.$api.get('/utilisateurs/' + this.profile.id + '/info')
      .then(res => {
        // Relation one-to-on dans le modèle de données
        // Si ces informations obligatoire ne sont pas remplies, 
        // on déduit qu'il s'agit d'une première visite (ou modification)
        if (res.data.length === 0) {
          this.dialog_completer_infos = true;
          this.$root.$emit("hide_loading");
        }
        // Autrement on procède à l'enregistrement
        else {
          var bodyFormData = new FormData();
          bodyFormData.append('date', this.trajet.date);
          bodyFormData.append('heure', this.trajet.heure);

          if (this.trajet.adresse_depart_id != null) {
            if (this.trajet.adresse_depart_id > 0) {
              bodyFormData.append('adresse_depart_id', this.trajet.adresse_depart_id);
            } else if (this.trajet.adresse_depart_id === -1) {
              this.trajet.adresse_depart += ', ' + this.adresses.depart.commune_choisie.cp + ', ' + this.adresses.depart.commune_choisie.nom;
            }
          }

          if (this.trajet.adresse_arrivee_id != null) {
            if (this.trajet.adresse_arrivee_id > 0) {
              bodyFormData.append('adresse_arrivee_id', this.trajet.adresse_arrivee_id);
            } else if (this.trajet.adresse_arrivee_id === -1) {
              this.trajet.adresse_arrivee += ', ' + this.adresses.arrivee.commune_choisie.cp + ', ' + this.adresses.arrivee.commune_choisie.nom;
            }
          }
          // 
          bodyFormData.append('adresse_depart', this.trajet.adresse_depart);
          bodyFormData.append('adresse_arrivee', this.trajet.adresse_arrivee);
          bodyFormData.append('adresse_depart_latitude', this.trajet.adresse_depart_latitude);
          bodyFormData.append('adresse_arrivee_latitude', this.trajet.adresse_arrivee_latitude);
          bodyFormData.append('adresse_depart_longitude', this.trajet.adresse_depart_longitude);
          bodyFormData.append('adresse_arrivee_longitude', this.trajet.adresse_arrivee_longitude);
          bodyFormData.append('nb_places_proposees', this.trajet.nb_places_proposees);
          bodyFormData.append('is_resa_automatique', this.trajet.is_resa_automatique);
          bodyFormData.append('is_roles_passagers', this.trajet.is_roles_passagers);
          bodyFormData.append('roles_passagers', this.trajet.roles_passagers.join(','));
          bodyFormData.append('montant_contribution', this.trajet.montant_contribution);

          if (this.trajet.villes_etapes_set == 1) {
            bodyFormData.append('villes_etapes', JSON.stringify(this.bornes));
            bodyFormData.append('montants_etapes', JSON.stringify(this.montants));
          }

          // la description
          if (this.trajet.description) {
            bodyFormData.append('description', this.trajet.description);
          }

          bodyFormData.append('is_publie', 1);

          this.$api.post('/trajets', bodyFormData)
          .then(res => {
            if (res.data.id) {
              this.trajet.id = res.data.id;
              this.trajet.publie = true;

              if (this.trajet.code_promo) {
                
                var bodyFormDataPromo = new FormData();
                bodyFormDataPromo.append(
                  'code_promo', 
                  this.trajet.code_promo
                );
                bodyFormDataPromo.append(
                  'trajet_id', 
                  this.trajet.id
                );

                this.$api.post('/code-promo', bodyFormDataPromo)
                .then(() => {
                  console.log("Code pris en compte !");
                })
                .catch(err => {
                  console.log("Code non pris en compte !", err);                  
                })
                .finally(() => {
                  this.$root.$emit("hide_loading");
                  router.push({
                    path: `/covoiturage/${this.trajet.id}?shareNow=1`
                  })
                });
              } 
              else {
                router.push({
                  path: `/covoiturage/${this.trajet.id}?shareNow=1`
                });
              }
            }
            else {
              this.$api.erreur('401');
            }
          })
          .catch(err => {
            this.$api.erreur('401');
            console.error(err);
          })
          .finally(() => this.$root.$emit("hide_loading"));
        }
      });
    },
  }
}
</script>