<template>
    <div>
        <div class="section pointer" v-show="adresses[typeAdresse].cp.length == 5 
            || (adresses[typeAdresse].commune_choisie && adresses[typeAdresse].commune_choisie.nom) 
            || trajet['adresse_' + typeAdresse]"
            v-on:click="toggles['adresse_' + typeAdresse] = !toggles['adresse_' + typeAdresse]">
            <h6>
                Adresse de {{ typeAdresse == 'depart' ? 'départ' : 'destination' }}
                <span class="mdi mdi-numeric-3-box" v-if="trajet['adresse_' + typeAdresse]"></span>
                <span class="mdi mdi-numeric-2-box" v-else-if="adresses[typeAdresse].commune_choisie && adresses[typeAdresse].commune_choisie.nom"></span>
                <span class="mdi mdi-numeric-1-box" v-else-if="adresses[typeAdresse].cp.length == 5"></span>
                <span class="mdi mdi-numeric-0-box" v-else></span>
                <span>/</span>
                <span class="mdi mdi-numeric-3-box"></span>
            </h6>
            <span class="mdi mdi-chevron-right" v-show="!toggles['adresse_' + typeAdresse]"></span>
            <span class="mdi mdi-chevron-up" v-show="toggles['adresse_' + typeAdresse]"></span>
        </div>
        <div class="resume-item sans-margin-top" v-show="toggles['adresse_' + typeAdresse] && adresses[typeAdresse].cp.length == 5">
            <div class="resume-label">
                <span class="mdi" :class="{
                    'mdi-home-circle': typeAdresse == 'depart', 
                    'mdi-home-map-marker': typeAdresse == 'arrivee'
                }"></span>
                <span>Code Postal :</span>
            </div>
            <div>{{ adresses[typeAdresse].cp }}</div>
            <div v-on:click="adresses[typeAdresse].cp = '';
                adresses[typeAdresse].communes = [];
                adresses[typeAdresse].commune_choisie = {};
                adresses[typeAdresse].lieux = [];
                trajet['adresse_' + typeAdresse] = null;" 
                class="mdi mdi-pencil pointer">
            </div>
        </div>
        <div class="resume-item" v-show="toggles['adresse_' + typeAdresse] && adresses[typeAdresse].commune_choisie.nom">
            <div class="resume-label">
                <span class="mdi" :class="{
                    'mdi-home-circle': typeAdresse == 'depart', 
                    'mdi-home-map-marker': typeAdresse == 'arrivee'
                }"></span>
                <span>Commune :</span>
            </div>
            <div>{{ adresses[typeAdresse].commune_choisie.nom }}</div>
            <div v-on:click="adresses[typeAdresse].commune_choisie = {};
                adresses[typeAdresse].lieux = [];
                trajet['adresse_' + typeAdresse] = null;" 
                v-if="adresses[typeAdresse].communes && adresses[typeAdresse].communes.length > 1"
                class="mdi mdi-pencil pointer">
            </div>
            <div v-else>&nbsp;</div>
        </div>
        <div class="resume-item" v-show="toggles['adresse_' + typeAdresse] && trajet['adresse_' + typeAdresse]">
            <div class="resume-label" v-if="typeAdresse == 'depart'">
                <span class="mdi mdi-home-circle"></span>
                <span>Les retrouvailles :</span>
            </div>
            <div class="resume-label" v-if="typeAdresse == 'arrivee'">
                <span class="mdi mdi-home-map-marker"></span>
                <span>Le Terminus :</span>
            </div>
            <div>{{ trajet['adresse_' + typeAdresse] }}</div>
            <div v-on:click="trajet['adresse_' + typeAdresse] = null" class="mdi mdi-pencil pointer"></div>
        </div>
    </div>
</template>


<script>
export default {
  name: 'resume-adresse',
  props: [
    'typeAdresse',
    'adresses',
    'toggles',
    'trajet',
  ],
  data() {
    return {}
  }
}
</script>

<style>

</style>