<template>
  <div>
    <!--
      Différents types de cartes:
      - Covoiturage-simple : SIMPLE
      - Ancien covoiturage conducteur : CONDUCTEUR
      - Ancien covoiturage passager : PASSAGER

    -->
    <v-card
      class="mx-auto carte-covoiturage"
      :class="{ 'disponible': covoiturage.computed.nb_places_disponibles > 0, 'indisponible': typeCarte != 'SIMPLE' || covoiturage.computed.nb_places_disponibles <= 0 }"
      shaped
      v-show="info_message && info_message != ''"
    >
      <v-card-title style="word-break: normal">

        <v-row
          align="center"
          justify="center"
        >
        <div class="title font-weight-light texte"> {{ info_message }}</div>

        </v-row>
      </v-card-title>
    </v-card>

    <v-card
      class="mx-auto carte-covoiturage"
      :class="{ 'disponible': covoiturage.computed.nb_places_disponibles > 0, 'indisponible': typeCarte != 'SIMPLE' || covoiturage.computed.nb_places_disponibles <= 0 }"
      shaped
      v-show="!info_message || info_message == ''"
    >
      <v-card-title style="word-break: normal">

        <v-row
          align="center"
          justify="space-around"
        >
          <v-col
            cols="3"
            lg="3"
            md="3"
            sm="3"
            class="text-center"
            >
            <v-list-item-avatar
              size="64px"
              style="margin: 7px">
              <img :src="covoiturage.conducteur.avatar | avatar"
                v-show="covoiturage.conducteur.avatar && !avatars_defaut_conducteur" 
                @error="avatars_defaut_conducteur = true" 
                style="width: 64px; height: 64px;" />
              <img 
                src="@/assets/img/profil/profil_defaut.jpeg" 
                v-show="!covoiturage.conducteur.avatar || avatars_defaut_conducteur" 
                style="width: 64px; height: 64px;" />
            </v-list-item-avatar>
            <div class="title font-weight-light texte text-center" style="width:72px; margin:auto;">
              <span>{{ covoiturage.conducteur.prenom | premiereLettreCapitale }}</span><br />
              <!--<span class="age">{{ covoiturage.conducteur.date_naissance | dateToAge }} ans</span><br />-->
              <span v-if="typeCarte == 'SIMPLE'">
                <span v-for="n in 5" class="mdi mdi-star etoiles" :class="{ 'coloree' : !covoiturage.conducteur.note || n <= covoiturage.conducteur.note }" :key="n"></span>
              </span>
            </div>
          </v-col>
          <v-col
            cols="6"
            lg="6"
            md="6"
            sm="6" 
            >
            <v-list class="texte"
              v-if="typeCarte == 'SIMPLE'"
            >
              <v-list-item class="grow">
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col cols="12" class="title font-weight-light texte text-center">
                    <div>
                      <span class="hidden-sm-and-down">{{ covoiturage.date | formatDate }}</span>
                    </div>
                    <div>
                      <span>{{ covoiturage.computed.nb_places_disponibles }} place{{ covoiturage.computed.nb_places_disponibles > 1 ? 's' : ''}}</span>
                      <span class="hidden-sm-and-down"> disponible{{ covoiturage.computed.nb_places_disponibles > 1 ? 's' : ''}}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item class="grow">
                <v-row
                  align="center"
                  justify="center"
                >
                  <div  
                    class="pictos">
                    <span class="boule" :class="{'actif': covoiturage.is_resa_automatique != 0}">
                      <v-icon v-if="covoiturage.is_resa_automatique != 0" class="mr-1">mdi-flash-auto</v-icon>
                      <v-icon v-if="covoiturage.is_resa_automatique == 0" class="mr-1">mdi-flash-off</v-icon>
                      <span style="font-size: 9px !important;">RESA AUTO</span>
                    </span>
                  </div>
                  <div  
                    class="pictos">
                    <span class="boule"
                      :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[3] == 1}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-1"
                            v-bind="attrs"
                            v-on="on">
                            mdi-soccer
                          </v-icon>
                        </template>
                        <span>Foot {{ covoiturage.is_roles_passagers == 1 && roles_passagers[3] == 1 ? 'passager' : '' }}</span>
                      </v-tooltip>
                      <span style="font-size: 9px !important;">FOOT</span>
                    </span>
                  </div>
                  <div  
                    class="pictos">
                    <span class="boule"
                      :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[0] == 1}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-1"
                            v-bind="attrs"
                            v-on="on">
                            mdi-sign-direction
                          </v-icon>
                        </template>
                        <span>Co-pilote {{ covoiturage.is_roles_passagers == 1 && roles_passagers[0] == 1 ? 'passager' : '' }}</span>
                      </v-tooltip>
                      <span style="font-size: 9px !important;">CO-PIL</span>
                    </span>
                  </div>
                  <div  
                    class="pictos">
                    <span class="boule"
                      :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[1] == 1}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-1"
                            v-bind="attrs"
                            v-on="on">
                            mdi-microphone
                          </v-icon>
                        </template>
                        <span>Journaliste {{ covoiturage.is_roles_passagers == 1 && roles_passagers[1] == 1 ? 'passager' : '' }}</span>
                      </v-tooltip>
                      <span style="font-size: 9px !important;">BLA BLA</span>
                    </span>
                  </div>
                  <div 
                    class="pictos">
                    <span class="boule"
                      :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[2] == 1}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-1"
                            v-bind="attrs"
                            v-on="on">
                            mdi-music-note-bluetooth
                          </v-icon>
                        </template>
                        <span>DJ {{ covoiturage.is_roles_passagers == 1 && roles_passagers[2] == 1 ? 'passager' : '' }}</span>
                      </v-tooltip>
                      <span style="font-size: 9px !important;">DJ</span>
                    </span>
                  </div>
                  <!--
                  <div  
                    class="pictos">
                    <span class="boule"
                      :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[3] == 1}">
                      <v-icon
                        class="mr-1" 
                        :alt="'Game master ' + covoiturage.is_roles_passagers == 1 && roles_passagers[3] == 1 ? 'passager' : 'non défini'">
                        mdi-gamepad-variant
                      </v-icon>
                    </span>
                  </div>
                  -->
                </v-row>
              </v-list-item>
            </v-list>
            
            <v-list class="texte"
              v-else>
              <v-list-item class="grow">
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col cols="12">
                    <button class="btn center large"
                      v-show="(typeCarte == 'CONDUCTEUR' || typeCarte == 'PASSAGER')"
                      :disabled="!editable"
                      v-on:click="gotoDetails()">
                      Voir l'annonce
                    </button>
                    <div class="title text-center">{{ covoiturage.date | formatDate }}</div>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            cols="3"
            lg="3"
            md="3"
            sm="3" 
            >
            <div 
              v-show="typeCarte == 'PASSAGER'"
              class="title font-weight-bold prix-carte-covoiturage"
            >
              <span>{{ prix_paye | price }} &euro;</span><br />
              <div style="margin-top: 16px; font-size: 0.57em; line-height: 1.6em" v-show="covoiturage.nb_places_reservees > 1">
                <span>{{ covoiturage.nb_places_reservees }} x {{ prix_public | price }} &euro;</span><br />
                <span>- {{ remise }} &euro; de remise</span>
              </div>
            </div>
            <div 
              v-show="typeCarte == 'CONDUCTEUR'"
              class="title font-weight-bold prix-carte-covoiturage"
            >
              {{ covoiturage.montant_contribution | price }} &euro;
            </div>
            <div 
              v-show="typeCarte == 'SIMPLE'"
              class="title font-weight-bold prix-carte-covoiturage"
            >
              {{ prix_public | price }} &euro;
            </div>

            <!-- Chat button -->
            <v-row
              align="center"
              justify="center">

              <!-- Bouton chat -->
              <v-col
                cols="6"
                v-if="editable && is_passager"
              >
                <button class="btn center bleu-clair"
                  @click="openChat()"
                  style="padding: 7px 12px; margin-top: 9px; z-index:7;">
                  <span class="mdi mdi-chat"></span> &nbsp; Chat 
                </button>
              </v-col>

              <!-- Simple -->
              <v-col
                cols="6" 
                v-show="typeCarte == 'SIMPLE'"
              >
                <button class="btn center "
                  @click="gotoDetails()"
                  :disabled="!editable"
                  style="padding: 7px 12px; margin-top: 9px; z-index:7;">
                  <span class="mdi mdi-chevron-right"></span> &nbsp; Détails 
                </button>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="headline font-weight-normal">
        <v-row
          align="center"
          justify="center">
          <v-col cols="5" class="text-right">
            <span class="ville">{{ covoiturage.adresse_depart | ville }}</span>
            <i class="ecart-distance proche" v-if="covoiturage.distance_depart && covoiturage.distance_depart >= 6.25 && covoiturage.distance_depart < 12.5"> (&plusmn; 20 km)</i>
            <i class="ecart-distance loin" v-else-if="covoiturage.distance_depart && covoiturage.distance_depart >= 12.5 && covoiturage.distance_depart < 18.75"> (&plusmn; 30 km)</i>
            <i class="ecart-distance loin" v-else-if="covoiturage.distance_depart && covoiturage.distance_depart >= 18.75 && covoiturage.distance_depart < 25"> (&plusmn; 40 km)</i>
            <i class="ecart-distance tres-loin" v-else-if="covoiturage.distance_depart && covoiturage.distance_depart >= 25 && covoiturage.distance_depart < 31.25"> (&plusmn; 50 km)</i>
            <i class="ecart-distance tres-loin" v-else-if="covoiturage.distance_depart && covoiturage.distance_depart >= 31.25 && covoiturage.distance_depart < 37.5"> (&plusmn; 60 km)</i>
            <br />
            <span class="heure">{{ covoiturage.heure | heure }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <i class="mdi mdi-arrow-right" />
          </v-col>
          <v-col cols="5" class="text-left">
            <span class="ville">{{ covoiturage.adresse_arrivee | ville }}</span>
            <i class="ecart-distance proche" v-if="covoiturage.distance_arrivee && covoiturage.distance_arrivee >= 6.25 && covoiturage.distance_arrivee < 12.5"> (&plusmn; 20 km)</i>
            <i class="ecart-distance loin" v-else-if="covoiturage.distance_arrivee && covoiturage.distance_arrivee >= 12.5 && covoiturage.distance_arrivee < 18.75"> (&plusmn; 30 km)</i>
            <i class="ecart-distance loin" v-else-if="covoiturage.distance_arrivee && covoiturage.distance_arrivee >= 18.75 && covoiturage.distance_arrivee < 25"> (&plusmn; 40 km)</i>
            <i class="ecart-distance tres-loin" v-else-if="covoiturage.distance_arrivee && covoiturage.distance_arrivee >= 25 && covoiturage.distance_arrivee < 31.25"> (&plusmn; 50 km)</i>
            <i class="ecart-distance tres-loin" v-else-if="covoiturage.distance_arrivee && covoiturage.distance_arrivee >= 31.25 && covoiturage.distance_arrivee < 37.5"> (&plusmn; 60 km) {{ covoiturage.distance_arrivee }}</i>
            <br />
            <span class="heure" v-if="heure_arrivee">{{ heure_arrivee }}</span>
            <span class="heure" v-else>***</span>
          </v-col>
        </v-row>

        
        <!-- Gestion conducteur -->
        <v-row
          align="center"
          justify="center"
          v-show="editable && profile && profile.id > 0 && typeCarte == 'CONDUCTEUR'">
          <!--
          <v-col 
            cols="12"
            lg="4"
            md="6"
            sm="12">
            <button class="btn center large"
              :disabled="false"
              v-on:click="function() { return false;}">
              <span class="mdi mdi-car"></span> &nbsp; Modifier le trajet
            </button>
          </v-col>
          -->
          <v-col 
            cols="12"
            lg="4"
            md="6"
            sm="12">
            <button class="btn center large"
              v-on:click="dialog_gestion_passager = true">
              <span class="mdi mdi-account-multiple-outline"></span> &nbsp; Gestion passagers
            </button>
          </v-col>
          <v-col 
            cols="12"
            lg="4"
            md="6"
            sm="12">
            <button class="btn center large vert"
              v-on:click="dialog_partage = true">
              <span class="mdi mdi-whatsapp"></span> &nbsp; Partager le trajet
            </button>
          </v-col>
          <v-col 
            cols="12"
            lg="4"
            md="6"
            sm="12"
            v-show="!covoiturage.parent_id">
            <button class="btn center large rouge"
              v-on:click="dialog = true">
              <span class="mdi mdi-close"></span> &nbsp; Annuler le trajet
            </button>
          </v-col>
        </v-row>
        
        <!-- Gestion passager -->
        <v-row
          align="center"
          justify="center"
          v-show="editable && profile && profile.id > 0 && typeCarte == 'PASSAGER'">
          <v-col 
            cols="12"
            lg="3"
            md="6"
            sm="12">
            <button class="btn center large"
              v-on:click="dialog_message = true">
              <span class="mdi mdi-message-text"></span> &nbsp; Envoyer un message
            </button>
          </v-col>
          <v-col 
            cols="12"
            lg="3"
            md="6"
            sm="12">
            <button class="btn center large"
              disabled="disabled"
              v-on:click="function() { return false;}">
              <span class="mdi mdi-account-multiple-outline"></span> &nbsp; Choisir un rôle
            </button>
            <!--
            <button class="btn center large"
              :disabled="covoiturage.is_roles_passagers == 0"
              v-on:click="function() { return false;}">
              <span class="mdi mdi-account-multiple-outline"></span> &nbsp; Choisir un rôle
            </button>
            -->
          </v-col>
          <v-col 
            cols="12"
            lg="3"
            md="6"
            sm="12">
            <button class="btn center large vert"
              v-on:click="dialog_partage = true">
              <span class="mdi mdi-whatsapp"></span> &nbsp; Partager le trajet
            </button>
          </v-col>
          <v-col 
            cols="12"
            lg="3"
            md="6"
            sm="12">
            <button class="btn center large rouge"
              v-on:click.stop="dialog = true">
              <span class="mdi mdi-close"></span> &nbsp; Annuler la réservation
            </button>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="480"
      >
        <v-card>
          <v-card-title class="headline" style="word-break: normal">
            Voulez-vous vraiment supprimer votre {{ typeCarte == 'CONDUCTEUR' ? 'trajet' : 'réservation' }} ?
          </v-card-title>

          <v-card-text
            v-if="typeCarte == 'PASSAGER'"
          >
            Il se pourrait qu'une partie du montant soit retenu en fonction de notre politique d'annulation et de remboursement.
          </v-card-text>
          <v-card-text
            v-else
          >
            Vous perdrez les réservations déjà effectuées sur votre trajet si c'est le cas.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Non, je renonce
            </v-btn>

            <v-btn
              color="green red-1"
              text
              @click="annulerTrajet()"
            >
              Oui, j'annule
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog_annulation_succes"
        max-width="480"
      >
        <v-card>
          <v-card-title class="headline" style="word-break: normal">
            <span class="mdi mdi-check-circle-outline"></span>Votre {{ typeCarte == 'CONDUCTEUR' ? 'trajet' : 'réservation' }} a bien été {{ typeCarte == 'CONDUCTEUR' ? 'annulé' : 'annulée' }}.
          </v-card-title>

          <v-card-text
            v-if="typeCarte == 'PASSAGER'"
          >
            Nous vous confirmons l'annulation de votre réservation. Nous espérons que vous profiterez à nouveau de notre service.
          </v-card-text>
          <v-card-text
            v-else
          >
            Nous vous confirmons l'annulation de votre trajet. N'hésitez pas à utiliser notre plateforme pour proposer d'autres trajets à la prochaine occasion.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="dialog_annulation_succes = false"
            >
              C'est noté, merci.
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Réservation Message -->
      <v-dialog
        v-model="dialog_message"
        max-width="480"
      >
        <v-card>
          <v-card-title
            class="headline grey lighten-2" 
            style="word-break: normal">
            Votre message
          </v-card-title>

          <v-card-text>
            <textarea 
              class="textarea-message" 
              v-model="message" 
              placeholder="Bonjour, ..." 
              maxlength="255">
            </textarea>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="envoyerMessage()"
            >
              Envoyer un message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Réservation gestion passager -->
      <v-dialog
        v-model="dialog_gestion_passager"
        max-width="480"
      >
        <v-card>
          <v-card-title
            class="headline grey lighten-2" 
            style="word-break: normal">
            Gérer vos passagers
          </v-card-title>

          <v-card-text>
            <v-tabs
              v-model="tab"
              background-color="primary lighten-2 accent-4"
              centered
              dark
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                Résas ({{ covoiturage.passagers.length }})
                <v-icon>mdi-account-box</v-icon>
              </v-tab>

              <v-tab href="#tab-2">
                Messages ({{ messages_trajet.length }})
                <v-icon>mdi-mail</v-icon>
              </v-tab>

              <v-tab href="#tab-3">
                Rôles
                <v-icon>mdi-heart</v-icon>
              </v-tab>

              <v-tab href="#tab-4">
                Nb. places
                <v-icon>mdi-numeric</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="i in 4"
                :key="i"
                :value="'tab-' + i"
              >
                <!-- Réservation -->
                <v-card flat class="text-center" v-if="i == 1">
                  <v-row class="sans-margin-top" v-if="covoiturage.passagers.length > 0">
                    <v-col
                      cols="12"
                      lg="3"
                      md="3"
                      sm="4"
                      v-for="(passager, index) in covoiturage.passagers" :key="passager.id">
                      <v-list-item-avatar
                        size="70px">
                        <img 
                          :src="passager.avatar | avatar" 
                          v-show="passager.avatar && !avatars_defaut_passagers[index]" 
                          @error="avatars_defaut_passagers[index] = true"
                          style="width: 70px" />
                        <img 
                          src="@/assets/img/profil/profil_defaut.jpeg" 
                          v-show="!passager.avatar || avatars_defaut_passagers[index]" 
                          style="width: 70px" />
                      </v-list-item-avatar>
                    </v-col>
                  </v-row>
                  <v-row class="sans-margin-top" v-if="covoiturage.passagers.length > 0">
                    <v-col
                      cols="12"
                      lg="3"
                      md="3"
                      sm="4"
                      v-for="passager in covoiturage.passagers" :key="passager.id">
                      <div class="font-weight-light">
                        <span>{{ passager.prenom | premiereLettreCapitale }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="covoiturage.passagers.length == 0">
                    <v-col cols="12">
                      <span class="font-weight-light">Pas encore de réservation</span>  
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Messages -->
                <v-card flat class="text-center" v-if="i == 2">
                  <v-row class="sans-margin-top" v-if="messages_trajet.length > 0">
                    <v-col
                      cols="12"
                      v-for="(mt, index) in messages_trajet" :key="mt.id">
                      <v-row align="start">
                        <div 
                          style="width: 18%;"
                          v-if="mt.expediteur.id != profile.id">
                        </div>
                        <div 
                          style="width: 12%; margin-right: 6px;"
                          v-if="mt.expediteur.id != profile.id">
                          <img 
                            :src="mt.expediteur.avatar | avatar" 
                            v-show="mt.expediteur.avatar && !avatars_defaut_passagers[index]" 
                            @error="avatars_defaut_passagers[index] = true"
                            style="width: 36px; border-radius: 50%" />
                          <img 
                            src="@/assets/img/profil/profil_defaut.jpeg" 
                            v-show="!mt.expediteur.avatar || avatars_defaut_passagers[index]" 
                            style="width: 36px; border-radius: 50%" />
                          <span>{{ mt.expediteur.prenom | premiereLettreCapitale }}</span>
                        </div>
                        <div 
                          class="message-trajet-bulle" 
                          :class="{ 'vous': mt.expediteur.id == profile.id, 'expediteur': mt.expediteur.id != profile.id }">
                          <div>{{ mt.message }}</div>
                        </div>
                        <div 
                          style="width: 12%; margin-left: 6px;"
                          v-if="mt.expediteur.id == profile.id">
                          <img 
                            :src="profile.avatar | avatar" 
                            v-show="profile.avatar && !avatars_defaut_conducteur" 
                            @error="avatars_defaut_conducteur = true"
                            style="width: 36px; border-radius: 50%" />
                          <img 
                            src="@/assets/img/profil/profil_defaut.jpeg" 
                            v-show="!profile.avatar || avatars_defaut_conducteur" 
                            style="width: 36px; border-radius: 50%" />
                          <span>Vous</span>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="messages_trajet.length == 0">
                    <v-col cols="12">
                      <span class="font-weight-light">Pas de message.</span>  
                    </v-col>
                  </v-row>
                </v-card>
                
                <!-- Rôles -->
                <v-card flat class="text-center" v-if="i == 3">
                  <v-row class="sans-margin-top">
                    <v-col
                      cols="12">
                      <span>Choisissez si vous souhaitez que les passagers s'attribuent des rôles pendant le trajet.</span>
                      <v-switch 
                        v-model="roles_passagers_activation"
                        :label="`${roles_passagers_activation ? 'Rôles passagers activés' : 'Rôles passagers désactivé'}`"
                        :disabled="loading"
                        :loading="loading">
                      </v-switch>
                      
                      <v-row
                        align="center"
                        justify="center"
                        style="margin: 12px auto 25px"
                      >
                        <div>Voici la liste des rôles</div>
                      </v-row>

                      <v-row
                        align="center"
                        justify="center"
                      >
                        <div  
                          class="pictos pointer"
                          @click="toogleRole('CO-PILOTE')">
                          <span class="boule"
                            :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[0] == 1}">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  mdi-sign-direction
                                </v-icon>
                              </template>
                              <span>Co-pilote {{ covoiturage.is_roles_passagers == 1 && roles_passagers[0] == 1 ? 'passager' : '' }}</span>
                            </v-tooltip>
                            <span style="font-size: 9px !important;">CO-PILOTE {{ covoiturage.is_roles_passagers == 1 && roles_passagers[0] == 1 ? 'ACTIV&Eacute;' : 'DESACTIV&Eacute;' }}</span>
                          </span>
                        </div>
                        <div  
                          class="pictos pointer"
                          @click="toogleRole('JOURNALISTE')">
                          <span class="boule"
                            :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[1] == 1}">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  mdi-microphone
                                </v-icon>
                              </template>
                              <span>Journaliste {{ covoiturage.is_roles_passagers == 1 && roles_passagers[1] == 1 ? 'passager' : '' }}</span>
                            </v-tooltip>
                            <span style="font-size: 9px !important;">JOURNALISTE {{ covoiturage.is_roles_passagers == 1 && roles_passagers[1] == 1 ? 'ACTIV&Eacute;' : 'DESACTIV&Eacute;' }}</span>
                          </span>
                        </div>
                        <div 
                          class="pictos pointer"
                          @click="toogleRole('DJ')">
                          <span class="boule"
                            :class="{'actif': covoiturage.is_roles_passagers == 1 && roles_passagers[2] == 1}">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  mdi-music-note-bluetooth
                                </v-icon>
                              </template>
                              <span>DJ {{ covoiturage.is_roles_passagers == 1 && roles_passagers[2] == 1 ? 'passager' : '' }}</span>
                            </v-tooltip>
                            <span style="font-size: 9px !important;">DJ {{ covoiturage.is_roles_passagers == 1 && roles_passagers[2] == 1 ? 'ACTIV&Eacute;' : 'DESACTIV&Eacute;' }}</span>
                          </span>
                        </div>
                      </v-row>

                    </v-col>
                  </v-row>
                </v-card>
                
                <!-- Nb places -->
                <v-card flat class="text-center" v-if="i == 4">
                  <v-row class="sans-margin-top">
                    <v-col
                      cols="12">
                      
                      <div class="input-group flex">
                  
                        <span class="note">Vous pouvez modifier le nombre de places avant la première réservation confirmée.</span>

                        <div 
                          class="btn centre"
                          :class="{'chiffre-actif': covoiturage.nb_places_proposees == chiffre, 'chiffre': covoiturage.nb_places_proposees != chiffre , 'chiffre-inactif': loading }"
                          v-for="chiffre in 8"
                          :key="chiffre"
                          v-show="chiffre < 5"
                          @click="toogleNbPlaces(chiffre)"
                          style="width: 75px">
                          {{ chiffre }}
                        </div>
                    </div>

                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_gestion_passager = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog_partage"
        max-width="480"
      >
        <v-card>
          <v-card-title class="headline" style="word-break: normal">
            Partagez votre trajet à votre entourage via les réseaux sociaux
          </v-card-title>

          <v-card-text>
            <v-row justify="center">
              <ShareNetwork
                class="btn share whatsapp"
                network="whatsapp"
                :url="'https://www.simoneverte.fr/covoiturage/' + covoiturage.id"
                :description="typeCarte == 'PASSAGER' ? sharedMessagePassager : sharedMessageConducteur"
                title="*SimoneVerte - Info Covoiturage*"
                quote="SimoneVerte le covoiturage longue distance à petits prix."
                hashtags="simoneverte,covoiturage"
              >
                <span class="mdi mdi-whatsapp"></span> &nbsp; Partager sur WhatsApp
              </ShareNetwork>
            </v-row>
            <v-row justify="center">
              <ShareNetwork
                class="btn share facebook"
                network="facebook"
                :url="'https://www.simoneverte.fr/covoiturage/' + covoiturage.id"
                :description="typeCarte == 'PASSAGER' ? sharedMessagePassager : sharedMessageConducteur"
                title="SimoneVerte - Info Covoiturage"
                quote="SimoneVerte le covoiturage longue distance à petits prix."
                hashtags="simoneverte,covoiturage"
              >
                <span class="mdi mdi-facebook"></span> &nbsp; Partager sur Facebook
              </ShareNetwork>
            </v-row>
            <v-row justify="center">
              <ShareNetwork
                class="btn share telegram"
                network="telegram"
                :url="'https://www.simoneverte.fr/covoiturage/' + covoiturage.id"
                :description="typeCarte == 'PASSAGER' ? sharedMessagePassager : sharedMessageConducteur"
                title="SimoneVerte - Info Covoiturage"
                quote="SimoneVerte le covoiturage longue distance à petits prix."
                hashtags="simoneverte,covoiturage"
              >
                <span class="mdi mdi-telegram"></span> &nbsp; Partager sur Telegram
              </ShareNetwork>
            </v-row>
            <v-row justify="center">
              <ShareNetwork
                class="btn share email"
                network="email"
                :url="'https://www.simoneverte.fr/covoiturage/' + covoiturage.id"
                :description="typeCarte == 'PASSAGER' ? sharedMessagePassager : sharedMessageConducteur"
                title="SimoneVerte - Info Covoiturage"
                quote="SimoneVerte le covoiturage longue distance à petits prix."
                hashtags="simoneverte,covoiturage"
              >
                <span class="mdi mdi-email"></span> &nbsp; Partager par Email
              </ShareNetwork>
            </v-row>
            <v-row justify="center">
              <ShareNetwork
                class="btn share sms"
                network="sms"
                :url="'https://www.simoneverte.fr/covoiturage/' + covoiturage.id"
                :description="typeCarte == 'PASSAGER' ? sharedMessagePassager : sharedMessageConducteur"
                title="SimoneVerte - Info Covoiturage"
                quote="SimoneVerte le covoiturage longue distance à petits prix."
                hashtags="simoneverte,covoiturage"
              >
                <span class="mdi mdi-message-text"></span> &nbsp; Partager par SMS
              </ShareNetwork>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="dialog_partage = false"
            >
              Merci
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import router from '../router';
import constantes from '../constantes';
import { mapGetters } from 'vuex';

export default {
  name: 'carte-covoiturage',
  props: [
    'typeCarte',
    'covoiturage'
  ],
  data() {
    //
    return {
      dialog: false,
      dialog_message: false,
      dialog_partage: false,
      dialog_gestion_passager: false,
      dialog_annulation_succes: false,
      chatbot_open: true,
      message: '',
      info_message: '',
      avatars_defaut_conducteur: false,
      avatars_defaut_passagers:[
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      messages_trajet: [],
      tab: null,
      roles_passagers_activation: false,
      loading: false,
    }
  },
  mounted() {
    this.typeCarte = this.typeCarte ? this.typeCarte : 'SIMPLE';
    var icons = document.querySelectorAll('.v-stepper__step__step i');
    for (var i=0; i<icons.length; i++) {
      icons[i].classList.replace('mdi-check', i % 2 == 0 ? 'mdi-map-marker' : 'mdi-flag-variant');
    }

    this.roles_passagers_activation = this.covoiturage.is_roles_passagers == 1;

    this.$api.get('/trajets/' + this.covoiturage.id + '/messages')
    .then(res => {
      
      if (res.data.data) {
        this.messages_trajet = res.data.data;
      }
    })
    .catch(err => console.error(err));
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated"
    ]),
    profile() {
      return this.getProfile;
    },
    prix_public() {
      return Math.round(constantes.autres_fees * 100 + this.covoiturage.montant_contribution * (1 + constantes.pourcentage_fees) * 100) / 100;
    },
    remise() {
      let r = (this.covoiturage.nb_places_reservees - 1) * constantes.montant_retrait_degressif;
      return r < 1 ? r : 1; 
    },
    prix_paye() {
      return this.prix_public * this.covoiturage.nb_places_reservees - this.remise;
    },
    roles_passagers() {
      return this.covoiturage.is_roles_passagers == 1 && this.covoiturage.roles_passagers ? this.covoiturage.roles_passagers.split(',') : undefined;
    },
    heure_arrivee() {
      if (this.covoiturage.duree_secondes) {
        return this.$options.filters.dureeToHeure(
          parseInt(this.$options.filters.heureToDuree(this.covoiturage.heure)) 
          + parseInt(this.covoiturage.duree_secondes)
        );
      }
      return null;
    },
    sharedMessagePassager() {
      return 'Bonjour ! J\'ai réservé mon covoiturage de ' 
        + this.covoiturage.adresses.depart.ville + ' vers ' 
        + this.covoiturage.adresses.arrivee.ville + ' sur SimoneVerte'
        + ' - (prix: ' + this.$options.filters.price(this.prix_public) 
        + ' €, date: ' + this.$options.filters.formatDate(this.covoiturage.date) 
        + '). Si tu es intéressé(e), rejoins-nous. À bientôt.';
    },
    sharedMessageConducteur() {
      return 'Bonjour ! Je propose un trajet en covoiturage ' 
        + this.covoiturage.adresses.depart.ville + ' vers ' 
        + this.covoiturage.adresses.arrivee.ville + ' sur SimoneVerte'
        + ' - (prix: ' + this.$options.filters.price(this.prix_public) 
        + ' €, date: ' + this.$options.filters.formatDate(this.covoiturage.date) 
        + '). Si tu es intéressé(e), rejoins-nous. À bientôt !';
    },
    editable() {
      return this.covoiturage.is_effectue == 0 && moment(this.covoiturage.date).isSameOrAfter(moment(), 'day');
    },
    is_passager() {
      if (this.covoiturage.passagers && this.covoiturage.passagers.length > 0) {
        return this.isAuthenticated && this.covoiturage.passagers
          .filter(passager => passager.id == this.profile.id)
          .length > 0;
      }
      return this.isAuthenticated && this.covoiturage.conducteur.id == this.profile.id;
    }
  },
  watch: {
    roles_passagers_activation(value) {
      if (value != undefined) {
        // Création du request body utile pour les appels methode PUT url-encoded 
        const requestBody = {
          is_roles_passagers: value === true ? 1 : 0
        };

        if (requestBody.is_roles_passagers != this.covoiturage.is_roles_passagers) {
          this.loading = true;

          axios({
            method: 'put',
            url: constantes.apiBaseUrl + '/trajets/' + this.covoiturage.id,
            data: new URLSearchParams(requestBody),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
          .then(() => {
            this.covoiturage.is_roles_passagers = requestBody.is_roles_passagers;
          })
          .finally(() => this.loading = false);
        }
      }
    }
  },
  methods: {
    openChat: function() {
      this.$root.$emit("set_chatbotconfiguration_open", {
        titre: "Covoiturage " + this.$options.filters.ville(this.covoiturage.adresse_depart) 
          + " - " + this.$options.filters.ville(this.covoiturage.adresse_arrivee),
        channel_trajet_id: this.covoiturage.id,
      });
    },
    toogleNbPlaces: function(nb) {
      if (nb != this.covoiturage.nb_places_proposees) {
        // Création du request body utile pour les appels methode PUT url-encoded 
        const requestBody = {
          nb_places_proposees: nb
        };

        this.loading = true;
        axios({
          method: 'put',
          url: constantes.apiBaseUrl + '/trajets/' + this.covoiturage.id,
          data: new URLSearchParams(requestBody),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .then(() => {
          this.covoiturage.nb_places_proposees = requestBody.nb_places_proposees;
        })
        .finally(() => this.loading = false);
      }
    },
    toogleRole: function(roleName) {
      var roles = this.covoiturage.roles_passagers.split(',');
      var index = -1;
      var rolesStr = '';

      switch(roleName) {
        case 'CO-PILOTE':
          index = 0;
          break;
        case 'JOURNALISTE':
          index = 1;
          break;
        case 'DJ':
          index = 2;
          break;
        default:
          index = -1;
          break;
      }

      roles[index] = Math.abs(roles[index] - 1);
      rolesStr = roles.join(',');
      
      // Création du request body utile pour les appels methode PUT url-encoded 
      const requestBody = {
        roles_passagers: rolesStr
      };

      if (requestBody.roles_passagers != this.covoiturage.roles_passagers) {
          this.loading = true;

          axios({
            method: 'put',
            url: constantes.apiBaseUrl + '/trajets/' + this.covoiturage.id,
            data: new URLSearchParams(requestBody),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
          .then(() => {
            this.covoiturage.roles_passagers = requestBody.roles_passagers;
          })
          .finally(() => this.loading = false);
        }
    },
    envoyerMessage: function() {
      this.dialog_message = false;
      if (this.message != '') {
        var bodyFormData = new FormData();
        bodyFormData.append('trajet_id', this.covoiturage.id);
        bodyFormData.append('utilisateur_id', this.profile.id);
        bodyFormData.append('destinataire_id', this.covoiturage.conducteur.id);
        bodyFormData.append('objet', "Demande d'information");
        bodyFormData.append('message', this.message);
        bodyFormData.append('is_public', 1);

        this.$root.$emit("start_loading");

        this.$api.post(
          '/trajets/' + this.covoiturage.id + '/messages', 
          bodyFormData
        )
        .then(() => {
          this.info_message = 'Votre message a bien été envoyé ! Une notification vous sera adressée lorsque vous recevrez une réponse.';
          this.message = '';
          setTimeout(() => {
            this.info_message = '';
          }, 9000);
        })
        .catch(err => console.error(err))
        .finally(() => this.$root.$emit("hide_loading"));
      }
    },
    gotoDetails: function() {
      if (this.covoiturage.computed.nb_places_disponibles > 0) {
        router.push({ path: '/covoiturage/' + this.covoiturage.id });
      }
    },
    annulerTrajet: function() {
      
      var bodyFormData = new FormData();
      bodyFormData.append('trajet_id', this.covoiturage.id);
      if (this.typeCarte == 'PASSAGER') {
        bodyFormData.append('passager_id', this.covoiturage.passager_id);
      }
      this.$root.$emit("start_loading");

      this.$api.post(
        '/annulations', 
        bodyFormData
      )
      .then(() => {
        
        this.info_message = 'Votre ' + (this.typeCarte == 'PASSAGER' ? 'réservation' : 'trajet') 
          + ' a bien été ' + (this.typeCarte == 'PASSAGER' ? 'annulée' : 'annulé')
          + ' ! Une notification vient de vous être envoyé.';
        this.covoiturage.actif = 0;
        this.dialog_annulation_succes = true;
        
        //
        this.$root.$emit(
          "succes_annulation_trajet",
          this.covoiturage.id
        );

        setTimeout(() => {
          this.info_message = '';
          this.dialog_annulation_succes = false;
        }, 9000);
      })
      .catch(err => console.error(err))
      .finally(() => {
        this.dialog = false;
        this.$root.$emit("hide_loading");
      });
    }
  }
}
</script>

<style>
.carte-covoiturage {
  margin-bottom: 25px;
  z-index: 3;
}
.carte-covoiturage.displonible {
  cursor: pointer;
}
.carte-covoiturage.indisponible {
  cursor: default;
}

.carte-covoiturage .v-stepper {
  box-shadow: none;
}

.carte-covoiturage .v-stepper .v-stepper__header .v-divider.complet {
    border-color: #50d8af;
    border-width: 2px;
    text-align: center;
    content: 'Jésus';
}

.carte-covoiturage .texte span,
.carte-covoiturage .texte i {
  font-size: 16px;
}

.carte-covoiturage .etoiles.coloree {
  color: yellow;
  font-size: 12px;
}

.carte-covoiturage .pictos .v-icon,
.v-dialog .pictos .v-icon {
  color: #ddd;
  font-size: 12px;
}

.carte-covoiturage .pictos .boule,
.v-dialog .pictos .boule {
  background-color: white;
  color: #aaa;
  border: 2px solid;
  height: 25px;
  width: 52px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin: 4px;
  font-size: 9px !important;
  font-weight: 600;
  letter-spacing: 0.18em;
  line-height: 9px;
  word-break: normal;
}

.v-dialog .pictos .boule {
  width: 180px;
}

.carte-covoiturage .pictos .boule.actif .v-icon,
.carte-covoiturage .pictos .boule.actif,
.v-dialog .pictos .boule.actif .v-icon,
.v-dialog .pictos .boule.actif {
  color: #4c4;
}

.chiffre-actif {
  background-color: #4c4 !important;
  cursor: pointer;
}
.chiffre-inactif {
  background-color: #ccc !important;
  cursor: normal;
}
.chiffre {
  background-color: #17a2b8;
  cursor: pointer;
}
.trace {
  width:100%;
  border-bottom: 1px solid #4c4;
  height: 5px;
  border-radius: 3px;
  background-color: #50d8af;
  font-size: 2em;
}
.trace span {
  position: relative;
  top: -25px;
  color: #50d8af;
}
.trace span.pic-depart {
  left: -40%;
}
.trace span.pic-arrivee {
  right: -40%;
}
.prix-carte-covoiturage {
  color: #17a2b8;
}
.heure,
.ville {
  font-weight: bold;
}
.ville {
  font-size: 16px;
}
.heure {
  font-size: 14px;
}
.ecart-distance {
  font-size: 0.63rem;
  letter-spacing: 2px;
}
.ecart-distance.proche {
 color: #17a2b8;
}
.ecart-distance.loin {
  color: orange;
}
.ecart-distance.tres-loin {
  color: #ff2c76;
}
a.v-tab {
  font-size: 0.72em;
}
.message-trajet-bulle {
  padding: 7px;
  width: 61%; 
  border-radius: 9px; 
}
.message-trajet-bulle.expediteur {
  border: 1px solid #619; 
  background-color: #cdf;
}
.message-trajet-bulle.vous {
  border: 1px solid #691;
  background-color: #cfd;
}
.v-card__title {
  padding: 7px;
}
.v-card__title + .v-card__subtitle, .v-card__title + .v-card__text {
  padding: 0;
}

@media(max-width: 480px) {  
  .carte-covoiturage .texte span,
  .carte-covoiturage .texte i {
    font-size: 12px;
  }
  .heure,
  .ville {
    font-size: 0.63rem;
    letter-spacing: 2px;
  }
  a.v-tab {
    font-size: 0.70em;
  }
}
</style>