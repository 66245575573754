const listeHoraires = [
    // Matin
    {"moment": "matin", "index_moment": 0, "heure": "06:00"},
    {"moment": "matin", "index_moment": 0, "heure": "06:15"},
    {"moment": "matin", "index_moment": 0, "heure": "06:30"},
    {"moment": "matin", "index_moment": 0, "heure": "06:45"},
    {"moment": "matin", "index_moment": 0, "heure": "07:00"},
    {"moment": "matin", "index_moment": 0, "heure": "07:15"},
    {"moment": "matin", "index_moment": 0, "heure": "07:30"},
    {"moment": "matin", "index_moment": 0, "heure": "07:45"},
    {"moment": "matin", "index_moment": 0, "heure": "08:00"},
    {"moment": "matin", "index_moment": 0, "heure": "08:15"},
    {"moment": "matin", "index_moment": 0, "heure": "08:30"},
    {"moment": "matin", "index_moment": 0, "heure": "08:45"},
    {"moment": "matin", "index_moment": 0, "heure": "09:00"},
    {"moment": "matin", "index_moment": 0, "heure": "09:15"},
    {"moment": "matin", "index_moment": 0, "heure": "09:30"},
    {"moment": "matin", "index_moment": 0, "heure": "09:45"},
    {"moment": "matin", "index_moment": 0, "heure": "10:00"},
    {"moment": "matin", "index_moment": 0, "heure": "10:15"},
    {"moment": "matin", "index_moment": 0, "heure": "10:30"},
    {"moment": "matin", "index_moment": 0, "heure": "10:45"},
    {"moment": "matin", "index_moment": 0, "heure": "11:00"},
    {"moment": "matin", "index_moment": 0, "heure": "11:15"},
    {"moment": "matin", "index_moment": 0, "heure": "11:30"},
    // Midi
    {"moment": "midi", "index_moment": 1, "heure": "11:45"},
    {"moment": "midi", "index_moment": 1, "heure": "12:00"},
    {"moment": "midi", "index_moment": 1, "heure": "12:15"},
    {"moment": "midi", "index_moment": 1, "heure": "12:30"},
    {"moment": "midi", "index_moment": 1, "heure": "12:45"},
    {"moment": "midi", "index_moment": 1, "heure": "13:00"},
    {"moment": "midi", "index_moment": 1, "heure": "13:15"},
    {"moment": "midi", "index_moment": 1, "heure": "13:30"},
    {"moment": "midi", "index_moment": 1, "heure": "13:45"},
    // Après midi
    {"moment": "apresmidi", "index_moment": 2, "heure": "14:00"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "14:15"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "14:30"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "14:45"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "15:00"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "15:15"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "15:30"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "15:45"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "16:00"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "16:15"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "16:30"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "16:45"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "17:00"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "17:15"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "17:30"},
    {"moment": "apresmidi", "index_moment": 2, "heure": "17:45"},
    // le soir
    {"moment": "soir", "index_moment": 3, "heure": "18:00"},
    {"moment": "soir", "index_moment": 3, "heure": "18:15"},
    {"moment": "soir", "index_moment": 3, "heure": "18:30"},
    {"moment": "soir", "index_moment": 3, "heure": "18:45"},
    {"moment": "soir", "index_moment": 3, "heure": "19:00"},
    {"moment": "soir", "index_moment": 3, "heure": "19:15"},
    {"moment": "soir", "index_moment": 3, "heure": "19:30"},
    {"moment": "soir", "index_moment": 3, "heure": "19:45"},
    {"moment": "soir", "index_moment": 3, "heure": "20:00"},
    {"moment": "soir", "index_moment": 3, "heure": "20:15"},
    {"moment": "soir", "index_moment": 3, "heure": "20:30"},
    {"moment": "soir", "index_moment": 3, "heure": "20:45"},
    // la nuit
    {"moment": "nuit", "index_moment": 4, "heure": "21:00"},
    {"moment": "nuit", "index_moment": 4, "heure": "21:15"},
    {"moment": "nuit", "index_moment": 4, "heure": "21:30"},
    {"moment": "nuit", "index_moment": 4, "heure": "21:45"},
    {"moment": "nuit", "index_moment": 4, "heure": "22:00"},
    {"moment": "nuit", "index_moment": 4, "heure": "22:15"},
    {"moment": "nuit", "index_moment": 4, "heure": "22:30"},
    {"moment": "nuit", "index_moment": 4, "heure": "22:45"},
    {"moment": "nuit", "index_moment": 4, "heure": "23:00"},
    {"moment": "nuit", "index_moment": 4, "heure": "23:15"},
    {"moment": "nuit", "index_moment": 4, "heure": "23:30"},
    {"moment": "nuit", "index_moment": 4, "heure": "23:45"},
    // 
    {"moment": "nuit", "index_moment":5, "heure": "00:00"},
    {"moment": "nuit", "index_moment":5, "heure": "00:15"},
    {"moment": "nuit", "index_moment":5, "heure": "00:30"},
    {"moment": "nuit", "index_moment":5, "heure": "00:45"},
    {"moment": "nuit", "index_moment":5, "heure": "01:00"},
    {"moment": "nuit", "index_moment":5, "heure": "01:15"},
    {"moment": "nuit", "index_moment":5, "heure": "01:30"},
    {"moment": "nuit", "index_moment":5, "heure": "01:45"},
    {"moment": "nuit", "index_moment":5, "heure": "02:00"},
    {"moment": "nuit", "index_moment":5, "heure": "02:15"},
    {"moment": "nuit", "index_moment":5, "heure": "02:30"},
    {"moment": "nuit", "index_moment":5, "heure": "02:45"},
    {"moment": "nuit", "index_moment":5, "heure": "03:00"},
    {"moment": "nuit", "index_moment":5, "heure": "03:15"},
    {"moment": "nuit", "index_moment":5, "heure": "03:30"},
    {"moment": "nuit", "index_moment":5, "heure": "03:45"},
    {"moment": "nuit", "index_moment":5, "heure": "04:00"},
    {"moment": "nuit", "index_moment":5, "heure": "04:15"},
    {"moment": "nuit", "index_moment":5, "heure": "04:30"},
    {"moment": "nuit", "index_moment":5, "heure": "04:45"},
    {"moment": "nuit", "index_moment":5, "heure": "05:00"},
    {"moment": "nuit", "index_moment":5, "heure": "05:15"},
    {"moment": "nuit", "index_moment":5, "heure": "05:30"},
    {"moment": "nuit", "index_moment":5, "heure": "05:45"},
];
export default listeHoraires;