<template>
  <v-container id="intro">
    <div class="intro-content" style="padding: 6px;">
      <h2>Le service WhatsApp rétabli [25/10/2022] à 10:52</h2>
      <p style="text-align: left">
        <b>10:52</b> A priori le service est rétabli.      
      </p>
      <p style="text-align: left">
        <b>10:06</b> Ce mardi 25 octobre 2022, l'application de messagerie instantanée 
        <a href="https://www.whatsapp.com/" target="_blank">WhatsApp</a> connaît des dysfonctionnements majeurs
        qui l'empèche de fonctionner actuellement. Les équipes de Meta travaillent à un rétablissement du service
        au plus vite.      
      </p>
    </div>
  </v-container>
</template>

<script>
export default {
  mounted() {
    var url = require("../assets/img/banners/md/amitie-1.jpg");
    document.querySelector('#fond').style.backgroundImage = "url(" + url + ")";
  }
}
</script>